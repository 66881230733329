import { useState, ChangeEvent, useEffect } from "react";
import { AppendixCombinedWithPolicy, InsuranceAppendix, shrinkCombinedObjectToAppendix } from "../../../interfaces/InsuranceAppendix"
import { formatDate } from "../../../utils/formatDate"
import { FamilyMember } from "../../../interfaces/Client"
import { getClientFamilyMembers } from "../../../utils/getClientFamilyMembers"
import { editInsurancePolicy } from "../../../utils/api/insurancePolicy";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { InsuranceAppendixRecommendation } from "../../../interfaces/InsuranceAppendix"
import { deleteInsuranceAppendix } from "../../../utils/api/insuranceAppendix";
import CreateOrEditAppendixModal from "./insuranceCreationAndEdit/AppendixCreationAndEditingModal";
import { createNewAppendix, editExistingAppendix } from "./insuranceCreationAndEdit/appendixAndPolicyCreationFunctions";
import { useProductsContext } from "./insuranceProductsContext";
import { useTranslation } from "react-i18next";
import { camelCaseToText } from "../../../utils/camelCaseToText";
import Swal from 'sweetalert2';
import EditableInput from "../../../components/EditableInput";
import { InsurancePolicyStatuses } from "../../../interfaces/InsurancePolicy";
import { FormInput } from "../../../components";

const noDataFound = () => {
  return <>---</>
}

export const PolicyHolderColumn = ({ row: { original: policy } }: { row: { original: AppendixCombinedWithPolicy } }) => {
  const { client } = useProductsContext();
  const familyMembers = getClientFamilyMembers(client, true);
  const [selectedHolder, setSelectedHolder] = useState<string>(policy.policyHolder?.idNumber || 'None');
  const { insurancePolicies, setInsurancePolicies } = useProductsContext();

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedIdNumber = event.target.value;
    let policyHolder = { name: '', idNumber: '' };
    if (selectedIdNumber !== 'None') {
      const selectedMember = familyMembers.find((member) => member.idNumber === selectedIdNumber);
      if (selectedMember) {
        policyHolder = {
          name: `${selectedMember.firstName ?? ''} ${selectedMember.lastName ?? ''}`,
          idNumber: selectedMember.idNumber ?? '',
        };
      }
    }
    editInsurancePolicy(policy.policyId, { policyHolder })
      .then(() => {
        const newInsurancePolicies = insurancePolicies.map((insurancePolicy) => insurancePolicy._id === policy.policyId ? { ...insurancePolicy, policyHolder } : insurancePolicy);
        setInsurancePolicies(newInsurancePolicies);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    setSelectedHolder(policy.policyHolder?.idNumber || 'None');
  }, [policy.policyHolder?.idNumber])

  return (
    <select onChange={handleSelectChange} onClick={e => e.stopPropagation()} value={selectedHolder}>
      <option value="None">None</option>
      {familyMembers.map((member: FamilyMember) => (
        <option key={member.idNumber} value={member.idNumber}>
          {member.firstName} {member.lastName}
        </option>
      ))}
    </select>
  );
};


export const PolicyNumberColumn = ({ row: { original: policy } }: { row: { original: AppendixCombinedWithPolicy } }) => {
  const { insurancePolicies, setInsurancePolicies } = useProductsContext();
  if (!policy.isNew) {
    return <>{policy.policyNumber}</>
  }
  const policyNumber = policy.policyNumber ? policy.policyNumber.toString() : 'N/A';
  const validation = (value: string) => {
    return value.length > 0 && value.length <= 40;
  }

  const handlePolicyNumberChange = (value: string) => {
    editInsurancePolicy(policy.policyId, { policyNumber: value })
      .then(() => {
        const newInsurancePolicies = insurancePolicies.map((insurancePolicy) => insurancePolicy._id === policy.policyId ? { ...insurancePolicy, policyNumber: value } : insurancePolicy);
        setInsurancePolicies(newInsurancePolicies);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return <EditableInput value={policyNumber} onChange={handlePolicyNumberChange} validate={validation} />
}

export const PolicyCompanyNameColumn = ({ row: { original: policy } }: { row: { original: AppendixCombinedWithPolicy } }) => {
  if (!policy.company) {
    return noDataFound()
  }
  // if (typeof policy.company === 'object') {
  //   const company = policy.company as any;
  //   return <>{company.name}</>
  // }

  return <>{policy.company}</>
}

export const PolicyMainBranchColumn = ({ row: { original: policy } }: { row: { original: AppendixCombinedWithPolicy } }) => {
  const { t } = useTranslation("insurance")
  if (!policy.mainBranch) {
    return noDataFound()
  }
  return <>{t(camelCaseToText(policy.mainBranch))}</>
}

export const PolicyPlanTypeColumn = ({ row: { original: policy } }: { row: { original: AppendixCombinedWithPolicy } }) => {
  if (!policy.planType) {
    return noDataFound()
  }
  return <>{policy.planType}</>
}

// export const PolicyTotalPremiumColumn = ({ row: { original: policy } }: { row: { original: AppendixCombinedWithPolicy } }) => {
//   if (!policy.totalPremiumAfterChanges && policy.totalPremiumAfterChanges !== 0) {
//     return noDataFound()
//   }
//   const totalPremiumBeforeChanges = policy.totalPremiumBeforeChanges !== undefined ? policy.totalPremiumBeforeChanges : policy.totalPremiumAfterChanges;
//   const changeInTotalPremium = policy.totalPremiumAfterChanges - totalPremiumBeforeChanges;
//   const sign = changeInTotalPremium > 0 ? '+' : '';
//   const changeInTotalPremiumPercentage = (policy.totalPremiumAfterChanges / totalPremiumBeforeChanges) * 100;
//   const totalPremiumChangesInfoTooltip = (
//     <Tooltip id="totalPremiumChangesInfo-tooltip">
//       Total Before Changes: <span className='text-success'>{totalPremiumBeforeChanges || 'N/A'}</span>
//       <br />
//       Changes: <span className='text-success'>{sign}{changeInTotalPremium.toFixed(2)}</span>
//       <br />
//       Changes Percentage: <span className='text-success'>{changeInTotalPremiumPercentage.toFixed(2)}%</span>
//     </Tooltip>
//   );
//   return (
//     <OverlayTrigger placement="top" overlay={totalPremiumChangesInfoTooltip}>
//       <span className='cursor-pointer' >
//         {policy.totalPremiumAfterChanges}
//       </span>
//     </OverlayTrigger>
//   )
// }

export const PolicyStatusColumn = ({ row: { original: policy } }: { row: { original: AppendixCombinedWithPolicy } }) => {
  const [status, setStatus] = useState<InsurancePolicyStatuses>(policy.status);
  const { insurancePolicies, setInsurancePolicies } = useProductsContext();

  if (!policy.status || !policy.isNew) {
    return noDataFound()
  }
  const handleChange = async (event: ChangeEvent<HTMLSelectElement>) => {
    const newStatus = event.target.value as InsurancePolicyStatuses;
    try {
      await editInsurancePolicy(policy.policyId, { status: newStatus });
      setStatus(newStatus);
      const newInsurancePolicies = insurancePolicies.map((insurancePolicy) => insurancePolicy._id === policy.policyId ? { ...insurancePolicy, status: newStatus } : insurancePolicy);
      setInsurancePolicies(newInsurancePolicies);
    }
    catch (error) {
      console.log(error);
    }
  }

  return <>
    <select value={status} onClick={(e: any) => e.stopPropagation()} onChange={handleChange}>
      {Object.values(InsurancePolicyStatuses).map((value) => (
        <option key={value} value={value}>
          {camelCaseToText(value)}
        </option>
      ))}
    </select>
  </>
}

export const AppendixTypeColumn = ({ row: { original: appendix } }: { row: { original: AppendixCombinedWithPolicy } }) => {
  if (!appendix.type) {
    return noDataFound()
  }
  return <>{appendix.type}</>
}

export const PolicyIsAgentAppointedColumn = ({ row: { original: policy } }: { row: { original: AppendixCombinedWithPolicy } }) => {
  const { insurancePolicies, setInsurancePolicies } = useProductsContext();

  if (policy.isAgentAppointed === undefined) {
    return noDataFound()
  }
  
  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const newIsAgentAppointed = event.target.checked;
    try {
      await editInsurancePolicy(policy.policyId, { isAgentAppointed: newIsAgentAppointed });
      const newInsurancePolicies = insurancePolicies.map((insurancePolicy) => insurancePolicy._id === policy.policyId ? { ...insurancePolicy, isAgentAppointed: newIsAgentAppointed } : insurancePolicy);
      setInsurancePolicies(newInsurancePolicies);
    }
    catch (error) {
      console.log(error);
    }
  }

  return <FormInput
    type="checkbox"
    name="isAgentAppointed"
    checked={policy.isAgentAppointed}
    onChange={handleChange}
    onClick={(e: any) => e.stopPropagation()}
    className="d-flex align-items-center justify-content-center"
  />
}

export const AppendixRecommendationColumn = ({ row: { original: appendix } }: { row: { original: AppendixCombinedWithPolicy } }) => {
  const [recommendation, setRecommendation] = useState<string>(appendix.recommendation || 'new');
  const [showNewAppendixCreationModal, setShowNewAppendixCreationModal] = useState(false);
  const [modalSubmitted, setModalSubmitted] = useState(false);
  const { insuranceAppendices, setInsuranceAppendices } = useProductsContext();
  const originalRecommendation = appendix.recommendation || InsuranceAppendixRecommendation.noChange;

  if (appendix.isNew) {
    return <>New</>
  }

  const currentAppendixState = shrinkCombinedObjectToAppendix(appendix);
  const isChangingDisabled = (appendix.replaceAppendix || appendix.replaceAppendixId || recommendation === InsuranceAppendixRecommendation.change || false) as boolean

  const handleRecommendationChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const newRecommendation = event.target.value;
    setRecommendation(newRecommendation);
    editExistingAppendix({ ...currentAppendixState, recommendation: newRecommendation as InsuranceAppendixRecommendation })
    if (newRecommendation === InsuranceAppendixRecommendation.change) {
      setShowNewAppendixCreationModal(true);
    }
  }

  const createRecommendedAppendix = async (newInsuranceAppendix: InsuranceAppendix) => {
    setModalSubmitted(true);
    const newInsuranceAppendixCopy = { ...newInsuranceAppendix };
    newInsuranceAppendixCopy.replaceAppendix = currentAppendixState._id;
    newInsuranceAppendixCopy.replaceAppendixId = currentAppendixState._id;
    newInsuranceAppendixCopy._id = "";
    const newAppendix = await createNewAppendix(newInsuranceAppendixCopy);
    setInsuranceAppendices([...insuranceAppendices, newAppendix]);
    return newAppendix;
  }

  const handleModalClose = () => {
    if (!modalSubmitted) {
      setRecommendation(originalRecommendation);
    }
    setShowNewAppendixCreationModal(false);
  }

  return (
    <>
      <select onChange={handleRecommendationChange} value={recommendation} disabled={isChangingDisabled} onClick={e => e.stopPropagation()}>
        {Object.values(InsuranceAppendixRecommendation).map((value) => (
          <option key={value} value={value}>
            {camelCaseToText(value)}
          </option>
        ))}
      </select>
      <CreateOrEditAppendixModal
        show={showNewAppendixCreationModal}
        onHide={handleModalClose}
        // onHide={() => setShowNewAppendixCreationModal(false)}
        currentAppendixState={currentAppendixState}
        isAppendixSuggestionsPhase={true}
        onSubmit={createRecommendedAppendix}
      />
    </>
  )
}

export const AppendixPremiumColumn = ({ row: { original: appendix } }: { row: { original: AppendixCombinedWithPolicy } }) => {
  if (!appendix.premium && appendix.premium !== 0) {
    return noDataFound();
  }
  const premiumDiscountData = appendix.premiumDiscountData;
  const isPremiumDiscountDataAvailable = premiumDiscountData && typeof premiumDiscountData === 'object';
  const premiumDiscountDataTooltip = isPremiumDiscountDataAvailable ? (
    <Tooltip id="premiumDiscountData-tooltip">
      Before Discount: <span className='text-success'>{premiumDiscountData.premiumBeforeDiscount || 'N/A'}</span>
      <br />
      Discount Term: <span className='text-success'>{premiumDiscountData.discountTerm || 'N/A'}</span>
      <br />
      Discount Percent: <span className='text-success'>{premiumDiscountData.currentDiscountPercent || 'N/A'}</span>
      <br />
      Discount Explanation: <span className='text-success'>{premiumDiscountData.discountExplanation || 'N/A'}</span>
      <br />
      {/* Discount Issue Date: <span className='text-success'>{formatDate(appendix.issueDate, true) || 'N/A'}</span> */}
      <br />
      After Discount: <span className='text-success'>{premiumDiscountData.premiumAfterDiscount || 'N/A'}</span>
    </Tooltip>
  ) : (
    <Tooltip id="premiumDiscountData-tooltip">
      No Discount Data Available
    </Tooltip>
  );

  return (
    <OverlayTrigger placement="top" overlay={premiumDiscountDataTooltip}>
      <span className='cursor-pointer' >
        {
          Math.round(appendix.premium)
        }
      </span>
    </OverlayTrigger>
  )
};

export const AppendixPremiumTermColumn = ({ row: { original: appendix } }: { row: { original: AppendixCombinedWithPolicy } }) => {
  if (!appendix.premiumTerm) {
    return noDataFound()
  }
  return <>{appendix.premiumTerm}</>
}

export const AppendixStartDateColumn = ({ row: { original: appendix } }: { row: { original: AppendixCombinedWithPolicy } }) => {
  if (!appendix.insuranceStartDate) {
    return noDataFound()
  }
  return <>{formatDate(new Date(appendix.insuranceStartDate), true)}</>
  // return <>{typeof appendix.insuranceStartDate === 'string' ? appendix.insuranceStartDate.substring(0, 10) : formatDate(appendix.insuranceStartDate, true)}</>
}

// export const AppendixEndDateColumn = ({ row: { original: appendix } }: { row: { original: AppendixCombinedWithPolicy } }) => {
//   if (!appendix.insuranceEndDate) {
//     return noDataFound()
//   }
//   return <>{formatDate(new Date(appendix.insuranceEndDate), true)}</>
// }

export const AppendixBranchColumn = ({ row: { original: appendix } }: { row: { original: AppendixCombinedWithPolicy } }) => {
  const { t } = useTranslation("insurance")
  if (!appendix.secondaryBranch) {
    return noDataFound()
  }
  return <>{t(camelCaseToText(appendix.secondaryBranch))}</>
}

export const AppendixBranchSectionColumn = ({ row: { original: appendix } }: { row: { original: AppendixCombinedWithPolicy } }) => {
  const { t } = useTranslation("insurance")
  if (!appendix.branchSection) {
    return noDataFound()
  }
  return <>{t(camelCaseToText(appendix.branchSection))}</>
}

export const AppendixInsuredPersonColumn = ({ row: { original: appendix } }: { row: { original: AppendixCombinedWithPolicy } }) => {
  const { client } = useProductsContext();
  const clientIdNumber = appendix.clientIdNumber;
  if (!clientIdNumber) {
    return noDataFound()
  }

  const familyMembers = getClientFamilyMembers(client, true);

  const selectedMember = familyMembers.find((member) => member.idNumber === clientIdNumber);
  if (selectedMember) {
    if (selectedMember.firstName) {
      return <>{`${selectedMember.firstName} ${selectedMember.lastName}`}</>
    }
    else {
      return <>{selectedMember.idNumber}</>
    }
  }
  return noDataFound();
}


export const AppendixReplaceAppendixIdColumn = ({ row: { original: appendix } }: { row: { original: AppendixCombinedWithPolicy } }) => {
  if (!appendix.replaceAppendix) {
    return noDataFound()
  }
  return <>{`. . .${appendix.replaceAppendix.slice(-5)}`}</>
}

export const AppendixIdColumn = ({ row: { original: appendix } }: { row: { original: AppendixCombinedWithPolicy } }) => {
  if (!appendix.appendixId) {
    return noDataFound()
  }
  return <>{`. . .${appendix.appendixId.slice(-5)}`}</>
}

export const PolicyIdColumn = ({ row: { original: appendix } }: { row: { original: AppendixCombinedWithPolicy } }) => {
  if (!appendix.policyId) {
    return noDataFound()
  }
  return <>{`. . .${appendix.policyId.slice(-5)}`}</>
}

export const DeleteAppendixColumn = ({ row: { original: appendix } }: { row: { original: AppendixCombinedWithPolicy } }) => {
  const { appendicesCombinedWithPolicies, setAppendicesCombinedWithPolicies } = useProductsContext();
  if (!appendix.isNew) {
    return noDataFound()
  }
  const deleteProduct = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    const confirmation = await Swal.fire({
      title: 'Are you sure you want to delete this appendix?',
      text: 'This action cannot be undone.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    });

    if (confirmation.isConfirmed) {
      try {
        await deleteInsuranceAppendix(appendix.appendixId);
        setAppendicesCombinedWithPolicies(appendicesCombinedWithPolicies.filter((appendixCombinedWithPolicy) => appendixCombinedWithPolicy.appendixId !== appendix.appendixId));
      }
      catch (error) {
        console.log(error);
      }
    }
  };


  return <Button className="text-white fw-bolder" variant="danger" onClick={deleteProduct}>X</Button>
}

export const EditAppendixColumn = ({ row: { original: appendix } }: { row: { original: AppendixCombinedWithPolicy } }) => {
  const [showEditAppendixModal, setShowEditAppendixModal] = useState(false);
  const { insuranceAppendices, setInsuranceAppendices } = useProductsContext();

  const handleEditAppendix = async (modifiedStateOfAppendix: InsuranceAppendix) => {
    try {
      const editedInsuranceAppendix = await editExistingAppendix(modifiedStateOfAppendix)
      setInsuranceAppendices(insuranceAppendices.map((insuranceAppendix) => insuranceAppendix._id === editedInsuranceAppendix._id ? editedInsuranceAppendix : insuranceAppendix));
      return editedInsuranceAppendix;
    }
    catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <Button className="text-white fw-bold" variant="info" onClick={() => setShowEditAppendixModal(true)}> Edit </Button>
      <CreateOrEditAppendixModal
        show={showEditAppendixModal}
        onHide={() => setShowEditAppendixModal(false)}
        currentAppendixState={shrinkCombinedObjectToAppendix(appendix)}
        isAppendixEditingPhase={true}
        onSubmit={handleEditAppendix}
      />
    </>
  )
}

export const ChangeInPremiumColumn = ({ row: { original: appendix } }: { row: { original: AppendixCombinedWithPolicy } }) => {
  const { insuranceAppendices } = useProductsContext();
  if (!appendix.replaceAppendix) {
    return noDataFound()
  }
  const currentPremium = appendix.premium;
  const replacedAppendix = insuranceAppendices.find((insuranceAppendix) => insuranceAppendix._id === appendix.replaceAppendix);
  if (!replacedAppendix) {
    return noDataFound()
  }
  const replacedPremium = replacedAppendix.premium;
  const changeInPremium = currentPremium - replacedPremium;
  const sign = changeInPremium > 0 ? '+' : '';
  const changeInPremiumPercentage = (currentPremium / replacedPremium) * 100;
  const percentageTooltip = (
    <Tooltip id="percentage-tooltip">
      {changeInPremiumPercentage.toFixed(2)}%
    </Tooltip>
  );
  return (
    <OverlayTrigger placement="top" overlay={percentageTooltip}>
      <span className='cursor-pointer' >
        {sign}{changeInPremium.toFixed(2)}
      </span>
    </OverlayTrigger>
  )
}


export const appendicesCombinedWithPolicesColumns = [
  // {
  //   Header: 'Edit',
  //   accessor: 'edit',
  //   classes: 'table-client',
  //   Cell: EditAppendixColumn,
  //   sort: false
  // },
  {
    Header: 'Recommendation',
    accessor: 'recommendation',
    classes: 'table-client',
    Cell: AppendixRecommendationColumn,
    sort: false
  },
  {
    Header: 'Policy Number',
    accessor: 'policyNumber',
    classes: 'table-client',
    Cell: PolicyNumberColumn
  },
  {
    Header: 'Policy Holder',
    accessor: 'policyHolder.name',
    classes: 'table-client',
    Cell: PolicyHolderColumn
  },
  {
    Header: 'Type',
    accessor: 'type',
    classes: 'table-client',
    Cell: AppendixTypeColumn,
    sort: false
  },
  {
    Header: 'Agent Appointed',
    accessor: 'isAgentAppointed',
    classes: 'table-client',
    Cell: PolicyIsAgentAppointedColumn
  },
  {
    Header: 'Main Branch',
    accessor: 'mainBranch',
    classes: 'table-client',
    Cell: PolicyMainBranchColumn,
    sort: false
  },
  {
    Header: 'Secondary Branch',
    accessor: 'secondaryBranch',
    classes: 'table-client',
    Cell: AppendixBranchColumn,
    sort: false
  },
  {
    Header: 'Branch Section',
    accessor: 'branchSection',
    classes: 'table-client',
    Cell: AppendixBranchSectionColumn,
    sort: false
  },
  {
    Header: 'Company',
    accessor: 'company',
    classes: 'table-client',
    Cell: PolicyCompanyNameColumn,
    sort: false
  },
  {
    Header: 'Plan Type',
    accessor: 'planType',
    classes: 'table-client',
    Cell: PolicyPlanTypeColumn,
    sort: false
  },
  {
    Header: 'Premium',
    accessor: 'premium',
    classes: 'table-client',
    Cell: AppendixPremiumColumn,
    sort: false
  },
  {
    Header: 'Change In Premium',
    accessor: 'changeInPremium',
    classes: 'table-client',
    Cell: ChangeInPremiumColumn,
    sort: false
  },
  {
    Header: 'Premium Term',
    accessor: 'premiumTerm',
    classes: 'table-client',
    Cell: AppendixPremiumTermColumn,
    sort: false
  },
  {
    Header: 'Start Date',
    accessor: 'insuranceStartDate',
    classes: 'table-client',
    Cell: AppendixStartDateColumn,
    sort: false
  },
  // {
  //   Header: 'End Date',
  //   accessor: 'insuranceEndDate',
  //   classes: 'table-client',
  //   Cell: AppendixEndDateColumn,
  //   sort: false
  // },
  {
    Header: 'Insured Person',
    accessor: 'clientIdNumber',
    classes: 'table-client',
    Cell: AppendixInsuredPersonColumn,
    sort: false
  },
  // {
  //   Header: 'Total Premium',
  //   accessor: 'totalPremiumAfterChange',
  //   classes: 'table-client',
  //   Cell: PolicyTotalPremiumColumn,
  //   sort: false
  // },
  {
    Header: 'Status',
    accessor: 'status',
    classes: 'table-client',
    Cell: PolicyStatusColumn,
    sort: false
  },
  // {
  //   Header: 'Replace Appendix Id',
  //   accessor: 'replaceAppendixId',
  //   classes: 'table-client',
  //   Cell: AppendixReplaceAppendixIdColumn,
  //   sort: false
  // },
  // {
  //   Header: 'Appendix Id',
  //   accessor: 'appendixId',
  //   classes: 'table-client',
  //   Cell: AppendixIdColumn,
  //   sort: false
  // },
  // {
  //   Header: 'Policy Id',
  //   accessor: 'policyId',
  //   classes: 'table-client',
  //   Cell: PolicyIdColumn,
  //   sort: false
  // },
  {
    Header: 'Delete',
    accessor: 'delete',
    classes: 'table-client',
    Cell: DeleteAppendixColumn,
    sort: false
  },
];





