import { Form } from 'react-bootstrap';
import { InsurancePolicy, InsurancePolicyMainBranch, InsurancePolicyPlanTypes } from '../../../../interfaces/InsurancePolicy';
import { setValueByField } from '../../../../utils/nestedFieldsLogic';
import { useTranslation } from 'react-i18next';
import { camelCaseToText } from '../../../../utils/camelCaseToText';
import { getClientFamilyMembers } from '../../../../utils/getClientFamilyMembers';
import { useProductsContext } from '../insuranceProductsContext';
import { FamilyMember } from '../../../../interfaces/Client';
import { getCompanies } from '../../../../utils/api/companies';
interface PolicyInputFieldsProps {
    newInsurancePolicy: InsurancePolicy;
    setNewInsurancePolicy: (newInsurancePolicy: InsurancePolicy) => void;
    companies: string[];
}

const PolicyInputFields = ({ newInsurancePolicy, setNewInsurancePolicy, companies }: PolicyInputFieldsProps) => {
    const { t } = useTranslation("insurance");
    const { client } = useProductsContext();
    const familyMembers = getClientFamilyMembers(client, true);

    const handlePolicyInputChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        const updatedPolicy = setValueByField({ ...newInsurancePolicy }, name, value);
        setNewInsurancePolicy(updatedPolicy);
    };

    const handleSelectPolicyHolder = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedIdNumber = event.target.value;
        let policyHolder = { name: '', idNumber: '' };
        if (selectedIdNumber !== 'None') {
            const selectedMember = familyMembers.find((member) => member.idNumber === selectedIdNumber);
            if (selectedMember) {
                policyHolder = {
                    name: `${selectedMember.firstName ?? ''} ${selectedMember.lastName ?? ''}`,
                    idNumber: selectedMember.idNumber ?? '',
                };
            }
        }
        setNewInsurancePolicy({ ...newInsurancePolicy, policyHolder });
    }
    return (
        <>
            {/* Company */}
                <Form.Group className="mb-3" controlId="companyName">
                    <Form.Label>Company Name</Form.Label>
                    <Form.Select
                        name="company"
                        value={newInsurancePolicy.company}
                        onChange={handlePolicyInputChange}
                    >
                        {companies.map((company) => (
                            <option key={company} value={company}>
                                {company}
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>

            {/* Main Branch */}
            <Form.Group className="mb-3" controlId="InsurancePolicyMainBranch">
                <Form.Label>Main Branch</Form.Label>
                <Form.Select
                    name="branch"
                    value={newInsurancePolicy.branch}
                    onChange={handlePolicyInputChange}
                >
                    {Object.values(InsurancePolicyMainBranch).map((branch) => (
                        <option key={branch} value={branch}>
                            {t(camelCaseToText(branch))}
                        </option>
                    ))}
                </Form.Select>
            </Form.Group>



            {/* Policy Holder Select according to family members */}
            <div className="row mb-3">
                <Form.Group className="col-md-6" controlId="InsurancePolicyHolder">
                    <Form.Label>Policy Holder</Form.Label>
                    <Form.Select
                        name="policyHolder"
                        value={newInsurancePolicy.policyHolder?.idNumber}
                        onChange={handleSelectPolicyHolder}
                    >
                        <option value="None">None</option>
                        {familyMembers.map((member: FamilyMember) => (
                            <option key={member.idNumber} value={member.idNumber}>
                                {member.firstName} {member.lastName}
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>

                {/* Policy Plan Type Select according to InsurancePolicyPlanTypes */}
                <Form.Group className="col-md-6" controlId="InsurancePolicyPlanType">
                    <Form.Label>Plan Type</Form.Label>
                    <Form.Select
                        name="planType"
                        value={newInsurancePolicy.planType}
                        onChange={handlePolicyInputChange}
                    >
                        {Object.values(InsurancePolicyPlanTypes).map((planType) => (
                            <option key={planType} value={planType}>
                                {t(camelCaseToText(planType))}
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>
            </div>

        </>

    )
}
export default PolicyInputFields
