import React from 'react';
import { Route } from 'react-router-dom';

// components
import PrivateRoute from './PrivateRoute';
import Root from './Root';
import Employers from "../pages/employers";
import BusinessDashboard from "../pages/business-dashboard";
import ProductTransfers from "../pages/product-transfers";
// import Whatsapp from "../pages/whatsapp"; // Old whatsapp page
import WhatsappManager from "../pages/whatsappManager";
import MyTeam from '../pages/myTeam';
import ClientProfileNew from '../pages/profile';
import Users from '../pages/users';
import PublicRoute from './PublicRoute';
// lazy load all the views

// admin
const Login = React.lazy(() => import('../pages/auth/Login'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));
const RegistrationDone = React.lazy(() => import('../pages/auth/RegistrationDone'));
const EmailConfirmation = React.lazy(() => import('../pages/auth/EmailConfirmation'));
const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'));
const Register = React.lazy(() => import('../pages/auth/Register'));
const SignInSignUp = React.lazy(() => import('../pages/auth/SignInSignUp'));
const LockScreen = React.lazy(() => import('../pages/auth/LockScreen'));
const Clients = React.lazy(() => import('../pages/clients'));
const Tasks = React.lazy(() => import('../pages/tasks'));
const Product = React.lazy(() => import('../pages/product'));
const Files = React.lazy(() => import('../pages/files'));
const DocumentsSigned = React.lazy(() => import('../pages/documents-signed'));
const MissingFiles = React.lazy(() => import('../pages/missing-files'));
const UploadLink = React.lazy(() => import('../pages/uploadLink1'));
const Companies = React.lazy(() => import('../pages/companies'));
const Availability = React.lazy(() => import('../pages/availability'));
const InventoryProducts = React.lazy(() => import('../pages/inventory-products'));
const PensionProductCommissions = React.lazy(() => import('../pages/pension-product-commissions'));
const BetterTerminal = React.lazy(() => import('../pages/better-terminal'));
const ClientEmails = React.lazy(() => import('../pages/emails'));
const PensionActions = React.lazy(() => import('../pages/pension-actions'));
const TaxReturnList = React.lazy(() => import('../pages/tax-return-list'));
const SlaScreen = React.lazy(() => import('../pages/sla-screen'));
const StatusChart = React.lazy(() => import('../pages/status-chart'));
// insert new route import here
const Error404 = React.lazy(() => import('../pages/error/Error404'));
const Error404Two = React.lazy(() => import('../pages/error/Error404Two'));
const Error500 = React.lazy(() => import('../pages/error/Error500'));
const Error500Two = React.lazy(() => import('../pages/error/Error500Two'));


const wrapWithPrivateRoute = (element: any, roles: string[]) => {
    return <PrivateRoute roles={roles}>{element}</PrivateRoute>;
}

const wrapWithPublicRoute = (element: any) => {
    return <PublicRoute>{element}</PublicRoute>;
}

export interface RouteProps {
    path: string;
    name?: string;
    icon?: string;
    children?: RouteProps[];
    element?: any;
}

const rootRoute: RouteProps = {
    path: '/',
    element: <Root />,
};

const clientsRoutes: RouteProps[] = [
    {
        path: '/clients',
        name: 'Clients',
        element: wrapWithPublicRoute(<Clients />)
    },
    {
        path: '/clients/profile/files',
        name: 'Files',
        element: wrapWithPublicRoute(<Files />)
    },
    {
        path: '/clients/profile',
        name: 'Profile',
        element: wrapWithPublicRoute(<ClientProfileNew />)
    },
];

const usersRoutes: RouteProps[] = [
    {
        path: '/users',
        name: 'Users',
        element: wrapWithPrivateRoute(<Users />, []),
    },
];


const missingFilesRoutes: RouteProps[] = [
    {
        path: '/missing-files',
        name: 'MissingFiles',        
        element: wrapWithPublicRoute(<MissingFiles />)
    },
];
const employersRoutes: RouteProps[] = [
    {
        path: '/employers',
        name: 'Employers',
        element: wrapWithPublicRoute(<Employers />)
    },
];

const businessDashboardRoutes: RouteProps[] = [
    {
        path: '/dashboard',
        name: 'BusinessDashboard',
        element: wrapWithPublicRoute(<BusinessDashboard />)
    },
];

const productTransfersRoutes: RouteProps[] = [
    {
        path: '/product-transfers',
        name: 'Product Transfers',
        element: wrapWithPrivateRoute(<ProductTransfers />, ['operator']),
    }
];

const WhatsappManagerRoutes: RouteProps[] = [
    {
        path: '/whatsapp-manager',
        name: 'Whatsapp Manager',
        element: wrapWithPublicRoute(<WhatsappManager />)
    }
];

const MyTeamRoutes: RouteProps[] = [
    {
        path: '/my-team',
        name: 'My Team',
        element: wrapWithPrivateRoute(<MyTeam />, ['manager']),
    }
];

const companiesRoutes: RouteProps[] = [
    {
        path: '/companies',
        name: 'Insurance Companies',
        element: wrapWithPublicRoute(<Companies />)
    }
];

const productsRoutes: RouteProps[] = [
    {
        path: '/product',
        name: 'Product',
        element: wrapWithPrivateRoute(<Product />, ['agent', 'operator']),
    }
];

const tasksRoutes: RouteProps[] = [
    {
        path: '/tasks',
        name: 'Tasks',
        element: wrapWithPublicRoute(<Tasks />)
    }
];

const AvailabilityCalendarRoutes: RouteProps[] = [
    {
        path: '/availability-calendar',
        name: 'Availability Calendar',
        element: wrapWithPublicRoute(<Availability />)
    }
];

const inventoryProductRoutes: RouteProps[] = [
    {
        path: '/inventory-products',
        name: 'Inventory Product',
        element: wrapWithPrivateRoute(<InventoryProducts />, ['agent', 'operator', 'analyst']),
    }
];

const pensionProductCommissionsRoutes: RouteProps[] = [
    {
        path: '/pension-product-commissions',
        name: 'Pension Product Commissions',
        element: wrapWithPrivateRoute(<PensionProductCommissions />, ['agent', 'operator', 'analyst']),
    }
];

const emailsRoutes: RouteProps[] = [
    {
        path: '/client-emails',
        name: 'ClientEmails',
        element: wrapWithPublicRoute(<ClientEmails />) 
    }
];

const betterTerminalRoutes: RouteProps[] = [
    {
        path: '/better-terminal',
        name: 'Better Terminal',
        element: wrapWithPrivateRoute(<BetterTerminal />, ['agent', 'operator', 'analyst']),
    }
];

const pensionActionsRoutes: RouteProps[] = [
    {
        path: '/pension-actions',
        name: 'Pension Actions',
        element: wrapWithPrivateRoute(<PensionActions />, ['agent', 'operator', 'analyst']),
    }
];

const taxReturnListRoutes: RouteProps[] = [
    {
        path: '/tax-return-list',
        name: 'Tax Return List',
        element: wrapWithPrivateRoute(<TaxReturnList />, ['agent', 'operator', 'analyst']),
    }
];

const slaScreenRoutes: RouteProps[] = [
  {
    path: '/sla-screen',
    name: 'SlaScreen',
    element: wrapWithPublicRoute(<SlaScreen />)
  }
];
const statusChartRoutes: RouteProps[] = [
  {
    path: '/status-chart',
    name: 'StatusChart',
    element: wrapWithPublicRoute(<StatusChart />)
  }
];
// insert new route array here


const appRoutes: RouteProps[] = [
    ...tasksRoutes,
    ...productsRoutes,
    ...companiesRoutes,
    ...employersRoutes,
    ...businessDashboardRoutes,
    ...productTransfersRoutes,
    ...WhatsappManagerRoutes,
    ...MyTeamRoutes,
    ...clientsRoutes,
    ...usersRoutes,
    ...missingFilesRoutes,
    ...AvailabilityCalendarRoutes,
    ...inventoryProductRoutes,
    ...pensionProductCommissionsRoutes,
    ...emailsRoutes,
    ...pensionActionsRoutes,
    ...taxReturnListRoutes,
    ...betterTerminalRoutes,
...slaScreenRoutes,
...statusChartRoutes,
// insert new route destructuring here
];

// auth
const authRoutes: RouteProps[] = [
    {
        path: '/auth/login',
        name: 'Login',
        element: <Login />,
    },
    {
        path: '/auth/register',
        name: 'Register',
        element: <Register />,
    },
    {
        path: '/auth/confirmation/done',
        name: 'RegistrationDone',
        element: <RegistrationDone />,
    },
    {
        path: '/auth/email-confirmation',
        name: 'Confirm',
        element: <EmailConfirmation />,
    },
    {
        path: '/auth/forget-password',
        name: 'Forget Password',
        element: <ForgetPassword />,
    },
    {
        path: '/auth/signin-signup',
        name: 'SignIn-SignUp',
        element: <SignInSignUp />,
    },
    {
        path: '/auth/lock-screen',
        name: 'Lock Screen',
        element: <LockScreen />,
    },
    {
        path: '/auth/logout',
        name: 'Logout',
        element: <Logout />,
    }
];

// public routes
const otherPublicRoutes: RouteProps[] = [
    {
        path: '/documents-signed',
        name: 'documents-signed',
        element: <DocumentsSigned />,
    },
    {
        path: '/error-404',
        name: 'Error - 404',
        element: <Error404 />,
    },
    {
        path: '/error-404-two',
        name: 'Error - 404 Two',
        element: <Error404Two />,
    },
    {
        path: '/error-500',
        name: 'Error - 500',
        element: <Error500 />,
    },
    {
        path: '/error-500-two',
        name: 'Error - 500 Two',
        element: <Error500Two />,
    },
];


const uploadFilesRoutes: RouteProps[] = [
    {
        path: '/files/upload',
        name: 'UploadLink',
        element: <UploadLink />,
    }
];

// All routes
const authProtectedRoutes = [rootRoute, ...appRoutes];
const publicRoutes = [...authRoutes, ...otherPublicRoutes];

export { publicRoutes, uploadFilesRoutes, authProtectedRoutes };
