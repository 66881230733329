import { useContext } from 'react';

import ManagerDisplayWrapper from './ManagerDisplayWrapper';
import FilterWrapper from './FilterWrapper';
import SearchWrapper from './SearchWrapper';
import { chatContext } from '../../context/chatContext';
import SortWrapper from './SortWrapper';


const ChatListActions = () => {

  const { chatData } = useContext(chatContext); 

  return (
    <div className='chat-list-actions'>
        {chatData.systemUser?.isManager ? <ManagerDisplayWrapper /> : <></>}

        <div className="agent-display-wrapper">
          <FilterWrapper />
          <SortWrapper />
          <SearchWrapper />          
        </div>
    </div>
  )
}

export default ChatListActions;