import {APICore, TASKS_URL} from './apiCore';
import {Task} from "../../interfaces/Task";

const api = new APICore();


const getTasks = (params: { page: number, clientId?: string, userId?: string, status?: string, type?: string, showFutureTasks?:boolean, sortBy?:string, asc?:boolean}) => {
    const relativeUrl = '/tasks';
    return api.get(`${TASKS_URL}${relativeUrl}`, params);
}


const getTaskCount = (params?: {clientId?:string}) => {
    const relativeUrl = '/tasks/count';
    return api.get(`${TASKS_URL}${relativeUrl}`, params);
}

const updateTask = (params: { taskId: string, userId?: string, status?: string, text?: string, dueDate?: Date}) => {
    const relativeUrl = '/tasks/' + params.taskId;
    return api.update(`${TASKS_URL}${relativeUrl}`, params);
}


const createTask = (task: Task) => {
    const relativeUrl = '/tasks/';

    return api.create(`${TASKS_URL}${relativeUrl}`, {userId: task.user._id, clientId: task.client?._id, ...task});
}

// const editTask = (params: { taskId: string, userId?: string, status?: string, text?: string, dueDate?: string}) => {



export {
    getTaskCount,
    getTasks,
    updateTask,
    createTask
}