import { APICore, API_URL } from './apiCore';
import { User } from "../../interfaces/User";
import { Client } from "../../interfaces/Client";

const api = new APICore();


const getMessages = (params: { clientId?: string, repId?: string, page: number, fromTime?: number }) => {
    const relativeUrl = '/messages';
    return api.get(`${API_URL}${relativeUrl}`, params);
}


const sendFreeformMessage = (client: Client, body: string, messageType?: string) => {
    const relativeUrl = '/messages';
    return api.create(`${API_URL}${relativeUrl}`, { clientId: client._id, body, messageType });
}

const sendFreeformMessageWithDate = (client: Client, body: string, messageType?: string, date?: Date) => {
    const relativeUrl = '/messages';
    return api.create(`${API_URL}${relativeUrl}`, { clientId: client._id, body, messageType, date });
}

const zoomStarted = (client: Client) => {
    const relativeUrl = '/messages/zoom-started';
    return api.create(`${API_URL}${relativeUrl}`, { clientId: client._id });
}

const zoomEnded = (client: Client) => {
    const relativeUrl = '/messages/zoom-ended';
    return api.create(`${API_URL}${relativeUrl}`, { clientId: client._id });
}


const getWhatsappTemplates = (client: Client) => {
    const relativeUrl = '/messages/templates/whatsapp';
    return api.get(`${API_URL}${relativeUrl}`, { clientId: client._id });
}


const sendWhatsappTemplate = (client: Client, messageType: string, isPreview: boolean) => {
    const relativeUrl = '/messages/templates/whatsapp';
    return api.create(`${API_URL}${relativeUrl}`, { clientId: client._id, messageType, isPreview });
}

const sendWhatsappTemplateWithDate = (client: Client, messageType: string, isPreview: boolean, date: Date) => {
    const relativeUrl = '/messages/templates/whatsapp';
    return api.create(`${API_URL}${relativeUrl}`, { clientId: client._id, messageType, isPreview, date });
}

const allDataReceived = (client: Client) => {
    const relativeUrl = '/messages/all-data-received';
    return api.create(`${API_URL}${relativeUrl}`, { clientId: client._id });
}

const getUnansweredClients = () => {
    const relativeUrl = '/messages/unanswered-clients';
    return api.get(`${API_URL}${relativeUrl}`);
}

const updateResponseStatus = (messageId: string, responseStatus: string) => {
    const relativeUrl = '/messages/response-status';
    return api.update(`${API_URL}${relativeUrl}`, { messageId, responseStatus });
}

const removeRepFromMessage = (messageId: string) => {
    const relativeUrl = '/messages/remove-rep';
    return api.update(`${API_URL}${relativeUrl}`, { messageId });
}

const fetchJotformSubmissionManually = (clientId: string, jotFormType: string) => {
    const relativeUrl = '/clients/fetch-jotform-manual-submission';
    return api.create(`${API_URL}${relativeUrl}`, { clientId, jotFormType });
}

const getSLAInfo = (params: { repId: string, startTime: Date, endTime: Date }) => {
    const relativeUrl = '/messages/messages-sla-info';
    return api.get(`${API_URL}${relativeUrl}`, params);
}

export {
    getWhatsappTemplates,
    sendWhatsappTemplate,
    sendWhatsappTemplateWithDate,
    sendFreeformMessage,
    sendFreeformMessageWithDate,
    getMessages,
    zoomEnded,
    zoomStarted,
    allDataReceived,
    fetchJotformSubmissionManually,
    getUnansweredClients,
    updateResponseStatus,
    removeRepFromMessage,
    getSLAInfo
}