// create a function that converts camelCase to text including iniitials




const camelCaseToText = (text?: string)=>{
    if (!text)
        return '';

    const result = text.replace(/([A-Z])/g, " $1").replace(/(\d+)/g, ' $1 ').trim();
    return result.charAt(0).toUpperCase() + result.slice(1);
}

export {
    camelCaseToText
}