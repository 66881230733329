import { Employer } from "./Employer";

export const ClientTypeOptions = [
    'lowPaid',
    'highPaid',
    'champion',
    'financiallyIndependent'
];

export enum SourceTypes {
    direct = "direct",
    lecture = "lecture",
    campaign = "campaign",
    referral = "referral",
    employeeReferral = "employeeReferral",
    spouse = "spouse",
    reserveForces = "reserveForces",
}

export const GenderOptions = [
    'female',
    'male'
];

export const EmploymentStatuses = [
    "employee",
    "selfEmployed",
    "companyOwner",
]

export const InsuranceRank = [
    "low",
    "normal",
    "high"
]

export const attendOBMeetingOptions = [
    'yes',
    'no',
]
export enum RatingLevel {
    unrated = 0,
    veryLow = 1,
    low = 2,
    medium = 3,
    high = 4,
    veryHigh = 5
}

export enum TargetAudienceStatuses {
	targetAudience = 'targetAudience',
	notTargetAudience = 'notTargetAudience',
	irrelevantClient = 'irrelevantClient',
};
// export const RatingLevel = Object.freeze({
// 	unrated: 0,
// 	veryLow: 1,
// 	low: 2,
// 	medium: 3,
// 	high: 4,
// 	veryHigh: 5
// })


// type RatingLevelValues = typeof RatingLevel[keyof typeof RatingLevel];



export type ClientsChild = {
    type: "child"
    firstName: string,
    lastName: string,
    hasSpecialNeeds: boolean,
    idNumber: string,
    dateOfBirth: Date,
    idIssueDate?: Date,
    phoneNumber?: string,
}

export type ClientsSpouse = {
    type?: "spouse",
    firstName?: string,
    lastName?: string,
    idNumber?: string,
    dateOfBirth?: Date,
    idIssueDate?: Date,
    client?: Client,
    phoneNumber?: string,
}

export type FamilyMember = ClientsChild | ClientsSpouse;

export type Module = {
    module: string,
    status: string,
    isActive?: boolean,
    statusDueDate?: string,
    _id?: string,
}
export interface Client {
    _id: string,
    targetAudienceStatus?: TargetAudienceStatuses,
    specialEducationChild?: boolean,
    retirementAge?: string,
    currentModule?: string,
    firstName: string;
    middleName?: string,
    lastName: string;
    fullName?: string;
    email?: string;
    idNumber?: string;
    phoneNumber?: string;
    phoneNumberStatus?: string;
    wolt?: boolean,
    isVIP?: boolean,
    isStarred?: boolean,
    handlingRep?: string,
    address?: {
        city: string,
        street: string,
        houseNumber: string
    },
    imageUrl?: string,
    pensionStatus?: string,
    allowedStatuses?: string[],
    messageControl?: {
        isActive?: boolean,
        assignedRep?: string
    },
    clientType?: string,
    onboardingRemarks?: string;
    payslipPasswords: string[],
    employmentStatus?: string,
    employer?: Employer,
    dateOfBirth?: Date,
    gender?: string,
    maritalStatus?: string,
    clientHanmakaLink?: string,
    pricingPlan?: string,
    source: {
        sourceType?: string,
        referredBy?: any,
        referredByEmployer?: any,
        campaign?: string,
        campaignName?: string,
    },
    attendOBMeeting?: string,
    spouse?: ClientsSpouse,
    children?: ClientsChild[],
    followers?: [{
        user: string,
        invitedBy: string
    }],
    status?: string,
    overDue?: number,
    lastStatusChangeDate?: Date,
    lastMessageDate?: Date,
    hasMetaExperimentPhoneNumber?: boolean
    qualificationInfo?: {
        status: string,
        reason: string,
        otherReason: string
    }
    generalFinanceKnowledge?: RatingLevel,
    personalFinanceKnowledge?: RatingLevel,
    financialIndependenceLevel?: RatingLevel,
    financeDetailsLevel?: RatingLevel,
    trustLevel?: RatingLevel,
    idIssueDate?: Date,
    vipDetails?: string,
    modules?: Module[]
    modulesOrder?: {
        moduleName: string,
        order: number,
        isRelevant: boolean,
    }[],
    createdAt?: Date,
    isIrrelevant?: boolean,
    irrelevantReason?: string,
};
