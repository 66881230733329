import { Form } from "react-bootstrap";
import BorderWithText from "../../../../../components/BorderWithText";
import { camelCaseToText } from "../../../../../utils/camelCaseToText";
import { PersonTaxData } from "../../../../../interfaces/TaxData";
import { Option } from "../../../../../interfaces/Option";
import { parseDateValue } from "../../../../../utils/parseDateValue";

interface NationalServiceBoxProps {
    t: (s: string) => string;
    prefix: string;
    data: PersonTaxData;
    onChange: (e: any) => void;
    serviceTypesOptions: Option;
}

const NationalServiceBox = ({ t, prefix, data, onChange, serviceTypesOptions }: NationalServiceBoxProps) => {
    return <BorderWithText label={t('National Service')}>
        <Form.Group controlId={`${prefix}NationalService`} className='d-flex justify-content-between gap-2'>
            <div>
                <Form.Label>{t('National Service Status')}</Form.Label>
                <Form.Select
                    name={`${prefix}.nationalService.serviceType`}
                    value={data.nationalService.serviceType}
                    onChange={onChange}
                >
                    <option disabled={true} value="">{t('Select Service Type')}</option>
                    {serviceTypesOptions && Object.entries(serviceTypesOptions).map(([key, value]) => (
                        <option key={key} value={key}>{t(camelCaseToText(value))}</option>
                    ))}
                </Form.Select>
            </div>
            <div>
                <Form.Label>{t('Date of discharge')}</Form.Label>
                <Form.Control
                    type="date"
                    name={`${prefix}.nationalService.date`}
                    value={parseDateValue(data.nationalService.date)}
                    onChange={onChange} />
            </div>
        </Form.Group>
    </BorderWithText>;
}

export default NationalServiceBox;