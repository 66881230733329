import React from 'react'
interface InsuranceTotalDataInfoHeaderProps {
    totalElementaryPolicies: number;
    totalIndividualPolicies: number;
}
const InsuranceTotalDataInfoHeader = ({ totalElementaryPolicies, totalIndividualPolicies }: InsuranceTotalDataInfoHeaderProps) => {
    return (
        <div className='d-flex flex-row fs-5 fw-bold  ms-4'>
            <div className='p-2'>
                <span>{`Total Elementary policies: ${totalElementaryPolicies}`}</span>
            </div>
            <div className='p-2'>
                <span>{`Total Individual policies: ${totalIndividualPolicies}`}</span>
            </div>
        </div>
    )
}

export default InsuranceTotalDataInfoHeader