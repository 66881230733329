import { Client, FamilyMember } from '../interfaces/Client';

export function getClientFamilyMembers(client: Client, inculdeSelf?:boolean): FamilyMember[] {
  if (!client) {
    return [];
  }

  const familyMembers: FamilyMember[] = [];

  if (client.spouse) {
    familyMembers.push(client.spouse);
  }

  if (client.children) {
    familyMembers.push(...client.children);
  }

  if (inculdeSelf) {
    const { firstName, lastName, idNumber } = client;
    familyMembers.push({ firstName, lastName, idNumber });
  }
  
  return familyMembers.filter((familyMember) => familyMember.firstName && familyMember.lastName && familyMember.idNumber);
}