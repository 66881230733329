import {APICore, API_URL, DEPOSIT_URL} from './apiCore';

const api = new APICore();

const createDeposit = (clientId: string) => {
    return api.create(`${DEPOSIT_URL}/deposits`, {clientId});
}

const editDeposit = ( taxReturnId: string, body: any ) => {
    const relativeUrl = `/deposits/${taxReturnId}`;
    return api.update(`${DEPOSIT_URL}${relativeUrl}`, body);
}

const getDepositStatuses = () => {
    return api.get(`${DEPOSIT_URL}/deposits/statuses`);
}

export {
    getDepositStatuses,
    createDeposit,
    editDeposit
}