import {Company} from "./Company";

export const PricingPlans = [
    'oneYearFree',
    'performanceBased'
];

export const EmployerTypeOptions = [
    'company',
    'ngo',
    'government',
    'selfEmployed',
];
export interface Employer {
    _id: string,
    type: string,
    name: string,
    shortName: string,
    pricingPlan?: string,
    companyId: string,
    logoUrl?: string,
    thumbUrl?: string,
    address: string,
    contactPerson: {
        name: string,
        phoneNumber: string,
        email: string,
    },
    pensionOperators: string[],
    lectureDate: string,
}

export interface Deal {
    employerId?: string,
    company?: Company | string, // get list from server (for dropdown) /api/companies/?isDeal=true
    productType: string, 
    commissions: {
        deposit: number | undefined, 
        savings: number | undefined, 
    },
    validity: number | undefined 
    _id?: string
}
