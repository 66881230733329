import React, { useEffect } from 'react'
import { Button } from 'react-bootstrap';
import { mailProductTransfers } from '../../utils/api/productTransfers';
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';
import { camelCaseToText } from '../../utils/camelCaseToText';
import { Typography } from '@mui/material';

interface MailingButtonsAndLogicProps {
    setIsUserPickingProducts: React.Dispatch<React.SetStateAction<boolean>>;
    isUserPickingProducts: boolean;
    selectedProducts: string[];
    setSelectedProducts: React.Dispatch<React.SetStateAction<string[]>>;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    setStatus: React.Dispatch<React.SetStateAction<string>>;
    allowedStatus: string;
    onSuccessfulMail: (successfulSentProducts: string[]) => void;
}
const MailingButtonsAndLogic = ({ setIsUserPickingProducts, isUserPickingProducts, selectedProducts, setSelectedProducts, setLoading, setStatus, allowedStatus, onSuccessfulMail }: MailingButtonsAndLogicProps) => {
    const { t } = useTranslation("productTransfer");

    const mailProducts = async () => {
        try {
            const res = await mailProductTransfers(selectedProducts) as any;
            const { sentStatuses } = res;
            const successfulSentProducts = sentStatuses.success;
            const failedSentProducts = sentStatuses.failed;
            alert(`Successfully sent ${successfulSentProducts.length} products out of ${selectedProducts.length} to the insurance company`);
            onSuccessfulMail(successfulSentProducts);
        } catch (err: any) {
            if (err.status === 402)
                alert(err.json.message);
            else
                alert(err);
        }
    }

    const pickProductsButton = () => {
        const handleClick = () => {
            setIsUserPickingProducts(prev => !prev);
        }
        const str = t("upon pressing this button youll be able to choose products in the status of") + " \"" + t(camelCaseToText(allowedStatus)) + "\" " + t("and send them to the insurance company");
        const tooltipTitle = <Typography sx={{ fontSize: 14, direction: 'rtl' }}>{str}</Typography>;
        const cancelButton = <Button onClick={handleClick}>
            {t('Cancel')}
            &nbsp;
            <i className="mdi mdi-cancel" />
        </Button>
        const pickProductsButton = <Tooltip title={tooltipTitle} arrow placement="top">
            <Button onClick={handleClick}>
                {t('Pick products')}
                &nbsp;
                <i className="mdi mdi-information-outline" />
            </Button>
        </Tooltip>
        return isUserPickingProducts ? cancelButton : pickProductsButton;
    }

    useEffect(() => {
        if (!isUserPickingProducts) {
            setSelectedProducts([]);
        }
    }, [isUserPickingProducts, setSelectedProducts]);

    useEffect(() => {
        if (isUserPickingProducts) {
            setStatus(allowedStatus);
        }
        else {
            setStatus('');
        }
    }, [isUserPickingProducts, setStatus, allowedStatus]);

    const mailProductsButton = () => {
        const disabled = selectedProducts.length === 0;
        const handleClick = async () => {
            setLoading(true);
            await mailProducts();
            setLoading(false);
        }
        return <Button disabled={disabled} onClick={handleClick}>
            {t('Send products')}
            &nbsp;
            <i className="mdi mdi-email-outline" />
        </Button>
    }
    return (
        <div className="d-flex end mx-3 gap-3">
            {pickProductsButton()}
            {mailProductsButton()}
        </div>
    )
}

export default MailingButtonsAndLogic