import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { Button, Form, Modal } from 'react-bootstrap';
import './GalleryImageModal.scss';
import { camelCaseToText } from '../../utils/camelCaseToText';
import { autoLabelFileData, editFileDataLabeling } from '../../utils/api/files';
import { Spinner } from 'react-bootstrap';
import { useUser } from '../../hooks';
import useGetUserNameById from '../../hooks/useGetUserNameById';
import { Tooltip } from '@mui/material';
import InteractiveZoomImage from '../../components/InteractiveZoomImage';
import { ValidFileClassificationDataForLabeling } from '../../interfaces/ClientFileDataLabeling';
import DynamicFormForDataLabelingGenerator from './DynamicFormForDataLabelingGenerator';
import { GalleryImage } from '../../interfaces/GalleryImage';
import Select from "react-select";
import { useTranslation } from 'react-i18next';
import { delay } from 'redux-saga/effects';


interface InteractiveZoomImageHandle {
    resetZoom: () => void;
}

type GalleryImageModalProps = {
    fileImage: GalleryImage;
    fileIndex: number;
    fileId: string;
    fileClassifications: string[];
    closeLightbox: () => void;
    moveNext: () => void;
    movePrev: () => void;
    fileData: ValidFileClassificationDataForLabeling[];
    updateFileDatas: (index: number, fileData: ValidFileClassificationDataForLabeling[]) => void;
    isFileRelevantForDataLabeling: boolean;
    optionsForMultiClassificationSelect: { label: string, value: string }[];
    getClassificationInHebrew: (classification: string) => string;
    setClassificationsForFile: (options: any[]) => void;
    isPageLoading: boolean;
    markedFields?: string[]
};

const GalleryImageModal = ({ fileImage, fileIndex, fileId, fileClassifications, closeLightbox, moveNext, movePrev, fileData, updateFileDatas, isFileRelevantForDataLabeling, optionsForMultiClassificationSelect, getClassificationInHebrew, setClassificationsForFile, isPageLoading, markedFields }: GalleryImageModalProps) => {
    const user = useUser();
    const findUserNameById = useGetUserNameById();
    const { t } = useTranslation("files");
    const zoomImageRef = useRef<InteractiveZoomImageHandle>(null);
    const [currentFileData, setCurrentFileData] = useState<ValidFileClassificationDataForLabeling[]>(_.cloneDeep(fileData));
    const [loadingAutoLabel, setLoadingAutoLabel] = useState(false);
    const [loadingUpdate, setLoadingUpdate] = useState(false);

    const isSendDataForLabelingButtonDisabled = loadingAutoLabel || !fileClassifications || !fileClassifications.length;
    const buttonTooltipTitle = loadingAutoLabel ? "Be patient..." : "File must have classifications to be sent for auto labeling.";

    useEffect(() => {
        setCurrentFileData(_.cloneDeep(fileData));
    }, [fileData]);

    useEffect(() => {
        const handleKeyPress = (event: KeyboardEvent) => {
            if (event.key === 'ArrowLeft') {
                handlePrevNextClick("prev");
            } else if (event.key === 'ArrowRight') {
                handlePrevNextClick("next");
            }
        };
        window.addEventListener('keydown', handleKeyPress);
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [moveNext, movePrev]);

    const handlePrevNextClick = (direction: "prev" | "next") => {
        if (zoomImageRef.current) {
            zoomImageRef.current.resetZoom();
        }
        if (direction === "prev") {
            movePrev();
        } else if (direction === "next") {
            moveNext();
        }
    }

    const renderFormFields = () => {
        if (!currentFileData || currentFileData.length === 0) {
            return null;
        }

        return currentFileData.map((classificationData, fileIndex) => (
            classificationData && (
                <div key={`fileData-${fileIndex}`} className='w-100'>
                    <h4>{t(classificationData.fileClassification)}</h4>
                    <DynamicFormForDataLabelingGenerator
                        data={classificationData}
                        setData={(newData) => {
                            setCurrentFileData(currentFileData.map((data, index) => index === fileIndex ? _.cloneDeep(newData) : data));
                        }}
                        {...(markedFields && { markedFields })}
                    />
                </div>
            )
        ));
    };

    const sendFileToAutoLabeling = async () => {
        setLoadingAutoLabel(true);
        try {
            const { data } = await autoLabelFileData(fileId);
            updateFileDatas(fileIndex, data);
        } catch (err) {
            console.log(err);
        }
        finally {
            setLoadingAutoLabel(false);
        }
    };

    const updateFileDataLabeling = async () => {
        if (!currentFileData) {
            return;
        }
        setLoadingUpdate(true);
        try {
            const t0 = Date.now();
            const didErrorOccur = await handleEditFileDataLabeling(fileId, currentFileData, fileData, t);
            // if (!didErrorOccur) {
            //     alert(t("Data updated successfully"));
            // }
            const t1 = Date.now();
            const delta = Math.max(t1 - t0);
            const delay = Math.max(0, 300 - delta);
            setTimeout(() => {
                updateFileDatas(fileIndex, currentFileData);
                setLoadingUpdate(false);
            }, delay);
        }
        catch (err) {
            setLoadingUpdate(false);
            console.log(err);
        }

    }

    const approveFileDataLabeling = async () => {
        if (!user?._id) return;
        try {
            const currentDataApproved = currentFileData.map(data => data ? ({ ...data, approvedByUser: user._id }) : data);
            const didErrorOccur = await handleEditFileDataLabeling(fileId, currentDataApproved, fileData, t);
            if (!didErrorOccur) {
                alert(t("Data updated successfully"));
            }
            updateFileDatas(fileIndex, currentDataApproved);
            setCurrentFileData(currentDataApproved);
        }
        catch (err) {
            console.log(err);
        }
    }

    const generateHeader = () => {
        return (
            <div className='d-flex justify-content-between align-items-center gap-2'>
                <span>{fileIndex + 1}. {fileImage?.clientName}</span>
                <Select
                    isClearable={false}
                    isMulti={true}
                    placeholder="Select Classifications"
                    options={optionsForMultiClassificationSelect}
                    className="react-select react-select-container"
                    value={fileClassifications.map(classification => ({ label: getClassificationInHebrew(classification), value: classification }))}
                    onChange={(selectedOptions) => {
                        if (!selectedOptions) {
                            setClassificationsForFile([]);
                            return;
                        }
                        setClassificationsForFile(selectedOptions.map(option => ({ label: option.label, value: option.value })));
                    }}
                    styles={{
                        menu: (base) => ({
                            ...base,
                            width: '300px',
                            minWidth: '300px'
                        }),
                        multiValue: (base) => ({
                            ...base,
                            backgroundColor: 'var(--bs-primary)',
                            color: 'white'
                        }),
                        multiValueLabel: (base) => ({
                            ...base,
                            color: 'white'
                        }),

                    }}
                />

            </div>
        )
    }

    return (
        <Modal show={true} onHide={closeLightbox} dialogClassName="modal-fullscreen">
            <Modal.Header className='gallery-modal-header' closeButton>
                <Modal.Title>{generateHeader()}</Modal.Title>
                <div className="right-side-of-header">
                    {isFileRelevantForDataLabeling &&
                        <Tooltip title={isSendDataForLabelingButtonDisabled ? buttonTooltipTitle : ""} arrow>
                            <div className="auto-label-button">
                                <Button
                                    variant="primary"
                                    onClick={sendFileToAutoLabeling}
                                    disabled={isSendDataForLabelingButtonDisabled}
                                >
                                    {loadingAutoLabel ?
                                        <span>
                                            <Spinner aria-hidden="true" as="span" animation="border" size="sm" role="status" className="me-2" />
                                            {t("May take a minute...")}
                                        </span> :
                                        t("Send Data To Auto Labeling")
                                    }
                                </Button>
                            </div>
                        </Tooltip>
                    }
                    {
                        thereIsDataForTheFile(currentFileData) && userApprovedAllData(currentFileData) && (
                            <div className="approved-by text-success">
                                <p>{t("Approved By:")} {" "}
                                    {Array.from(new Set(currentFileData.map(file => file ? findUserNameById(file.approvedByUser) : ""))).join(", ")}
                                </p>
                            </div>
                        )
                    }
                </div>
            </Modal.Header>
            {isPageLoading ?
                <Modal.Body className="d-flex justify-content-center align-items-center">
                    <Spinner variant="primary" />
                </Modal.Body> :
                <Modal.Body className="d-flex m-1">
                    <div className="image-container">
                        <InteractiveZoomImage
                            ref={zoomImageRef}
                            src={fileImage.src}
                            alt={fileImage.caption}
                            zoomScale={4}
                            style={{ display: 'block' }}
                        />
                        <button onClick={() => handlePrevNextClick("prev")} className="nav-button prev-button">&#8592;</button>
                        <button onClick={() => handlePrevNextClick("next")} className="nav-button next-button">&#8594;</button>
                    </div>
                    {thereIsDataForTheFile(currentFileData) &&
                        <div className="form-container" dir="rtl">
                            <Form className="label-form">
                                {renderFormFields()}
                                <div className="form-buttons">
                                    <Button variant="success" onClick={approveFileDataLabeling}>
                                        {userApprovedAllData(currentFileData) ? t("Reapprove") : t("Approve")}
                                    </Button>
                                    <Button variant="primary" onClick={updateFileDataLabeling} disabled={loadingUpdate}>
                                        {loadingUpdate && (
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                className="me-2"
                                            />
                                        )}
                                        {t("Update")}
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    }
                </Modal.Body>}
        </Modal>
    );
};

export default GalleryImageModal;

const fieldsToExclude = ['_id', '__v', 'fileId', 'updatedAt', 'createdAt', 'autolabeled', 'rawData', 'fileClassification', 'autolabeled'];

const isFieldRelevant = (fieldName: string) => {
    return !fieldsToExclude.includes(fieldName);
};

const thereIsDataForTheFile = (fileData: ValidFileClassificationDataForLabeling[]) => {
    return fileData && fileData.length > 0;
}

const userApprovedAllData = (fileData: ValidFileClassificationDataForLabeling[]) => {
    return fileData.every(file => file?.approvedByUser);
}

const handleEditFileDataLabeling = async (fileId: string, currentFileData: ValidFileClassificationDataForLabeling[], originalFileData: ValidFileClassificationDataForLabeling[], t: (str: string) => string) => {
    if (!currentFileData || currentFileData.length === 0) {
        return;
    }

    let didErrorOccur = false;

    currentFileData.forEach(async (currentData, index) => {
        const originalData = originalFileData[index];
        if (!currentData || !originalData) {
            return;
        }
        const changes: any = {};

        Object.keys(currentData).forEach(key => {
            if (currentData[key as keyof ValidFileClassificationDataForLabeling] !== originalData[key as keyof ValidFileClassificationDataForLabeling]) {
                if (isFieldRelevant(key))
                    changes[key] = currentData[key as keyof ValidFileClassificationDataForLabeling];
            }
        });

        if (Object.keys(changes).length > 0) {
            try {
                await editFileDataLabeling(fileId, changes, currentData.fileClassification);
            }
            catch (err: any) {
                console.log(err);
                alert(`Error updating ${camelCaseToText(currentData.fileClassification)} data: ${err?.json?.message} ${err.message}`);
                didErrorOccur = true;
            }
        }
    });
    return didErrorOccur;
}