import React, { useState } from 'react'
import { Item } from '.';
import { ProductTransfer } from '../../interfaces/ProductTransfer';
import { FileUploader } from 'react-drag-drop-files';
import { uploadFileToProductTransfer } from '../../utils/api/productTransfers';
import { Tooltip } from '@mui/material';
import Loader from '../../components/Loader';
import { useTranslation } from 'react-i18next';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import { on } from 'events';

interface ProductTransferFileUploadProps {
    productTransfer: ProductTransfer,
    setList: React.Dispatch<React.SetStateAction<Item[]>>,
    list: Item[]
    onSuccessfulUpload?: () => void
}

const types = ["pdf", "application/pdf"];

const ProductTransferFileUpload = ({ productTransfer, setList, list, onSuccessfulUpload }: ProductTransferFileUploadProps) => {
    const { t } = useTranslation("productTransfer");
    const [isLoadingUpload, setIsLoadingUpload] = useState<boolean>(false);

    const handleChange = async (file: File) => {
        setIsLoadingUpload(true);
        try {
            await uploadFileToProductTransfer(productTransfer._id, { file });
            setIsLoadingUpload(false);
            setList(
                list.map((item: any) => {
                    if (item.product.productTransfer._id === productTransfer._id) {
                        item.product.productTransfer.file = {
                            fileName: file.name,
                            link: URL.createObjectURL(file)
                        };
                        item.product.productTransfer.hasFileBeenChangedOnce = true;
                    }
                    return item;
                })
            );
            if (onSuccessfulUpload)
                onSuccessfulUpload();
            alert(t("file upload success"));
        }
        catch (err) {
            alert(t("file upload error"));
        }
        finally {
            setIsLoadingUpload(false);
        }
    }

    const DropZone = () => <div className='text-primary text-wrap mt-3 position-relative' >
        {productTransfer.hasFileBeenChangedOnce &&
            <Tooltip title={t("The file has already been replaced once")} placement='top' arrow>
                <PublishedWithChangesIcon className="position-absolute top-0 start-0 translate-middle text-danger fs-2" />
            </Tooltip>
        }
        <i className="mdi mdi-cloud-upload fs-3"></i>
        <div>{t("drag and drop file here")}</div>
        {productTransfer.file &&
            <div className='text-secondary text-wrap fs-7'>
                <Tooltip title={productTransfer.file?.fileName}>
                    <span>{t("hover to see file name")}</span>
                </Tooltip>
            </div>}
    </div>

    return <FileUploader
        multiple={false}
        disabled={isLoadingUpload}
        classes="file-upload-area"
        types={types}
        onTypeError={() => alert(t("file type is not pdf"))}
        children={isLoadingUpload ? <Loader /> : <DropZone />}
        handleChange={handleChange}
    />;

}

export default ProductTransferFileUpload
