import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { ErrorData } from '../../../../../interfaces/ErrorData';

const useErrorHandler = () => {
    const { t } = useTranslation("taxReturn");
    const navigate = useNavigate();
    const location = useLocation();

    const navigateToFileViewPage = (errorData: ErrorData[]) => {
        const { fileId } = errorData[0];
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('fileId', fileId);
        const newUrl = `${location.pathname}?${searchParams.toString()}`;
        navigate(newUrl, { state: errorData });
        window.location.href = newUrl.toString();
    };

    const handleErrorCases = (error: any) => {
        console.log(error?.status, error?.json?.message, error?.json?.extraData);
        if (error?.status === 411 || error?.status === 410) {
            const errorDetails = error?.json?.extraData;
            if (isErrorDataValid(errorDetails)) {
                const userResponse = window.confirm(t("There are some problematic files. Would you want to view them?"));
                if (userResponse) {
                    navigateToFileViewPage(errorDetails);
                }
                return;
            }
        }
        alert(error?.json?.message + "--- errorData: " + error?.json?.extraData + "--- status: " + error?.status);
    }

    return { handleErrorCases };
};

export default useErrorHandler;

const isErrorDataValid = (errorData: ErrorData[]) => {
    return errorData && errorData.length > 0&& errorData.some((error) => error.fields?.length && error.fileId) && errorData[0].fileId;
}