const getCompanyName = (name?: string) => {
    if (!name)
        return;

    const names = [
        'חברה לביטוח',
        'חברה לבטוח',
        'פנסיה וגמל',
        'קרנות',
        'ניהול',
        'גמל',
    ]

    for (const n of names) {
        if (name.includes(n))
            return name.split(n)[0].trim();
    }

    return name;
}

export {getCompanyName};