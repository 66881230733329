import {APICore, API_URL} from './apiCore';
import {Client} from "../../interfaces/Client";
import {FileType} from "../../interfaces/FileType";

const api = new APICore();


const getAllBots = () => {
    const relativeUrl = '/automated-messages/bots';
    return api.get(`${API_URL}${relativeUrl}`);
}


const assignBot = (client: Client, botName: string) => {
    const relativeUrl = '/automated-messages/assign-bot';
    return api.create(`${API_URL}${relativeUrl}`, {clientId: client._id, botName});
}

const cancelBot = (client: Client, botName: string) => {
    const relativeUrl = '/automated-messages/cancel-bot';
    return api.create(`${API_URL}${relativeUrl}`, {clientId: client._id, botName});
}

const sendFutureMessageNow = (futureMessageId: string) => {
    const relativeUrl = '/automated-messages/send-future-message-now';
    return api.create(`${API_URL}${relativeUrl}`, {futureMessageId});
}

const cancelFutureMessage = (futureMessageId: string) => {
    const relativeUrl = '/automated-messages/cancel-future-message';
    return api.create(`${API_URL}${relativeUrl}`, {futureMessageId});
}

const assignTestBot = (client: Client, file: FileType) => {
    const relativeUrl = '/automated-messages/assign-test-bot';
    const params = {
        file,
        clientId: client._id,
    }

    return api.createWithFile(`${API_URL}${relativeUrl}`, params);
}


export {
    getAllBots,
    assignBot,
    cancelBot,
    sendFutureMessageNow,
    cancelFutureMessage,
    assignTestBot,
}