import {APICore, API_URL} from './apiCore';
const api = new APICore();


const getSignatures = (params: { clientId: string, documentType?: string}) => {
    const relativeUrl = '/signatures';
    return api.get(`${API_URL}${relativeUrl}`, params);
}

const archiveSignature = (signedDocumentId: string) => {
    const relativeUrl = `/signatures/${signedDocumentId}/archive`;
    return api.update(`${API_URL}${relativeUrl}`,{});
}

export {
    getSignatures,
    archiveSignature
}
