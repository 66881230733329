
import React from "react";
import {formatDate} from "../utils/formatDate";

const DateTimeColumn = (params: any) => {
    return (
        <>
            {formatDate(params.value, false, false)}
        </>
    );
};

export default DateTimeColumn;