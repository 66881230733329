
import React from "react";
import {getClientsAutocomplete} from "../utils/api/clientsBySearch";
import {Autocomplete, TextField} from "@mui/material";
import {useTranslation} from "react-i18next";
import { ClientBySearch } from "../interfaces/ClientBySearch";


interface ClientAutocompleteProps {
    handleFreeSearch: (search:string) => void;
    onAutocompleteSelect: (value: string) => void;
    defaultValue?: string;
    onClear?: () => void;
}
const ClientAutocomplete = ({handleFreeSearch, onAutocompleteSelect, defaultValue, onClear}: ClientAutocompleteProps) => {
    const {t} = useTranslation("components");
    const [search, setSearch] = React.useState<string>('');
    const [autocompleteOptions, setAutocompleteOptions] = React.useState<any[]>([]);
    const handleKeyDown = (event: any) => {

        if (event.key === 'Enter') {
            handleFreeSearch(search);
        }
    }

    const getLabels = (client: ClientBySearch) => {
        if (search.startsWith('05')) {
            if (client.idNumber?.toString().includes(search))
                return `${client.idNumber} | ${client.firstName} ${client.lastName}`;
            else if (client.phoneNumber?.includes(search.substring(1)))
                return `${client.phoneNumber?.replace('+972', '0')} | ${client.firstName} ${client.lastName}`;
        }
        else if (search.startsWith('+972')) {
            return `${client.phoneNumber} | ${client.firstName} ${client.lastName}`;
        }
        else if (/^\d+$/.test(search)) {//idNumber
            return `${client.idNumber} | ${client.firstName} ${client.lastName}`;
        }
        else if (client.email?.includes(search)) {
            return client.email;
        }
        else if (client.middleName) {
            return [
                `${client.firstName} ${client.middleName} ${client.lastName}`,
                `${client.firstName} ${client.lastName}`
            ];
        }
        else {
            return `${client.firstName} ${client.lastName}`;
        }
    }

    const onSearchTextChanged = async (search:string) => {        
        console.log("search",search);

        if (search === undefined)
            return;

        setSearch(search);

        if (search === '')
            return;
        // debugger;
        try {
            const {clients} = await getClientsAutocomplete({freeText: search});
            const options:any[] = [];
            clients.forEach((c: ClientBySearch) => {
                const labels = getLabels(c);
                if (Array.isArray(labels)) {
                    labels.forEach((label: string) => {
                        options.push({label, value: c.client});
                    });
                }
                else {
                    options.push({label: labels, value: c.client});
                }
            });
            console.log("options",options);
            setAutocompleteOptions(options);
        } catch (err) {
            console.error(err);
        }
        // setSearchText(e.target.value);
    }

    const onSelect = (option: any) => {
        if (!option && onClear) {
            onClear();
        }
        if (option) {
            onAutocompleteSelect(option.value);
        }
    }

    return (

        <Autocomplete
            size="small"
            noOptionsText={t("No options")}
            options={autocompleteOptions}
            getOptionLabel={(option:any) => option.label}
            onInputChange={(event, newInputValue) => {
                onSearchTextChanged(newInputValue);
            }}                        
            onChange={(event, newValue) => {onSelect(newValue)}}
            renderInput={(params) => <TextField {...params} label={t("Search by name, phone, ID or employer")} />}
            onKeyDown={handleKeyDown}
        />
    );
};

export default ClientAutocomplete;