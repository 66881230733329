import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { NationalInsurance, PerYearPersonData, Form106AggData } from '../../../../../interfaces/TaxData';
import { getAllModulesStatuses } from '../../../../../utils/api/clientsBySearch';
import BorderWithText from '../../../../../components/BorderWithText';
import GenericFormBoxRenderer from '../components/GenericFormBoxRenderer';

interface PersonYearlyDataFormProps {
    data: PerYearPersonData;
    prefix: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const PersonYearlyDataForm = ({ data, prefix, onChange }: PersonYearlyDataFormProps) => {
    const { t } = useTranslation("taxReturn");
    return (
        <div className="data-form">
            {data.nationalInsurance && (
                <BorderWithText label={t("National Insurance")}>
                    <NationalInsuranceForm
                        data={data.nationalInsurance}
                        onChange={onChange}
                        prefix={`${prefix}.nationalInsurance`}
                    />
                </BorderWithText>
            )}
            {data.form106?.aggregatedData && (
                <Form106AggDataForm
                    data={data.form106.aggregatedData}
                    onChange={onChange}
                    prefix={`${prefix}.form106`}
                />
            )}
        </div>
    );
};

interface NationalInsuranceFormProps {
    data: NationalInsurance;
    onChange: (e: any) => void;
    prefix: string;
}

const NationalInsuranceForm = ({ data, onChange, prefix }: NationalInsuranceFormProps) => {
    const { t } = useTranslation("taxReturn");
    const [benefitTypesOptions, setBenefitTypesOptions] = useState<{ value: string, label: string }>();

    useEffect(() => {
        const fetchBenefitTypes = async () => {
            const response = await getAllModulesStatuses();
            setBenefitTypesOptions(response.BenefitTypes);
        }
        fetchBenefitTypes();
    }, []);

    return (
        <div className="NationalInsuranceForm">
            <Form.Group controlId="TotalBenefitAmount">
                <Form.Label>{t("Total Benefit Amount")}</Form.Label>
                <Form.Control
                    type="number"
                    name={`${prefix}.totalBenefitAmount`}
                    value={data.totalBenefitAmount}
                    onChange={onChange}
                />
            </Form.Group>
            <Form.Group controlId="TotalTaxDeductionAmount">
                <Form.Label>{t("Total Tax Deduction Amount")}</Form.Label>
                <Form.Control
                    type="number"
                    name={`${prefix}.totalTaxDeductionAmount`}
                    value={data.totalTaxDeductionAmount}
                    onChange={onChange}
                />
            </Form.Group>
            {/* <Form.Group controlId="Types">
                <Form.Label>{t("Types")}</Form.Label>
                <Form.Select
                    name={`${prefix}.types`}
                    value={data.types}
                    onChange={onChange}
                >
                    <option disabled={true} value="">{t("Select Type")}</option>
                    {benefitTypesOptions && Object.entries(benefitTypesOptions).map(([key, value]) => (
                        <option key={key} value={key}>{t(camelCaseToText(value))}</option>
                    ))}
                </Form.Select>
            </Form.Group> */}
        </div>
    )
};

interface Form106AggDataFormProps {
    data: Form106AggData;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    prefix: string;
}

const Form106AggDataForm = ({ data, onChange, prefix }: Form106AggDataFormProps) => {
    return <GenericFormBoxRenderer
        data={data}
        prefix={prefix}
        onChange={onChange}
        excludeFields={["_id"]}
        title="Form 106 Aggregate Data"
    />;
};

export default PersonYearlyDataForm;
