import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Row, Col, Card, Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { withSwal } from 'react-sweetalert2';
import './Employers.scss'

import { Employer } from "../../interfaces/Employer";
import { useTranslation } from "react-i18next";
import { ApiError } from "../../utils/api/ApiError";
import { deleteEmployer, getEmployers, getEmployersFile } from "../../utils/api/employers";
import PageTitle from "../../components/PageTitle";
import Table from "../../components/Table";
import Loader from "../../components/Loader";
import MoneyColumn from "../../components/MoneyColumn";
import LaddaButton, { EXPAND_LEFT } from "react-ladda-button";
import EditDeals from './employer-deals/Deals';
import EmployerAutocomplete from "../../components/EmployerAutocomplete";
import useEmployerEdit from './hooks/useEmployerEdit';


// main component
const Employers = withSwal((props: any) => {

    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState('');
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);

    const [employers, setEmployers] = useState<Employer[]>([]);
    const [total, setTotal] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(0);


    const onEmployerClick = (employer?: Employer) => {
        if (employer) {
            setSelectedEmployer(employer)
            setShowAddEmployer(true);
        }
    }

    const onEditEmployerDeals = (employer: Employer) => {
        setSelectedEmployer(employer)
        setShowEmployerDeals(true);
    }

    const downloadEmployersFile = async () => {
        setIsLoading(true);
        try {
            const result = await getEmployersFile({ search });
            const blob = await result.blob();
            const url = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
            const link = document.createElement("a");
            link.href = url;

            link.setAttribute(
                "download",
                `employers.xlsx`
            );
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        document.title = "Employers";

        return () => {
            document.title = 'Better Admin';
        }
    }, [])

    // basic info column render
    const BasicInfoColumn = ({ row }: { row: any }) => {
        return (
            <>
                {/* <Link to="#" className="text-body fw-semibold"> */}
                {row.original.name}
                {/* </Link> */}
            </>
        );
    }

    const ActionColumn = ({ row }: { row: any }) => {

        return (
            <React.Fragment>
                <span className="action-icon" onClick={(event) => {
                    event.stopPropagation();
                    onEmployerClick(row.original)
                }}>
                    <OverlayTrigger placement="top" overlay={<Tooltip id="">Edit employer</Tooltip>}>
                        <i className="mdi mdi-square-edit-outline"></i>
                    </OverlayTrigger>

                </span>
                <span className="action-icon" onClick={(event) => {
                    event.stopPropagation();
                    onDeleteEmployer(row.original)
                }}>
                    <OverlayTrigger placement="top" overlay={<Tooltip id="">Delete employer</Tooltip>}>
                        <i className="mdi mdi-delete"></i>
                    </OverlayTrigger>
                </span>
                <span className="action-icon">
                    <OverlayTrigger placement="top" overlay={<Tooltip id="">Better website link</Tooltip>}>
                        <Link to={{ pathname: `https://allbetter.co.il/free/${row.original._id}` }} target="_blank">
                            <i className="mdi mdi-link"></i>
                        </Link>
                    </OverlayTrigger>
                </span>
                <span className="action-icon" onClick={(event) => {
                    event.stopPropagation();
                    onEditEmployerDeals(row.original)
                }}>
                    <OverlayTrigger placement="top" overlay={<Tooltip id="">Edit employer deals</Tooltip>}>
                        <i className="mdi mdi-briefcase-check"></i>
                    </OverlayTrigger>
                </span>

            </React.Fragment>
        );
    };

    const columns = [
        {
            Header: 'Name',
            accessor: 'name',
            sort: true,
            classes: 'table-employer',
            Cell: BasicInfoColumn
        },
        {
            Header: 'Company ID',
            accessor: 'companyId',
            sort: true,
            classes: 'table-employer',
        },
        {
            Header: 'Address',
            accessor: 'address',
            sort: true,
            classes: 'table-employer',
        },
        {
            Header: 'Pension operator',
            accessor: 'pensionOperators',
            sort: true,
            classes: 'table-employer',
        },
        {
            Header: 'Employees',
            accessor: 'employees.length',
            sort: true,
            classes: 'table-employer',
        },
        {
            Header: 'Savings per employee',
            accessor: 'avgSavingPerUser',
            sort: true,
            classes: 'table-employer',
            Cell: MoneyColumn
        },
        {
            Header: 'Actions',
            accessor: 'action',
            sort: false,
            classes: 'table-action',
            Cell: ActionColumn,
        },
    ];

    const handleEmployers = async (page: number) => {

        try {
            setLoading(true);
            const { employers, total, itemsPerPage } = await getEmployers({ page, search });
            setEmployers(employers);
            setTotal(total);
            setItemsPerPage(itemsPerPage);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            console.log(err);
            if (err instanceof ApiError && err.status === 401)
                return navigate('/auth/logout');

            alert("Can't get employers");
        }
    }

    useEffect(() => {
        handleEmployers(0);
    }, [search]);

    const [showEmployerDeals, setShowEmployerDeals] = useState<boolean>(false);

    const onCloseEmployerDealsModal = () => setShowEmployerDeals(false);

    const onAddEmployerModal = () => {
        setSelectedEmployer(undefined);
        setShowAddEmployer(true);
    }

    const onAddEmployer = useCallback((employer: Employer) => {
        const newEmployers = [employer, ...employers];
        setEmployers(newEmployers);
        onCloseAddEmployerModal();
    }, []);


    const onEditEmployer = async (employer: Employer) => {
        const index = employers.findIndex(e => e._id === employer._id);
        employers[index] = employer;
        setEmployers(employers);
        onCloseAddEmployerModal();
    };

    const { showAddEmployer, setShowAddEmployer, selectedEmployer, setSelectedEmployer, AddEmployerModal, onCloseAddEmployerModal } = useEmployerEdit({
        onAddEmployer,
        onEditEmployer
    });

    const onDeleteEmployer = async (employer: Employer) => {
        try {
            if (!window.confirm(`Are you sure you want to delete the employer ${employer.name}?`))
                return;

            const result = await deleteEmployer(employer._id);
            const index = employers.findIndex(u => u._id === employer._id);
            employers.splice(index, 1);

            setEmployers([...employers]);
        } catch (err: any) {
            console.log(err);
            if (err.status === 402)
                alert("Can't delete this employer, it has employees on the system");
            else
                alert('Something went wrong...');
        }

        onCloseAddEmployerModal();
    };

    const onEmployerSelected = async (employer?: Employer) => {
        if (employer) {
            setEmployers([employer]);
        }
    }
    const handleKeyDown = async (event: any) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            await handleEmployers(0);
        }
    }

    return (
        <React.Fragment>

            <PageTitle
                breadCrumbItems={[
                    { label: 'Employers', path: '/apps/crm/customers', active: true },
                ]}
                title={'Employers'}
            />

            <Row>
                <Col xl={12}>
                    <Card>
                        <Card.Body data-test-id='employers-search' className='employers-wrapper'>
                            {loading && <Loader />}

                            <Row className="mb-2">
                                <Col sm={3}>
                                    <EmployerAutocomplete
                                        handleFreeSearch={setSearch}
                                        onAutocompleteSelect={onEmployerSelected}
                                    />
                                </Col>
                                <Col sm={3}></Col>
                                <Col sm={3}>
                                    <LaddaButton loading={isLoading} data-style={EXPAND_LEFT}
                                        className="btn btn-primary center-vertically right btn btn-info chat-send waves-effect waves-light"
                                        dir="ltr" onClick={downloadEmployersFile}>
                                        {!isLoading ?
                                            <i className="mdi mdi-plus-circle me-1" /> : null} Download Excel
                                    </LaddaButton>
                                </Col>
                                <Col sm={3}>
                                    <Button data-test-id='add-employer' variant="danger" className="waves-effect waves-light right"
                                        onClick={onAddEmployerModal}>
                                        <i className="mdi mdi-plus-circle me-1"></i> Add Employer
                                    </Button>
                                </Col>

                            </Row>
                            <div className='employers-table'>
                                {itemsPerPage ? <Table
                                    columns={columns}
                                    paginationProps={{
                                        onPageSelected: handleEmployers,
                                        totalItems: total,
                                        numberOfPages: Math.ceil(total / itemsPerPage)
                                    }}
                                    data={employers}
                                    pageSize={itemsPerPage}
                                    isSortable={true}
                                    pagination={true}
                                    isSelectable={false}
                                    tableClass="table-nowrap table-striped table-employers"
                                /> : null}
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            {/* add employer modal */
                showAddEmployer && <AddEmployerModal />
            }

            {/* Add / Edit employers deals modal */
                showEmployerDeals && selectedEmployer &&
                <EditDeals
                    show={showEmployerDeals}
                    onHide={onCloseEmployerDealsModal}
                    employerId={selectedEmployer._id}
                    employerName={selectedEmployer.name}
                />
            }

        </React.Fragment>
    );
});

export default Employers;
