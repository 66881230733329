import { InsuranceAppendix, InsuranceAppendixPremiumTerm, InsuranceAppendixRecommendation } from "../../../../interfaces/InsuranceAppendix"
import { InsurancePolicy, InsurancePolicyMainBranch, InsurancePolicyPlanTypes, InsurancePolicyStatuses } from "../../../../interfaces/InsurancePolicy"

export const initialInsurancePolicyState: InsurancePolicy = {
    _id: '',
    clientId: '',
    status: InsurancePolicyStatuses.initial,
    policyNumber: '',
    policyHolder: { name: '', idNumber: '' },
    company: '',
    branch: InsurancePolicyMainBranch.healthInsurance,
    planType: InsurancePolicyPlanTypes.individual,
    insuranceId: '',
}
export const initialInsuranceAppendixState: InsuranceAppendix = {
    _id: '',
    isNew: true,
    clientId: '',
    clientIdNumber: '',
    type: '',
    policyId: "", // change to policy response id
    policy: "", // change to policy response id
    recommendation: InsuranceAppendixRecommendation.noChange,
    insuredList: [],
    branch: "",
    premium: NaN,
    premiumDiscountData: {
        premiumBeforeDiscount: NaN,
        discountTerm: 'lifeTime',
        currentDiscountPercent: NaN,
        discountExplanation: '',
        premiumAfterDiscount: NaN,
    },
    premiumTerm: InsuranceAppendixPremiumTerm.monthly,
    insuranceStartDate: "",
    // insuranceEndDate: "",
    issueDate: "",
}