import { createContext, useContext } from 'react';
import { InsurancePolicy } from '../../../interfaces/InsurancePolicy';
import { AppendixCombinedWithPolicy, InsuranceAppendix } from '../../../interfaces/InsuranceAppendix';
import { Client } from '../../../interfaces/Client';

interface ProductsContextData {
    insurancePolicies: InsurancePolicy[];
    setInsurancePolicies: (insurancePolicies: InsurancePolicy[]) => void;
    insuranceAppendices: InsuranceAppendix[];
    setInsuranceAppendices: (insuranceAppendices: InsuranceAppendix[]) => void;
    appendicesCombinedWithPolicies: (AppendixCombinedWithPolicy)[];
    setAppendicesCombinedWithPolicies: (appendicesCombinedWithPolicies: (AppendixCombinedWithPolicy)[]) => void;
    combineAppendixWithPolicy: (appendix: InsuranceAppendix, policy: InsurancePolicy) => void;
    client: Client;
}


export const ProductsContext = createContext<ProductsContextData | undefined>(undefined);

export function useProductsContext() {
    const context = useContext(ProductsContext);
    if (!context) {
        throw new Error('useProductsContext must be used within a ProductsContext.Provider');
    }
    return context;
}
