import React, { useState, useEffect, useContext, useRef } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { getWhatsappTemplates } from '../../utils/api/messages';
import { camelCaseToText } from '../../utils/camelCaseToText';
import { UserProfileContext } from '../../context/userProfileContext';

interface ModulesMessageTypes {
    [key: string]: string[];
}

interface TemplatesDropdownProps {
    title: string;
    onTemplateSelected: (messageType: string) => void;
}

const TemplatesDropdown = ({ onTemplateSelected, title }: TemplatesDropdownProps) => {
    const [templates, setTemplates] = useState<ModulesMessageTypes>({});
    const [groupedTemplates, setGroupedTemplates] = useState<Map<string, string[]>>(new Map());
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
    const { profileContextData } = useContext(UserProfileContext);
    const { client } = profileContextData;
    const [activeModule, setActiveModule] = useState<string | null>(client?.currentModule || null);
    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const fetchTemplates = async () => {
            const response = await getWhatsappTemplates(client);
            setTemplates(response.modulesMessageTypes);
        };
        fetchTemplates();
    }, [client]);

    useEffect(() => {
        const grouped = Object.entries(templates).reduce((acc, [module, messageTypes]) => {
            messageTypes && messageTypes.length && acc.set(module, messageTypes);
            return acc;
        }, new Map<string, string[]>());

        setGroupedTemplates(grouped);
    }, [templates]);

    const handleDropdownToggle = (nextShow: boolean, meta: any) => {
        setIsDropdownOpen(nextShow);
        if (nextShow && dropdownRef.current) {
            const rect = dropdownRef.current.getBoundingClientRect();
            const windowHeight = window.innerHeight;
            const dropdownHeight = 400; // Set a fixed height for the dropdown
            const dropdownBottom = rect.bottom + dropdownHeight;

            if (dropdownBottom > windowHeight) {
                dropdownRef.current.style.transform = `translateY(-${dropdownHeight + 20}px)`;
            } else {
                dropdownRef.current.style.transform = 'translateY(0)';
            }
        }
    };

    const handleModuleToggle = (module: string) => {
        setActiveModule(activeModule === module ? null : module);
    };

    const getHeaderClassName = (index: number, array: Array<[string, string[]]>) => {
        let classNames = "fixed-width d-flex justify-content-between align-items-center fw-bold fs-5 cursor-pointer text-dark w-100";
        if (index > 0 && array[index - 1][0] === activeModule) {
            classNames += " border-top-0";
        } else {
            classNames += " border-top";
        }
        if (index === array.length - 1) {
            classNames += " border-bottom";
        }
        return classNames;
    };

    return (
        <div className="assign-template">
            <Dropdown className="select-action text-center" align={'end'} onToggle={handleDropdownToggle} show={isDropdownOpen}>
                <Dropdown.Toggle as="a" className="btn-sm card-drop cursor-pointer">
                    <span>{camelCaseToText(title)}<i className="mdi mdi-chevron-down"></i></span>
                </Dropdown.Toggle>
                <Dropdown.Menu ref={dropdownRef} className="dropdown-menu-scroll">
                    <div className="dropdown-menu-inner">
                        {Array.from(groupedTemplates.entries()).map(([module, messageTypes], index, array) => (
                            <React.Fragment key={module}>
                                <Dropdown.Header
                                    className={getHeaderClassName(index, array)}
                                    onClick={() => handleModuleToggle(module)}
                                >
                                    {camelCaseToText(module)}
                                    <span className="accordion-icon">{activeModule === module ? '➖' : '➕'}</span>
                                </Dropdown.Header>
                                {activeModule === module && messageTypes.map((messageType) => (
                                    <Dropdown.Item key={messageType} onClick={() => onTemplateSelected(messageType)}>{camelCaseToText(messageType)}</Dropdown.Item>
                                ))}
                            </React.Fragment>
                        ))}
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
};

export default TemplatesDropdown;