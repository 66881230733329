import { Form } from "react-bootstrap";
import BorderWithText from "../../../../../components/BorderWithText";
import { PersonTaxData } from "../../../../../interfaces/TaxData";
import { camelCaseToText } from "../../../../../utils/camelCaseToText";
import { Option } from "../../../../../interfaces/Option";
import { parseDateValue } from "../../../../../utils/parseDateValue";

interface DegreeCertificateBoxProps {
    t: (s: string) => string;
    prefix: string;
    data: PersonTaxData;
    onChange: (e: any) => void;
    certificateTypesOptions: Option;
}

const DegreeCertificate = ({ t, prefix, data, onChange, certificateTypesOptions }: DegreeCertificateBoxProps) => {
    return <BorderWithText label={t('Degree Certificate')}>
        <Form.Group controlId={`${prefix}DegreeCertificate`} className='d-flex justify-content-between gap-2'>
            <div>
                <Form.Label>{t('Degree Certificate Status')}</Form.Label>
                <Form.Select
                    name={`${prefix}.degreeCertificate.certificateType`}
                    value={data.degreeCertificate.certificateType}
                    onChange={onChange}
                >
                    <option disabled={true} value="">{t('Select Certificate Type')}</option>
                    {certificateTypesOptions && Object.entries(certificateTypesOptions).map(([key, value]) => (
                        <option key={key} value={key}>{t(camelCaseToText(value))}</option>
                    ))}
                </Form.Select>
            </div>
            <div>
                <Form.Label>{t('Entitlement Date')}</Form.Label>
                <Form.Control
                    type="date"
                    name={`${prefix}.degreeCertificate.entitlementDate`}
                    value={parseDateValue(data.degreeCertificate.entitlementDate)}
                    onChange={onChange} />
            </div>
        </Form.Group>
    </BorderWithText>;
}

export default DegreeCertificate;