
export const notEmpty_ = ( value: any ) => {
    if ( value ) {
        return true;
    }
}

export const validateEmail_ = ( value: any ) => {
    return value.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const minValue_ = ( minValue: number ) => {
    return function( value: any ) {
        if ( Number( value ) >= minValue ) {
            return true;
        }
    }
}

export const maxValue_ = ( maxValue: number ) => {
    return function( value: any ) {
        if ( Number( value ) <= maxValue ) {
            return true;
        }
    }
}

// Add here more validation you need...
