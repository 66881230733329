import React, { useEffect, useState } from 'react';
import { Modal, Button, ButtonGroup, Dropdown, Form } from 'react-bootstrap';
import { Employer, EmployerTypeOptions, PricingPlans } from "../../interfaces/Employer";
import { FormInput, VerticalForm } from "../../components";
import { camelCaseToText } from "../../utils/camelCaseToText";
import { useTranslation } from "react-i18next";
import EmployerAutocomplete from "../../components/EmployerAutocomplete";
import { createEmployer, editEmployer } from "../../utils/api/employers";


interface AddEmployerProps {
    show: boolean;
    onHide: () => void;
    onAddEmployer?: (employer: Employer) => void;
    onEditEmployer?: (employer: Employer) => void;
    employer?: Employer,
    showShortName?: boolean,
    newEmployerName?: string,
}

const newEmployer = {
    _id: '',
    type: 'company',
    name: '',
    shortName: '',
    companyId: '',
    address: '',
    contactPerson: {
        name: '',
        phoneNumber: '',
        email: '',
    },
    pensionOperators: []
}
const AddEmployer = (params: AddEmployerProps) => {

    const {t} = useTranslation("employers");

    const [employer, setEmployer] = useState<Employer>();

    useEffect(() => {
        if (params.employer) {
            setEmployer(params.employer);
        }
    }, [params.employer]);

    useEffect(() => {
        if (params.newEmployerName) {
            const employer: any = {
                name: params.newEmployerName,
                contactPerson: {},
                type: 'company'
            };
            setEmployer(employer);
        }
    }, [params.newEmployerName]);

    const onSubmit = async () => {
        if (employer) {

            if (!employer.name) {
                alert('Please enter employer name');
                return;
            }

            try {
                if (params.employer) {
                    const result = await editEmployer(params.employer._id, employer);
                    if (params.onEditEmployer)
                        params.onEditEmployer(result.employer);
                } else {
                    const result = await createEmployer(employer);
                    if (params.onAddEmployer)
                        params.onAddEmployer(result.employer);
                }
            } catch (err: any) {
                console.log(err);
                if (err.status === 400) {
                    alert(err.json.message);
                }
                else {
                    alert('Something went wrong...');
                }//
            }
        }
    }

    const onChange = (e: any) => {
        const { name, value } = e.target;
        const tmp: any = { ...employer, [name]: value };
        if (name === "pensionOperators") {
            tmp.pensionOperators = [value];
        }
        if (name.includes(".")) {
            const [parent, child] = name.split(".");
            if (!tmp[parent]) {
                tmp[parent] = {};
            }
            tmp[parent][child] = value;
        }
        if (!tmp.contactPerson) {
            tmp.contactPerson = {};
        }
        setEmployer(tmp);
    }

    const getCompanyIdLabel = () => {
        if (employer?.type === 'selfEmployed') {
            return `Self Employed Number (${t("Self Employed Number")})`
        }
        if (employer?.type === 'ngo') {
            return 'NGO Number'
        }
        return `Company ID (${t("Company ID")})`
    }

    const getTitle = () => {
        if (params.employer)
            return 'Edit Employer';

        return 'Add New Employer';
    }


    return (
        <>
            <Modal show={params.show} onHide={params.onHide} aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header className="bg-light" onHide={params.onHide} closeButton>
                    <Modal.Title data-test-id='employer-title' className="m-0">{getTitle()}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-4">


                    <FormInput
                        label="Employer type"
                        type="select"
                        onChange={onChange}
                        value={employer?.type}
                        name="type"
                        placeholder="Status"
                        containerClass={'mb-3'}
                        options={EmployerTypeOptions.map((type: string) => ({
                            label: camelCaseToText(type) || '',
                            value: type
                        }))}
                    // onInputChange={onInputChange}
                    />

                    <FormInput
                        // value={employer?.firstName}
                        label="Formal Business Name"
                        onChange={onChange}
                        type="text"
                        name="name"
                        value={employer?.name}
                        placeholder="Enter formal name"
                        containerClass={'mb-3'}
                    />
                    {params.showShortName && <FormInput
                        // value={employer?.firstName}
                        label="Short Name"
                        value={employer?.shortName}
                        onChange={onChange}
                        type="text"
                        name="shortName"
                        placeholder="Enter short name"
                        containerClass={'mb-3'}
                    />}
                    {employer && employer.type !== EmployerTypeOptions[2] && <FormInput
                        label={getCompanyIdLabel()}
                        onChange={onChange}
                        type="text"
                        value={employer?.companyId}
                        name="companyId"
                        placeholder="Enter company ID"
                        containerClass={'mb-3'}
                    />}

                    <FormInput
                        label="Address"
                        onChange={onChange}
                        type="text"
                        value={employer?.address}
                        name="address"
                        placeholder="Enter full address"
                        containerClass={'mb-3'}
                    />

                    <FormInput
                        label="Pension Operator"
                        onChange={onChange}
                        type="text"
                        value={employer?.pensionOperators}
                        name="pensionOperators"
                        placeholder="Enter pension agent"
                        containerClass={'mb-3'}
                    />

                    <FormInput
                        label="Lecture Date"
                        onChange={onChange}
                        type="date"
                        value={employer?.lectureDate ? new Date(employer?.lectureDate).toISOString().split('T')[0] : ''}
                        name="lectureDate"
                        placeholder="Enter lecture date"
                        containerClass={'mb-3'}
                    />
                    
                    <p>
                        <b>Contact Person</b>
                    </p>
                    <FormInput
                        label="Full Name"
                        onChange={onChange}
                        type="text"
                        value={employer?.contactPerson?.name}
                        name="contactPerson.name"
                        placeholder="Enter full name"
                        containerClass={'mb-3'}
                    />
                    <FormInput
                        label="Phone Number"
                        onChange={onChange}
                        type="text"
                        value={employer?.contactPerson?.phoneNumber}
                        name="contactPerson.phoneNumber"
                        placeholder="Enter full name"
                        containerClass={'mb-3'}
                    />
                    <FormInput
                        label="Email"
                        onChange={onChange}
                        type="text"
                        value={employer?.contactPerson?.email}
                        name="contactPerson.email"
                        placeholder="Enter full name"
                        containerClass={'mb-3'}
                    />


                    <div className="text-end">
                        <Button variant="success" type="submit" className="waves-effect waves-light me-1"
                            onClick={onSubmit}>
                            Save
                        </Button>
                    </div>

                </Modal.Body>
            </Modal>
        </>
    );
};

export default AddEmployer;
