import {Company} from "./Company";
import {PensionProductTypes} from "./PensionProduct";
import {Fund} from "./Fund";
import {Client} from "./Client";
import { Employer } from "./Employer";


export enum TransferStatuses {
    Initial = 'initial',
    StartTransfer = 'startTransfer',
    PendingClientSignature = 'pendingClientSignature',
    SignedByClient = 'signedByClient',
    SignedByAgent = 'signedByAgent', // aka 'preSubmission'
    ProcessingAtCompany = 'processingAtCompany',
    RequestApprovedByCompany = 'requestApprovedByCompany',
    WaitingForFirstPremium = 'waitingForFirstPremium',
    MissingOrWrongData = 'missingOrWrongData',
    EstimatedTransferDateSettled = 'transferDateSettled',
    Regret = 'regret',
    TransferDeclined = 'clientDeclined',
    TransferDone = 'transferDone',
    Unknown = 'unknown',
    TransferDoneCheckPolicy = 'transferDoneCheckPolicy',
    HandlingCompleted = 'handlingCompleted',
    AgentChanged = 'agentChanged',
    TransferOut = 'transferOut',
}

export enum InvestmentPolicyType { 
    Compensation = 'compensation',
    Severance = 'severance',
}

export enum ProductTransferRegretReasons {
    NotInterested = 'notInterested',
    EmployerRetention = 'employerRetention',
    AgentRetention = 'agentRetention',
    CompanyRetention = 'companyRetention',
    Unknown = 'unknown',
};

export enum ProductTransferRejectionReasons {
    AccountClosed = 'accountClosed',
    DivorceProcess = 'divorceProcess',
    LoanExist = 'loanExist',
    NoSavings = 'noSavings',
    OutOfDateWithNoSavings = 'outOfDateWithNoSavings',


};

export enum ProductTransferMissingDataTypes {
    TransferDepartmentError = 'transferDepartmentError',
    MissingForms = 'missingForms',
    AgentError = 'agentError',
    IdCardError = 'idCardError',
    ProductUniqueIdError = 'productUniqueIdError',
    CompanyIdError = 'companyIdError',
};

export enum B2Statuses {
    Initial = 'initial',
    ClientSigned = 'signedByClient',
    AgentSigned = 'signedByAgent',
    Submitted = 'submitted',
    Rejected = 'rejected',
    InProcess = 'inProcess',
    Done = 'done',
}



export interface ProductTransfer {
    _id: string;
    action: string,
    client: Client,
    productType: PensionProductTypes,
    clientSignatureDate: Date,
    agentSignatureDate: Date,
    submissionDate: Date,
    estimatedTransferDate: Date,
    closeDate: Date,
    expirationDate: Date,
    company: Company | string,
    isActive: boolean,
    extendedDisabilityInsurance: boolean,
    companyId: string,
    yearsForCommissionPayOff: number,
    potentialAnnualSavings: number,
    retirementSavings: number,
    investmentPolicies: [{
        fund: Fund | string,
        percentages: {
            compensation: number,
            severance: number
        }
        policy: string
    }],
    newInvestmentPolicies: [{
        fund: any,
        percentage: number,
        policy: string,
        type: InvestmentPolicyType
    }],    
    B2: {
        status: B2Statuses,
        error: String,
    }
    commissions: {
        deposit: number,
        savings: number,
        decreasingCommission: boolean,
        lifelongCommission: boolean,
        isEmployerDeal: boolean,
        agentCommissions: {
            oneTime: {
                received:
                    {
                        date: Date,
                        amount: number,
                    }[],

                estimatedTotal: number,
                productivity: number,
                productivityFactor: number,
                savings: number,
                savingsFactor: number
            },
            monthly: {
                received:
                    {
                        date: Date,
                        amount: number,
                    }[],
                estimatedTotal: number,
                monthlyFactor: number,
            },
        }
    },
    flags: [{
        flagType: string,
        message: string,
        extraData: any
    }],
    salary: number,
    employerDepositPercentage: number,
    employeeDepositPercentage: number,
    severancePercentage: number,
    requestSignId: string,
    clientSigningLink: string,
    agentSigningLink: string,
    completedFormLink: string,
    status: TransferStatuses,
    optimalSavingsYears: number,
    peakSavingsDiff: number,
    potentialSavings: number,
    insurance: {
        riskPercentage: number,
        disabilityPercentage: number,
        giveUpSpouseAndChildInsurance: boolean,
        giveUpSpouseInsurance: boolean
    },
    moreOptions: [{
        commissions: {
            deposit: number,
            savings: number,
            decreasingCommission: boolean,
            lifelongCommission: boolean
        },
        company: Company
    }],
    hasEmployerInForms: boolean,
    employer?: Employer,
    templates: [{
        name: string,
        isUsed: boolean
    }],
    employmentStatus: string,
    monthlyDepositAmount: number,
    bankAccount: { //for self employed
        bankNumber: number,
        branch: number,
        address: {
            city: string,
            street: string,
            houseNumber: number
        },
        accountNumber: number
    },
    newPolicyNumber: string,
    isRejected: boolean,
    rejectionReason: string,
    isRegret: boolean,
    regretReason: string,
    missingDataType: string,
    comments: string,
    updatedAt?: Date,
    file?: any,
    hasFileBeenChangedOnce: boolean,
};

