import React, {useEffect, useState} from "react";
import {Button} from "react-bootstrap";
import LaddaButton, {EXPAND_LEFT} from "react-ladda-button";
import {Link} from "react-router-dom";
import {SignedDocument} from "../../interfaces/SignedDocument";
import './DualSigningButton.scss';
import {archiveSigningDocument} from "../../utils/api/productTransfers";
import {sendFileToClient} from "../../utils/api/files";


interface DualSigningButtonProps {
    text?: string,
    signedDocument?: SignedDocument,
    isEnabled: boolean,
    generateSigningLink: (preview: boolean) => any,
    preGenerationCallback?: () => any,
    onError: (err: any) => void,
    onArchive?: () => void,
    previewMessage?: string,
    PreGenerate?: React.ComponentType<any>,
}


const DualSigningButton = (params: DualSigningButtonProps) => {

    const [isLoadingMain, setIsLoadingMain] = useState(false);
    const [isLoadingPreview, setIsLoadingPreview] = useState(false);

    const [signedDocument, setSignedDocument] = useState<SignedDocument>();
    const [showPregenerate, setShowPregenerate] = useState<boolean>(false);

    const mainButtonClick = async () => {        
        if (!params.isEnabled) {
            if (params.previewMessage)
                window.alert(params.previewMessage);

            return;
        }

        if (params.PreGenerate) {
            setShowPregenerate(true);
            return;
        } else {
            await startGeneration();
        }
    }

    const startGeneration = async () => {
        setIsLoadingMain(true);
        try {
            const signedDocument: SignedDocument = await params.generateSigningLink(false);
            if (signedDocument)
                setSignedDocument(signedDocument);
        } catch (err: any) {
            console.log(err)
            params.onError(err);
        } finally {
            setIsLoadingMain(false);
        }
    }


    const handlePreviewClick = async () => {
        
        if (isLoadingPreview)
            return;

        if (params.previewMessage) {
            if (!window.confirm(params.previewMessage))
                return;
        }

        setIsLoadingPreview(true);
        try {
            const result = await params.generateSigningLink(true);
            if (result) {
                const blob = await result.blob();
                const url = window.URL.createObjectURL(new Blob([blob], {type: blob.type}));
                const link = document.createElement("a");
                link.href = url;

                link.setAttribute(
                    "download",
                    `file.pdf`
                );
                document.body.appendChild(link);
                link.click();
                link.parentNode?.removeChild(link);
            }
        } catch (err: any) {
            console.log(err)
            params.onError(err);
        } finally {
            setIsLoadingPreview(false);
        }
    }

    useEffect(() => {
        setSignedDocument(params.signedDocument);
    }, [params.signedDocument]);

    const getDownloadLink = () => {
        if (!signedDocument)
            return;

        if (signedDocument.file)
            return {link: signedDocument.file.link, text: 'Download'}

        if (signedDocument.agentSigningLink)
            return {link: signedDocument.agentSigningLink, text: 'Agent link'}

        if (signedDocument.clientSigningLink)
            return {link: signedDocument.clientSigningLink, text: 'Client link'}
    }

    const sendToClient = async () => {
        if (!signedDocument)
            return;

        if (!window.confirm("Email this document to the client. Are you sure?"))
            return;

        try {
            await sendFileToClient(signedDocument.file._id);
            alert("Sent to client");
        } catch (err: any) {
            alert("Can't send to client");
        }
    }

    return (
        <div className="signing-links">
            {params.PreGenerate?<params.PreGenerate show={showPregenerate} onHide={()=>setShowPregenerate(false)} onSave={startGeneration} />:null}
            <div>
                {params.text?<LaddaButton
                    loading={isLoadingMain}
                    data-style={EXPAND_LEFT}
                    className="btn btn-primary grouped-btn"
                    dir="ltr"
                    onClick={() => mainButtonClick()}
                >
                    {!isLoadingMain ? <i className="mdi mdi-plus-circle me-1"/> : null}
                    {params.text}
                </LaddaButton>:null}
                <Button
                    className="grouped-btn second-btn"
                    onClick={handlePreviewClick}
                >
                    <i className="mdi mdi-download"></i>
                    {" "}
                    {isLoadingPreview ? 'Loading...' : 'Download pdf'}
                </Button>

                {params.onArchive && params.text?
                    <Button
                        // style={{marginLeft: '10px', fontSize: '23px', padding: 0}}
                        className="grouped-btn delete"
                        onClick={params.onArchive}
                    >
                        <i className="mdi mdi-delete"></i>
                    </Button>
                    : null}

                {signedDocument?.file ? <Button href="#"
                    className="grouped-btn second-btn"
                    onClick={sendToClient}
                 >
                    <i className="mdi mdi-message"></i>
                    {" "}
                    Send to client
                </Button> : null}

            </div>

            {getDownloadLink() && !isLoadingMain ?
                <Button className="link">
                    <Link to={`${getDownloadLink()?.link}`}
                          target="_blank"
                          className="text-body fw-semibold">
                        {getDownloadLink()?.text}
                    </Link>
                </Button>
                : ''}
        </div>
    )
}

export default DualSigningButton;