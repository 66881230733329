import React, { useContext, useEffect, useState } from 'react'
import { UserProfileContext } from '../../../context/userProfileContext';
import InputWrapper from '../../../components/inputWrapper/InputWrapper';
import { User } from '../../../interfaces/User';
import { camelCaseToText } from '../../../utils/camelCaseToText';
import { Pension } from "../../../interfaces/Pension";
import Modules from "../../../utils/modules";
import { TaxReturn } from "../../../interfaces/taxReturn";
import { FormInput } from "../../../components";
import { taxReturnFileClassifications } from './consts';
import DonwloadClientFilesButton from '../../../components/DownloadClientFilesButton';
import { formatDate } from '../../../utils/formatDate';

const Details = () => {
    const { profileContextData, onEditRound, users } = useContext(UserProfileContext);
    const { client } = profileContextData;
    const [currentRound, setCurrentRound] = useState<TaxReturn>(profileContextData.currentRound as TaxReturn);

    const selectedModule = profileContextData.selectedModule;
    const allowedStatusesOptions = selectedModule && Object.values(selectedModule.allowedStatuses);
    const selectedModuleName = selectedModule?.data.moduleName;
    const repType = selectedModuleName === Modules.pension ? 'agent' : 'rep';

    const [repAgents, setRepAgents] = useState<User[]>(users.filter((rep: User) => rep.roles.includes('agent') && rep.agentModules?.includes("taxReturn")));
    const [repOperators, setRepOperators] = useState<User[]>(users.filter((rep: User) => rep.roles.includes('operator')));

    useEffect(() => {
        setRepAgents(users.filter((rep: User) => rep.roles.includes('agent') && rep.agentModules?.includes("taxReturn")));
        setRepOperators(users.filter((rep: User) => rep.roles.includes('operator')));
    }, [users]);


    const isPension = (object: any): object is Pension => {
        return 'agent' in object;
    }

    const hasOperator = (object: any): object is (Pension | TaxReturn) => {
        return 'operator' in object;
    }

    const callToOnEditRound = (data: any) => {
        if (currentRound) {
            onEditRound(data, selectedModuleName, currentRound._id, () => { setCurrentRound({ ...currentRound, ...data }) })
        }
    }
    const lastChangeDateString = currentRound?.lastStatusChangeDate ? `Status (Last change: ${formatDate(new Date(currentRound.lastStatusChangeDate))})` : 'Status';
    return (
        <div className='detailed-tab d-flex justify-content-between'>
            <div className='select-area w-75'>
                {client && currentRound ?
                    <>
                        {allowedStatusesOptions && allowedStatusesOptions.length > 0 ?
                            <InputWrapper status={{ text: '' }} label={lastChangeDateString} wrapperClass='input-field select-field'>
                                <select
                                    value={currentRound.status || ''}
                                    className='form-select'
                                    name="status"
                                    onChange={(e: any) => {
                                        callToOnEditRound({ status: e.target.value })
                                    }}
                                >
                                    <option disabled value={''}>Select status</option>
                                    {allowedStatusesOptions.map((option: any, index: number) => (
                                        <option key={index} value={option}>
                                            {camelCaseToText(option)}
                                        </option>
                                    ))}
                                </select>
                            </InputWrapper>
                            : null}

                        {repAgents && repAgents.length > 0 ?
                            <InputWrapper status={{ text: '' }} label={'Agent / Rep'}
                                wrapperClass='input-field select-field'>
                                <select
                                    value={currentRound.rep}
                                    className='form-select'
                                    name="rep"
                                    onChange={(e: any) => {
                                        callToOnEditRound({ rep: e.target.value })
                                    }}
                                >
                                    <option disabled value="">Select {repType} </option>

                                    {repAgents.map((option: any, index: number) => (
                                        <option key={index} value={option._id}>
                                            {option.fullName}
                                        </option>
                                    ))}
                                </select>
                            </InputWrapper>
                            :
                            <div className="pension-status">
                                No {repType} found.
                            </div>
                        }

                        {repOperators && repOperators.length > 0 && hasOperator(currentRound) ?
                            <InputWrapper status={{ text: '' }} label={'Operator'}
                                wrapperClass='input-field select-field'>

                                <select
                                    value={currentRound.operator}
                                    className='form-select'
                                    name="operator"
                                    onChange={(e: any) => {
                                        callToOnEditRound({ operator: e.target.value })
                                    }}
                                >
                                    <option disabled value="">Select Operator</option>

                                    {repOperators.map((operator: User, index: number) => (
                                        <option key={index} value={operator._id}>
                                            {operator.fullName}
                                        </option>
                                    ))}
                                </select>
                            </InputWrapper>
                            :
                            <div className="pension-status">
                                No operators found.
                            </div>
                        }

                        <InputWrapper>
                            <FormInput
                                label="Estimated tax return"
                                type="number"
                                value={(currentRound).estimatedAmount}
                                name="estimatedAmount"
                                placeholder="Enter amount in NIS"
                                containerClass={'mb-3'}
                                onChange={(e: any) => {
                                    callToOnEditRound({ estimatedAmount: e.target.value })
                                }}
                            />
                        </InputWrapper>

                        <InputWrapper>
                            <FormInput
                                label="Actual tax return"
                                type="number"
                                value={(currentRound).actualAmount}
                                name="actualAmount"
                                placeholder="Enter amount in NIS"
                                containerClass={'mb-3'}
                                onChange={(e: any) => {
                                    callToOnEditRound({ actualAmount: e.target.value })
                                }}
                            />
                        </InputWrapper>

                        <InputWrapper>
                            <FormInput
                                label="IRS Transfer Date"
                                type="date"
                                value={(currentRound).IRSTransferDate ? (currentRound).IRSTransferDate.toString().substring(0, 10) : ''}
                                name="IRSTransferDate"
                                containerClass={'mb-3'}
                                onChange={(e: any) => {
                                    callToOnEditRound({ IRSTransferDate: e.target.value })
                                }}
                            />
                        </InputWrapper> 
                    </>
                    : <div>Sorry, no data yet.</div>}
            </div>
            <div className='right'>
                <DonwloadClientFilesButton clientId={client._id} clientName={`${client.firstName} ${client.lastName}`} fileClassifications={taxReturnFileClassifications} />
            </div>
        </div>
    )
}

export default Details;