import {APICore, API_URL, BANK_FEES_URL} from './apiCore';

const api = new APICore();

const createBankFees = (clientId: string) => {
    return api.create(`${BANK_FEES_URL}/bank-fees`, {clientId});
}

const editBankFees = ( taxReturnId: string, body: any ) => {
    const relativeUrl = `/bank-fees/${taxReturnId}`;
    return api.update(`${BANK_FEES_URL}${relativeUrl}`, body);
}

const getBankFeesStatuses = () => {
    return api.get(`${BANK_FEES_URL}/bank-fees/statuses`);
}

export {
    getBankFeesStatuses,
    createBankFees,
    editBankFees
}