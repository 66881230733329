import { useEffect, useState } from 'react';
import { withSwal } from 'react-sweetalert2';
import { chatContext } from '../../context/chatContext';
import { APICore, SOCKET_URL, getUserFromCookie } from "../../utils/api/apiCore";
import './WhatsappManager.scss';

// components
import ChatsListWrapper from './ChatsListWrapper';
import ChatUserWrapper from './ChatUserWrapper';
import { getUser, getUsers } from '../../utils/api/users';
import { io } from 'socket.io-client';
import { set } from 'date-fns';

const Events = Object.freeze({
    incomingMessage: 'incomingMessage',
    connectToClientCommand: 'connectToClientCommand',
    connectRepCommand: 'connectRepCommand'
});

// main component
const WhatsappManager = withSwal((props: any) => {
    const { user } = new APICore().getLoggedInUser();
    const [shouldUpdateChatsList, setShouldUpdateChatsList] = useState(false);

    useEffect(() => {
        const socket = io(SOCKET_URL);
        socket.on('connect', () => {
            console.log("connected to WAM socket")
            socket.emit(Events.connectRepCommand, { token: getUserFromCookie().token });
        });

        socket.on('disconnect', () => {
            console.log("disconnected from WAM socket")
        });

        socket.on(Events.incomingMessage, async (message) => {
            console.log("incomingMessage on WAM socket", message);
            if (!message.resetUnreadCount) { // The message does NOT comes to notify about the reset of unread count.
                setTimeout(() => { // Delay to make sure that the chat list is updated after the message is received.
                    setShouldUpdateChatsList(true);
                }, 1000);
            }
        });

        return () => {
            socket.disconnect();
            socket.off('connect');
            socket.off('disconnect');
            socket.off(Events.incomingMessage);
        };
    }, []);

    // structure data to put into context:
    const [chatData, setChatData] = useState({
        selectedChat: null,
        isSelectedNotActive: false,
        selectedUnreadCount: 0,
        joinChatIds: [], // Ids of selected chat to join another rep.        
        systemUser: {
            fullNAme: user.firstName + " " + user.lastName,
            isManager: user.isAdmin || user.roles.includes('manager'),
            userId: user._id,
            isActive: true // Manager can set the user as not active for handling chats.
        },
        users: [],
    });

    const [filters, setFilters] = useState({
        chatFilter: null, // starred, unread, starred + unread
        pensionStatus: null,
        search: null,
        repId: null
    });

    const [sortBy, setSortBy] = useState({
        sort: 'newest' // newest, oldest
    })

    const [page, setPage] = useState(0);

    useEffect(() => {
        document.title = 'Whatsapp Manager';

        return () => {
            document.title = 'Better Admin';
        }
    }, [])

    useEffect(() => {
        const getSystemUser = async () => {
            // Get the full system user data:            
            const result = await getUser(user._id);
            const { users } = await getUsers();

            // Update the 'isActive' status of system user:
            setChatData(currentData => ({
                ...currentData,
                users,
                systemUser: {
                    ...currentData.systemUser,
                    isActive: result.user.messageControl?.assignedRep === undefined
                }
            }))
        }
        getSystemUser();

    }, [])

    return (
        <>
            <chatContext.Provider value={{ chatData, setChatData, filters, setFilters, sortBy, setSortBy, page, setPage, shouldUpdateChatsList, setShouldUpdateChatsList }}>
                <div className='whatsapp-manager-wrapper'>
                    <ChatsListWrapper />
                    <ChatUserWrapper />
                </div>
            </chatContext.Provider>
        </>
    );
});

export default WhatsappManager;
