import { useContext } from 'react';
import { UserProfileContext } from '../../../context/userProfileContext';
import ClientInfo from "../clientInfo/ClientInfo";
import ModuleTabs from "../moduleTabs/ModuleTabs";
import TimeLine from "../timeLine/TimeLine";
import MainData from './MainData';

const Investment = () => {
    const { profileContextData } = useContext(UserProfileContext);

    return <div className={`regular-modules ${profileContextData.expandClientData ? 'data-expanded' : ''}`}>
        <ClientInfo />
        <ModuleTabs />
        <div className={'timeline-row-wrapper not-active-module'}>
            <TimeLine />
        </div>
        {profileContextData.currentRound ? <MainData /> : null}
    </div>
}

export default Investment