import { Button, Form } from 'react-bootstrap';
import { InsuranceAppendix, InsuranceAppendixPremiumTerm, policyBranchToAppendixBranchMap, appendixBranchToAppendixBranchSection } from '../../../../interfaces/InsuranceAppendix';
import { useEffect, useState } from 'react';
import { useProductsContext } from '../insuranceProductsContext';
import { getClientFamilyMembers } from '../../../../utils/getClientFamilyMembers';
import EditPremiumDiscountDataModal from './EditPremiumDiscountDataModal';
import { InsurancePolicy } from '../../../../interfaces/InsurancePolicy';
import { useTranslation } from 'react-i18next';
import { camelCaseToText } from '../../../../utils/camelCaseToText';


interface AppendixInputFieldsProps {
    isPolicyCreationPhase: boolean,
    isAppendixEditingPhase?: boolean,
    isAppendixSuggestionsPhase?: boolean,
    newInsuranceAppendix: InsuranceAppendix;
    setNewInsuranceAppendix: (newInsuranceAppendix: InsuranceAppendix) => void;
    associatedPolicyState?: InsurancePolicy
}
const AppendixInputFields = ({ isPolicyCreationPhase, isAppendixEditingPhase = false, isAppendixSuggestionsPhase = false, newInsuranceAppendix, setNewInsuranceAppendix, associatedPolicyState }: AppendixInputFieldsProps) => {
    const { insurancePolicies, client } = useProductsContext();
    const { t } = useTranslation("insurance");
    const familyMembers = getClientFamilyMembers(client, true);
    associatedPolicyState = associatedPolicyState || insurancePolicies.find(policy => policy._id === newInsuranceAppendix.policyId)

    const [showEditDiscountModal, setShowEditDiscountModal] = useState(false);
    const [relevantBranchesBasedOnPolicy, setRelevantBranchesBasedOnPolicy] = useState(policyBranchToAppendixBranchMap(associatedPolicyState))
    const [relevantBranchSections, setRelevantBranchSections] = useState(appendixBranchToAppendixBranchSection(newInsuranceAppendix));

    const handleAppendixInputChange = (e: any) => {
        const name = e.target.name;
        let value = e.target.value;
        setNewInsuranceAppendix({
            ...newInsuranceAppendix,
            [name]: value,
        });
    }

    const handleAppendixDateChange = (newValue: any, field: string) => {
        if (newValue) {
            setNewInsuranceAppendix({
                ...newInsuranceAppendix,
                [field]: newValue,
            });
        }
    }
    useEffect(() => { // set clientId for request
        setNewInsuranceAppendix({ ...newInsuranceAppendix, clientId: client._id });
    }, [client])

    useEffect(() => { // each time the main branch changes - reselect the secondary branch
        setRelevantBranchesBasedOnPolicy(policyBranchToAppendixBranchMap(associatedPolicyState))
    }, [associatedPolicyState])

    useEffect(() => { // each time the secondary branch changes - reselect the branch section
        setRelevantBranchSections(appendixBranchToAppendixBranchSection(newInsuranceAppendix))
    }, [newInsuranceAppendix])

    useEffect(() => { // each time the main branch changes - resset the branch section
        setRelevantBranchSections(null)
    }, [associatedPolicyState])

    useEffect(() => { // when there is only one option on the select, pick it automatically
        if (relevantBranchSections && Object.keys(relevantBranchSections).length === 1) {
            setNewInsuranceAppendix({
                ...newInsuranceAppendix,
                branchSection: Object.keys(relevantBranchSections)[0]
            })
        }
    }, [relevantBranchSections])

    return (
        <>
            {!isPolicyCreationPhase &&
                <Form.Group className="mb-3" controlId="policy">
                    <Form.Label>Policy Number</Form.Label>
                    <Form.Select
                        name="policyId"
                        value={newInsuranceAppendix.policyId || ''}
                        onChange={handleAppendixInputChange}
                        required
                    >
                        <option value="" disabled>Select a Policy</option>
                        {insurancePolicies.map((policy) => (
                            <option key={policy._id} value={policy._id}>
                                {`${policy.policyNumber} - ${policy.branch}`}
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>
            }

            {relevantBranchesBasedOnPolicy &&
                <Form.Group className="mb-3" controlId="branch">
                    <Form.Label>Branch</Form.Label>
                    <Form.Select
                        name="branch"
                        value={newInsuranceAppendix.branch}
                        onChange={handleAppendixInputChange}
                        required
                    >
                        <option value="" disabled>Select a Branch</option>
                        {relevantBranchesBasedOnPolicy &&
                            Object.keys(relevantBranchesBasedOnPolicy).map(branch => (
                                <option key={branch} value={branch}>
                                    {t(camelCaseToText(branch))}
                                </option>
                            ))
                        }
                    </Form.Select>
                </Form.Group>}

            {relevantBranchSections &&
                <Form.Group className="mb-3" controlId="branchSection">
                    <Form.Label>Branch Section</Form.Label>
                    <Form.Select
                        name="branchSection"
                        value={newInsuranceAppendix.branchSection ?? ''}
                        onChange={handleAppendixInputChange}
                    >
                        <option value="">No Branch Section</option>
                        {Object.keys(relevantBranchSections).map(branchSection => (
                            <option key={branchSection} value={branchSection}>
                                {t(camelCaseToText(branchSection))}
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>
            }

            <Form.Group className="mb-3" controlId="insuredPerson">
                <Form.Label>Insured Person</Form.Label>
                <Form.Select
                    name="clientIdNumber"
                    value={newInsuranceAppendix.clientIdNumber}
                    onChange={handleAppendixInputChange}
                    required
                >
                    <option value="" disabled>Select the insured person</option>
                    {familyMembers.map((member: any) => (
                        <option key={member.idNumber} value={member.idNumber}>
                            {member.firstName} {member.lastName}
                        </option>
                    ))}
                </Form.Select>
            </Form.Group>

            <div className='row'>
                <Form.Group className="mb-3 col-md-6" controlId="issueDate">
                    <Form.Label>Insurance Issue Date</Form.Label>
                    <Form.Control
                        type="date"
                        name="issueDate"
                        value={newInsuranceAppendix.issueDate ? newInsuranceAppendix.issueDate.substring(0, 10) : undefined}
                        onChange={handleAppendixInputChange}
                        required
                    />
                </Form.Group>

                <Form.Group className="mb-3 col-md-6" controlId="insuranceStartDate">
                    <Form.Label>Insurance Start Date</Form.Label>
                    <Form.Control
                        type="date"
                        name="insuranceStartDate"
                        value={newInsuranceAppendix.insuranceStartDate ? newInsuranceAppendix.insuranceStartDate.substring(0, 10) : undefined}
                        onChange={handleAppendixInputChange}
                        required
                    />
                </Form.Group>
            </div>

            <div className="row">
                <div className='mb-3 col-md-6 d-flex justify-content-center'>
                    <Button className="w-100 fs-5" onClick={() => setShowEditDiscountModal(true)} style={{marginTop: '16.5px', height: '75%'}}>
                        Edit Premium Discount
                    </Button>
                </div>
                <Form.Group className="mb-3 col-md-6" controlId="premiumTerm">
                    <Form.Label>Premium Term</Form.Label>
                    <Form.Select
                        name="premiumTerm"
                        value={newInsuranceAppendix.premiumTerm}
                        onChange={handleAppendixInputChange}
                        disabled={isAppendixSuggestionsPhase}
                    >
                        {Object.values(InsuranceAppendixPremiumTerm).map((term) => (
                            <option key={term} value={term}>
                                {term}
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>
            </div>

            <EditPremiumDiscountDataModal
                show={showEditDiscountModal}
                onHide={() => setShowEditDiscountModal(false)}
                appendix={newInsuranceAppendix}
                setAppendix={setNewInsuranceAppendix}
            />
        </>
    )
}
export default AppendixInputFields
