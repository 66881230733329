
import React, {useState} from "react";
import {formatDate} from "../utils/formatDate";
import {AutoComplete} from "rsuite";
import {Employer} from "../interfaces/Employer";
import {getEmployersAutocomplete} from "../utils/api/employers";
import {Autocomplete, TextField} from "@mui/material";


interface EmployerAutocompleteProps {
    handleFreeSearch: (search:string) => void;
    onAutocompleteSelect: (employer?: Employer) => void;
    value?: string;
}
const EmployerAutocomplete = ({handleFreeSearch, onAutocompleteSelect}: EmployerAutocompleteProps) => {
    const [search, setSearch] = React.useState<string>('');
    const [autocompleteOptions, setAutocompleteOptions] = React.useState<any[]>([{label: "New employer", value: ''}]);
    const [employers, setEmployers] = React.useState<Employer[]>([]);
    const handleKeyDown = (event: any) => {

        if (event.key === 'Enter') {
            onAutocompleteSelect();
            handleFreeSearch(search);
        }
    }
    const onSearchTextChanged = async (search:string) => {
        const getLabel = (employer: Employer) => {
            if (/^\d+$/.test(search)) { // companyId
                return `${employer.companyId} | ${employer.name}`;
            }

            if (employer.companyId)
                return `${employer.name} | ${employer.companyId}`;

            return employer.name;
        }

        if (search === undefined)
            return;

        setSearch(search);

        if (search == '')
            return;
        // debugger;
        try {
            const {employers} = await getEmployersAutocomplete(search);
            setEmployers(employers);

            const options = employers.map((c: Employer) => ({label: getLabel(c), value: c._id}));
            options.push({label: "New employer", value: ''});
            setAutocompleteOptions(options);
        } catch (err) {
            console.error(err);
        }
        // setSearchText(e.target.value);
    }

    const onSelect = (option: any) => {
        if (!option)
            return;
        // setTimeout(()=>setSearch(item.label as string), 100);
        const employer:Employer|undefined = employers.find((e:Employer)=>e._id === option.value);

        if (!employer) {
            handleFreeSearch('');
        }
        else {
            onAutocompleteSelect(employer);
        }
    }

    return (

        <Autocomplete
            size="small"
            options={autocompleteOptions}
            getOptionLabel={(option:any) => option.label}
            onInputChange={(event, newInputValue) => {
                onSearchTextChanged(newInputValue);
            }}

            onChange={(event, newValue) => {onSelect(newValue)}}
            renderInput={(params) => <TextField {...params} label="Employer name or company ID" />}
            onKeyDown={handleKeyDown}
        />
    );
};

export default EmployerAutocomplete;