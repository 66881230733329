import { PensionProduct } from "../interfaces/PensionProduct";
import { BETTER_ID } from "../constants/constantValues";

const isProductHandledByBetter = (product: PensionProduct) => {
  if (!product || !product.agent || !product.agent.agentId) {
    return false;
  }
  const agentId = product.agent.agentId;
  return agentId === BETTER_ID;
};


export default isProductHandledByBetter;