import React, { useContext, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormInput, VerticalForm } from "../../components";
import { camelCaseToText } from "../../utils/camelCaseToText";
import { Company } from "../../interfaces/Company";
import { getNewProductData } from "../../utils/api/products";
import "../product/product.scss";
import { NewPensionProduct } from "../../interfaces/NewPensionProduct";
import { InvestmentPolicies } from "../../interfaces/Fund";
import { PensionProductTypes } from "../../interfaces/PensionProduct";
import { Client, EmploymentStatuses } from '../../interfaces/Client';
import { banks } from '../../utils/banks';
import { structuralClone } from "../../helpers/misc";
import { UserProfileContext } from "../../context/userProfileContext";

interface CreateNewProductProps {
    show: boolean;
    onHide?: () => void;
    onSave?: (newProduct: NewPensionProduct) => void;
}

interface NewPensionProductDataItem {
    productType: string,
    companies: Company[],
    investmentPolicies: any
}

interface ShowField {
    productTypes: PensionProductTypes[],
    fields: string[]
}

const CreateNewPensionProduct = ({ show, onHide, onSave }: CreateNewProductProps) => {
    const { profileContextData } = useContext(UserProfileContext);
    const { currentRound: pension, client } = profileContextData;

    const [newProduct, setNewProduct] = useState<NewPensionProduct>({
        employmentStatus: "employee",
        clientId: client._id,
        insurance: { riskPercentage: 0, disabilityPercentage: 0 }
    });
    const [data, setData] = useState<NewPensionProductDataItem[]>();
    const [isPension, setIsPension] = useState(false);
    const [companies, setCompanies] = useState<Company[]>([]);
    const [investmentPolicies, setInvestmentPolicies] = useState<string[]>([]);

    const handleData = async () => {
        const result = await getNewProductData();
        const productType = result.data[0].productType;
        setIsPension(productType === PensionProductTypes.comprehensivePensionFund ||
            productType === PensionProductTypes.complementaryPensionFund ||
            productType === PensionProductTypes.directorsInsurance);
        setCompanies(result.data[0].companies);

        const np = { ...newProduct };
        np.productType = productType;

        const company = result.data[0].companies[0];
        np.companyId = company?._id;
        np.investmentPolicy = 'age50';
        
        setInvestmentPolicies(result.data[0].investmentPolicies[result.data[0].companies[0]._id]);

        setNewProduct(np);
        setData(result.data);
    }

    useEffect(() => {
        handleData();
    }, [client]);

    const onSubmit = (e: any) => {
        if (onSave)
            onSave(newProduct);
    }

    const onError = (err: any) => {
        console.log(err)
        if (err.status === 402)
            alert("Missing employer details");
        else
            alert("Can't generate forms");
    }

    const showFields: ShowField[] = [{
        productTypes: [PensionProductTypes.complementaryPensionFund, PensionProductTypes.comprehensivePensionFund],
        fields: [
            'productType',
            'companyId',
            'relevantSalary',
            'employmentStatus',
            'employerDepositPercentage',
            'employeeDepositPercentage',
            'severancePercentage',
            'investmentPolicy',
            'commissions.deposit',
            'commissions.savings',
            'monthlyDepositAmount',
            'insurance.disabilityPercentage',
            'insurance.riskPercentage'
        ],
    }, {
        productTypes: [PensionProductTypes.providentFund],
        fields: [
            'productType',
            'companyId',
            'oneTimeDepositAmount',
            'employmentStatus',
            'employerDepositPercentage',
            'employeeDepositPercentage',
            'severancePercentage',
            'relevantSalary',
            'monthlyDepositAmount',
            'investmentPolicy',
            'commissions.savings'
        ]
    }, {
        productTypes: [PensionProductTypes.studyFund],
        fields: [
            'productType',
            'companyId',
            'employmentStatus',
            'employerDepositPercentage',
            'employeeDepositPercentage',
            'relevantSalary',
            'monthlyDepositAmount',
            'investmentPolicy',
            'commissions.savings'
        ]
    }, {
        productTypes: [PensionProductTypes.investmentFund],
        fields: [
            'productType',
            'companyId',
            'oneTimeDepositAmount',
            'monthlyDepositAmount',
            'investmentPolicy',
            'commissions.savings'
        ]
    }];

    const showField = (name: string) => {
        const item = showFields.find((item: ShowField) => newProduct.productType ? item.productTypes.includes(newProduct.productType) : false);
        if (item)
            return item.fields.includes(name);

        return false;
    }

    const insuranceRiskOptions = () => {
        if (newProduct?.productType === 'comprehensivePensionFund') {
            return initialInsurancePercentage.risk.filter(option => option !== 0);
        } else {
            return initialInsurancePercentage.risk;
        }
    }

    const insuranceDisabilityOptions = () => {
        if (newProduct?.productType === 'comprehensivePensionFund') {
            return initialInsurancePercentage.disability.filter(option => option !== 0);
        } else {
            return initialInsurancePercentage.disability;
        }
    }

    const onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;

        if (!newProduct)
            return;

        const product = structuralClone(newProduct);

        if (name.includes('.')) {
            const list = name.split(".");
            const parent: string = list[0];
            const child: string = list[1];
            const grandChild: string = list[2];

            if (parent === 'template') {
                const index: number = Number(list[1]);

                if (!isNaN(index)) {
                    product.templates[index].isUsed = e.target.checked;
                }
            } else {
                if (!product[parent])
                    product[parent] = {};

                if (list.length === 2)
                    product[parent][child] = value;
                else if (list.length === 3) {
                    if (!product[parent][child])
                        product[parent][child] = {};

                    product[parent][child][grandChild] = value;
                }
            }

        }

        if (name === 'productType') {
            if (data && newProduct.productType !== e.target.value) {
                product.productType = e.target.value;

                const item = data.find((item: NewPensionProductDataItem) => item.productType === product.productType);
                if (item) {
                    setCompanies(item.companies);
                    let company = item.companies.find(c => c._id === product.companyId);
                    if (!company) {
                        company = item.companies[0];
                    }

                    product.companyId = company._id;

                    if (!product.investmentPolicy) {

                        product.investmentPolicy = item.investmentPolicies[company._id][0];

                    }

                    setInvestmentPolicies(item.investmentPolicies[company._id]);
                }

                if (product.productType === PensionProductTypes.investmentFund) {

                    product.employmentStatus = "selfEmployed";
                }
            }
            setNewProduct(product);
            setIsPension(
                product.productType === PensionProductTypes.comprehensivePensionFund ||
                product.productType === PensionProductTypes.complementaryPensionFund ||
                product.productType === PensionProductTypes.directorsInsurance
            )

        } else if (name === 'companyId') {

            if (data && product.companyId !== e.target.value) {
                product.companyId = e.target.value;
                const item = data.find((item: NewPensionProductDataItem) => item.productType === product.productType);
                if (item && product.companyId) {
                    const investmentPolicies = item.investmentPolicies[product.companyId];
                    if (!product.investmentPolicy || !investmentPolicies.includes(product.investmentPolicy))
                        product.investmentPolicy = investmentPolicies[0];

                    setInvestmentPolicies(investmentPolicies);
                }
            }
            setNewProduct(product);
        } else if (name === 'employmentStatus') {
            product.employmentStatus = e.target.value;
            setNewProduct(product);
        }
        else {
            product[name] = e.target.value;
            setNewProduct(product);
        }
    }

    return (
        <>
            <Modal show={show} onHide={onHide} aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header className="bg-light" onHide={onHide} closeButton>
                    <Modal.Title className="m-0">New Product</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-4">
                    <div>
                        {/*<FormInput*/}
                        {/*    label="Product is active"*/}
                        {/*    type="checkbox"*/}
                        {/*    name="isActive"*/}
                        {/*    placeholder="activation status"*/}
                        {/*    containerClass={'mb-3'}*/}
                        {/*/>*/}

                        <FormInput
                            label="Product type"
                            type="select"
                            name="productType"
                            placeholder="Product type"
                            containerClass={showField('productType') ? 'mb-3' : 'hidden'}
                            onChange={onChange}
                            options={data?.map((item: NewPensionProductDataItem) => ({
                                label: camelCaseToText(item.productType),
                                value: item.productType
                            }))}
                        />

                        <FormInput
                            label="Company"
                            type="select"
                            name="companyId"
                            value={newProduct.companyId}
                            placeholder="Company"
                            containerClass={showField('companyId') ? 'mb-3' : 'hidden'}
                            onChange={onChange}
                            options={companies.map((company: Company) => ({
                                label: company.name,
                                value: company._id
                            }))}
                        />

                        <FormInput
                            label="Investment policy"
                            type="select"
                            name="investmentPolicy"
                            value={newProduct.investmentPolicy}
                            placeholder="Investment policy"
                            onChange={onChange}
                            containerClass={showField('investmentPolicy') ? 'mb-3 half' : 'hidden'}
                            // onSelected={(e:any)=>onInvestmentPolicySelected(e,i)}
                            // options={investmentPolicies.map((investmentPolicy:string) => ({
                            //     label: investmentPolicy,
                            //     value: investmentPolicy
                            // }))}
                            options={Object.entries(InvestmentPolicies).map((item: string[]) => ({
                                label: item[0],
                                value: item[1]
                            }))}
                        />

                        <b>100%</b>

                        <FormInput
                            label="Disability insurance (%)"
                            type="select"
                            containerClass={showField('insurance.disabilityPercentage') ? 'half' : 'hidden'}
                            name="insurance.disabilityPercentage"
                            onChange={onChange}
                            options={insuranceDisabilityOptions().map((val: number | string) => ({
                                label: typeof val === "string" ? val : val + "%",
                                value: val.toString()
                            }))}
                        />

                        <FormInput
                            label="Risk insurance (%)"
                            type="select"
                            containerClass={showField('insurance.riskPercentage') ? 'half' : 'hidden'}
                            name="insurance.riskPercentage"
                            onChange={onChange}
                            options={insuranceRiskOptions().map((val: number | string) => ({
                                label: typeof val === "string" ? val : val + "%",
                                value: val.toString()
                            }))}
                        />

                        <FormInput
                            label="Savings commission"
                            type="number"
                            name="commissions.savings"
                            onChange={onChange}
                            containerClass={showField('commissions.savings') ? 'half' : 'hidden'}
                            placeholder="Enter savings commission"
                            min="0"
                        />
                        <FormInput
                            label="Deposit commission"
                            type="number"
                            min="0"
                            name="commissions.deposit"
                            onChange={onChange}
                            containerClass={showField('commissions.deposit') ? 'half' : 'hidden'}
                            placeholder="Enter deposit commission"
                        />

                        <FormInput
                            label="Employment Status"
                            type="select"
                            name="employmentStatus"
                            value={newProduct.employmentStatus || ''}
                            onChange={onChange}
                            placeholder="Employment Status"                            
                            containerClass={'mb-3'}
                            options={EmploymentStatuses.map((item: string) => ({
                                label: camelCaseToText(item),
                                value: item
                            }))}
                        />

                        {newProduct.employmentStatus === "selfEmployed" ? <>
                            <FormInput
                                label="Monthly deposit amount"
                                type="number"
                                name="monthlyDepositAmount"
                                placeholder="Enter monthly deposit amount"
                                min="0"
                                onChange={onChange}
                                containerClass={'half'}
                            />

                            <FormInput
                                label="Bank Number"
                                type="select"
                                options={banks}
                                name="bankAccount.bankNumber"
                                onChange={onChange}
                                containerClass={'half'}
                            />

                            <FormInput
                                label="Branch"
                                type="number"
                                name="bankAccount.branch"
                                onChange={onChange}
                                containerClass={'half'}
                            />
                            <FormInput
                                label="Account number"
                                type="number"
                                name="bankAccount.accountNumber"
                                onChange={onChange}
                                containerClass={'half'}
                            />

                            <FormInput
                                label="Bank city"
                                type="string"
                                name="bankAccount.address.city"
                                onChange={onChange}
                                containerClass={'half'}
                            />
                            <FormInput
                                label="Bank street"
                                type="string"
                                name="bankAccount.address.street"
                                onChange={onChange}
                                containerClass={'half'}
                            />
                            <FormInput
                                label="Bank house number"
                                type="number"
                                name="bankAccount.address.houseNumber"
                                onChange={onChange}
                                containerClass={'half'}
                            />

                            <FormInput
                                label="One time deposit amount"
                                type="number"
                                containerClass={showField('oneTimeDepositAmount') && newProduct.employmentStatus === "selfEmployed" ? 'half' : 'hidden'}
                                name="oneTimeDepositAmount"
                                onChange={onChange}
                            />

                        </> : <>

                            <FormInput
                                label="Monthly Salary"
                                type="number"
                                containerClass={showField('relevantSalary') && newProduct.employmentStatus !== "selfEmployed" ? 'half' : 'hidden'}
                                name="relevantSalary"
                                onChange={onChange}
                            />

                            <FormInput
                                label="Employer deposit (%)"
                                type="number"
                                containerClass={showField('employerDepositPercentage') && newProduct.employmentStatus !== "selfEmployed" ? 'half' : 'hidden'}
                                name="employerDepositPercentage"
                                onChange={onChange}
                            />

                            <FormInput
                                label="Employee deposit (%)"
                                type="number"
                                name="employeeDepositPercentage"
                                containerClass={showField('employeeDepositPercentage') && newProduct.employmentStatus !== "selfEmployed" ? 'half' : 'hidden'}
                                onChange={onChange}
                            />

                            <FormInput
                                label="Severance (%)"
                                type="number"
                                name="severancePercentage"
                                onChange={onChange}
                                containerClass={showField('severancePercentage') && newProduct.employmentStatus !== "selfEmployed" ? 'half' : 'hidden'}
                            />
                        </>}



                        <div className="buttons">
                            <Button variant="success" onClick={onSubmit}
                                className="right waves-effect waves-light me-1">
                                Save
                            </Button>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </>
    );
};

export default CreateNewPensionProduct;

const initialInsurancePercentage = {
    risk: [0, 40, 60, 100],
    disability: [0, 37.5, 75]
}