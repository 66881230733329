import React, { useContext, useState } from 'react'
import { Button } from 'react-bootstrap';
import { chatContext } from '../../context/chatContext';

interface SearchWrapperProps {
    
}

const SearchWrapper = ( {  }: SearchWrapperProps ) => {

    const { setPage, filters, setFilters } = useContext(chatContext)
    const [search, setSearch] = useState<string>("");

    const handleKeyDown = async (event: any) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            searchClicked();
        }
    }

    const onSearchChanged = (e: any) => {
        setSearch(e.target.value);        
    }

    const searchClicked = () => {
        setPage(0);
        setFilters({...filters, search});        
    }

  return (
    <div className="search-bar form-inline search-wrapper">
        <div className="position-relative center-vertically search-input">
            <input
                type="text"
                className="form-control"
                title="Search by first name, last name or phone"
                placeholder="Search"
                onChange={onSearchChanged}
                onKeyDown={handleKeyDown}
            />
            <span 
                className="mdi mdi-magnify apply-search" 
                onClick={searchClicked}
            ></span>
        </div>
    </div>
  )
}

export default SearchWrapper