export class ApiError extends Error {
    url: string;
    status: number;
    json?: any;

    constructor(message: string, url: string, status: number, json?: any) {
        super(message);
        this.url = url;
        this.status = status;
        this.json = json;
    }
}