import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Row, Col, Card, Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { withSwal } from 'react-sweetalert2';

// components
import PageTitle from '../../components/PageTitle';
import Table from '../../components/Table';

import AddUser from './AddUser';
import "./users.scss";
// dummy data
import { useTranslation } from "react-i18next";
import { createUser, deleteUser, editUser, getUsers } from "../../utils/api/users";
import { User } from "../../interfaces/User";
import { ApiError } from "../../utils/api/ApiError";
import Loader from "../../components/Loader";
import { APICore } from "../../utils/api/apiCore";
import { useQuery } from "../../hooks";
import ArrayColumn from '../../components/ArrayColumn';
import { FileType } from '../../interfaces/FileType';


// main component
const Users = withSwal((props: any) => {

    const [searchValue, setSearchValue] = useState('');
    const [search, setSearch] = useState('');
    const [showInactive, setShowInactive] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [users, setUsers] = useState<User[]>([]);
    const [total, setTotal] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(0);
    const [startPage, setStartPage] = useState(0);
    const [page, setPage] = useState(0);
    const [selectedUser, setSelectedUser] = useState<User>();
    const [showUploadLink, setShowUploadLink] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [isRelevantForTaxReturn, setIsRelevantForTaxReturn] = useState(undefined);
    const [isVIP, setIsVIP] = useState(undefined);
    const [isStarred, setIsStarred] = useState(undefined);
    const [pensionStatus, setPensionStatus] = useState(undefined);
    const [showAddUser, setShowAddUser] = useState<boolean>(false);


    const systemUser = new APICore().getLoggedInUser();
    const canEdit = (systemUser.user.isAdmin || systemUser.user.isAdmin);

    const { t } = useTranslation();
    const navigate = useNavigate();
    const query = useQuery();

    const handleUsers = async () => {
        try {
            setLoading(true);

            const result = await getUsers({ search, page, showInactive });

            setUsers(result.users);
            setTotal(result.total);
            setItemsPerPage(result.itemsPerPage);
            setLoading(false);
        } catch (err) {
            setLoading(false);

            if (err instanceof ApiError && err.status === 401)
                return navigate('/auth/logout');

            alert("Can't get users");
            console.log(err);
        }
    }

    const getQueryPage = () => {
        let page = parseInt(query.get('page') || '1') - 1;
        if (page < 0) {
            return 0;
        }
        return page;
    }

    useEffect(() => {
        document.title = 'Users';

        return () => {
            document.title = 'Better Admin';
        }
    }, [])

    useEffect(() => {
        setStartPage(getQueryPage());
        setPage(getQueryPage())
    }, []);

    useEffect(() => {
        handleUsers();
    }, [page, search, showInactive]);

    const paginationChanged = (page: number) => {
        navigate({
            search: `?page=${page + 1}`
        });
        setPage(page);
    }

    const CheckboxColumn = (params: any) => {
        const user = params.row.original;

        return (
            <span>
                <input readOnly checked={params.row.original[params.column.id]} type="checkbox" name="" id="" />
            </span>
        )
    }
    const ModulesColumn = (params: any) => {
        const user = params.row.original;

        return (
            <span>
                {user.agentModules.join(', ')}
            </span>
        )
    }

    const ActionColumn = ({ row }: { row: any }) => {

        return (
            <React.Fragment>
                <span className="action-icon">
                    <OverlayTrigger placement="top" overlay={<Tooltip id="">User referral link</Tooltip>}>
                        <a href={`https://allbetter.co.il/user/${row.original._id}`} target="_blank" rel="noopener noreferrer">
                            <i className="mdi mdi-link"></i>
                        </a>
                    </OverlayTrigger>
                </span>

            </React.Fragment>
        );
    };

    const columns = [
        {
            Header: 'Name',
            accessor: 'fullName',
            classes: 'table-user',
        },
        {
            Header: 'Gender',
            accessor: 'gender',
            classes: 'table-user',
        },
        {
            Header: 'phone',
            accessor: 'phoneNumber',
        },
        {
            Header: 'Email',
            accessor: 'email',
        },
        {
            Header: 'User Role',
            accessor: 'roles',
            classes: 'table-user',
            Cell: ArrayColumn
        },
        {
            Header: 'Is Admin',
            accessor: 'isAdmin',
            classes: 'table-user',
            Cell: CheckboxColumn
        },
        {
            Header: "Notify By Whatsapp",
            accessor: "notifyByWhatsapp",
            classes: "table-user",
            Cell: CheckboxColumn,
        },
        {
            Header: 'Agent License',
            accessor: 'agentLicense',
        },
        {
            Header: 'Modules',
            accessor: 'agentModules',
            Cell: ModulesColumn
        },
        {
            Header: 'Meetings/day',
            accessor: 'meetingSlots'
        },
        {
            Header: 'Action',
            accessor: 'action',
            classes: 'table-action',
            Cell: ActionColumn,
        },
    ];

    const onSearchChanged = (e: any) => {
        setSearchValue(e.target.value);
    }

    const onCloseAddUserModal = () => setShowAddUser(false);

    const onAddUser = async (newUser: User, oldUser?: User, file?: FileType) => {
        try {
            if (oldUser) { // Edit existing user:                
                await editUser(oldUser._id, newUser, file)
                const newUsers = [...users];
                const user: User = { ...newUsers.find(u => u._id === oldUser._id), ...newUser };
                const index = newUsers.findIndex(u => u._id === oldUser._id);
                newUsers[index] = user;
                if (!user.isActive) {
                    newUsers.splice(index, 1);
                }
                setUsers(newUsers);
            } else { // Creating new user:
                const result = await createUser(newUser);
                const newUsers = [...users, result.user];
                setUsers(newUsers);
            }
            onCloseAddUserModal();
        } catch (err) {
            console.log(err);
            alert('Something went wrong...');
        }
    };

    const onUserSelected = (selectedUser: User) => {
        if (!canEdit) {
            return;
        }
        setShowAddUser(true);
        setSelectedUser(selectedUser);
    }

    const onOpenCreateUserModal = () => {
        setSelectedUser(undefined);
        setShowAddUser(true);
    }

    const onDeleteUser = async (user: User) => {
        try {
            if (!window.confirm(`Are you sure you want to delete the user ${user.firstName} ${user.lastName}?`))
                return;

            const result = await deleteUser(user._id);
            const index = users.findIndex(u => u._id === user._id);
            users.splice(index, 1);

            setUsers([...users]);
        } catch (err) {
            console.log(err);
            alert('Something went wrong...');
        }

        onCloseAddUserModal();
    };


    const handleKeyDown = async (event: any) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            setSearch(searchValue);
            setPage(0);
        }
    }

    return (
        <React.Fragment>

            <PageTitle
                breadCrumbItems={[
                    { label: 'Users', path: '/apps/crm/Users', active: true },
                ]}
                title={'Users'}
            />


            <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            {loading && <Loader />}
                            <Row className="mb-4 search-wrapper">
                                {/*<Col sm={4}>*/}
                                {/*   /!* { canEdit ? <Button variant="primary " className="waves-effect waves-light" onClick={onOpenCreateUserModal}>*/}
                                {/*       <i className="mdi mdi-plus-circle me-1"></i> Add User*/}
                                {/*   </Button> : <></>} *!/*/}
                                {/*</Col>*/}

                                <Col sm={4}>

                                    <form className="search-bar form-inline">
                                        <input
                                            type="text"
                                            value={searchValue}
                                            className="form-control"
                                            placeholder="Search by name or phone number"
                                            onChange={onSearchChanged}
                                            onKeyDown={handleKeyDown}
                                        />
                                        <span className="mdi mdi-magnify apply-search" onClick={() => setPage(0)}></span>
                                        <div className='mt-2 mx-1 d-flex align-items-center gap-1'>
                                            <input
                                                type="checkbox"
                                                name="showInactiveUsers"
                                                id="showInactiveUsers"
                                                onChange={(e) => setShowInactive(e.target.checked)}
                                                checked={showInactive}
                                            />
                                            <label htmlFor="showInactiveUsers">Show Inactive Users</label>
                                        </div>
                                    </form>

                                </Col>

                                <Col sm={5}>
                                    {canEdit ? <Button variant="primary " className="waves-effect waves-light" onClick={onOpenCreateUserModal}>
                                        <i className="mdi mdi-plus-circle me-1"></i> Add User
                                    </Button> : <></>}
                                </Col>

                            </Row>

                            <Row><Col>Total: {total}</Col></Row>

                            <Row>
                                {itemsPerPage ?
                                    <Table
                                        columns={columns}
                                        data={users}
                                        onRowClicked={(row) => onUserSelected(row.original)}
                                        cellClick={[0, 1, 2, 3, 4, 5, 6, 7, 8]}
                                        paginationProps={{
                                            onPageSelected: (page: number) => { paginationChanged(page) },
                                            totalItems: total,
                                            numberOfPages: Math.ceil(total / itemsPerPage),
                                            startPage: startPage
                                        }}
                                        pageSize={itemsPerPage}
                                        isSortable={true}
                                        pagination={true}
                                        isSelectable={false}
                                        tableClass="table-nowrap table-striped"
                                    />
                                    : null}
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            {showAddUser && <AddUser show={showAddUser} onHide={onCloseAddUserModal} onAddUser={onAddUser} user={selectedUser} />}

        </React.Fragment>
    );
});

export default Users;
