import qs from 'qs';
import React, { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ParsedQs } from 'qs';
import isEqual from 'lodash/isEqual';

export const useLocationQuery = (): ParsedQs => {
  const { search } = useLocation();
  return qs.parse(search, { ignoreQueryPrefix: true });
};

export function usePrevious<T>(value: T): T | null {
  const ref = useRef<T | null>(null);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export default function useQueryStringSync<T extends Object>(memoizedValue: T, addToCurrentQuery = true) {
  const query = useLocationQuery();
  const navigate = useNavigate();
  const previousValue = usePrevious(memoizedValue);
  React.useEffect(() => {
    if (memoizedValue === previousValue || isEqual(memoizedValue, previousValue)) {
      // The query string isn't changing, so let's bail out rather than creating an infinite loop.
      return;
    }
    
    const newSearch = qs.stringify(
      addToCurrentQuery
        ? {
            // Conditionally spread query only if addToCurrentQuery is true
            ...query,
            ...memoizedValue,
          }
        : memoizedValue, // Otherwise, use only memoizedValue
      { addQueryPrefix: true },
    );
    navigate(newSearch, { replace: true });

  }, [memoizedValue, previousValue, query, navigate]);
}

export function useQueryStringSingleFieldSync(
  key: string,
  memoizedValue: Object,
) {
  useQueryStringSync(
    React.useMemo(
      () => ({
        [key]: memoizedValue,
      }),
      [key, memoizedValue],
    ),
  );
}