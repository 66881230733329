import { APICore, API_URL } from './apiCore';
import { User } from "../../interfaces/User";
import { FileType } from '../../interfaces/FileType';

const api = new APICore();

const getUsers = (params?: { page?: number, search?: string, showInactive?: boolean }) => {
    const relativeUrl = '/users';
    console.trace('getUsers');
    return api.get(`${API_URL}${relativeUrl}`, params);
}

const getUser = (id: string) => {
    const relativeUrl = '/users/' + id;
    return api.get(`${API_URL}${relativeUrl}`);
}

const createUser = (userData: User, file?: FileType) => {
    const relativeUrl = '/users';
    if (file) {
        const userDataWithFile = { ...userData, file };
        return api.createWithFile(`${API_URL}${relativeUrl}`, userDataWithFile);
    }
    return api.create(`${API_URL}${relativeUrl}`, userData);
}

const editUser = (id: string, user: any, file?: FileType) => {
    const relativeUrl = '/users/' + id;
    if (file) {
        user.file = file;
        return api.updateWithFile(`${API_URL}${relativeUrl}`, user);
    }
    return api.update(`${API_URL}${relativeUrl}`, user);
}

const deleteUser = (id: string) => {
    const relativeUrl = '/users/' + id;
    return api.delete(`${API_URL}${relativeUrl}`);
}

const logout = (token: string) => {
    const relativeUrl = '/users/logout';
    return api.get(`${API_URL}${relativeUrl}`, undefined, token);
}

const assignMessages = (fromRepId: string, toRepId: string) => {
    const relativeUrl = '/messages/assign-messages';
    return api.create(`${API_URL}${relativeUrl}`, { fromRepId, toRepId });
}

const removeAssignment = (repId: string) => {
    const relativeUrl = '/messages/assign-messages';
    return api.update(`${API_URL}${relativeUrl}`, { repId });
}

export {
    assignMessages,
    removeAssignment,
    editUser,
    logout,
    getUser,
    deleteUser,
    getUsers,
    createUser
}