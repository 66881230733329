import {APICore, API_URL, LOAN_URL} from './apiCore';

const api = new APICore();

const createLoan = (clientId: string) => {
    return api.create(`${LOAN_URL}/loans`, {clientId});
}

const editLoan = ( taxReturnId: string, body: any ) => {
    const relativeUrl = `/loans/${taxReturnId}`;
    return api.update(`${LOAN_URL}${relativeUrl}`, body);
}

const getLoanStatuses = () => {
    return api.get(`${LOAN_URL}/loans/statuses`);
}

export {
    getLoanStatuses,
    createLoan,
    editLoan
}