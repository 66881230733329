import React, { useContext, useEffect, useState } from 'react'
import { UserProfileContext } from '../../../context/userProfileContext';
import InputWrapper from '../../../components/inputWrapper/InputWrapper';
import { User } from '../../../interfaces/User';
import { camelCaseToText } from '../../../utils/camelCaseToText';
import Modules from "../../../utils/modules";
import { Investment } from "../../../interfaces/Investment";
import { formatDate } from '../../../utils/formatDate';
import { Client } from '../../../interfaces/Client';
import { getAllModulesStatuses } from '../../../utils/api/clientsBySearch';

const Details = () => {
    const { profileContextData, onEditRound, users } = useContext(UserProfileContext);
    const { currentRound, client } = profileContextData as { currentRound: Investment, client: Client };

    const [riskToleranceOptions, setRiskToleranceOptions] = useState<string[]>([]);
    useEffect(() => {
        const getRiskToleranceOptions = async () => {
            const response = await getAllModulesStatuses();
            const options = response.RiskTolerance;
            setRiskToleranceOptions(Object.values(options));
        }
        getRiskToleranceOptions();
    }, []);

    const selectedModule = profileContextData.selectedModule;
    const allowedStatusesOptions = selectedModule && Object.values(selectedModule.allowedStatuses);
    const selectedModuleName = selectedModule?.data.moduleName;
    const repType = selectedModuleName === Modules.pension ? 'agent' : 'rep';

    const [repAgents, setRepAgents] = useState<User[]>(users.filter((rep: User) => rep.roles.includes("agent") && rep.agentModules?.includes("investment")));
    console.log("users", users.filter((rep: User) => rep.roles.includes("agent")));
    useEffect(() => {
        setRepAgents(users.filter((rep: User) => rep.roles.includes("agent") && rep.agentModules?.includes("investment")));
    }, [users]);

    const lastChangeDateString = currentRound?.lastStatusChangeDate ? `Status (Last change: ${formatDate(new Date(currentRound.lastStatusChangeDate))})` : 'Status';

    return (
        <div className='detailed-tab'>
            <div className='select-area'>
                {client && currentRound ?
                    <>
                        {allowedStatusesOptions && allowedStatusesOptions.length > 0 ?
                            <InputWrapper status={{ text: '' }} label={lastChangeDateString} wrapperClass='input-field select-field'>
                                <select
                                    value={currentRound.status || ''}
                                    className='form-select'
                                    name="status"
                                    onChange={(e: any) => onEditRound({ status: e.target.value }, selectedModuleName, currentRound._id)}
                                >
                                    <option disabled value={''}>Select status</option>
                                    {/* {
                        !allowedStatusesContainsCurrentStatus &&
                          <option disabled value={client.pensionStatus} > {client.pensionStatus} </option>  
                      } */}

                                    {allowedStatusesOptions.map((option: any, index: number) => (
                                        <option key={index} value={option}>
                                            {camelCaseToText(option)}
                                        </option>
                                    ))}
                                </select>
                            </InputWrapper>
                            : null}

                        {repAgents && repAgents.length > 0 ?
                            <InputWrapper status={{ text: '' }} label={'Agent / Rep'}
                                wrapperClass='input-field select-field'>
                                <select
                                    value={currentRound.rep}
                                    className='form-select'
                                    name="rep"
                                    onChange={(e: any) => {
                                        onEditRound({ [repType]: e.target.value }, selectedModuleName, currentRound._id)
                                    }}
                                >
                                    <option disabled value="">Select {repType} </option>

                                    {repAgents.map((option: any, index: number) => (
                                        <option key={index} value={option._id}>
                                            {option.fullName}
                                        </option>
                                    ))}
                                </select>
                            </InputWrapper>
                            :
                            <div className="pension-status">
                                No {repType} found.
                            </div>
                        }
                        <InputWrapper status={{ text: '' }} label={'Risk Tolerance'}
                            wrapperClass='input-field select-field'>
                            <select
                                value={currentRound.riskTolerance || ''}
                                className='form-select'
                                name="rep"
                                onChange={(e: any) => {
                                    onEditRound({ riskTolerance: e.target.value }, selectedModuleName, currentRound._id)
                                }}
                            >
                                <option disabled value="">Select Risk Tolerance </option>

                                {riskToleranceOptions.map((option: any, index: number) => (
                                    <option key={index} value={option}>
                                        {camelCaseToText(option)}
                                    </option>
                                ))}
                            </select>
                        </InputWrapper>
                    </>
                    : <div>Sorry, no data yet.</div>}
            </div>
        </div>
    )
}

export default Details;