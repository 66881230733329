import React, { useState, useContext, useEffect } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { UserProfileContext } from "../../context/userProfileContext";
import { getAllBots } from "../../utils/api/automated-messages";
import { FileType } from '../../interfaces/FileType';
import FileUploadButton from "../fileUploadButton";
import { camelCaseToText } from '../../utils/camelCaseToText';

interface BotDropdownProps {
    onBotSelected: (botName: string) => void;
    onUploadTestBot: (file: FileType | undefined) => void;
}

const BotsDropdown = ({ onBotSelected, onUploadTestBot }: BotDropdownProps) => {
    const [bots, setBots] = useState<any[]>([]);
    const [groupedBots, setGroupedBots] = useState<Map<string, any[]>>(new Map());
    const { profileContextData } = useContext(UserProfileContext);
    const { client } = profileContextData;

    const moduleName = profileContextData?.selectedModule?.data?.moduleName;

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [activeModule, setActiveModule] = useState<string | null>(null);

    useEffect(() => {
        setActiveModule(moduleName);
    }, [moduleName]);

    const handleModuleToggle = (module: string) => {
        setActiveModule(activeModule === module ? null : module);
    };

    useEffect(() => {
        const fetchBots = async () => {
            const response = await getAllBots()
            setBots(response.bots);
        };

        fetchBots();
    }, []);

    useEffect(() => {
        const grouped = bots.reduce((acc, bot) => {

            let { module } = bot;
            if (!module) {
                module = 'Other';
            }

            if (!acc.has(module)) {
                acc.set(module, []);
            }
            acc.get(module).push(bot);
            return acc;
        }, new Map());

        setGroupedBots(grouped);
    }, [bots, moduleName]);


    const handleDropdownToggle = (isOpen: boolean, ...args: any[]) => {
        setIsDropdownOpen(isOpen);
    };


const getHeaderClassName = (index: number, array: any[]) => {
    let classNames = "fixed-width d-flex justify-content-between align-items-center fw-bold fs-5 cursor-pointer text-dark w-100";
    if (index > 0 && array[index - 1][0] === activeModule) {
      classNames += " border-top-0";
    } else {
      classNames += " border-top";
    }
    if (index === array.length - 1) {
      classNames += " border-bottom";
    }
    return classNames;
  };

    return (
        <div className="assign-bot">
            <Dropdown className="select-action" align={'end'} onToggle={handleDropdownToggle} show={isDropdownOpen}>
                <Dropdown.Toggle as="a" className="btn-sm card-drop cursor-pointer">
                    <span>Assign bot<i className="mdi mdi-chevron-down"></i></span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {Array.from(groupedBots.entries()).map(([module, bots], index, array) => (
                        <React.Fragment key={module}>
                            <Dropdown.Header
                                className={getHeaderClassName(index, array)}
                                onClick={() => handleModuleToggle(module)}
                            >
                                {camelCaseToText(module)}
                                <span className="accordion-icon">{activeModule === module ? '➖' : '➕'}</span>
                            </Dropdown.Header>
                            {activeModule === module && bots.map(bot =>
                                <Dropdown.Item key={bot.name} onClick={() => onBotSelected(bot.name)}>{bot.name}</Dropdown.Item>
                            )}
                        </React.Fragment>
                    ))}
                    <Dropdown.Item className="border-0" key={"test-bot"}>
                        <FileUploadButton className="test-json-button text-primary" handleFileUpload={onUploadTestBot} text="Upload test bot" />
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
};

export default BotsDropdown;

  