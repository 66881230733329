import React, { useEffect, useState } from 'react';
import { PersonTaxData } from '../../../../../interfaces/TaxData';
import { getAllModulesStatuses } from '../../../../../utils/api/clientsBySearch';
import { useTranslation } from 'react-i18next';
import NationalServiceBox from './NationalServiceBox';
import { Option } from '../../../../../interfaces/Option';
import { isThereDataToPresent } from '../utils';
import ImmigrantStatusBox from './ImmigrantStatusBox';
import DegreeCertificate from './DegreeCertificateBox';


interface GeneralDataFormProps {
    data: PersonTaxData;
    onChange: (e: any) => void;
    prefix: string;
}

const GeneralDataForm = ({ data, onChange, prefix }: GeneralDataFormProps) => {
    const { t } = useTranslation("taxReturn");
    const [serviceTypesOptions, setServiceTypesOptions] = useState<Option>({ label: '', value: '' });
    const [immigrationTypesOptions, setImmigrationTypesOptions] = useState<Option>({ label: '', value: '' });
    const [certificateTypesOptions, setCertificateTypesOptions] = useState<Option>({ label: '', value: '' });

    useEffect(() => {
        const fetchTypes = async () => {
            const response = await getAllModulesStatuses();
            setServiceTypesOptions(response.ServiceTypes);
            setImmigrationTypesOptions(response.ImmigrationTypes);
            setCertificateTypesOptions(response.CertificateTypes);
        }
        fetchTypes();
    }, []);

    const commonBoxProps = {
        t,
        prefix,
        data,
        onChange,
    };

    const isAnyDataPresent = isThereDataToPresent(data.nationalService) ||
        isThereDataToPresent(data.newImmigrant) ||
        isThereDataToPresent(data.degreeCertificate);

    return (
        <div className="data-form">
            {isThereDataToPresent(data.nationalService) && (
                <NationalServiceBox
                    {...commonBoxProps}
                    serviceTypesOptions={serviceTypesOptions}
                />
            )}
            {isThereDataToPresent(data.newImmigrant) && (
                <ImmigrantStatusBox
                    {...commonBoxProps}
                    immigrationTypesOptions={immigrationTypesOptions}
                />
            )}
            {isThereDataToPresent(data.degreeCertificate) && (
                <DegreeCertificate
                    {...commonBoxProps}
                    certificateTypesOptions={certificateTypesOptions}
                />
            )}
            {!isAnyDataPresent && <p className="text-center">{t('noDataAvailable')}</p>}
        </div>
    )
}

export default GeneralDataForm;




