const removeTags = (str: string)=> {

    if ((str === null) || (str === ''))
        return;
    else
        str = str.toString();

    // replace multiple spaces or new lines with one space
    // str = str.replace(/(\r\n|\n|\r| )+/gm," ");
    //
    // // replace all html tags except <p>
    //replace <p> and </p> tags with ***
    str = str.replace(/<p>/gm,"***");
    str = str.replace(/<\/p>/gm,"***");
    return str.replace( /(<([^>]+)>)/ig, '');


    // str = str.replace("\n","");
    // // replace all html tags except <p> and </p>
    // str = str.replace(/<(?!\/?p)([^>]+)>/ig, '');
    // str = str.replace("<p>","");
    // str = str.replace("</p>","\n");
}

export {
    removeTags
};