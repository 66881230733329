import React, { useContext } from 'react';
import { updateChat } from '../../utils/api/chat';
import { Chat, ChatStatuses } from '../../interfaces/Chat';
import InfiniteScroll from 'react-infinite-scroll-component';
import ChatItem from './ChatItem';
import { chatContext } from '../../context/chatContext';

interface ChatListProps {
  chatList: Chat[],
  setChatList: any,
  hasMore: boolean
}

const ChatList = ({ chatList, setChatList, hasMore }: ChatListProps) => {

  const { chatData, setChatData, page, setPage } = useContext(chatContext);

  const optimisticUpdate = (field: string, item: any, value: string | number | undefined) => {
    setChatList(chatList.map((listItem: any) => {
      if (listItem._id === item._id) {
        if (field === "isStarred" || field === "isPinned") {
          return { ...listItem, [field]: !item[field] }
        }
      }
      return listItem;
    }))
  }


  const starChat = async (chat: Chat) => {
    try {
      optimisticUpdate("isStarred", chat, ChatStatuses.transferredToManager);
      await updateChat(chat._id, { isStarred: !chat.isStarred });
    } catch (error) {
      console.log(error)
      alert("can't do this right now");
      optimisticUpdate("isStarred", chat, ChatStatuses.transferredToManager);
    }
  }

  const pinChat = async (chat: Chat) => {
    try {
      optimisticUpdate("isPinned", chat, ChatStatuses.transferredToManager);
      await updateChat(chat._id, { isPinned: !chat.isPinned });
    } catch (error) {
      console.log(error)
      alert("can't do this right now");
      optimisticUpdate("isPinned", chat, ChatStatuses.transferredToManager);
    }
  }

  const resetUnreadCountChat = (chat: Chat) => {
    setChatList(chatList.map((listItem: any) => {
      if (listItem._id === chat._id) {
        return { ...listItem, unreadCount: 0 }
      }
      return listItem;
    }))
  }

  const setUnreadCountChat = async (chat: Chat) => {
    const updatedChatList = chatList.map((listItem: any) => {
      if (listItem._id === chat._id) {
        return { ...listItem, unreadCount: 1 }
      }
      return listItem;
    })
    try {
      await updateChat(chat._id, { unreadCount: 1 });
      setChatList(updatedChatList);
    }
    catch (error) {
      console.log(error);
      alert("can't do this right now");
    }
  }

  const onChatSelected = (chat: Chat) => {
    setChatData((prev: any) => ({
      ...prev,
      selectedChat: chat,
      isSelectedNotActive: chat.status === ChatStatuses.inactive,
      selectedUnreadCount: chat.unreadCount
    }));
    if (chat.unreadCount > 0) {
      resetUnreadCountChat(chat);
    }
  }

  const next = () => {
    setPage((page || 0) + 1);
  }

  return (
    <ul className={`chat-list ${chatData.systemUser?.isManager ? "is-manager" : ""}`}>
      <InfiniteScroll
        dataLength={chatList.length}
        next={next}
        hasMore={hasMore}
        loader={<h4 style={{ textAlign: "center" }}>{"Loading..."}</h4>}
        height={'50vh'}
        endMessage={
          <p style={{ textAlign: "center", marginTop: '15px' }}>
            <b>No more chats.</b>
          </p>
        }
      >
        {
          chatList?.filter((chat: Chat) => chat.client).map((chat: Chat) =>
            <ChatItem
              key={chat._id}
              onChatSelected={onChatSelected}
              chat={chat}
              starChat={starChat}
              pinChat={pinChat}
              setUnreadCountChat={setUnreadCountChat}
            />
          )
        }
      </InfiniteScroll>
    </ul>
  )
}

export default ChatList;
