
const SourceTypes = Object.freeze({
    direct: "direct",
	lecture: "lecture",
	campaign: "campaign",
	referral: "referral",
	affiliate: "affiliate",
	employeeReferral: "employeeReferral",
	spouse: "spouse",
	relative: "relative",
    reserveForces: "reserveForces",
    web1: "web1",
    web2: "web2",
});

const CampaignTypes = Object.freeze({
    facebook: "facebook",
    youtube: "youtube",
    linkedin: "linkedin",
    taboola: "taboola",
    outbrain: "outbrain",
    geektime: "geektime",
    twitter: "twitter",
    hr: 'hr',
    tiktok: 'tiktok',
    google: 'google',
    webbee: 'webbee',
});

const TimeOfDay = Object.freeze([
    {name: "morning", value: [0, 10]},
    {name: "noon", value: [10, 12]},
    {name: "afternoon", value: [12, 15]},
    {name: "evening", value: [15, 24]}
]);

const Days = Object.freeze([
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday'
]);

const PensionTransferredOptions = Object.freeze([
    "noTransfer",
	"noPensionInTransfer",
	"activePension"
]);

const MeetingAttendance = Object.freeze([
    "firstSchedule",
    "secondSchedule",
    "thirdScheduleOrMore",
    "noShowWithFutureSchedule",
    "noShowWithNoFutureSchedule"
]);

const RegistrationToOnboardingDays = Object.freeze([
    {name: "zeroToOneDay", value: [-1, 1]},
    {name: "oneToTwoDays", value: [1, 2]},
    {name: "twoToFourDays", value: [2, 4]},
    {name: "fourToSevenDays", value: [4, 7]},
    {name: "sevenToFourteenDays", value: [7, 14]},
    {name: "fourteenToTwentyOneDays", value: [14, 21]},
    {name: "twentyOnePlusDays", value: [21, Infinity]}
]);

const PensionSavingAmounts = Object.freeze([
    {name: "zero", value: [-Infinity, 0]},
    {name: "oneToTenThousand", value: [0, 10000]},
    {name: "tenToHundredThousand", value: [10000, 100000]},
    {name: "hundredToTwoHundredFiftyThousand", value: [100000, 250000]},
    {name: "twoHundredFiftyToFiveHundredThousand", value: [250000, 500000]},
    {name: "fiveHundredThousandPlus", value: [500000, Infinity]}
]);

const GroupBy = Object.freeze({
    all: "all",
    registrationToOnboarding: "registrationToOnboarding",
    savingAmount: "savingAmount",
    onboardingRep: "onboardingRep",
    pensionAgent: "pensionAgent",
    source: "source",
    onboardingDate: "onboardingDate",
    pensionAgentMeetingDate: "pensionAgentMeetingDate",
    pensionTransferred: "pensionTransferred",
    day: "daily",
    week: "weekly",
    month: "monthly",
    onboardingTimeOfDay: "onboardingTimeOfDay",
    onboardingDayOfWeek: "onboardingDayOfWeek",
    pensionTimeOfDay: "pensionTimeOfDay",
    pensionDayOfWeek: "pensionDayOfWeek",
    onboardingMeetingAttendance: "onboardingMeetingAttendance",
    pensionMeetingAttendance: "pensionMeetingAttendance",
    age: "age",
    salary: "salary",
    gender: "gender",
    maritalStatus: "maritalStatus",
    abTest: "abTest",
    campaignName: "campaignName",
    pensionSignDays: "pensionSignDays"
});


const DashboardExtraStatuses = Object.freeze({
    onboardingDate: "onboardingDate",
    pensionAgentCallDate: "pensionAgentCallDate",
    onboardingCallShow: "onboardingCallShow",
    pensionAgentCallShow: "pensionAgentCallShow"
})

const AgeRanges = Object.freeze({
    betweenEighteenAndTwentyFive: "betweenEighteenAndTwentyFive",
    betweenTwentyFiveAndThirtyFive: "betweenTwentyFiveAndThirtyFive",
    betweenThirtyFiveAndFortyFive: "betweenThirtyFiveAndFortyFive",
    betweenFortyFiveAndFiftyFive: "betweenFortyFiveAndFiftyFive",
    betweenFiftyFiveAndSixtyFive: "betweenFiftyFiveAndSixtyFive",
    betweenSixtyFiveAndSeventyFive: "betweenSixtyFiveAndSeventyFive",
    aboveSeventyFive: "aboveSeventyFive",
});

const SalaryRanges = Object.freeze({
    betweenZeroAndTenK: "betweenZeroAndTenK",
    betweenTenKAndTwentyK: "betweenTenKAndTwentyK",
    betweenTwentyKAndThirtyK: "betweenTwentyKAndThirtyK",
    betweenThirtyKAndFortyK: "betweenThirtyKAndFortyK",
    betweenFortyKAndFiftyK: "betweenFortyKAndFiftyK",
    betweenFiftyKAndSixtyK: "betweenFiftyKAndSixtyK",
    betweenSixtyKAndSeventyK: "betweenSixtyKAndSeventyK",
    aboveSeventyK: "aboveSeventyK",
});

const MaritalStatuses = Object.freeze({
	married: "married",
	widowed: "widowed",
	divorced: "divorced",
	single: "single",
	commonLaw: "commonLaw"
});

const Genders = Object.freeze({
	male: "male",
	female: "female"
});

const PensionSignDays = Object.freeze({
    "0": "0",
    "1": "1",
    "2": "2",
    "3": "3",
    "4": "4",
    "5" : "5",
    "6" : "6",
    "7+" : "7+"
});

export {    
    SourceTypes,
    CampaignTypes,
    TimeOfDay,
    Days,
    RegistrationToOnboardingDays,
    PensionSavingAmounts,
    GroupBy,
    DashboardExtraStatuses,
    PensionTransferredOptions,
    MeetingAttendance,
    AgeRanges,
    SalaryRanges,
    MaritalStatuses,
    Genders,
    PensionSignDays
}