import React, { useContext, useEffect, useState } from 'react'
import { UserProfileContext } from '../../../context/userProfileContext';
import InputWrapper from '../../../components/inputWrapper/InputWrapper';
import { User } from '../../../interfaces/User';
import { camelCaseToText } from '../../../utils/camelCaseToText';
import Modules from "../../../utils/modules";
import { Insurance, InsuranceClosedLostReasons, InsurancePolywizzStatuses, polywizzFields } from "../../../interfaces/Insurance";
import { formatDate } from '../../../utils/formatDate';
import { DebounceInput } from 'react-debounce-input';
import { displayNumericInputFieldsValue } from '../../../utils/displayNumericValues';
import FamilyMemebrsToInsureCheckBoxes from './FamilyMemebrsToInsureCheckBoxes';
import { Tooltip } from '@mui/material';
import { resubmitPolywizz } from '../../../utils/api/insurance';
import Spinner from 'react-bootstrap/Spinner';
import { is } from 'date-fns/locale';

const closedLost = 'closedLost'

const Details = () => {
    const { profileContextData, onEditRound, users } = useContext(UserProfileContext);
    const { client } = profileContextData;
    const selectedModule = profileContextData.selectedModule;
    const allowedStatusesOptions = selectedModule && Object.values(selectedModule.allowedStatuses);
    const selectedModuleName = selectedModule?.data.moduleName;
    const repType = [Modules.pension, Modules.insurance].includes(selectedModuleName) ? 'agent' : 'rep';

    const [currentRound, setCurrentRound] = useState<Insurance>(profileContextData.currentRound as Insurance)
    const [repAgents, setRepAgents] = useState<User[]>(users.filter((rep: User) => rep.roles.includes('agent') && rep.agentModules?.includes("insurance")));
    const [analysts, setAnalysts] = useState<User[]>(users.filter((rep: User) => rep.roles.includes('analyst')));
    const [operators, setOperators] = useState<User[]>(users.filter((rep: User) => rep.roles.includes('operator')));
    const [isPolywizzResubmitLodading, setIsPolywizzResubmitLodading] = useState<boolean>(false);
    const [isPolywizzResubmitSuccess, setIsPolywizzResubmitSuccess] = useState<boolean>(false);

    const handleStatusChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const newStatus: string = event.target.value
        if (newStatus !== closedLost) {
            onEditRound({ status: newStatus }, selectedModuleName, currentRound._id)
        }
        setCurrentRound({ ...currentRound, status: newStatus, closedLostReason: undefined })
    }

    const handleReasonChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const newReason: InsuranceClosedLostReasons = event.target.value as InsuranceClosedLostReasons
        onEditRound({ status: closedLost, closedLostReason: newReason }, selectedModuleName, currentRound._id, () => setCurrentRound({ ...currentRound, status: closedLost, closedLostReason: newReason }))
    }

    const onFieldsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        onEditRound({ [name]: value }, selectedModuleName, currentRound._id, () => setCurrentRound({ ...currentRound, [name]: value }))
    }

    useEffect(() => {
        setRepAgents(users.filter((rep: User) => rep.roles.includes('agent') && rep.agentModules?.includes("insurance")));
        setAnalysts(users.filter((rep: User) => rep.roles.includes('analyst') && rep.agentModules?.includes("insurance")));
        setOperators(users.filter((rep: User) => rep.roles.includes('operator')));
    }, [users]);

    const handleResubmitPolywizz = async (signee: "client" | "spouse") => {
        try {
            setIsPolywizzResubmitLodading(true)
            await resubmitPolywizz(client._id, signee)
            setIsPolywizzResubmitSuccess(true)
        }
        catch (err) {
            console.log(err)
            alert('Something went wrong, please try again later')
        }
        finally {
            setIsPolywizzResubmitLodading(false)
        }
    }

    const onAgentChanged = (agentId: string) => {
        const prevAgentId = currentRound[repType];
        setCurrentRound({ ...currentRound, [repType]: agentId })
        onEditRound({ [repType]: agentId }, selectedModuleName, currentRound._id, undefined, () => setCurrentRound({ ...currentRound, [repType]: prevAgentId }))
    }

    const getStatusContent = (data: polywizzFields, signee: "client" | "spouse") => {
        if (!data) return null;
        const status = data.status;
        if (!status) return null;

        if ([InsurancePolywizzStatuses.failed, InsurancePolywizzStatuses.timeout, InsurancePolywizzStatuses.missingData].includes(status)) {
            return (
                <span className='d-inline-flex align-items-center gap-1'>
                    Failed{" "}
                    <Tooltip arrow placement="top" title="Resubmit Polywizz">
                        {isPolywizzResubmitLodading
                            ? <Spinner animation="border" variant="secondary" size="sm" />
                            : isPolywizzResubmitSuccess
                                ? <i className="fas fa-check-circle text-success"></i>
                                : <i className="mdi mdi-reload text-secondary cursor-pointer" onClick={() => handleResubmitPolywizz(signee)}></i>
                        }
                    </Tooltip>
                </span>
            );
        } else {
            return camelCaseToText(status);
        }
    };

    const showKitStatus = () => {
        if (client && currentRound && currentRound.polywizzTracker) {
            const { client: clientData, spouse: spouseData } = currentRound.polywizzTracker;

            const clientStatusContent = getStatusContent(clientData, 'client');
            const spouseStatusContent = getStatusContent(spouseData, 'spouse');

            return (
                <div className='mb-3'>
                    {clientStatusContent &&
                        <div className="">Client's Insurance Kit Status: <span className='fw-normal fs-5'>{clientStatusContent}</span></div>}
                    {spouseStatusContent &&
                        <div className="">Spouse's Insurance Kit Status: <span className='fw-normal fs-5'>{spouseStatusContent}</span></div>}
                </div>
            );
        }
    };
    
    const lastChangeDateString = currentRound?.lastStatusChangeDate ? `Status (Last change: ${formatDate(new Date(currentRound.lastStatusChangeDate))})` : 'Status';

    return (
        <div className='detailed-tab'>
            <div className='select-area' style={{ maxWidth: 'none' }}>
                {client && currentRound ?
                    <div className="row">
                        <div className="col-md-3">
                            {allowedStatusesOptions && allowedStatusesOptions.length > 0 ?
                                <InputWrapper status={{ text: '' }} label={lastChangeDateString} wrapperClass='input-field select-field'>
                                    <select
                                        value={currentRound.status || ''}
                                        className='form-select'
                                        name="status"
                                        onChange={handleStatusChange}
                                    >
                                        <option disabled value={''}>Select status</option>
                                        {/* {
                            !allowedStatusesContainsCurrentStatus &&
                              <option disabled value={client.pensionStatus} > {client.pensionStatus} </option>  
                                                  } */}
                                        {allowedStatusesOptions.map((option: any, index: number) => (
                                            <option key={index} value={option}>
                                                {camelCaseToText(option)}
                                            </option>
                                        ))}
                                    </select>
                                </InputWrapper>
                                : null}
                            {   // If the close is lost, present the reason for it
                                currentRound.status === closedLost &&
                                <InputWrapper {...(!currentRound.closedLostReason && { status: { text: 'Required field' } })} label='Close lost reason' wrapperClass='input-field select-field'>
                                    <select
                                        value={currentRound.closedLostReason || ''}
                                        className='form-select'
                                        name="reason"
                                        onChange={handleReasonChange}
                                    >
                                        <option disabled value={''}>Select reason</option>
                                        {Object.values(InsuranceClosedLostReasons).map((option: any, index: number) => (
                                            <option key={index} value={option}>
                                                {camelCaseToText(option)}
                                            </option>
                                        ))}
                                    </select>
                                </InputWrapper>}
                            {repAgents && repAgents.length > 0 ?
                                <InputWrapper status={{ text: '' }} label={'Agent'}
                                    wrapperClass='input-field select-field'>
                                    <select
                                        value={currentRound.agent}
                                        className='form-select'
                                        name="agent"
                                        onChange={(e: any) => onAgentChanged(e.target.value)}
                                    >
                                        <option disabled value="">Select {repType} </option>
                                        {repAgents.map((option: any, index: number) => (
                                            <option key={index} value={option._id}>
                                                {option.fullName}
                                            </option>
                                        ))}
                                    </select>
                                </InputWrapper>
                                :
                                <div className="pension-status">
                                    No {repType} found.
                                </div>
                            }
                            {analysts && analysts.length > 0 ?
                                <InputWrapper status={{ text: '' }} label={'Analyst'}
                                    wrapperClass='input-field select-field'>
                                    <select
                                        value={currentRound.analyst}
                                        className='form-select'
                                        name="analyst"
                                        onChange={(e: any) => {
                                            onEditRound({ "analyst": e.target.value }, selectedModuleName, currentRound._id, () => setCurrentRound({ ...currentRound, "analyst": e.target.value }))
                                        }}
                                    >
                                        <option disabled value="">Select {'analyst'} </option>
                                        {analysts.map((option: any, index: number) => (
                                            <option key={index} value={option._id}>
                                                {option.fullName}
                                            </option>
                                        ))}
                                    </select>
                                </InputWrapper>
                                :
                                <div className="pension-status">
                                    No analyst found.
                                </div>
                            }
                            {operators && operators.length > 0 ?
                                <InputWrapper status={{ text: '' }} label={'Operator'}
                                    wrapperClass='input-field select-field'>
                                    <select
                                        value={currentRound.operator}
                                        className='form-select'
                                        name="operator"
                                        onChange={(e: any) => {
                                            onEditRound({ "operator": e.target.value }, selectedModuleName, currentRound._id, () => setCurrentRound({ ...currentRound, "operator": e.target.value }))
                                        }}
                                    >
                                        <option disabled value="">Select {'operator'} </option>
                                        {operators.map((option: any, index: number) => (
                                            <option key={index} value={option._id}>
                                                {option.fullName}
                                            </option>
                                        ))}
                                    </select>
                                </InputWrapper>
                                :
                                <div className="pension-status">
                                    No operator found.
                                </div>
                            }
                        </div>
                        <div className='col-md-4' style={{ marginLeft: '30px' }}>
                            <InputWrapper status={{ text: '' }} label='First Kit Signature Date' wrapperClass='input-field input-field'>
                                <input
                                    value={formatDate(currentRound.firstKitSignatureDate, true, false, "en-CA")}
                                    className='form-control'
                                    name="firstKitSignatureDate"
                                    type='date'
                                    onChange={onFieldsChange}
                                // onChange={e => onEditRound({ "firstKitSignatureDate": e.target.value }, selectedModuleName, currentRound._id)}
                                />
                            </InputWrapper>
                            <InputWrapper status={{ text: '' }} label='Appendices Submission Date' wrapperClass='input-field input-field'>
                                <input
                                    value={formatDate(currentRound.appendicesSubmissionDate, true, false, "en-CA")}
                                    className='form-control'
                                    name="appendicesSubmissionDate"
                                    type='date'
                                    onChange={onFieldsChange}
                                />
                            </InputWrapper>
                            <InputWrapper status={{ text: '' }} label='Saving Amount' wrapperClass='input-field input-field'>
                                <DebounceInput
                                    value={displayNumericInputFieldsValue(currentRound.savingAmount)}
                                    className='form-control'
                                    name="savingAmount"
                                    type='number'
                                    min={0}
                                    max={100}
                                    debounceTimeout={1500}
                                    onChange={onFieldsChange}
                                />
                            </InputWrapper>
                            <InputWrapper status={{ text: '' }} label='SMS2010 Link' wrapperClass='input-field input-field'>
                                <DebounceInput
                                    value={currentRound.sms2010Link}
                                    className='form-control'
                                    debounceTimeout={1500}
                                    name="sms2010Link"
                                    onChange={onFieldsChange}
                                />
                            </InputWrapper>

                        </div>
                        <div className="col-md-4 fw-bold" style={{ fontSize: '1.1rem' }}>
                            {showKitStatus()}
                            <FamilyMemebrsToInsureCheckBoxes />
                        </div>

                    </div>
                    : <div>Sorry, no data yet.</div>}
            </div>
        </div>
    )
}

export default Details;