import { BrowserRouter, Route, Routes } from 'react-router-dom';
import DefaultLayout from '../layouts/Default';
import { authProtectedRoutes, publicRoutes, uploadFilesRoutes } from './index';
import UnansweredClientsWarningBanner from '../components/unanswered-chats-warning/UnansweredClientsWarningBanner';
import { useAppContext } from '../context/AppContext';
import useMessagesSocket from '../hooks/useMessagesSocket';

const RoutesHandler = () => {
    useMessagesSocket();

    const { layout, getLayout } = useAppContext();
    const Layout = getLayout();

    return (
        <BrowserRouter>
            <UnansweredClientsWarningBanner />
            <Routes>
                {[...publicRoutes, ...uploadFilesRoutes].map((route, index) => (
                    <Route
                        key={index}
                        path={route.path}
                        element={<DefaultLayout layout={layout}>{route.element}</DefaultLayout>}
                    />
                ))}
                {authProtectedRoutes.map((route, index) => (
                    <Route key={index} path={route.path} element={
                        <Layout>{route.element}</Layout>
                    } />
                ))}
            </Routes>
        </BrowserRouter>
    );
};

export default RoutesHandler;
