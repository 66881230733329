
import {APICore, CLIENT_SEARCH_URL, DASHBOARD_URL} from './apiCore';

const api = new APICore();


interface GetDataParams {
    startDate?: string,
    endDate?: string,
    startStatus: string,
    startStatusModule: string,
    endStatus: string,
    endStatusModule: string,
    groupBy: string
    onboardingTimeOfDay: string,
    onboardingDayOfWeek: string,
    pensionTimeOfDay: string,
    pensionDayOfWeek: string,
    onboardingRep: string,
    pensionAgent: string,
    registrationToOnboarding: string,
    savingAmount: string,
    source: string,
    pensionTransferred: string,
    onboardingMeetingAttendance: string,
    pensionMeetingAttendance: string
}

const getData = ( params: GetDataParams) => {
    const relativeUrl = '/business-dashboard/filter-data';
    return api.get(`${DASHBOARD_URL}${relativeUrl}`, params);
}

const getAllModulesStatuses = () => {
    const relativeUrl = '/search/enums';
    return api.get(`${CLIENT_SEARCH_URL}${relativeUrl}`);
}   

export {
    getData,
    getAllModulesStatuses
}