import { CubesForm } from '../../interfaces/CubesForm';
import {APICore, API_URL} from './apiCore';

const api = new APICore();


const getCubeForm = (pensionId: string) => {
    const relativeUrl = '/cubes';
    return api.get(`${API_URL}${relativeUrl}`,{pensionId});
}


const editCubeForm = (cubesForm: CubesForm) => {
    const relativeUrl = '/cubes/'+cubesForm._id;
    
    return api.update(`${API_URL}${relativeUrl}`, cubesForm);
}

const downloadCubeForm = (pensionId: string) => {
    const relativeUrl = '/cubes/generate';
    return api.getFile(`${API_URL}${relativeUrl}`, {pensionId});
}

const sendCubeToEmployer = (pensionId: string) => {
    const relativeUrl = `/pension/${pensionId}/send-cubes-doc-to-employer`;
    return api.create(`${API_URL}${relativeUrl}`, {});
}



export {
    editCubeForm,
    getCubeForm,
    downloadCubeForm,
    sendCubeToEmployer
}