import { createContext } from "react";
import { BankDeposit } from '../interfaces/BankDeposit';
import { Client } from "../interfaces/Client";
import { Insurance } from '../interfaces/Insurance';
import { Onboarding } from "../interfaces/Onboarding";
import { Pension } from "../interfaces/Pension";
import { User } from "../interfaces/User";
import { TaxReturn } from "../interfaces/taxReturn";
import { BankFees } from "../interfaces/BankFees";
import { Investment } from "../interfaces/Investment";
import { Mortgage } from "../interfaces/Mortgage";
import { Loan } from "../interfaces/Loan";


export interface UserProfileContextData {
    selectedModule: any,
    currentRound: Onboarding | Pension | TaxReturn | Insurance | BankDeposit | BankFees | Investment | Loan | Mortgage | undefined,
    client: Client,
    setClient: (client: Client) => void,
    optionalModules: any[],
    modules: any,
    expandClientData: boolean,
    isManager: boolean
}
interface UserProfileContextType {
    setProfileContextData: (data: any) => void;
    profileContextData: UserProfileContextData;
    users: User[];
    onEditRound: (body: any, module: string, roundId: string, onSuccess?: ()=>void, onError?: ()=>void) => void;
    onEditClient: () => void;
    onActivateNextModule: (clientId: string, module: string) => void;
    onCreateNewRound: (clientId: string, module: string) => void;
    isRoundBeingCreated: boolean;
}

export const UserProfileContext = createContext<UserProfileContextType>({
    setProfileContextData: (data: any) => {},
    profileContextData: {
        selectedModule: undefined,
        currentRound: undefined,
        client: {
            _id: "",
            firstName: "",
            lastName: "",
            payslipPasswords: [],
            source: {},
            spouse: {},
        },
        setClient: (client: Client) => {},
        optionalModules: [],
        modules: [],
        expandClientData: false,
        isManager: false,
    },
    users: [],
    onEditRound: (body: any, module: string, roundId: string)=>{},
    onEditClient: () => {},
    onActivateNextModule: (clientId: string, module: string) => {},
    onCreateNewRound: (clientId: string, module: string) => {},
    isRoundBeingCreated: false,
});
