import { APICore, CLIENT_SEARCH_URL } from './apiCore';

const api = new APICore();

const getClientsBySearch = (params: {
    client?: string,
    page?: number,
    freeText?: string,
    isVIP?: boolean,
    isRelevantForTaxReturn?: boolean,
    campaign?: string,
    campaignName?: string,
    sortBy?: string,
    "signUpDate.dateRangeStart"?: string,
    "signUpDate.dateRangeEnd"?: string,
    "onboarding.onboardingDate.dateRangeStart"?: string,
    "onboarding.onboardingDate.dateRangeEnd"?: string,
    "source.sourceType"?: string,
    "source.campaign"?: string,
    "source.campaignName"?: string,
    "onboarding.elementExists"?: boolean,
    "pension.elementExists"?: boolean,
    "taxReturn.elementExists"?: boolean,
    "deposit.elementExists"?: boolean,
    "loan.elementExists"?: boolean,
    "insurance.elementExists"?: boolean,
    "mortgage.elementExists"?: boolean,
    "bankFees.elementExists"?: boolean,
    "investment.elementExists"?: boolean,
    "onboarding.isActive"?: boolean,
    "pension.isActive"?: boolean,
    "taxReturn.isActive"?: boolean,
    "deposit.isActive"?: boolean,
    "loan.isActive"?: boolean,
    "insurance.isActive"?: boolean,
    "mortgage.isActive"?: boolean,
    "bankFees.isActive"?: boolean,
    "investment.isActive"?: boolean,
    "onboarding.status"?: string,
    "pension.status"?: string,
    "taxReturn.status"?: string,
    "deposit.status"?: string,
    "loan.status"?: string,
    "insurance.status"?: string,
    "mortgage.status"?: string,
    "bankFees.status"?: string,
    "investment.status"?: string,
    "onboarding.rep"?: string,
    "pension.rep"?: string,
    "taxReturn.rep"?: string,
    "deposit.rep"?: string,
    "loan.rep"?: string,
    "insurance.rep"?: string,
    "mortgage.rep"?: string,
    "bankFees.rep"?: string,
    "investment.rep"?: string,
    "targetAudienceStatus"?: string,
}) => {
    const relativeUrl = '/search/client';
    return api.get(`${CLIENT_SEARCH_URL}${relativeUrl}`, params);
}

const getClientsAutocomplete = (params: {
    freeText: string,
}) => {
    const relativeUrl = '/search/client/autocomplete';
    return api.get(`${CLIENT_SEARCH_URL}${relativeUrl}`, params);
}

const getAllModulesStatuses = () => {
    const relativeUrl = '/search/enums';
    return api.get(`${CLIENT_SEARCH_URL}${relativeUrl}`);
}   

const getClientsByIds = (params: { clientIds: string[] }) => {
    const relativeUrl = `/search/clients?${convertArrayToUrlQuery('clientIds', params.clientIds)}`;
    return api.get(`${CLIENT_SEARCH_URL}${relativeUrl}`);
}

const convertArrayToUrlQuery = (arrayName: string, array: string[]) => {
    let res = '';
    array.forEach((element, index) => {
        res += `${arrayName}[${index}]=${element}&`;
    });
    return res;
}

const getStatusesFrequency = (params: { module: string, showInactiveStatuses: boolean }) => {
    const relativeUrl = `/search/client/module-status/count`;
    return api.get(`${CLIENT_SEARCH_URL}${relativeUrl}`, { isActive: !params.showInactiveStatuses, moduleName: params.module });
}

export {
    getClientsBySearch,
    getClientsAutocomplete,
    getAllModulesStatuses,
    getClientsByIds,
    getStatusesFrequency,
}