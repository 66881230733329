import React, { useContext, useCallback, useEffect, useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap';
import { UserProfileContext } from '../../../context/userProfileContext';
import Details from './Details';
import ProfileFiles from "../ProfileFiles";
import TaxReturnCompleteForm from './tax-return-complete-form';
import { TaxReturn } from '../../../interfaces/taxReturn';
import { useSearchParams } from 'react-router-dom';

const TabsData = [
    { name: 'Details', },
    { name: 'Files', },
    { name: 'Forms', },
];

const MainData = () => {
    const [searchParams] = useSearchParams();
    const selectedFileId = searchParams.get('fileId');
    const [activeTab, setActiveTab] = useState(selectedFileId ? 'Files' : TabsData[0].name);

    const { profileContextData } = useContext(UserProfileContext);
    const { currentRound, client } = profileContextData;

    const getTabComponent = (tabName: string) => {
        if (!client)
            return;
        switch (tabName) {
            case 'Details':
                return <Details />

            case 'Files':
                return <ProfileFiles />

            case 'Forms':
                return <TaxReturnCompleteForm taxReturn={currentRound as TaxReturn} />

            default:
                break;
        }
    }


    return (
        <div className='main-data-wrapper'>
            <Tabs
                defaultActiveKey="Details"
                transition={false}
                activeKey={activeTab}
                onSelect={(k: any) => setActiveTab(k)}
            >
                {
                    client && TabsData.map((tab: any, index: number) => {
                        return <Tab
                            key={index}
                            eventKey={tab.name}
                            title={tab.name}
                        >
                            {activeTab === tab.name ? getTabComponent(tab.name) : <></>}
                        </Tab>
                    })
                }
            </Tabs>
        </div>
    )
}

export default MainData;