import { APICore, API_URL } from './apiCore';

const api = new APICore();

const getChats = (params: { page?: number, chatFilter?: string, sort?: string, pensionStatus?: string, search?: string, repId?: string }) => {
    if (!params.page) {
        params.page = 0;
    }

    const relativeUrl = '/chats';
    return api.get(`${API_URL}${relativeUrl}`, params);
}

const getUnreadCount = () => {
    const relativeUrl = '/chats/unread-count';
    return api.get(`${API_URL}${relativeUrl}`);
}

const getManagerTeam = () => {
    const relativeUrl = '/users/manager-team';
    return api.get(`${API_URL}${relativeUrl}`);
}

const getChatMessages = (chatId: string) => {
    const relativeUrl = '/chats';
    return api.get(`${API_URL}${relativeUrl}/${chatId}/messages`);
}

const updateChat = (chatId: string, params: { unreadCount?: number, isStarred?: boolean, isPinned?: boolean, addFollowerId?: string, removeFollowerId?: string, removeFollower?: boolean }) => {
    const relativeUrl = '/chats';
    return api.update(`${API_URL}${relativeUrl}/${chatId}`, params);
}

const updateChatFollower = (body: { followerId: string, chatIds: string[] }) => {
    const relativeUrl = `/chats/add-follower`;
    return api.create(`${API_URL}${relativeUrl}`, body);
}


export {
    getChats,
    getUnreadCount,
    getChatMessages,
    updateChat,
    getManagerTeam,
    updateChatFollower,
}