import {APICore, API_URL, TAX_URL} from './apiCore';
import { TaxReturnListFetchParams } from '../../interfaces/taxReturn';

const api = new APICore();

const createTaxReturn = (clientId: string) => {
    return api.create(`${TAX_URL}/tax-return`, {clientId});
}

const editTaxReturn = ( taxReturnId: string, body: any ) => {
    const relativeUrl = `/tax-return/${taxReturnId}`;
    return api.update(`${TAX_URL}${relativeUrl}`, body);
}

const getTaxReturnList = (params?: TaxReturnListFetchParams) => {
    return api.get(`${TAX_URL}/tax-return/list`, params);
}

const getTaxReturnStatuses = () => {
    return api.get(`${TAX_URL}/tax-return/statuses`);
}

const getTaxData = (taxDataId: string) => {
    return api.get(`${TAX_URL}/tax-data/${taxDataId}`);
}

const editTaxData = (taxDataId: string, body: any) => {
    return api.update(`${TAX_URL}/tax-data/${taxDataId}`, body);
}

const createTaxData = (taxReturnId: string) => {
    return api.create(`${TAX_URL}/tax-data/updateData`, {taxReturnId});
}

export {
    getTaxReturnList,
    getTaxReturnStatuses,
    createTaxReturn,
    editTaxReturn,
    getTaxData,
    editTaxData,
    createTaxData
}