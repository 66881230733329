import React, { useContext, useEffect } from 'react';
import { Button, Dropdown, Modal } from 'react-bootstrap';
import { UserProfileContext } from '../../../context/userProfileContext';
import { useTranslation } from 'react-i18next';
import i18n from "i18next";
import './cubes.scss';
import { editCubeForm, getCubeForm } from '../../../utils/api/cubes';
import { CubesForm } from '../../../interfaces/CubesForm';
import { PensionProduct, PensionProductTypes } from '../../../interfaces/PensionProduct';
import { camelCaseToText } from '../../../utils/camelCaseToText';
import Loader from '../../../components/Loader';
import { Pension } from '../../../interfaces/Pension';

interface CubesModalProps {
    show: boolean;
    onHide: () => void;
    onSave?: () => void;
    product?: PensionProduct;
}

const CubesModal = ({ show, onHide, onSave, product }: CubesModalProps) => {
    const { t } = useTranslation("cubes");

    // useEffect(() => {
    //     setSelectedTab(profileContextData.selectedModule?.data?.moduleName);
    // }, [profileContextData.selectedModule]);

    const { profileContextData } = useContext(UserProfileContext);

    const { currentRound: pension, client } = profileContextData;

    const [cubesForm, setCubesForm] = React.useState<CubesForm>();
    const [loading, setLoading] = React.useState<boolean>(true);

    const saveCubes = async () => {
        if (!cubesForm)
            return;

        if (loading)
            return;

        setLoading(true);

        try {            
            const result = await editCubeForm(cubesForm);
            onHide();
            if (onSave) {
                await onSave();
            }
        } catch (err: any) {
            alert(err.message);
            console.log(err);
        } finally {
            setLoading(false);
        }
    }


    const handleCubes = async () => {
        const pensionId = pension?._id || (product?.pension as Pension)?._id || (product?.pension as string);
        
        if (!pensionId)
            return;

        try {
            const { cubeForm } = await getCubeForm(pensionId);
            cubeForm.rows.forEach((row: any) => {
                if (!row.depositRates)
                    row.depositRates = {};
            });
            setCubesForm(cubeForm);

        } catch (err: any) {
            alert(err.message);
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {    
        if (show)
            handleCubes();
    }, [show]);

    const onPersistenceSelected = (persistence: number, rowIndex: number) => {
        
        const tmp: any = { ...cubesForm };        
        tmp.rows[rowIndex].persistence = persistence;            
        // debugger;        
        setCubesForm(tmp);
    }

    const onChange = (e: any, rowIndex: number) => {
        const { value, name } = e.target;
        const tmp: any = { ...cubesForm };
        const splits = name.split(".");
        if (splits.length > 1) {
            tmp.rows[rowIndex][splits[0]][splits[1]] = value;
        }
        else {
            tmp.rows[rowIndex][name] = value;
        }
        setCubesForm(tmp);
    }

    const onWorkDisabilityChange = (e: any, rowIndex: number) => {
        const { value, name } = e.target;
        const tmp: any = { ...cubesForm };
        tmp.rows[rowIndex].depositRates[name] = value;
        setCubesForm(tmp);
    }

    const onSelected = (e: any, rowIndex: number) => {        
        const { value, name } = e.target;
        const tmp: any = { ...cubesForm };
        tmp.rows[rowIndex].depositRates[name] = value;
        setCubesForm(tmp);
    }

    const options = {
        employee: [0, 2.5, 5, 5.5, 6, 6.5, 7],
        employer: [0, 5, 5.5, 6, 6.5, 7, 7.5],
        severance: [0, 6, 8.33]
    };

    const getRows = () => {
        if (!cubesForm)
            return [];

        if (!product)
            return cubesForm?.rows;
    
        return cubesForm?.rows.filter((row:any)=>row.product === product._id);
    }

        
    const onDeleteRow = (index: number) => {
        if (!window.confirm(t("Delete this row?")))
            return;

        const tmp: any = { ...cubesForm };
        tmp.rows.splice(index, 1);
        setCubesForm(tmp);
    }

    const onDuplicateRow = (index: number) => {
        if (!window.confirm(t("Duplicate this row?")))
            return;
            
        const tmp: any = { ...cubesForm };
        tmp.rows.splice(index, 0, JSON.parse(JSON.stringify(tmp.rows[index])));
        setCubesForm(tmp);
    }

    return (

        <Modal className="cubes-modal" backdropClassName="cubes-backdrop" show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{t("Cubes Form")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loading && <Loader/>}
                <table style={{ direction: i18n.dir() }}>
                    <thead>
                        <tr>
                            <th>{t("Product Type")}</th>
                            <th>{t("Company ID")}</th>
                            <th>{t("Company MH")}</th>
                            {/* <th>{t("Policy Number")}</th> */}
                            <th>{t("Salary")}</th>
                            <th>{t("Severance Deposit")}</th>
                            <th>{t("Employer Deposit")}</th>
                            <th>{t("Work Disability")}</th>
                            <th>{t("Employee Deposit")}</th>
                            <th>{t("Persistance")}</th>
                            <th>{t("New Product")}</th>
                            <th>{t("Actions")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {getRows().map((row: any, index: number) => <tr key={index}>
                            <td>{t(camelCaseToText(row.productType))}</td>
                            <td>{row.company.name}</td>
                            <td>{row.mhNumber}</td>
                            {/* <td>{row.policyNumber}</td> */}
                            <td><input name="relevantSalary" type="number" value={row.relevantSalary} onChange={(e) => onChange(e, index)} /><span className='shekel'>₪</span></td>
                            <td>{row.productType !== PensionProductTypes.studyFund ? <><span className='percent'>%</span><input name="depositRates.severance" type="number" value={row.depositRates?.severance} onChange={(e) => onChange(e, index)} /></> : null}</td>                            
                            <td><span className='percent'>%</span><input name="depositRates.employer" type="number" value={row.depositRates?.employer} onChange={(e) => onChange(e, index)} /></td>
                            <td>{row.productType === PensionProductTypes.risk || row.productType === PensionProductTypes.directorsInsurance ? <><span className='percent'>%</span><input name="workDisability" type="number" value={row.depositRates?.workDisability} onChange={(e) => onWorkDisabilityChange(e, index)} /></> : null}</td>
                            <td><span className='percent'>%</span><input name="depositRates.employee" type="number" value={row.depositRates?.employee} onChange={(e) => onChange(e, index)} /></td>
                            <td><Dropdown className="select-action" align={'end'}>
                                        <Dropdown.Toggle as="a" className="btn-sm card-drop cursor-pointer">{row.persistence}<i className="mdi mdi-chevron-down"></i></Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {[1,2,3,4,5].map((persistence, i) => <Dropdown.Item key={i} onClick={(e) => onPersistenceSelected(persistence, index)}><b>{persistence}</b> ({t(`Persistance ${persistence}`)})</Dropdown.Item>)}                                       
                                        </Dropdown.Menu>
                                    </Dropdown>
                                
                                </td>
                            <td>{t(row.isNewProduct ? 'Yes' : 'No')}</td>
                            <td className='actions'>
                                <i className="mdi mdi-delete-outline comment-icon delete" onClick={() => onDeleteRow(index)}></i>
                                <i className="mdi mdi-plus comment-icon plus" onClick={() => onDuplicateRow(index)}></i>
                            </td>
                        </tr>)}

                        {!getRows().length && <tr>
                            <td colSpan={10} className="no-products"><b>{t("No Products")}</b></td>
                            </tr>}
                    </tbody>
                </table>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    {t('Cancel')}
                </Button>
                <Button variant="success" onClick={saveCubes}>
                    {t('Save')}
                </Button>
            </Modal.Footer>
        </Modal>

    );
}

export default CubesModal;
