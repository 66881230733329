import React, { useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { Card, Dropdown, Row, Col, ButtonGroup, Button } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import * as yup from 'yup';
import io from 'socket.io-client';
import { yupResolver } from '@hookform/resolvers/yup';
import ScheduleIcon from '@mui/icons-material/Schedule';

import { FormInput } from "../../components";
import Loader from '../../components/Loader';

import { User } from "../../interfaces/User";
import { Message } from "../../interfaces/Message";
import {
    getMessages, getWhatsappTemplates, sendFreeformMessage, sendWhatsappTemplate,
    sendFreeformMessageWithDate, sendWhatsappTemplateWithDate, fetchJotformSubmissionManually
} from "../../utils/api/messages";
import './chatArea.scss';
import useInterval from "../../utils/useInterval";
import Dropzone from "react-dropzone";
import { FileType } from "../../interfaces/FileType";
import { uploadFile } from "../../utils/api/files";
import { APICore, getUserFromCookie, SOCKET_URL } from "../../utils/api/apiCore";
import { ApiError } from "../../utils/api/ApiError";
import { getChatMessages, updateChat } from '../../utils/api/chat';
import MessageItem from './MessageItem';
import { Client } from '../../interfaces/Client';
import { followClient, getFollowers } from "../../utils/api/clients";
import { Follower } from "../../interfaces/Follower";
import { UserProfileContext } from "../../context/userProfileContext";
import {
    assignBot,
    assignTestBot, cancelBot,
    cancelFutureMessage,
    sendFutureMessageNow
} from "../../utils/api/automated-messages";
import BotsDropdown from './BotsDropdown';
import FutureMessageItem from "./FutureMessageItem";
import { FutureMessage } from "../../interfaces/FutureMessage";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { formatDate } from '../../utils/formatDate';
import FollowersDropDown from './FollowersDropDown';
import { chatContext } from '../../context/chatContext';
import TemplatesDropdown from './TemplatesDropdown';
import { useUnansweredClientsContext } from '../../context/UnansweredClientsContext';

interface WhatsappMessage {
    message: string;
}


const Events = Object.freeze({
    incomingMessage: 'incomingMessage',
    connectToClientCommand: 'connectToClientCommand'
});


interface ChatAreaProps {
    client: Client;
    rep?: User;
    sendMessageEnabled: boolean,
    showJotformLinks: boolean,
    chatTitle: string,
    polling: boolean,
    chatId?: string,
    unreadCount?: number,
    enableSocket?: boolean
}


// ChatArea
const ChatArea = (props: ChatAreaProps) => {
    const {
        client,
        rep,
        sendMessageEnabled,
        showJotformLinks,
        chatTitle,
        polling,
        chatId,
        unreadCount,
        enableSocket = false
    } = props;

    const navigate = useNavigate();
    const [showBotUpload, setShowBotUpload] = useState(false);
    const [scrollToBottom, setScrollToBottom] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [sendingMessage, setSendingMessage] = useState<boolean>(false);
    const [messages, setMessages] = useState<Message[]>([]);
    const [futureMessages, setFutureMessages] = useState<FutureMessage[]>([]);
    const [assignedBots, setAssignedBots] = useState<any[]>([]);
    const [jotformLink1, setJotformLink1] = useState();
    const [jotformLink2, setJotformLink2] = useState();
    const [jotformLink3, setJotformLink3] = useState();
    const [isPreviewMode, setIsPreviewMode] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState<string>();
    const [currentModuleTemplates, setCurrentModuleTemplates] = useState<string[]>([]);
    const [viewAllTemplates, setViewAllTemplates] = useState<boolean>(false);
    const [allTemplates, setAllTemplates] = useState<string[]>([]);
    const [whatsappMessage, setWhatsappMessage] = useState<WhatsappMessage>({ message: '' });
    const [isConnected, setIsConnected] = useState(false);
    const [isFutureMessage, setIsFutureMessage] = useState(false);
    const [followers, setFollowers] = useState<Follower[]>([]);
    const { profileContextData } = useContext(UserProfileContext);
    const [bots, setBots] = useState([]);
    const [canSendFreeMessages, setCanSendFreeMessages] = useState(false);
    const [isPickerOpen, setIsPickerOpen] = useState(false);
    const [selectedDate, setSelectedDate] = React.useState<Date | null>(null);
    const chatRef = useRef<any>(null);
    const { setShouldUpdateChatsList } = useContext(chatContext);
    const { fetchUnansweredClients } = useUnansweredClientsContext();
    const fiveMin = 5 * 60 * 1000;
    const [socket, setSocket] = useState<any>(null);

    const handleSocket = () => {
        if (socket) {
            socket.disconnect();
        }

        const tmpSocket = io(SOCKET_URL);
        tmpSocket.on('connect', () => {
            setIsConnected(true);
            console.log("connected to chatArea socket")
            if (client)
                tmpSocket.emit(Events.connectToClientCommand, {
                    clientId: client._id,
                    token: getUserFromCookie().token
                });
        });

        tmpSocket.on('disconnect', () => {
            console.log("disconnected form chatArea socket")
            setIsConnected(false);
        });

        tmpSocket.on(Events.incomingMessage, async () => {
            console.log("Socket chatArea event", Events.incomingMessage)
            // debugger;
            await handleMessages(true);

        });
        setSocket(tmpSocket);
    }

    let isLoading: boolean = false;

    useInterval(() => {
        if (!isLoading) {
            isLoading = true;
            handleMessages(true).catch(err => {
                console.log(err);
            }).catch(err => {
                if (err instanceof ApiError && err.status===401)
                    return navigate('/auth/logout');
            }).finally(() => {
                isLoading = false;
            });
        } else {
            console.log("skipping get messages");
        }
    }, polling ? fiveMin : 1000000000);

    /*
     *  Fetches the messages for selected user
     */
    const handleMessages = async (isOnLoad?: boolean, userChanged?: boolean) => {
        const newMessages = isOnLoad || userChanged ? [] : [...messages];
        let result;
        if (chatId) {
            try {
                result = await getChatMessages(chatId);
                if (unreadCount) {
                    updateChat(chatId, { unreadCount: 0 }).catch(err => {
                        console.log("error updating chat");
                    });
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            result = await getMessages({ clientId: client?._id, repId: rep?._id, page: 0 });
        }


        result.messages.reverse().forEach((message: Message) => {
            const existing = newMessages.find(m => m._id === message._id);

            if (existing) {
                existing.status = message.status;
            } else if (isOnLoad || !messages.length || new Date(message.createdAt) > new Date(messages[messages.length - 1].createdAt)) {
                newMessages.push(message);
            }
        });

        setScrollToBottom(true);

        setCanSendFreeMessages(result.canSendFreeMessages);
        // if (newMessages[0].client._id === client._id)
        setMessages(newMessages);
        setFutureMessages(result.futureMessages || []);
        setAssignedBots(result.assignedBots || []);

        setJotformLink1(result.jotformLink1);
        setJotformLink2(result.jotformLink2);
        setJotformLink3(result.jotformLink3);
    }

    const handleIconClick = () => {
        if (isPickerOpen) {
            // If the date picker is open, close it
            setIsPickerOpen(false);
            if (selectedDate) {
                setIsFutureMessage(true)
            }
            return;
        }

        if (isFutureMessage) {
            setIsFutureMessage(false);
            return;
        }

        setIsPickerOpen(true);
    };


    const onLoad = async (userChanged?: boolean) => {
        setWhatsappMessage({ message: getClientChatText() });

        if (messages.length === 0 || userChanged) {
            setLoading(true);
        }

        if (userChanged) {
            handleSocket();
        }

        try {

            await handleMessages(true);
            setLoading(false);

            if (client && sendMessageEnabled) {
                await handleWhatsappTemplates(client);
            }

            // const result = await getAllBots();
            // setBots(result.bots);

            await handleFollowers();

        } catch (err) {
            console.log(err);
            setLoading(false);
            alert("can't get messages");
        }

    }

    const handleFollowers = async () => {
        try {
            const { followers } = await getFollowers(client._id);
            const myId = new APICore().getLoggedInUser().user._id;
            // sort followers - first myId, then active, then followers, then the rest
            // find myId, set full name to 'You'
            if (followers.length) {
                const sortedFollowers = followers.sort((a: Follower, b: Follower) => {
                    if (a.user._id === myId) return -1;
                    if (b.user._id === myId) return 1;
                    if (a.isActiveRep && !b.isActiveRep) return -1;
                    if (b.isActiveRep && !a.isActiveRep) return 1;
                    if (a.isWatcher && !b.isWatcher) return -1;
                    if (b.isWatcher && !a.isWatcher) return 1;
                    if (a.isInvitee && !b.isInvitee) return -1;
                    if (b.isInvitee && !a.isInvitee) return 1;
                    return 0;
                });

                sortedFollowers[0].user.fullName = 'You'

                setFollowers(sortedFollowers);
            }
        } catch (err) {
            console.log(err);
        }
    }

    const handleWhatsappTemplates = async (client: Client) => {
        const result = await getWhatsappTemplates(client);
        // setWhatsappTemplates(result.messageTypes);
        setAllTemplates(result.messageTypes);
        const { moduleName } = profileContextData?.selectedModule?.data || client.currentModule;
        if (moduleName)
            setCurrentModuleTemplates(result.modulesMessageTypes[moduleName] || []);
    }

    useEffect(() => {
        onLoad(true);

        return () => {
            if (socket) {
                socket.disconnect();
                socket.off('connect');
                socket.off('disconnect');
                socket.off(Events.incomingMessage);
            }
        };
    }, [chatId]);

    useEffect(() => {
        if (chatRef && chatRef.current && scrollToBottom) {
            chatRef.current.getScrollElement().scrollTop = chatRef.current.getScrollElement().scrollHeight;
            setScrollToBottom(false);
        }
    }, [messages, futureMessages, whatsappMessage.message]);

    const viewAllTemplatesClicked = (e: any) => {
        e.stopPropagation();
        setViewAllTemplates(!viewAllTemplates);
    }

    /*
     * form validation schema
     */
    const schemaResolver = yupResolver(
        yup.object().shape({
            // newMessage: yup.string().required('Please enter your messsage'),
        })
    );

    /*
     * form methods
     */
    const methods = useForm({ resolver: schemaResolver });
    const {
        handleSubmit,
        register,
        control,
        formState: { errors },
        reset,
    } = methods;

    const chatNotActive = () => {
        alert("That chat becomes active for 24 hours after the user sends us a message");
    }

    /**
     * sends the chat message
     */
    const sendMessage = async () => {
        if (!whatsappMessage.message)
            return;
        if (sendingMessage || !client)
            return;

        if (isPreviewMode) {
            await sendTemplate();
            return;
        }

        if (!canSendFreeMessages && whatsappMessage.message.includes("\n")) {
            window.alert("You can only send one line messages or templates until the client replies");
            return;
        }

        try {
            setSendingMessage(true);
            reset();
            let message;
            if (selectedDate && isFutureMessage) {
                ({ message } = await sendFreeformMessageWithDate(client, whatsappMessage.message, selectedTemplate, selectedDate));
                setSelectedDate(null);
                setIsFutureMessage(false);
            } else {
                ({ message } = await sendFreeformMessage(client, whatsappMessage.message, selectedTemplate));
            }
            fetchUnansweredClients();
            setMessages([...messages, message]);
            setScrollToBottom(true);
            setSelectedTemplate(undefined);
            setSendingMessage(false);
            setClientChatText('');
        } catch (err) {
            console.log(err);
            alert("can't send message");
        } finally {
            setSendingMessage(false);
            setWhatsappMessage({ message: '' });
            setIsPreviewMode(false);
            if (setShouldUpdateChatsList && typeof setShouldUpdateChatsList === 'function')
                setShouldUpdateChatsList(true)
        }
    };

    const isChatInputEnabled = () => {
        return sendingMessage===false;
    }

    const isSendEnabled = () => isChatInputEnabled() || selectedTemplate;


    const getTemplateText = (messageType: string) => {
        if (messageType==='zoomStartedWhatsapp')
            return 'Zoom has started';
        else if (messageType==='zoomEndedWhatsapp')
            return 'Zoom has ended';
        else if (messageType==='allDataReceivedWhatsapp')
            return 'All the documents received';
        else if (messageType==='powerOfAttorneyWhatsapp')
            return 'Sign power of attorney docs';
        else if (messageType==='uploadLinkWhatsapp')
            return 'Secure file upload link';
        else if (messageType==='savingsFoundWhatsapp')
            return 'Savings Found';
        else if (messageType==='zoom10MinutesWhatsappReminder')
            return '10 minutes to Zoom';
        else if (messageType==='referralWhatsapp')
            return 'Refer a friend to Better';

        return messageType;
    }

    const whatsappTemplatesSelected = async (messageType: string) => {
        if (!client)
            return;

        setIsPreviewMode(true);
        setSelectedTemplate(messageType);
        setSendingMessage(true);
        try {

            const result = await sendWhatsappTemplate(client, messageType, true);

            setWhatsappMessage({ message: result.message });
            reset();
            // setSelectedTemplate('');
            setSendingMessage(false);
        } catch (err: any) {
            setSendingMessage(false);
            setIsPreviewMode(false);
            console.log(`error loading templates:` + err.message);
            if (err.status === 500 && messageType === 'clientDashboardPensionSignLink') {
                alert("Documents for signature have to be generated first");
            } else {
                alert("Failed to receive template");
            }
        }

    }

    const sendTemplate = async () => {
        if (!client || !selectedTemplate)
            return;

        setSendingMessage(true);
        try {
            let result;
            if (selectedDate && isFutureMessage) {
                result = await sendWhatsappTemplateWithDate(client, selectedTemplate, false, selectedDate);
                setSelectedDate(null);
                setIsFutureMessage(false);
            }
            else {
                result = await sendWhatsappTemplate(client, selectedTemplate, false);
            }
            setWhatsappMessage({ message: '' });
            setIsPreviewMode(false);
            reset();
            await handleMessages(false);
        } catch (err: any) {
            if (err.status === 409) {
                alert("Invalid phone number or client not registered at Landbot");
            } else {
                alert("Failed to send template");
            }
        } finally {
            setSendingMessage(false);
        }
    }

    const setClientChatText = (text: string) => {
        localStorage.setItem(`chatText_${client._id}`, text);
    }

    const getClientChatText = (): string => {
        return localStorage.getItem(`chatText_${client._id}`) || '';
    }

    const handleChange = (event: any) => {
        setWhatsappMessage({ message: event.target.value });
        setClientChatText(event.target.value);
    }

    const inputClass = () => {

        const r = !whatsappMessage.message || (whatsappMessage.message.charCodeAt(0) >= 1488 && whatsappMessage.message.charCodeAt(0) <= 1514) ?
            'rtl' : 'ltr';

        return r;
    }

    const onFileUpload = async (files: FileType[]) => {
        if (client) {
            setSendingMessage(true);
            try {
                const result = await uploadFile({ clientId: client._id, file: files[0], sendToUser: true });
                await handleMessages(true);
            } catch (err) {
                console.log(err);
                alert("Can't upload file");
            } finally {
                setSendingMessage(true);
            }
        }
    }


    const getButtonClass = () => {
        let str = isSendEnabled() ? 'btn btn-success chat-send' : 'btn btn-disabled chat-send';
        if (isPreviewMode)
            str += ' w-75';
        else
            str += ' w-100';

        return str;
    }

    const cancelTemplate = () => {
        setIsPreviewMode(false);
        setWhatsappMessage({ message: '' });
        reset();
    }

    const onUploadTestBot = async (file: FileType | undefined) => {
        if (!file)
            return;

        try {
            setLoading(true);
            const result = await assignTestBot(client, file);

            await handleMessages(true);
            setLoading(false);

            alert("Test bot uploaded successfully");

        } catch (err: any) {
            setLoading(false);
            console.log(err);

            if (err.status === 410) {
                alert('Please send a message to system in order to test json');
            }
            else if (err.status === 409) {
                alert(err.json.message);
            }
            else {
                alert('Something went wrong...');
            }
        }
    };

    const onBotSelected = async (botName: string) => {
        try {
            setLoading(true);
            const result = await assignBot(client, botName);
            await handleMessages(true);
            fetchUnansweredClients();
            setLoading(false);
        } catch (err:any) {
            debugger;
            setLoading(false);
            console.log(err);
            if (err.json?.message)
                alert(err.json.message);
            else
                alert('Something went wrong...');
        }
    }


    const onSendFutureMessageNow = async (message: FutureMessage) => {
        try {
            const result = await sendFutureMessageNow(message._id);
            await handleMessages(true);
            fetchUnansweredClients();
            alert("Message sent");
        } catch (err) {
            console.log(err);
            alert('Something went wrong...');
        }
    }

    const handleJotFetchClick = async (jotformType: string) => {
        try {
            const result = await fetchJotformSubmissionManually(client._id, jotformType);
            alert("Jotform fetched successfully");
        } catch (err) {
            console.log(err);
            alert(err);
        }
    }

    const onCancelFutureMessage = async (message: FutureMessage) => {
        try {
            const result = await cancelFutureMessage(message._id);
            await handleMessages(true);
            alert("Message cancelled");
        } catch (err) {
            console.log(err);
            alert('Something went wrong...');
        }
    }

    const onBotCancelled = async (assignedbot: string) => {
        try {
            if (!window.confirm(`Are you sure you want to cancel the bot ${assignedbot}?`)) {
                return;
            }

            await cancelBot(client, assignedbot);
            setAssignedBots(assignedBots.filter(b => b !== assignedbot));
            await handleMessages(true);

        } catch (err) {
            console.log(err);
            alert('Something went wrong...');
        }
    }

    return (
        <>
            <Card className={"chat-area"}>
                <Card.Body className={"py-2 px-3 border-bottom chat-area-header"}>
                    <Row className="justify-content-between py-1">
                        <div className="grid">

                            <div className="user-name">
                                <span className="mt-0 mb-0 font-15">
                                    <Link to={{
                                        pathname: "/clients/profile",
                                        search: "?clientId=" + client._id
                                    }}>                                        <b>{chatTitle}</b>
                                    </Link>
                                </span>
                                {client.phoneNumberStatus && <span className={`badge ${client.phoneNumberStatus === 'valid' ? 'bg-success' : 'bg-warning'}`}>{client.phoneNumberStatus}</span>}
                            </div>
                            <FollowersDropDown
                                followers={followers}
                                setFollowers={setFollowers}
                                client={client}
                                followClient={followClient}
                            />

                            <BotsDropdown onBotSelected={onBotSelected} onUploadTestBot={onUploadTestBot} />
                            
                            <div className="assigned-bots">
                                {assignedBots.map((assignedBot: string) =>
                                    <div key={assignedBot} >
                                        <span className="bot-name">{assignedBot}</span>
                                        <span className="cancel" onClick={() => onBotCancelled(assignedBot)}>X</span>
                                    </div>
                                )}
                            </div>

                        </div>

                    </Row>
                </Card.Body>


                <Card.Body className='chat-area-body'>
                    {loading && <Loader />}
                    <SimpleBar
                        className={`whatsapp-window ${sendMessageEnabled ? 'includes-send-area' : ''}`}
                        style={{
                            height: `${chatId ? '' : (window.innerHeight - 165 - (sendMessageEnabled ? 174 : 0)) + "px"}`,
                            width: '100%'
                        }} ref={chatRef}>
                        <ul className="conversation-list">
                            {messages.map((message, index) => {
                                return <MessageItem
                                    key={index}
                                    message={message}
                                />;
                            })}

                            {futureMessages.map((futureMessage, index) => {
                                return <FutureMessageItem
                                    key={index}
                                    message={futureMessage}
                                    onSendFutureMessageNow={onSendFutureMessageNow}
                                    onCancelFutureMessage={onCancelFutureMessage}
                                />;
                            })}
                        </ul>
                    </SimpleBar>

                    {sendMessageEnabled ? <Row className='send-msg-box'>
                        <Col className="form-wrapper">


                            <div className="col mb-2 mb-sm-0 no-margin-right">
                                <FormInput
                                    type="textarea"
                                    onChange={handleChange}
                                    name="message"
                                    value={whatsappMessage.message}
                                    className={inputClass()}
                                    placeholder={isChatInputEnabled() ? "Enter your text" : 'Loading template text...'}
                                    register={register}
                                    key="message"
                                    errors={errors}
                                    disabled={!isChatInputEnabled() || isPreviewMode}
                                    control={control}
                                />

                                {isChatInputEnabled() ? (
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}>
                                        {!isPickerOpen && <>
                                            {/* Cancel Template Button (if in preview mode) */}
                                            {isPreviewMode && (
                                                <button
                                                    type="submit"
                                                    className={'btn btn-danger chat-send w-25'}
                                                    style={{ marginRight: '10px', marginTop: '10px' }}  // Adjusted style
                                                    onClick={cancelTemplate}
                                                >Cancel</button>
                                            )}

                                            {/* Send Message Button */}
                                            <button
                                                type="submit"
                                                disabled={!isSendEnabled()}
                                                onClick={sendMessage}
                                                className={getButtonClass()}
                                                style={{ marginRight: '10px', marginTop: '10px' }}  // Adjusted style
                                            >
                                                {!sendingMessage ? (
                                                    <span>
                                                        <i className="fe-send"></i>{" "}Send
                                                        {selectedDate && isFutureMessage ? ` (${formatDate(selectedDate, false, false)})` : ""} {/* Conditionally showing the date */}
                                                    </span>
                                                ) : (
                                                    <div className="status w-100">
                                                        <div className="spinner">
                                                            <div></div>
                                                            <div></div>
                                                            <div></div>
                                                        </div>
                                                    </div>
                                                )}
                                            </button>
                                        </>}

                                        {/* Date Picker Trigger Icon */}
                                        <div
                                            style={{
                                                backgroundColor: isFutureMessage && selectedDate ? 'grey' : 'transparent', // changing background color based on date selection
                                                borderRadius: '50%', // if you want it to be circle-shaped
                                                height: '24px',
                                                width: '24px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                cursor: 'pointer'
                                            }}
                                            onClick={handleIconClick}
                                        >
                                            <ScheduleIcon
                                                style={{
                                                    color: isFutureMessage && selectedDate ? 'blue' : 'black', // changing colors based on date selection
                                                }}
                                            />
                                        </div>

                                        {isPickerOpen && (

                                            <DatePicker
                                                selected={selectedDate}
                                                onChange={(date) => setSelectedDate(date)}
                                                excludeDateIntervals={[
                                                    { start: new Date(0), end: new Date(Date.now() - 24 * 60 * 60 * 1000) },
                                                ]}
                                                // showTimeSelect
                                                timeFormat="HH:mm"
                                                timeIntervals={15}
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                inline
                                                timeInputLabel="Time:"
                                                showTimeInput
                                            />

                                        )}
                                    </div>
                                ) : null}

                                {currentModuleTemplates.length && !isPreviewMode ?
                                    <TemplatesDropdown onTemplateSelected={whatsappTemplatesSelected} title={selectedTemplate ? getTemplateText(selectedTemplate) : 'Select template' + (selectedDate && isFutureMessage ? ` (${formatDate(selectedDate, false, false)})` : "")}     />
                                    : null
                                }
                                {!currentModuleTemplates.length && !isChatInputEnabled() ?
                                    <div className='chat-inactive' onClick={chatNotActive}>Chat is not
                                        active</div>
                                    :
                                    null
                                }
                            </div>


                            <div className="upload-attachment">
                                <Dropzone
                                    maxFiles={1}
                                    // onFileUpload={onFileUpload}
                                    onDrop={(acceptedFiles) => onFileUpload(acceptedFiles)}>
                                    {({ getRootProps, getInputProps }) => (
                                        <div className="dropzone">
                                            {!isPreviewMode ? <div className="dz-message" {...getRootProps()}>
                                                <i className="mdi mdi-attachment" />
                                            </div> : null}
                                            <div className="needsclick" {...getRootProps()}>
                                                <input {...getInputProps()} />
                                            </div>
                                        </div>
                                    )}
                                </Dropzone>
                            </div>
                        </Col>
                    </Row> : null}
                    {showJotformLinks ? <Row>
                        <Row className="zoom-interview-form">
                            <Col className="mb-2 font-13">
                                <Link to={`${jotformLink1}`} target="_blank">
                                    <span><i className="mdi mdi-form-select"></i>Jotform 1</span>
                                </Link>
                                <br />
                                <br />
                                <Button variant="outline-success" size="sm" onClick={e => handleJotFetchClick("jotFormOne")}>Fetch Jot1</Button>
                            </Col>
                            {jotformLink2 ? <Col className="mb-2 font-13">
                                <Link to={`${jotformLink2}`} target="_blank">
                                    <span><i className="mdi mdi-form-select"></i>Jotform 2</span>
                                </Link>
                                <br />
                                <br />
                                <Button variant="outline-success" size="sm" onClick={e => handleJotFetchClick("jotFormTwo")}>Fetch Jot2</Button>
                            </Col> :
                                <Col className="inactive mb-2 font-13"><i className="mdi mdi-form-select"></i>Jotform 2
                                </Col>}

                            {jotformLink3 ? <Col className="mb-2 font-13">
                                <Link to={`${jotformLink3}`} target="_blank">
                                    <span><i className="mdi mdi-form-select"></i>Jotform 3</span>
                                </Link>
                                <br />
                                <br />
                                <Button variant="outline-success" size="sm" onClick={e => handleJotFetchClick("jotFormThree")}>Fetch Jot3</Button>
                            </Col> :
                                <Col className="inactive mb-2 font-13"><i className="mdi mdi-form-select"></i>Jotform 3
                                </Col>}



                        </Row>
                    </Row> : null}

                </Card.Body>
            </Card>
        </>
    )
        ;
};

export default ChatArea;
