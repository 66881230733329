import React, { useEffect, useState } from "react";
import { DateRangePicker } from "rsuite";
import Loader from "../../components/Loader";
import BarChart from "../../components/BarChart";
import { DateRange } from "rsuite/esm/DateRangePicker/types";
import { User } from "../../interfaces/User";
import { Button, Row } from "react-bootstrap";
import { Dropdown } from 'rsuite';
import "./dashboard-graph.scss";
import { getData } from "../../utils/api/business-dashboard";
import { getOnboardingStatuses } from "../../utils/api/clients";
import { getPensionStatuses } from "../../utils/api/pension";
import { getInsuranceStatuses } from "../../utils/api/insurance";
import { CampaignTypes, GroupBy, Days, DashboardExtraStatuses, SourceTypes, PensionTransferredOptions, MeetingAttendance, AgeRanges, Genders, SalaryRanges, MaritalStatuses, PensionSignDays } from "./consts";
import { camelCaseToText } from "../../utils/camelCaseToText";
import { getAbTestsVariations } from "../../utils/api/abTests";
import { getAllCampaignNames } from "../../utils/api/clients";
import AutoCompleteSelect from "../../components/auto-complete-select/AutoCompleteSelect";

interface DashboardGraphProps {
    users?: User[],
    groupBy?: string,
    title: string,
    startDate?: Date,
    endDate?: Date,
    dynamicGraph?: boolean,
    filterBySource?: boolean,
    startStatus?: string,
    endStatus?: string
}

const DashboardGraph = (props: DashboardGraphProps) => {
    const { users, title, dynamicGraph } = props;
    const [data, setData] = useState<any>(null);
    const [startDate, setStartDate] = useState<Date>(props.startDate || new Date(`${new Date().getFullYear()}-${new Date().getMonth() + 1}-01`));
    const [endDate, setEndDate] = useState<Date>(props.endDate || new Date());
    const [onboardingTimeOfDay, setOnboardingTimeOfDay] = useState<string>('');
    const [onboardingDayOfWeek, setOnboardingDayOfWeek] = useState<string>('');
    const [pensionTimeOfDay, setPensionTimeOfDay] = useState<string>('');
    const [pensionDayOfWeek, setPensionDayOfWeek] = useState<string>('');
    const [onboardingRep, setOnboardingRep] = useState<User>();
    const [pensionAgent, setPensionAgent] = useState<User>();
    const [insuranceAgent, setInsuranceAgent] = useState<User>();
    const [showFilters, setShowFilters] = useState<boolean>(false);
    const [dynamicGraphFilterView, setDynamicGraphFilterView] = useState<boolean>(dynamicGraph || false);
    const [registrationToOnboarding, setRegistrationToOnboarding] = useState<string>('');
    const [savingAmount, setSavingAmount] = useState<string>('');
    const [groupBy, setGroupBy] = useState<string>(props.groupBy || GroupBy.day);
    const [startStatus, setStartStatus] = useState<string>(props.startStatus || 'onboarding_preZoom');
    const [endStatus, setEndStatus] = useState<string>(props.endStatus || 'onboarding_postZoom');
    const [source, setSource] = useState<string>('');
    const [pensionTransferred, setPensionTransferred] = useState<string>('');
    const [onboardingMeetingAttendance, setOnboardingMeetingAttendance] = useState<string>('')
    const [pensionMeetingAttendance, setPensionMeetingAttendance] = useState<string>('')

    const [age, setAge] = useState<string>('');
    const [salary, setSalary] = useState<string>('');
    const [maritalStatus, setMaritalStatus] = useState<string>('');
    const [gender, setGender] = useState<string>('');
    const [testName, setTestName] = useState<string>('');
    const [testValue, setTestValue] = useState<string>('');
    const [campaignName, setCampaignName] = useState<string>('');
    const [pensionSignDays, setPensionSignDays] = useState<string>('');

    const [onboardingStatuses, setOnboardingStatuses] = useState<string[]>([]);
    const [pensionStatuses, setPensionStatuses] = useState<string[]>([]);
    const [insuranceStatuses, setInsuranceStatuses] = useState<string[]>([]);
    const [abTestVariations, setAbTestVariations] = useState<{ [key: string]: string[] }>({});
    const [yLabels, setYLabels] = useState<string[]>(['', '']);
    const [campaignNames, setCampaignNames] = useState<string[]>([]);
    const [campaignSearch, setCampaignSearch] = useState('');
    const [filteredCampaignNames, setFilteredCampaignNames] = useState(campaignNames);

    const [isLoading, setIsLoading] = useState(false);

    const handleData = async () => {
        setIsLoading(true);
        const params = {
            startDate: startDate.toString(),
            endDate: endDate.toString(),
            startStatus: startStatus.split("_")[1],
            startStatusModule: startStatus.split("_")[0],
            endStatus: endStatus.split("_")[1],
            endStatusModule: endStatus.split("_")[0],
            onboardingTimeOfDay,
            onboardingDayOfWeek,
            pensionTimeOfDay,
            pensionDayOfWeek,
            onboardingRep: onboardingRep?._id || '',
            pensionAgent: pensionAgent?._id || '',
            insuranceAgent: insuranceAgent?._id || '',
            registrationToOnboarding,
            savingAmount,
            source,
            pensionTransferred,
            onboardingMeetingAttendance,
            pensionMeetingAttendance,
            age,
            salary,
            maritalStatus,
            gender,
            testName,
            testValue,
            groupBy,
            campaignName,
            pensionSignDays: encodeURIComponent(pensionSignDays)
        }

        const data = await getData(params);
        setData(data);

        setIsLoading(false);

        const y1Label = camelCaseToText(startStatus.split("_")[1]);
        const y2Label = camelCaseToText(endStatus.split("_")[1]);
        setYLabels([y1Label, y1Label !== y2Label ? y2Label : y2Label + '*']);


        if (dynamicGraph && !onboardingStatuses.length && !pensionStatuses.length && !abTestVariations.length) {
            const r1 = await getOnboardingStatuses();
            setOnboardingStatuses([DashboardExtraStatuses.onboardingDate, DashboardExtraStatuses.onboardingCallShow, ...r1.statuses]);

            const r2 = await getPensionStatuses();
            setPensionStatuses([DashboardExtraStatuses.pensionAgentCallDate, DashboardExtraStatuses.pensionAgentCallShow, ...r2.statuses]);

            const r3 = await getAbTestsVariations();
            setAbTestVariations(r3.variations);

            const r4 = await getAllCampaignNames();
            setCampaignNames(r4.campaignNames);

            const r5 = await getInsuranceStatuses();
            setInsuranceStatuses(r5.statuses);
        }
    }

    useEffect(() => {
        handleData();
    }, []);

    useEffect(() => {
        const filtered = campaignNames.filter(option =>
            option?.toLowerCase().includes(campaignSearch?.toLowerCase())
        );
        setFilteredCampaignNames(filtered);
    }, [campaignSearch, campaignNames]);

    const onOk = (date: DateRange) => {

        if (date) {
            setStartDate(date[0]);
            setEndDate(date[1]);
        }
    }

    const onShowFilters = async () => {
        setShowFilters(!showFilters);
        setDynamicGraphFilterView(prev => {
            if (prev) {
                return dynamicGraph || false;
            }
            else {
                return true
            }
        });
        if (!onboardingStatuses.length || !pensionStatuses.length || !abTestVariations.length) {
            const r1 = await getOnboardingStatuses();
            setOnboardingStatuses([DashboardExtraStatuses.onboardingDate, DashboardExtraStatuses.onboardingCallShow, ...r1.statuses]);

            const r2 = await getPensionStatuses();
            setPensionStatuses([DashboardExtraStatuses.pensionAgentCallDate, DashboardExtraStatuses.pensionAgentCallShow, ...r2.statuses]);

            const r3 = await getAbTestsVariations();
            setAbTestVariations(r3.variations);

            const r4 = await getAllCampaignNames();
            setCampaignNames(r4.campaignNames);
        }
    }

    const onAgentSelected = (userId: string) => {
        const user = users?.find((u: User) => u._id === userId);
        setOnboardingRep(undefined);
        setInsuranceAgent(undefined);
        setPensionAgent(user);
    }

    const onInsuranceAgentSelected = (userId: string) => {
        const user = users?.find((u: User) => u._id === userId);
        setPensionAgent(undefined);
        setOnboardingRep(undefined);
        setInsuranceAgent(user);
    }

    const onOnboarderSelected = (userId: string) => {
        const user = users?.find((u: User) => u._id === userId);
        setPensionAgent(undefined);
        setInsuranceAgent(undefined);
        setOnboardingRep(user);
    }

    const getTitle = () => {
        if (startStatus === props.startStatus && endStatus === props.endStatus) {
            return title;
        }

        return `${camelCaseToText(startStatus.split("_")[1])} to ${camelCaseToText(endStatus.split("_")[1])}`;
    }

    return (
        <div className="dashboard-graph">
            <div>
                <Row>
                    <div className="date-and-filters">
                        <DateRangePicker
                            format="dd/MM/yyyy"
                            placeholder="Select Dates"
                            defaultValue={[startDate, endDate]}
                            onOk={onOk}
                        />

                        {dynamicGraphFilterView &&
                            <>
                                <AutoCompleteSelect
                                    onSelect={(selectedItem) => setStartStatus(selectedItem as string)}
                                    toggleText={
                                        <Dropdown
                                            title={<span>From: <b>{camelCaseToText(startStatus.split("_")[1])}</b></span>}
                                        />
                                    }
                                    options={[
                                        ...onboardingStatuses.map(status => ({ label: camelCaseToText(status), value: `onboarding_${status}` })),
                                        { label: "divider", value: "" },
                                        ...pensionStatuses.map(status => ({ label: camelCaseToText(status), value: `pension_${status}` })),
                                        { label: "divider", value: "" },
                                        ...insuranceStatuses.map(status => ({ label: camelCaseToText(status), value: `insurance_${status}` }))
                                    ]}
                                    searchKey="label"
                                    valueKey="value"
                                    placeholder="Search status..."
                                    renderItem={(item) => item.label === "divider" ? <Dropdown.Item divider /> : <span>{item.label}</span>}
                                    applyExistingClasses={false}
                                    additionalClasses="autocomplete-dropdown"
                                />

                                <AutoCompleteSelect
                                    onSelect={(selectedItem) => setEndStatus(selectedItem as string)}
                                    toggleText={
                                        <Dropdown
                                            title={<span>To: <b>{camelCaseToText(endStatus.split("_")[1])}</b></span>}
                                        />
                                    }
                                    options={[
                                        ...onboardingStatuses.map(status => ({ label: camelCaseToText(status), value: `onboarding_${status}` })),
                                        { label: "divider", value: "" },
                                        ...pensionStatuses.map(status => ({ label: camelCaseToText(status), value: `pension_${status}` })),
                                        { label: "divider", value: "" },
                                        ...insuranceStatuses.map(status => ({ label: camelCaseToText(status), value: `insurance_${status}` }))
                                    ]}
                                    searchKey="label"
                                    valueKey="value"
                                    placeholder="Search status..."
                                    renderItem={(item) => item.label === "divider" ? <Dropdown.Item divider /> : <span>{item.label}</span>}
                                    applyExistingClasses={false}
                                    additionalClasses="autocomplete-dropdown"
                                />
                            </>
                        }

                        <AutoCompleteSelect
                            onSelect={(selectedItem) => setGroupBy(selectedItem as string)}
                            toggleText={
                                <Dropdown
                                    title={<span>Group by: <b>{Object.keys(GroupBy)[Object.values(GroupBy).indexOf(groupBy as typeof GroupBy[keyof typeof GroupBy])]}</b></span>}
                                />
                            }
                            options={Object.entries(GroupBy).map(([key, value]) => ({ label: camelCaseToText(key), value }))}
                            searchKey="label"
                            valueKey="value"
                            placeholder="Search group..."
                            renderItem={(item) => <span>{item.label}</span>}
                            applyExistingClasses={false}
                            additionalClasses="autocomplete-dropdown"
                        />

                        <span
                            className="clickable-text"
                            onClick={onShowFilters}>
                            <i className="mdi mdi-filter" />
                            {showFilters ? 'Hide' : 'Show'} Filters
                        </span>

                        <Button
                            onClick={handleData}
                            className="apply-button"
                            variant="primary"
                        >
                            Apply
                        </Button>

                        {showFilters && <>
                            {!dynamicGraphFilterView && <>
                                <AutoCompleteSelect
                                    onSelect={(selectedItem) => setStartStatus(selectedItem as string)}
                                    toggleText={
                                        <Dropdown
                                            title={<span>From: <b>{camelCaseToText(startStatus.split("_")[1])}</b></span>}
                                        />
                                    }
                                    options={[
                                        ...onboardingStatuses.map(status => ({ label: camelCaseToText(status), value: `onboarding_${status}` })),
                                        { label: "divider", value: "" },
                                        ...pensionStatuses.map(status => ({ label: camelCaseToText(status), value: `pension_${status}` }))
                                    ]}
                                    searchKey="label"
                                    valueKey="value"
                                    placeholder="Search status..."
                                    renderItem={(item) => item.label === "divider" ? <Dropdown.Item divider /> : <span>{item.label}</span>}
                                    applyExistingClasses={false}
                                    additionalClasses="autocomplete-dropdown"
                                // additionalStyles={{ width: 'auto', border: '1px solid red' }}
                                />
                                <AutoCompleteSelect
                                    onSelect={(selectedItem) => setEndStatus(selectedItem as string)}
                                    toggleText={
                                        <Dropdown
                                            title={<span>To: <b>{camelCaseToText(endStatus.split("_")[1])}</b></span>}
                                        />
                                    }
                                    options={[
                                        ...onboardingStatuses.map(status => ({ label: camelCaseToText(status), value: `onboarding_${status}` })),
                                        { label: "divider", value: "" },
                                        ...pensionStatuses.map(status => ({ label: camelCaseToText(status), value: `pension_${status}` }))
                                    ]}
                                    searchKey="label"
                                    valueKey="value"
                                    placeholder="Search status..."
                                    renderItem={(item) => item.label === "divider" ? <Dropdown.Item divider /> : <span>{item.label}</span>}
                                    applyExistingClasses={false}
                                    additionalClasses="autocomplete-dropdown"
                                />
                            </>}

                            {users && <Dropdown title={onboardingRep?.fullName || "Onboarding Rep"} onSelect={onOnboarderSelected}>
                                <Dropdown.Item eventKey=''>All</Dropdown.Item>
                                {users.filter((u: User) => u.roles.includes('interviewer')).map(u => <Dropdown.Item
                                    eventKey={u._id}>{u.fullName}</Dropdown.Item>)}
                            </Dropdown>}

                            {users && <Dropdown title={pensionAgent?.fullName || "Pension Agent"} onSelect={onAgentSelected}>
                                <Dropdown.Item eventKey=''>All</Dropdown.Item>
                                {users.filter((u: User) => u.roles.includes('agent') && u.agentModules?.includes('pension')).map(u => <Dropdown.Item
                                    eventKey={u._id}>{u.fullName}</Dropdown.Item>)}
                            </Dropdown>}

                            {users && <Dropdown title={insuranceAgent?.fullName || "Insurance Agent"} onSelect={onInsuranceAgentSelected}>
                                <Dropdown.Item eventKey=''>All</Dropdown.Item>
                                {users.filter((u: User) => u.roles.includes('agent') && u.agentModules?.includes('insurance')).map(u => <Dropdown.Item
                                    eventKey={u._id}>{u.fullName}</Dropdown.Item>)}
                            </Dropdown>}

                            <Dropdown title={camelCaseToText(onboardingTimeOfDay) || "Onboarding Time of Day"}
                                onSelect={setOnboardingTimeOfDay}>
                                <Dropdown.Item eventKey=''>All</Dropdown.Item>
                                <Dropdown.Item eventKey='morning'>Morning (8:00-10:00)</Dropdown.Item>
                                <Dropdown.Item eventKey='noon'>Noon (10:00-12:00)</Dropdown.Item>
                                <Dropdown.Item eventKey='afternoon'>Afternoon (12:00-15:00)</Dropdown.Item>
                                <Dropdown.Item eventKey='evening'>Evening (15:00-24:00)</Dropdown.Item>
                            </Dropdown>

                            <Dropdown title={onboardingDayOfWeek !== '' ? Days[Number(onboardingDayOfWeek)] : 'Onboarding Day of Week'}
                                onSelect={setOnboardingDayOfWeek}>
                                <Dropdown.Item eventKey=''>All</Dropdown.Item>
                                {Days.map(day => <Dropdown.Item eventKey={Days.indexOf(day)}>{day}</Dropdown.Item>)}
                            </Dropdown>

                            <Dropdown title={camelCaseToText(pensionTimeOfDay) || "Pension Call Time of Day"}
                                onSelect={setPensionTimeOfDay}>
                                <Dropdown.Item eventKey=''>All</Dropdown.Item>
                                <Dropdown.Item eventKey='morning'>Morning (8:00-10:00)</Dropdown.Item>
                                <Dropdown.Item eventKey='noon'>Noon (10:00-12:00)</Dropdown.Item>
                                <Dropdown.Item eventKey='afternoon'>Afternoon (12:00-15:00)</Dropdown.Item>
                                <Dropdown.Item eventKey='evening'>Evening (15:00-24:00)</Dropdown.Item>
                            </Dropdown>

                            <Dropdown title={pensionDayOfWeek !== '' ? Days[Number(pensionDayOfWeek)] : 'Pension Call Day of Week'}
                                onSelect={setPensionDayOfWeek}>
                                <Dropdown.Item eventKey=''>All</Dropdown.Item>
                                {Days.map(day => <Dropdown.Item eventKey={Days.indexOf(day)}>{day}</Dropdown.Item>)}
                            </Dropdown>

                            <Dropdown title={pensionTransferred !== '' ? camelCaseToText(pensionTransferred) : 'Pension Transferred'}
                                onSelect={setPensionTransferred}>
                                <Dropdown.Item eventKey=''>All</Dropdown.Item>
                                {PensionTransferredOptions.map(item => <Dropdown.Item eventKey={item}>{camelCaseToText(item)}</Dropdown.Item>)}
                            </Dropdown>


                            <Dropdown title={camelCaseToText(registrationToOnboarding) || 'Reg. To Ob. Days'}
                                onSelect={setRegistrationToOnboarding}>
                                <Dropdown.Item eventKey=''>All</Dropdown.Item>
                                <Dropdown.Item eventKey='zeroToOneDay'>0-1 Days</Dropdown.Item>
                                <Dropdown.Item eventKey='oneToTwoDays'>1-2 Days</Dropdown.Item>
                                <Dropdown.Item eventKey='twoToFourDays'>2-4 Days</Dropdown.Item>
                                <Dropdown.Item eventKey='fourToSevenDays'>4-7 Days</Dropdown.Item>
                                <Dropdown.Item eventKey='sevenToFourteenDays'>7-14 Days</Dropdown.Item>
                                <Dropdown.Item eventKey='fourteenToTwentyOneDays'>14-21 Days</Dropdown.Item>
                                <Dropdown.Item eventKey='twentyOnePlusDays'>21+ Days</Dropdown.Item>
                            </Dropdown>

                            <Dropdown title={camelCaseToText(savingAmount) || "Saving Amount"} onSelect={setSavingAmount}>
                                <Dropdown.Item eventKey=''>All</Dropdown.Item>
                                <Dropdown.Item eventKey='zero'>0</Dropdown.Item>
                                <Dropdown.Item eventKey='oneToTenThousand'>1-10,000</Dropdown.Item>
                                <Dropdown.Item eventKey='tenToHundredThousand'>10,000-100,000</Dropdown.Item>
                                <Dropdown.Item eventKey='hundredToTwoHundredFiftyThousand'>100,000-250,000</Dropdown.Item>
                                <Dropdown.Item
                                    eventKey='twoHundredFiftyToFiveHundredThousand'>250,000-500,000</Dropdown.Item>
                                <Dropdown.Item eventKey='fiveHundredThousandPlus'>500,000+</Dropdown.Item>
                            </Dropdown>

                            <Dropdown title={camelCaseToText(source) || "Source"} onSelect={setSource}>
                                <Dropdown.Item eventKey=''>All</Dropdown.Item>
                                {[...Object.values(CampaignTypes), ...Object.values(SourceTypes)].map(campaign => <Dropdown.Item
                                    eventKey={campaign}>{campaign}</Dropdown.Item>)}
                            </Dropdown>

                            <Dropdown title={camelCaseToText(onboardingMeetingAttendance) || "onboarding Meeting Attendance"} onSelect={setOnboardingMeetingAttendance}>
                                <Dropdown.Item eventKey=''>All</Dropdown.Item>
                                {MeetingAttendance.map(option => <Dropdown.Item eventKey={option}>{camelCaseToText(option)}</Dropdown.Item>)}
                            </Dropdown>

                            <Dropdown title={camelCaseToText(pensionMeetingAttendance) || "pension meeting attendance"} onSelect={setPensionMeetingAttendance}>
                                <Dropdown.Item eventKey=''>All</Dropdown.Item>
                                {MeetingAttendance.map(option => <Dropdown.Item eventKey={option}>{camelCaseToText(option)}</Dropdown.Item>)}
                            </Dropdown>

                            <Dropdown title={camelCaseToText(age) || "Age"} onSelect={setAge}>
                                <Dropdown.Item eventKey=''>All</Dropdown.Item>
                                {Object.values(AgeRanges).map(option => <Dropdown.Item eventKey={option}>{camelCaseToText(option)}</Dropdown.Item>)}
                            </Dropdown>

                            <Dropdown title={camelCaseToText(salary) || "Salary"} onSelect={setSalary}>
                                <Dropdown.Item eventKey=''>All</Dropdown.Item>
                                {Object.values(SalaryRanges).map(option => <Dropdown.Item eventKey={option}>{camelCaseToText(option)}</Dropdown.Item>)}
                            </Dropdown>

                            <Dropdown title={camelCaseToText(gender) || "Gender"} onSelect={setGender}>
                                <Dropdown.Item eventKey=''>All</Dropdown.Item>
                                {Object.values(Genders).map(option => <Dropdown.Item eventKey={option}>{camelCaseToText(option)}</Dropdown.Item>)}
                            </Dropdown>

                            <Dropdown title={camelCaseToText(maritalStatus) || "Marital Status"} onSelect={setMaritalStatus}>
                                <Dropdown.Item eventKey=''>All</Dropdown.Item>
                                {Object.values(MaritalStatuses).map(option => <Dropdown.Item eventKey={option}>{camelCaseToText(option)}</Dropdown.Item>)}
                            </Dropdown>

                            <Dropdown title={testName ? `${testName}: ${testValue}` : camelCaseToText('AbTest')}>
                                {Object.entries(abTestVariations).map(([testName, values]) => (
                                    <Dropdown title={camelCaseToText(testName)} onSelect={() => setTestName(testName)}>
                                        {values.map(value => (
                                            <Dropdown.Item eventKey={value} onSelect={() => setTestValue(value)}>
                                                {value}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown>
                                ))}
                            </Dropdown>

                            <Dropdown title={camelCaseToText(campaignName) || "Campaign Name"} onSelect={setCampaignName}>
                                <div className="scrollable-dropdown">
                                    <input
                                        type="text"
                                        placeholder="Search..."
                                        value={campaignSearch}
                                        onChange={(e) => setCampaignSearch(e.target.value)}
                                        className="dropdown-search-input"
                                    />
                                    <Dropdown.Item eventKey=''>All</Dropdown.Item>
                                    {filteredCampaignNames.map(option => (
                                        <Dropdown.Item key={option} eventKey={option}>
                                            {camelCaseToText(option)}
                                        </Dropdown.Item>
                                    ))}
                                </div>
                            </Dropdown>

                            <Dropdown title={`Pension Sign Days: ${pensionSignDays !== '' ? pensionSignDays : 'All'}`} onSelect={setPensionSignDays}>
                                <Dropdown.Item eventKey=''>All</Dropdown.Item>
                                {Object.values(PensionSignDays).map(option => <Dropdown.Item eventKey={option}>{option}</Dropdown.Item>)}
                            </Dropdown>
                        </>}
                    </div>
                </Row>
            </div>

            {data ? <div className="chart-wrapper">
                {isLoading && <Loader />}
                <BarChart
                    title={getTitle()}
                    yLabels={yLabels}
                    data={data}
                /></div> : null}
        </div>
    );

}

export default DashboardGraph;