import React, { useState } from 'react'

interface EditableInputProps {
    onChange: (value: string) => void;
    value: string;
    validate?: (value: string) => boolean;
}

const EditableInput = ({ onChange, value, validate }: EditableInputProps) => {
    const [isEditing, setIsEditing] = useState(false)
    const [inputValue, setInputValue] = useState(value)

    const handleEditClick = (e: any) => {
        e.stopPropagation()
        setIsEditing(true)
    }
    const handleCheckClick = (e: any) => {
        e.stopPropagation()
        if (validate && !validate(inputValue)) {
            alert('Invalid input')
            return
        }
        setIsEditing(false)
        onChange(inputValue)
    }
    const onChangeInput = (e: any) => {
        setInputValue(e.target.value)
    }

    return (
        <div>
            <input
                type="text"
                value={inputValue}
                onChange={onChangeInput}
                onClick={e => e.stopPropagation()}
                style={{
                    border: !isEditing ? 'none' : '1px solid black',
                    outline: !isEditing ? 'none' : 'none',
                    backgroundColor: !isEditing ? 'transparent' : 'white',
                    width: '100%',
                }}
                readOnly={!isEditing}
            />
            {isEditing ?
                <i className="mdi mdi-checkbox-marked-outline" style={{ fontSize: "1rem", color: "" }} onClick={handleCheckClick} />
                :
                <i className="mdi mdi-square-edit-outline" style={{ fontSize: "1rem", color: "" }} onClick={handleEditClick} />}
        </div>
    )
}

export default EditableInput