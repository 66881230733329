import { useState, ChangeEvent } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { InsuranceAppendix, discountData } from '../../../../interfaces/InsuranceAppendix';
//expirationDate

type EditPremiumDiscountDataProps = {
    show: boolean;
    onHide: () => void;
    appendix: InsuranceAppendix;
    setAppendix: (updatedAppendix: InsuranceAppendix) => void;
};

const EditPremiumDiscountDataModal = ({ show, onHide, appendix, setAppendix }: EditPremiumDiscountDataProps) => {
    const [discountData, setDiscountData] = useState<discountData>({
        ...appendix.premiumDiscountData,
        premiumBeforeDiscount: appendix.premiumDiscountData?.premiumBeforeDiscount || NaN,
        discountTerm: appendix.premiumDiscountData?.discountTerm || 'lifeTime',
        currentDiscountPercent: isNaN(appendix.premiumDiscountData?.currentDiscountPercent) ? NaN : appendix.premiumDiscountData?.currentDiscountPercent,
        discountExplanation: appendix.premiumDiscountData?.discountExplanation || '',
        premiumAfterDiscount: isNaN(appendix.premiumDiscountData?.premiumAfterDiscount) ? NaN : appendix.premiumDiscountData?.premiumAfterDiscount,
    });
    const [discountTermType, setDiscountTermType] = useState<string>('lifeTime');

    const handleDiscountTermTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const selectedType = e.target.value;
        setDiscountTermType(selectedType);
        // If "Enter number of years" is selected, clear the discountTerm value
        if (selectedType === 'numeric') {
            setDiscountData({ ...discountData, discountTerm: '' });
        }
    };

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setDiscountData({ ...discountData, [name]: value });
    };

    const handleSave = () => {
        const premiumAfterDiscount = Number((Number(discountData.premiumBeforeDiscount) * (1 - Number(discountData.currentDiscountPercent) / 100)).toFixed(4));
        const updatedAppendix: InsuranceAppendix = {
            ...appendix,
            premiumDiscountData: {...discountData, premiumAfterDiscount}
        };
        setAppendix({...updatedAppendix, premium: premiumAfterDiscount});
        onHide();
    };

    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
            size='sm'
            onClick={(e: any) => e.stopPropagation()}
        >
            <Modal.Header closeButton className="bg-primary text-white">
                <Modal.Title style={{ color: 'white' }}>Edit Premium Discount Data</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ borderLeft: '1px solid #0dcaf0', borderRight: '1px solid #0dcaf0' }}>
                <Form>
                    <Form.Group controlId="premiumBeforeDiscount">
                        <Form.Label>Premium Before Discount</Form.Label>
                        <Form.Control
                            className='mb-1'
                            type="number"
                            name="premiumBeforeDiscount"
                            value={discountData.premiumBeforeDiscount}
                            onChange={handleInputChange}
                            required
                        />
                    </Form.Group>

                    <Form.Group controlId="discountTermType">
                        <Form.Label>Discount Term</Form.Label>
                        <Form.Select
                            className='mb-1'
                            name="discountTermType"
                            value={discountTermType}
                            onChange={handleDiscountTermTypeChange}
                        >
                            <option value="lifeTime">Life Time</option>
                            <option value="numeric">Enter number of years</option>
                        </Form.Select>
                    </Form.Group>

                    {discountTermType === 'numeric' && (
                        <Form.Group controlId="discountTermNumeric">
                            <Form.Label>Enter Number of Years</Form.Label>
                            <Form.Control
                                className='mb-1'
                                type="text"
                                name="discountTerm"
                                value={discountData.discountTerm}
                                onChange={handleInputChange}
                                required
                            />
                        </Form.Group>
                    )}

                    <Form.Group controlId="currentDiscountPercent">
                        <Form.Label>Current Discount Percent</Form.Label>
                        <Form.Control
                            className='mb-1'
                            type="number"
                            name="currentDiscountPercent"
                            value={discountData.currentDiscountPercent}
                            min={0}
                            max={100}
                            onChange={handleInputChange}
                            required
                        />
                    </Form.Group>

                    <Form.Group controlId="discountExplanation">
                        <Form.Label>Discount Explanation</Form.Label>
                        <Form.Control
                            className='mb-1'
                            type="text"
                            name="discountExplanation"
                            value={discountData.discountExplanation}
                            onChange={handleInputChange}
                            required
                        />
                    </Form.Group>

                    <Form.Group controlId="premiumAfterDiscount">
                        <Form.Label>Premium After Discount</Form.Label>
                        <Form.Control
                            className='mb-1 bg-light'
                            type="number"
                            name="premiumAfterDiscount"
                            value={(discountData.premiumBeforeDiscount * (1 - discountData.currentDiscountPercent / 100)).toFixed(4)}
                            disabled={true}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer style={{ borderLeft: '1px solid #0dcaf0', borderRight: '1px solid #0dcaf0', borderBottom: '1px solid #0dcaf0' }}>
                <Button variant="primary" onClick={handleSave}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditPremiumDiscountDataModal;
