import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Card } from 'react-bootstrap';
import { ChartData, ChartDataset } from 'chart.js';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

interface BarChartProps {
    title: string;
    yLabels: string[];
    data: {
        x: string;
        yValues: number[];
    }[];
}

const BarChart = (props: BarChartProps) => {

    // Utility function to create a gradient
    const createGradient = (ctx: CanvasRenderingContext2D, colorStart: string, colorEnd: string) => {
        const gradient = ctx.createLinearGradient(0, 500, 0, 150);
        gradient.addColorStop(0, colorStart);
        gradient.addColorStop(1, colorEnd);
        return gradient;
    };

    // barChartData function
    const barChartData = (): ChartData<"bar", number[], string> => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (!ctx) return { labels: [], datasets: [] };

        const colors = [
            createGradient(ctx, '#dc3545', '#fd7e14'),
            createGradient(ctx, '#6610f2', '#6f42c1'),
            createGradient(ctx, '#28a745', '#20c997'),
            createGradient(ctx, '#17a2b8', '#007bff')
        ];

        const datasets: ChartDataset<"bar", number[]>[] = props.yLabels.map((yLabel, index) => {
            return {
                label: yLabel,
                backgroundColor: colors[index % colors.length],
                borderColor: colors[index % colors.length],
                hoverBackgroundColor: colors[index % colors.length],
                hoverBorderColor: colors[index % colors.length],
                data: props.data.map(item => item.yValues[index]),
                barPercentage: 0.7,
                categoryPercentage: 0.5
            };
        });

        return {
            labels: props.data.map(item => item.x),
            datasets
        };
    };

    // Chart options
    const barChartOpts = {
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                backgroundColor: '#8c98a4',
                titleFontColor: '#fff',
                bodyFontColor: '#fff',
                bodyFontSize: 14,
                displayColors: false,
                callbacks: {
                    label: function (context: any) {
                        let label = context.dataset.label || '';
                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            label += context.parsed.y;
                        }
                        const orangeData = context.chart.data.datasets[0].data[context.dataIndex];
                        const blueData = context.parsed.y;
                        const percentage = (blueData / orangeData * 100).toFixed(2) + '%';
                        label += ` (${percentage})`;
                        return label;
                    }
                }
            },
        },
        scales: {
            y: {
                grid: {
                    display: false,
                    color: 'rgba(0,0,0,0.05)',
                },
                stacked: false,
                ticks: {
                    stepSize: 20,
                },
            },
            x: {
                stacked: false,
                grid: {
                    color: 'rgba(0,0,0,0.05)',
                },
            },
        },
    };

    return (
        <Card>
            <Card.Body>
                <h4 className="header-title mb-3">{props.title}</h4>
                <div style={{ height: '320px' }} className="chartjs-chart">
                    <Bar data={barChartData()} options={barChartOpts} />
                </div>
            </Card.Body>
        </Card>
    );
};

export default BarChart;
