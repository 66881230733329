export interface Insurance {
    _id: string,
    client: any,
    rep: string,
    agent: string,
    analyst: any,
    operator: string,
    status: string,
    isActive: boolean,
    endTime: Date,
    files: string[],
    closedLostReason?: InsuranceClosedLostReasons,
    round: number
    firstKitSignatureDate: Date,
    appendicesSubmissionDate: Date,
    savingAmount: number,
    sms2010Link: string,
    familyMembersIdsToInsure: string[],
    polywizzTracker: polywizzTracker,
    lastStatusChangeDate: Date | string,
}
interface polywizzTracker {
    client: polywizzFields,
    spouse: polywizzFields,
}

export interface polywizzFields {
    clientId: string,
    taskId: string,
    status: InsurancePolywizzStatuses,
    submissionTime: Date
}
export enum InsuranceClosedLostReasons {
    notEligibleDueToChangesInThePastMonth = 'notEligibleDueToChangesInThePastMonth',
    clientIsNotInterested = 'clientIsNotInterested',
    clientDidntScheduleMeeting = 'clientDidntScheduleMeeting',
    noShow = 'noShow',
    missingHafakotSignature = 'missingHafakotSignature',
    lostToCurrentCompany = 'lostToCurrentCompany',
}

export enum InsurancePolywizzStatuses {
    notStarted = "notStarted",
    inProgress = "inProgress",
    pending = "pending",
    timeout = "timeout",
    missingData = "missingData",
    mountainDataReceived = "mountainDataReceived",
    completed = "completed",
    failed = "failed"
}
