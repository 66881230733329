import React, { useContext, useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { UserProfileContext } from '../../../context/userProfileContext';
import Details from './Details';
import Products from '../clientProducts/Products';
import ProfileFiles from "../ProfileFiles";
import Deposits from '../Deposits';
import { APICore } from '../../../utils/api/apiCore';

const MainData = () => {
    const api = new APICore();

    const [activeTab, setActiveTab] = useState('Details');
    const { profileContextData } = useContext(UserProfileContext);
    const { client } = profileContextData;

    const userRoles = api.getLoggedInUser().user.roles;

    const isInterviewer = userRoles.includes('interviewer') && userRoles.length === 1 && !api.getLoggedInUser().user.isAdmin;

    const tabs = isInterviewer? ['Details'] : ['Details', 'Products', 'Files', 'Deposits'];

    const getTabComponent = (tabName: string) => {
        if (!client) return;

        switch (tabName) {
            case 'Details':
                return <Details />;
            case 'Products':
                return <Products />;
            case 'Files':
                return <ProfileFiles />;
            case 'Deposits':
                return <Deposits />;
            default:
                return <></>;
        }
    };

    return (
        <div className='main-data-wrapper'>
            <Tabs
                defaultActiveKey="Details"
                transition={false}
                activeKey={activeTab}
                onSelect={(k: any) => setActiveTab(k)}>

                {tabs.map((tab, index) =>
                    <Tab key={index} eventKey={tab} title={tab}>
                        {activeTab === tab ? getTabComponent(tab) : <></>}
                    </Tab>
                )}
            </Tabs>
        </div>
    );
}

export default MainData;
