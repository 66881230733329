
// This function is used to display numeric values in the input fields.
// It removes the leading zero from the value if it exists.
// It also returns 0 if the value is undefined or null.
export const displayNumericInputFieldsValue = (value: string | number | undefined): string | number => {
    if (value === undefined || value === null) {
        return 0;
    }
    if (typeof value === 'string' && value.startsWith('0')) {
        return value.slice(1);
    }
    return value;
}
