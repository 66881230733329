import { maxValue_, minValue_, notEmpty_ } from "../../../helpers/validations"

export const initialStatus = {
    company: "", 
    productType: "",
    savings: "",
    deposit: "",
    validity: "",
};

export const dealStatus = {
    saved: 'Deal has been successfully saved',
    updated: 'Deal has been successfully updated',
    created: 'This is an empty new deal, please fill & save it.',
    problem: 'Problem with your request',
    deleted: 'Deal successfully deleted',
}

export const validateDealObject = {
    company:  [
        {callback: notEmpty_, message: "company can't be empty"},
    ],
    productType:  [
        {callback: notEmpty_, message: "product type can't be empty"},
    ],
    savings:  [
        {callback: notEmpty_, message: "savings can't be empty"},
        {callback: minValue_(0), message: "minimum is 0"},
        {callback: maxValue_(2), message: "maximum is 2"},
    ],
    deposit:  [
        {callback: notEmpty_, message: "deposit can't be empty"},
        {callback: minValue_(0), message: "minimum is 0"},
        {callback: maxValue_(2), message: "maximum is 2"},
    ],
    validity:  [
        {callback: notEmpty_, message: "validity can't be empty"},
        {callback: minValue_(0), message: "minimum is 0"},
    ],
}
