import React from 'react';
import { TaxDeduction867Form } from '../../../../../interfaces/TaxData';
import GenericFormBoxRenderer from '../components/GenericFormBoxRenderer';

interface TaxDeduction867FormProps {
    data: TaxDeduction867Form;
    prefix: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const TaxDeductionForm = ({ data, prefix, onChange }: TaxDeduction867FormProps) => {
    return <GenericFormBoxRenderer
        data={data}
        prefix={prefix}
        onChange={onChange}
        excludeFields={["_id", "files"]}
        title="Tax Deduction"
    />
};

export default TaxDeductionForm;