import React, {useContext, useState} from 'react';
import {Modal} from 'react-bootstrap';
import {FormInput} from '../../../components';
import { useNavigate} from "react-router-dom";
import {camelCaseToText} from '../../../utils/camelCaseToText';
import {GenderOptions} from '../../../interfaces/Client';
import {Client} from "../../../interfaces/Client";
import {UserProfileContext} from "../../../context/userProfileContext";
import LaddaButton, {EXPAND_LEFT} from "react-ladda-button";
import {createClientSpouse} from "../../../utils/api/clients";
import { attendOBMeetingOptions } from '../../../interfaces/Client';

interface CreateSpouseProps {
    show: boolean;
    onHide: () => void;
}

const CreateSpouse = ({show, onHide}: CreateSpouseProps) => {
    const navigate = useNavigate();
    const {profileContextData, onEditClient} = useContext(UserProfileContext);
    const client:Client = profileContextData?.client;

    const [spouse, setSpouse] = useState<Client>({
        ...client?.spouse as Client,
        gender: client.gender === 'female'?'male':'female',
        attendOBMeeting: attendOBMeetingOptions[0]
    });
    const [isLoading, setIsLoading] = useState(false);

    const onChange = (event: any) => {
        const name = event.target.name;
        const value = event.target.value;

        console.log({name, value})

        const tempSpouse: any = {...spouse};

        tempSpouse[name] = value;
        setSpouse(tempSpouse);
    }


    const onSubmit = async () => {
        if (!spouse) {
            return;
        }

        const tmpSpouse:any = {...spouse};

        const mandatory = ['firstName','lastName','idNumber','email','phoneNumber'];
        for (const key of mandatory) {
            if (!tmpSpouse[key]) {
                alert(`Please enter ${camelCaseToText(key)}`);
                return;
            }
        }

        setIsLoading(true);
        try {
            const result = await createClientSpouse({spouse, clientId: client?._id})
            const url =`/clients/profile?clientId=${result.spouse._id}&module=taxReturn`;
            window.open(url, '_blank');
            onHide();
        } catch (err:any) {
            console.log(err);
            setIsLoading(false);
            if (err.status === 403) {
                alert('Spouse already exists');
                return;
            }
            alert('Error creating spouse');
        } finally {
            setIsLoading(false);
        }


    }
    

    return (
        <>
            <Modal show={show} onHide={onHide} aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header className="bg-light" onHide={onHide} closeButton>
                    <Modal.Title className="m-0">{client.fullName} - Create Spouse</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-4">
                    <FormInput
                        label="First Name"
                        type="text"
                        name="firstName"
                        defaultValue={spouse?.firstName}
                        placeholder="Enter first name"
                        containerClass={'mb-3'}
                        onChange={onChange}
                    />
                    <FormInput
                        label="Last Name"
                        type="text"
                        name="lastName"
                        defaultValue={spouse?.lastName}
                        placeholder="Enter last name"
                        containerClass={'mb-3'}
                        onChange={onChange}
                    />

                    <FormInput
                        label="ID number"
                        type="text"
                        name="idNumber"
                        defaultValue={spouse?.idNumber}
                        placeholder="Enter ID Number"
                        maxLength={9}
                        containerClass={'mb-3'}
                        onChange={onChange}
                    />

                    <FormInput
                        label="Gender"
                        type="select"
                        name="gender"
                        value={spouse?.gender}
                        placeholder="select gender"
                        containerClass={'mb-3'}
                        options={GenderOptions.map((gender:string) => ({
                            label: camelCaseToText(gender) || '',
                            value: gender
                        }))}
                        onChange={onChange}
                    />

                    <FormInput
                        label="Email Address"
                        type="email"
                        name="email"
                        defaultValue={spouse?.email}
                        placeholder="Enter email"
                        containerClass={'mb-3'}
                        onChange={onChange}
                    />

                    <FormInput 
                        label="Date of Birth"
                        type="date"
                        name="dateOfBirth"
                        defaultValue={spouse?.dateOfBirth?.toString().split('T')[0]}
                        placeholder="Enter date of birth"
                        containerClass={'mb-3'}
                        onChange={onChange}
                    />

                    <FormInput
                        label="Phone"
                        type="text"
                        name="phoneNumber"
                        defaultValue={spouse?.phoneNumber}
                        placeholder="Enter phone number"
                        containerClass={'mb-3'}
                        onChange={onChange}
                    />

                    <FormInput       
                        label="Attend the OB Meeting"
                        type="select"
                        name="attendOBMeeting"
                        value={spouse.attendOBMeeting}
                        defaultValue={attendOBMeetingOptions[0]}
                        placeholder="select"
                        containerClass={'mb-3'}
                        options={attendOBMeetingOptions.map((option:any) => ({
                            label: camelCaseToText(option) || '',
                            value: option
                        }))}
                        onChange={onChange}
                    />

                    <div className="text-end">
                        <LaddaButton loading={isLoading}
                                     data-style={EXPAND_LEFT}
                                     className="btn btn-primary grouped-btn"
                                     dir="ltr" onClick={onSubmit}>
                            Create Spouse
                        </LaddaButton>
                    </div>
                    {/* </VerticalForm> */}
                </Modal.Body>
            </Modal>
        </>
    );
};

export default CreateSpouse;
