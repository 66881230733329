import { is } from 'immutable';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
interface InteractiveZoomImageProps {
    src: string;
    alt: string;
    zoomScale?: number;
    [key: string]: any;
}
const InteractiveZoomImage = forwardRef(({
    src,
    alt,
    zoomScale = 2,
    ...rest
}: InteractiveZoomImageProps, ref: any) => {
    if (zoomScale <= 1) {
        throw new Error('Zoom scale must be greater than 1');
    }
    const imgRef = useRef<HTMLImageElement>(null);
    const [isZoomed, setIsZoomed] = useState(false);
    const [style, setStyle] = useState({});

    const resetZoom = () => {
        setIsZoomed(false);
        setStyle(prevStyle => ({ ...prevStyle, transform: 'scale(1)', cursor: 'zoom-in' }));
    };

    const applyZoom = (event: any) => {
        const imgRect = event.target.getBoundingClientRect();

        const clientX = event.clientX;
        const clientY = event.clientY;

        if (
            clientX >= imgRect.left &&
            clientX <= imgRect.right &&
            clientY >= imgRect.top &&
            clientY <= imgRect.bottom
        ) {
            const offsetX = clientX - imgRect.left;
            const offsetY = clientY - imgRect.top;

            setStyle(prevStyle => ({
                ...prevStyle,
                transform: `scale(${zoomScale})`,
                transformOrigin: `${offsetX}px ${offsetY}px`,
                cursor: 'zoom-out',
            }));
        }
        setIsZoomed(true);
    }

    const handleImageClick = (event: any) => {
        if (isZoomed) {
            resetZoom();
        }
        else {
            applyZoom(event);
        }
    };

    const styleFromProps = rest.style || {};
    const restOfPropsWithoutStyle = Object.entries(rest).reduce( // Needed to avoid bugs
        (acc: any, [key, value]) => {
            if (key !== 'style') {
                acc[key] = value;
            }
            return acc;
        },
        {}
    );

    useImperativeHandle(ref, () => ({
        resetZoom,
    }));

    const getWidthAndHeightBasedOnIntrinsicRatio = () => {
        if (imgRef.current) {
            const { naturalWidth, naturalHeight } = imgRef.current;
            const aspectRatio = naturalWidth / naturalHeight;
            const height = '85vh';
            const width = `${aspectRatio * 85}vh`;
            return { width, height };
        }
        return {};
    }

    const onError = () => {
        alert('Image failed to load');
    }
    
    return (
        <div style={getWidthAndHeightBasedOnIntrinsicRatio()}>
            <img
                ref={imgRef}
                src={src}
                alt={alt}
                style={{
                    transition: 'transform 0.2s ease',
                    ...style,
                    ...styleFromProps,
                }}
                onClick={handleImageClick}
                {...restOfPropsWithoutStyle}
                onError={onError}
            />
        </div>
    );
});

export default InteractiveZoomImage;
