
import React from "react";
import {formatDate} from "../utils/formatDate";

const DateColumn = (params: any) => {
    return (
        <>
            {formatDate(params.value, true, false)}
        </>
    );
};

export default DateColumn;