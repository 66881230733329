export const taxReturnFileClassifications = [
    "newIdentificationCardFront",
    "newIdentificationCardBack",
    "oldIdentificationCard",
    "identificationCardAttachment",
    "donationReceipt",
    "bankAccountOwnership",
    "annualLifeInsuranceReport",
    "militaryDischarge",
    "academicDegreeApproval",
    "academicDegreeApprovalSpouse",
    "other",
    "powerOfAttorney",
    "powerOfAttorneyIRS",
    "powerOfAttorneyNIIMerged",
    "powerOfAttorneyNII",
    "powerOfAttorneyInsuranceCompany",
    "form867",
    "nationalInsuranceBenefit",
    "nationalInsuranceBenefitSpouse",
    "newOrReturningImmigrant",
    "newOrReturningImmigrantSpouse",
]
