import './../clientProfile.scss'
import { Button } from 'react-bootstrap';
import { InsurancePolicy } from '../../../interfaces/InsurancePolicy';
import { InsuredPerson } from '../../../interfaces/InsuranceAppendix';
import { getAppendicesFile } from '../../../utils/api/insuranceAppendix';
import { useState } from 'react';
import LaddaButton, { EXPAND_LEFT } from 'react-ladda-button';
import { downloadFileFromAPI } from '../../../utils/downloadFileFromAPI';
import Select from 'react-select';


interface InsuranceProductsMenuHeaderProps {
    setShowNewProductCreationModal: (show: boolean) => void;
    setShowNewAppendixCreationModal: (show: boolean) => void;
    insurancePolicies: InsurancePolicy[];
    filterByPolicyNumbers: string[];
    setFilterByPolicyNumbers: (policyNumber: string[]) => void;
    insuredPersons: InsuredPerson[];
    filterByInsuredPerson: string;
    setFilterByInsuredPerson: (insuredPerson: string) => void;
    clientId: string;
    clientFullName: string;
}

const InsuranceProductsMenuHeader = ({ setShowNewProductCreationModal, setShowNewAppendixCreationModal, insurancePolicies, filterByPolicyNumbers, setFilterByPolicyNumbers, insuredPersons, filterByInsuredPerson, setFilterByInsuredPerson, clientId, clientFullName }: InsuranceProductsMenuHeaderProps) => {
    const [isDownloading, setIsDownloading] = useState(false);
    let controlShouldRenderValue = insurancePolicies.length !== filterByPolicyNumbers.length;
    const handleDownload = async () => {
        setIsDownloading(true);
        try {
            const response = await getAppendicesFile({ clientId });
            const fileName = clientFullName + "_appendices_" + new Date().toLocaleDateString();
            downloadFileFromAPI(response, fileName, "xlsx")
        } finally {
            setIsDownloading(false);
        }
    }

    const getLabelForPolicy = (policyId: string) => {
        const policy = insurancePolicies.find((policy) => policy._id === policyId);
      
        if (!policy) return "Policy with no data";
      
        const { policyNumber, branch, createdAt } = policy;
      
        const formatDate = (date: Date | string) => {
          const formattedDate = new Date(date);
          return `${formattedDate.toLocaleDateString()} - ${formattedDate.toLocaleTimeString()}`;
        };
      
        if (policyNumber && branch) return `${policyNumber} - ${branch}`;
        if (createdAt && branch) return `${formatDate(createdAt)} - ${branch}`;
        if (policyNumber) return policyNumber;
        if (createdAt) return formatDate(createdAt);
      
        return "Policy with no data";
      };
      

    const handleMultiSelectChange = (selected: any) => {
        if (selected.find((item: any) => item.value === "*")) {
            setFilterByPolicyNumbers(insurancePolicies.map((policy) => policy._id));
            return;
        }
        setFilterByPolicyNumbers(selected.map((policy: any) => policy.value));
    }

    const handleMenuOpen = () => {
        if (insurancePolicies.length === filterByPolicyNumbers.length) {
            setFilterByPolicyNumbers([]);
        }
    }

    return (
        <div className='d-flex flex-row-reverse'>
            <div className='p-2'>
                <Button onClick={() => setShowNewProductCreationModal(true)}>
                    New Policy
                </Button>
            </div>
            <div className='p-2'>
                <Button onClick={() => setShowNewAppendixCreationModal(true)}>
                    New Appendix
                </Button>
            </div>
            <div className='p-2'>
                <Select
                    isMulti
                    options={[
                        { label: "Show All", value: "*" },
                        ...insurancePolicies.map((policy) => ({ label: getLabelForPolicy(policy._id), value: policy._id }))
                    ]}

                    value={filterByPolicyNumbers.map((policyNumber) => ({ label: getLabelForPolicy(policyNumber), value: policyNumber }))}
                    onChange={handleMultiSelectChange}
                    placeholder="Filter by Policy Number"
                    className="multiple-select"
                    classNamePrefix="prefix "
                    isSearchable={true}
                    minMenuHeight={300}
                    controlShouldRenderValue={controlShouldRenderValue}
                    isClearable={insurancePolicies.length !== filterByPolicyNumbers.length}
                    onMenuOpen={handleMenuOpen}
                />
            </div>
            <div className='p-2'>
                <select
                    className="form-select btn-primary cursor-pointer"
                    value={filterByInsuredPerson}
                    onChange={(e: any) => setFilterByInsuredPerson(e.target.value)}
                >
                    <option value="">Filter by Insured Person</option>
                    {insuredPersons.map((insuredPerson) => (
                        <option key={insuredPerson.idNumber} value={insuredPerson.idNumber}>
                            {insuredPerson.name} - {insuredPerson.idNumber}
                        </option>
                    ))}
                </select>
            </div>
            <div className='p-2'>
                <LaddaButton
                    loading={isDownloading}
                    onClick={handleDownload}
                    data-color="#eee"
                    data-style={EXPAND_LEFT}
                    data-spinner-size={30}
                    data-spinner-color="#ddd"
                    data-spinner-lines={12}
                    className="btn btn-primary"
                >
                    <i className="mdi mdi-download"></i>
                    {'\u0020'}Download
                </LaddaButton>
            </div>
        </div>
    );
};



export default InsuranceProductsMenuHeader;