import React from 'react';
import './UnansweredClientsWarningBanner.scss';
import { useTranslation } from 'react-i18next';
import { useUnansweredClientsContext } from '../../context/UnansweredClientsContext';

const UnansweredClientsWarningBanner = () => {
    const { t } = useTranslation("components");
    const { unansweredClients, showUnansweredClientsWarningBanner } = useUnansweredClientsContext();
    const client = unansweredClients[0];
    const numberOfOthers = unansweredClients.length - 1;

    const handleClientNameClick = () => {
        window.open(`/clients/profile?clientId=${client.client}`, '_blank');
    }

    const textAfterFirstClientsName = () => {
        if (numberOfOthers === 0) {
            return ` ${t("is waiting for your answer")}`
        } else if (numberOfOthers === 1) {
            return ` ${t("and another one are waiting for your answer")}`;
        } else {
            return ` ${t("and")}-${numberOfOthers} ${t("others are waiting for your answer")}`;
        }
    };

    return (
        showUnansweredClientsWarningBanner && (
            <div className="warning-banner">
                <div className='warning-content'>
                    <i className="fas fa-exclamation-triangle mx-1"></i>
                    <u className="cursor-pointer" onClick={handleClientNameClick}>{client.fullName}</u>
                    {textAfterFirstClientsName()}
                    <i className="fas fa-exclamation-triangle mx-1"></i>
                </div>
            </div>
        )
    );
};

export default UnansweredClientsWarningBanner;
