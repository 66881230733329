import { APICore, INSURANCE_URL } from './apiCore';

const api = new APICore();

const createInsurance = (clientId: string) => {
    return api.create(`${INSURANCE_URL}/insurance`, { clientId });
}

const editInsurance = (insuranceId: string, body: any) => {
    const relativeUrl = `/insurance/${insuranceId}`;
    return api.update(`${INSURANCE_URL}${relativeUrl}`, body);
}

const getInsuranceStatuses = () => {
    return api.get(`${INSURANCE_URL}/insurance/statuses`);
}

const resubmitPolywizz = (clientId: string, signee: "client" | "spouse") => {
    return api.update(`${INSURANCE_URL}/insurance/polywizz/submit?clientId=${clientId}`, { signee });
}

export {
    getInsuranceStatuses,
    createInsurance,
    editInsurance,
    resubmitPolywizz
}