import {Option} from "../interfaces/Option";

const banks: Option[] = [
    {label: 'יהב (4)', value: '4'},
    {label: '(54) ירושלים', value: '54'},
    {label: 'הדואר (9)', value: '9'},
    {label: 'לאומי (10)', value: '10'},
    {label: 'דיסקונט (11)', value: '11'},
    {label: 'הפועלים (12)', value: '12'},
    {label: 'אגוד (13)', value: '13'},
    {label: 'אוצר החייל (14)', value: '14'},
    {label: 'מרכנתיל (17)', value: '17'},
    {label: 'מזרחי טפחות (20)', value: '20'},
    {label: 'יו בנק בע"מ (26)', value: '26'},
    {label: 'ההבינלאומי הראשון לישראל בע"מ (31)', value: '31'},
    {label: 'מסד (46)', value: '46'},
    {label: 'פועלי אגודת ישראל (52)', value: '52'},
    {label: '(18) One Zero', value: '18'},
    {label: '(10) PEPPER', value: '10'},
]

export {
    banks
}