import React, { useState, ChangeEvent } from 'react';
import { Dropdown } from 'react-bootstrap';
import './AutoCompleteSelect.scss';

interface AutoCompleteSelectProps {
    onSelect: (selectedItem: string | null, event?: any) => void;
    toggleText: React.ReactNode;
    options: any[];
    searchKey: string;
    valueKey: string;
    label?: string | React.ReactNode;
    placeholder?: string;
    noOptionsLabel?: React.ReactNode;
    renderItem?: (item: any) => React.ReactNode;
    additionalClasses?: string;
    additionalStyles?: React.CSSProperties;
    applyExistingClasses?: boolean;
}

const AutoCompleteSelect = ({
    onSelect,
    toggleText,
    options,
    searchKey,
    valueKey,
    placeholder = "Search...",
    label = "",
    noOptionsLabel = <span>No options found.</span>,
    renderItem = (item: any) => <>{item[searchKey]}</>,
    additionalClasses = "",
    additionalStyles = {},
    applyExistingClasses = true,
}: AutoCompleteSelectProps) => {
    const [searchTerm, setSearchTerm] = useState<string>('');

    const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const filteredOptions = options.filter((item) =>
        String(item[searchKey])
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
    );

    return (
        <Dropdown className={applyExistingClasses ? `auto-complete-select ${additionalClasses}` : additionalClasses} style={additionalStyles} onSelect={onSelect}>
            {typeof label === "string" ? <label htmlFor='auto-complete-select' className='form-label'>{label}</label> : label}
            <Dropdown.Toggle variant="" className="toggle overflow-hidden">
                {toggleText}
                {applyExistingClasses && <i className="mdi mdi-chevron-down arrow"></i>}
            </Dropdown.Toggle>
            <Dropdown.Menu className="menu" style={{ maxHeight: '70vh', overflowY: 'scroll'}}>
                <input
                    type="text"
                    placeholder={placeholder}
                    onChange={handleSearchChange}
                    value={searchTerm}
                    className="form-control"
                />
                {filteredOptions.map((item) => (
                    <Dropdown.Item key={String(item[valueKey])} eventKey={String(item[valueKey])} className="item">
                        {renderItem(item)}
                    </Dropdown.Item>
                ))}
                {filteredOptions.length === 0 && (
                    <Dropdown.Item disabled>{noOptionsLabel}</Dropdown.Item>
                )}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default AutoCompleteSelect;
