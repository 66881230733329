import React, {useContext, useEffect, useState} from 'react';
import {Button, Col, OverlayTrigger, Row, Tooltip} from 'react-bootstrap';
import {UserProfileContext} from '../../../context/userProfileContext';
import ClientInfo from "../clientInfo/ClientInfo";
import ModuleTabs from "../moduleTabs/ModuleTabs";
import ProfileBox from "../ProfileBox";
import FilesList from "../../files/FilesList";
import {APICore} from "../../../utils/api/apiCore";
import TimeLine from "../timeLine/TimeLine";
import ChatArea from "../../../components/chat-area/ChatArea";

const Onboarding = () => {
    const api = new APICore();
    // useEffect(() => {
    //     setSelectedTab(profileContextData.selectedModule?.data?.moduleName);
    // }, [profileContextData.selectedModule]);

    const {profileContextData} = useContext(UserProfileContext);
    const {client} = profileContextData;
    const currentModule = profileContextData?.selectedModule;

    return <div className={`onboarding-module ${profileContextData.expandClientData ? 'data-expanded' : ''}`}>
        <ClientInfo/>
        <ModuleTabs/>
        <Row className='onboarding-layout'>
            {!currentModule.data.rounds.length?<div className={`regular-modules ${profileContextData.expandClientData ? 'data-expanded' : ''}`}>
                <div className={'timeline-row-wrapper not-active-module'}>
                    <TimeLine createNewRoundEnabled={true}/>
                </div>
            </div>:null}

            <Col xl={8} lg={9}>
                <Row>
                    <Col xl={6} lg={9}>
                        <ProfileBox
                            isOnboarding={true}
                        />
                    </Col>
                    {api.canHandleSensitiveData(client) ?

                        <Col xl={6} lg={9}>
                            {client && profileContextData.currentRound? <FilesList shortList={true} client={client}/> : null}
                        </Col>
                        : null}
                </Row>
            </Col>
            {client ?
                <Col xl={4} lg={4}>
                    <ChatArea
                        client={client}
                        sendMessageEnabled={true}
                        showJotformLinks={true}
                        chatTitle={client.firstName + ' ' + client.lastName}
                        polling={true}
                        enableSocket={true}
                    />
                </Col>
                : null}
        </Row>
    </div>
}

export default Onboarding