import { Message, responseStatusToColor, responseStatus, colorToText, ColorCodes } from "../../interfaces/Message";
import betterAvatar from '../../assets/images/better-avatar.png';
import sent from '../../assets/images/profile/sent.png';
import delivered from '../../assets/images/profile/delivered.png';
import undelivered from '../../assets/images/profile/undelivered.png';
import read from '../../assets/images/profile/read.png';
import { removeTags } from "../../utils/removeTags";
import { getFileType } from "../../utils/getFileType";
import { formatDate } from "../../utils/formatDate";
import profileImg from '../../assets/images/users/user-avatar.png';
import emailIcon from "./email.png";
import whatsappIcon from "./whatsapp.png";
import { Dropdown } from 'react-bootstrap';
import { removeRepFromMessage, updateResponseStatus } from "../../utils/api/messages";
import Tooltip from '@mui/material/Tooltip';
import { useState } from "react";
import { useUnansweredClientsContext } from "../../context/UnansweredClientsContext";
import pdfThumbnail from "../../assets/images/pngThumbnail.png";
import { APICore } from "../../utils/api/apiCore";
import { User } from "../../interfaces/User";


interface UserMessageProps {
    message: Message,
}

const MessageItem = ({ message }: UserMessageProps) => {
    const [responseStatus, setResponseStatus] = useState<responseStatus>(message.responseStatus);
    const { fetchUnansweredClients } = useUnansweredClientsContext();
    const {user: currentUser} = new APICore().getLoggedInUser();

    const betterUser: any = {
        _id: '',
        role: 'client',
        interviewer: '',
        fullName: "",
        idNumber: "",
        firstName: 'Better',
        lastName: '',
        email: 'support@allbetter.co.il',
        phoneNumber: '+97235708030',
        imageUrl: betterAvatar,
        isAdmin: false // ?
    };

    if (!message.reps) {
        message.reps = [];
        message.reps[0] = message.rep;
    }

    const user = message.sender === 'client' ? message.client : message.reps[0] || betterUser;

    const getStatusImage = (message: Message) => {
        switch (message.status) {
            case 'sent':
                return sent;
            case 'read':
                return read;
            case 'delivered':
                return delivered;
            case 'undelivered':
                return undelivered;
            default:
                return sent;
        }
    }

    const getSenderName = () => {
        if (message.sender === 'rep') {
            return message.reps.length ? message.reps[0].fullName : 'Better Bot';
        }
        else if (message.sender === 'client')
            return message.client.fullName;
        else if (message.sender === 'bot') {
            return `Better Bot (${message.futureMessage?.assignedBot?.botName} #${message.futureMessage?.messageId})`;
        }
        else
            return 'Unknown';
    }

    const renderBody = () => {
        if (message.communicationMethod === 'email') {
            return removeTags(message.body)?.split("***").map((line, index) => <span
                key={index}>{line}<br /></span>)
        }
        else {
            return message.body.split("\n").map((line, index) => <span
                key={index}>{line}<br /></span>)
        }
    }

    const renderMedia = () => {
        return message.mediaDetails?.map((media, index) => {
            if (media.contentType.includes("image")) {
                return <img key={index} src={media.url} alt="" width="100%" />;
            } else if (media.contentType.includes("video")) {
                return (
                    <video key={index} controls width="110%" className="mt-1">
                        <source src={media.url} type="video/mp4" />
                    </video>
                );
            } else if (media.contentType.includes("pdf")) {
                return <div key={index} className="d-flex align-items-center justify-content-center">
                    <img key={index} src={pdfThumbnail} alt="" width="50%" />
                </div>;
            } else {
                return null;
            }
        });
    };


    const getClass = () => {
        let className = 'clearfix';
        if (message.messageType === "chatUpdate") {
            className += " chat-update";
            if (message.repliedMessage) {
                className += " danger";
            }
        }
        if (message.sender === 'client') {
            className += " odd";
        }
        return className;
    }

    const renderMenu = () => (
        <div className="message-drop-down">
            <Dropdown>
                <Dropdown.Toggle variant="transparent" id="chat-item-menu-dropdown">
                    <i className="mdi mdi-dots-vertical text-secondary fs-5 cursor-pointer mt-1"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={updateChatStatusToNoResponseRequired}>
                        No response required
                    </Dropdown.Item>
                    <Dropdown.Item onClick={removeClientFromUnansweredMessages}>
                        This message is not meant for me
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );

    const updateChatStatusToNoResponseRequired = async () => {
        try {
            await updateResponseStatus(message._id, 'noResponseRequired');
            fetchUnansweredClients();
            setResponseStatus('noResponseRequired');
        }
        catch (error) {
            console.error(error);
        }
    }

    const removeClientFromUnansweredMessages = async () => {
        try {
            await removeRepFromMessage(message._id);
            fetchUnansweredClients();
        }
        catch (error) {
            console.error(error);
        }
    }

    const getStatusColorCircle = () => {
        const color = responseStatusToColor[responseStatus]
        const title = colorToText[color as keyof typeof colorToText];
        return <Tooltip title={title}><span className="status-circle" style={{ backgroundColor: color }}></span></Tooltip>
    };

    const shouldRenderMenu = message.sender === "client" && 
        responseStatusToColor[responseStatus] !== ColorCodes.green && 
        (!!message.reps.find(rep=> rep._id === currentUser._id) || currentUser.isAdmin);

    return (
        <li className={getClass()}>
            {message.messageType !== "chatUpdate" ?
                <div className="chat-avatar position-relative">
                    {message.sender === 'client' ?
                        <img src={user.imageUrl || profileImg} className="rounded" alt="" /> :
                        message.sender === 'bot' ?
                            <span className="rep">BT</span> :
                            (user as User)?.profileImage?.url ? <img src={(user as User)?.profileImage?.url} className="rep" alt="" /> : <span className="rep">{user.firstName.substring(0, 1) + user.lastName.substring(0, 1)}</span>
                    }
                    <div className={`communication-method ${message.sender === 'client' ? 'right-side-icon' : 'left-side-icon'}`}>
                        <img src={message.communicationMethod === 'email' ? emailIcon : whatsappIcon}
                            alt={message.communicationMethod} />
                    </div>
                </div> : <></>
            }

            <div className="conversation-text position-relative">
                <div className="ctext-wrap">
                    {message.messageType !== "chatUpdate" ?
                        <div className="sender-name">{getSenderName()}</div> : null
                    }

                    {message.messageType !== "chatUpdate" ?
                        <>
                            {message.sender !== 'client' && <img className="message-status" src={getStatusImage(message)} alt={message.status} />}
                            {message.sender === 'client' && getStatusColorCircle()}
                        </> :
                        <></>
                    }

                    {message.repliedMessage ? <div className="replied-message">
                        {message.repliedMessage.body}
                    </div> : null}

                    {message.subject ? <p><b>{message.subject}</b></p> : null}

                    {message.mediaDetails && message.mediaDetails.length ? renderMedia() : null}

                    {message.body ? <p>{renderBody()}</p> : null}

                    {message.file ? <div className="attachment-container">
                        {message.file.mimeType.includes("image") ?
                            <img src={message.file.thumbUrl || message.file.link} alt="" className="img-fluid" />
                            :
                            message.file.mimeType.includes("video") ? <video controls>
                                <source src={message.file.link} type="video/mp4" />
                            </video>
                                :
                                <span className="file-placeholder">
                                    {getFileType(message.file)}
                                </span>
                        }
                    </div> : null}

                    <p><i>{formatDate(message.createdAt, false, false)}</i></p>

                </div>
                <div className="chat-buttons">
                    {message.buttons?.map((button, index) =>
                        button.action ? (
                            <a key={index}
                                href={button.action}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="btn">
                                {button.text}</a>) : (
                            <button key={index} className="btn">{button.text}</button>)
                    )}
                </div>

                {shouldRenderMenu &&
                    <div className="message-action-menu">
                        {renderMenu()}
                    </div>}
            </div>

        </li>
    );
};

export default MessageItem;
