import { useEffect } from 'react';
import { io, Socket } from 'socket.io-client';
import { useAppContext } from '../context/AppContext';
import { SOCKET_URL } from '../utils/api/apiCore';
import { useChatAndTaskCountContext } from '../context/ChatAndTaskCountContext';
import { getUserFromCookie } from '../utils/api/apiCore';
import { useUnansweredClientsContext } from '../context/UnansweredClientsContext';

const Events = Object.freeze({
    incomingMessage: 'incomingMessage',
    connectToClientCommand: 'connectToClientCommand',
    connectRepCommand: 'connectRepCommand',
    messageNotAnswered: 'messageNotAnswered',
});

const useMessagesSocket = () => {
    const { isUserLoggedIn } = useAppContext();
    const { getChatAndTaskCounts, setLastMessageTime } = useChatAndTaskCountContext();
    const { fetchUnansweredClients } = useUnansweredClientsContext();

    useEffect(() => {
        if (isUserLoggedIn) {
            const socket: Socket = io(SOCKET_URL);

            socket.on('connect', () => {
                console.log('connected to main socket');
                socket.emit(Events.connectRepCommand, { token: getUserFromCookie().token });
            });

            socket.on('disconnect', () => {
                console.log('disconnected from main socket');
            });

            socket.on(Events.incomingMessage, async () => {
                setTimeout(() => {
                    getChatAndTaskCounts();
                    setLastMessageTime(Date.now());
                }, 1000);

                console.log('Socket event, incomingMessage:', Events.incomingMessage);
            });

            socket.on(Events.messageNotAnswered, () => {
                fetchUnansweredClients();
                console.log('Socket event, messageNotAnswered:', Events.messageNotAnswered);
            });

            return () => {
                socket.disconnect();
                socket.off('connect');
                socket.off('disconnect');
                socket.off(Events.incomingMessage);
                socket.off(Events.messageNotAnswered);
            };
        }
    }, [isUserLoggedIn, getChatAndTaskCounts]);
};

export default useMessagesSocket;