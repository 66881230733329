import React, { useContext, useState } from 'react';
import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { UserProfileContext } from '../../../context/userProfileContext';
import { camelCaseToText } from "../../../utils/camelCaseToText";
import { formatDate } from "../../../utils/formatDate";
import "./../clientProfile.scss";
import CreateSpouse from "./CreateSpouse";
import { useQuery } from "../../../hooks";
import { useTranslation } from 'react-i18next';
import { RatingLevel } from '../../../interfaces/Client';
import EditClientFamilyMemebrsButton from '../../../components/family-members-editing/EditClientFamilyMemebrsButton';
import { Link } from 'react-router-dom';
import useEmployerEdit from '../../employers/hooks/useEmployerEdit';

const maxCharsInAText = 20;
const ClientInfo = () => {

    const { t } = useTranslation("clients");
    const query = useQuery();
    const { profileContextData, onEditClient } = useContext(UserProfileContext);
    const { client, setClient } = profileContextData;
    const [expandClientData, setExpandClientData] = useState(false);
    const [showCreateSpouse, setShowCreateSpouse] = useState(false);
    const [showVipDetailsDialog, setShowVipDetailsDialog] = useState(false);
    const [isDetailsCopied, setIsDetailsCopied] = useState(false);

    const { setShowAddEmployer, setSelectedEmployer, AddEmployerModal, onCloseAddEmployerModal } = useEmployerEdit({
        onEditEmployer: (employer) => {
            setClient({ ...client, employer });
            onCloseAddEmployerModal();
        }
    });

    const handleEmployerClick = () => {
        setSelectedEmployer(client?.employer);
        setShowAddEmployer(true);
    }
    const toggleExpanded = () => {
        setExpandClientData((prev: boolean) => !prev)
    }

    const createSpouse = () => {
        setShowCreateSpouse(true);
    }

    const onClientsEmails = () => {
        window.open(`/client-emails/?clientId=${client._id}`, '_blank');
    }

    const copyClientDetailsToClipboard = async () => {
        const text = `${client.firstName} ${client.lastName} ת.ז ${client.idNumber}`;
        await navigator.clipboard.writeText(text);
        setIsDetailsCopied(true);
    }

    return (<>
        {showCreateSpouse && <CreateSpouse show={showCreateSpouse} onHide={() => setShowCreateSpouse(false)} />}
        {client ?
            <div className={`client-info-wrapper card-shadow ${client.isVIP ? 'vip-client' : ''}`}>
                <div className='client-info-header'>
                    <div className='items'>
                        <div className="info-item">
                            <div className="title">Name:</div>
                            <div className="data name">
                                {client.fullName} {" "}
                                <i className={`mdi mdi-${isDetailsCopied ? 'check' : 'content-copy'} cursor-pointer`}
                                onClick={copyClientDetailsToClipboard} />
                            </div>
                        </div>

                        <div className="info-item">
                            <div className="title">Phone Number:</div>
                            <div className="data">{client.phoneNumber}</div>
                        </div>

                        <div className="info-item">
                            <div className="title">ID:</div>
                            <div className="data">{client.idNumber}</div>
                        </div>

                        <div className="info-item">
                            <div className="title">Email:</div>
                            <div className="data">{client.email}</div>
                        </div>

                        <div className="info-item">
                            <div className="title">Target Audience:</div>
                            <div className="data">{t(client.targetAudienceStatus || t("not set"))}</div>
                        </div>

                        {client.isVIP ? (
                            <div className="info-item">
                                <div className="title">VIP Details:</div>
                                <div
                                    className="data"
                                    onClick={client.vipDetails ? () => setShowVipDetailsDialog(true) : undefined}
                                    style={{ cursor: client.vipDetails ? 'pointer' : 'default' }}
                                >
                                    {client.vipDetails
                                        ? (client.vipDetails.length > maxCharsInAText
                                            ? client.vipDetails.substring(0, maxCharsInAText) + '...'
                                            : client.vipDetails)
                                        : 'No VIP details'}
                                </div>
                                {client.vipDetails && (
                                    <Modal show={showVipDetailsDialog} onHide={() => setShowVipDetailsDialog(false)}>
                                        <Modal.Body>
                                            <p>{client.vipDetails}</p>
                                        </Modal.Body>
                                    </Modal>
                                )}
                            </div>
                        ) : <>
                            {client.employmentStatus === 'selfEmployed' ?
                                <div className="info-item">

                                    <div className="title">Employment Status:</div>
                                    <div className="data">{camelCaseToText(client?.employmentStatus)}</div>
                                </div> :
                                <div className="info-item">
                                    <div className="title">Employer:</div>
                                    {client.employer?.name ?
                                        <>
                                            <div onClick={handleEmployerClick} className="data" style={{ cursor: 'pointer' }} >
                                                {client.employer?.name} ({camelCaseToText(client?.employmentStatus)})
                                            </div>
                                            <AddEmployerModal />
                                        </>
                                        : null}
                                </div>}
                        </>}

                        {expandClientData ? <>
                            {
                                client.isVIP && <>{client.employmentStatus === 'selfEmployed' ?
                                    <div className="info-item">

                                        <div className="title">Employment Status:</div>
                                        <div className="data">{camelCaseToText(client?.employmentStatus)}</div>
                                    </div> :
                                    <div className="info-item">
                                        <div className="title">Employer:</div>
                                        {client.employer?.name ?
                                            <>
                                                <div onClick={handleEmployerClick} className="data" style={{ cursor: 'pointer' }} >
                                                    {client.employer?.name} ({camelCaseToText(client?.employmentStatus)})
                                                </div>
                                                <AddEmployerModal />
                                            </>
                                            : null}
                                    </div>}</>
                            }

                            <div className="info-item">
                                <div className="title">Client Type:</div>
                                <div className="data">{camelCaseToText(client?.clientType) || 'Unknown'}</div>
                            </div>

                            <div className="info-item">
                                <div className="title">Date Of Birth:</div>
                                {client?.dateOfBirth ?
                                    <div className="data">
                                        {formatDate(client.dateOfBirth, true, false)} (age {new Date().getFullYear() - new Date(client?.dateOfBirth).getFullYear()})
                                    </div>
                                    : null}
                            </div>

                            <div className="info-item">
                                <div className="title">Gender:</div>
                                <div className="data">{client.gender}</div>
                            </div>

                            <div className="info-item">
                                <div className="title">Address:</div>
                                {client?.address ?
                                    <div
                                        className="data"> {client.address.street} {client?.address.houseNumber}, {client?.address.city}</div>
                                    : null}
                            </div>

                            <div className="info-item">
                                <div className="title">Marital Status:</div>
                                <div className="data">{client.maritalStatus}</div>
                            </div>


                            {['married', 'commonLaw'].includes(client.maritalStatus || '') || client.spouse?.firstName ? <div className="info-item">
                                <div className="title">Spouse:</div>
                                <div className="data">{client.spouse?.firstName} {client.spouse?.lastName}</div>
                                {client.spouse?.client ?
                                    <a href={`/clients/profile?clientId=${client.spouse.client}&module=${query.get('module')}`} target='_blank' className="data">Spouse profile</a>
                                    : <Button onClick={createSpouse}>Create profile</Button>
                                }
                            </div> : null}

                            <div className="info-item">
                                <div className="title">{t("level of awarness to personal financial situation")}</div>
                                <div className="data">{t(RatingLevel[client.personalFinanceKnowledge ?? 0])}</div>
                            </div>

                            <div className="info-item">
                                <div className="title">{t("comfortability with financial decision making")}</div>
                                <div className="data">{t(RatingLevel[client.financialIndependenceLevel ?? 0])}</div>
                            </div>

                            <div className="info-item">
                                <div className="title">ID Issue Date:</div>
                                {client?.idIssueDate ?
                                    <div className="data">
                                        {formatDate(client.idIssueDate, true, false)}
                                    </div>
                                    : null}
                            </div>
                            <div className="info-item">
                                <div className="title">View & Edit Family:</div>
                                <EditClientFamilyMemebrsButton client={client} setClient={setClient} />
                            </div>

                            <div className="info-item">
                                <div className="title">Files:</div>
                                <Link to={{
                                    pathname: '/clients/files',
                                    search: `?clientId=${client._id}`
                                }
                                }>
                                    <span>View all files</span>
                                </Link>
                            </div>
                        </> : null}
                    </div>
                </div>
                <div className="info-item buttons-item">
                    <OverlayTrigger placement="top" overlay={<Tooltip id="">View client's emails</Tooltip>}>
                        <Button className="edit-button" onClick={onClientsEmails}>
                            <i className="mdi mdi-email-outline" />
                            E-Mails
                        </Button>
                    </OverlayTrigger>
                    <OverlayTrigger placement="top" overlay={<Tooltip id="">Edit client data</Tooltip>}>
                        <Button className="edit-button" onClick={onEditClient}>
                            <i className="mdi mdi-square-edit-outline" />
                            Edit
                        </Button>
                    </OverlayTrigger>
                    <OverlayTrigger placement="top" overlay={<Tooltip id="">Expand client data</Tooltip>}>
                        <div className='expand-btn revert' onClick={toggleExpanded}>
                            <i className={expandClientData ? "fe-chevron-up" : "fe-chevron-down"} />
                        </div>
                    </OverlayTrigger>

                </div>
            </div>
            : <>No client found</>
        }
    </>
    )
}

export default ClientInfo