import {useContext, useState} from 'react'
import {Tab, Tabs } from 'react-bootstrap';
import {UserProfileContext} from '../../../context/userProfileContext';
import Details from './InsuranceDetails';
import ProfileFiles from "../ProfileFiles";
import InsuranceProductsMain from './InsuranceProductsMain';

const TabsData = [
    {name: 'Details',},
    {name: 'Files',},
    {name: 'Products',}
];

const MainData = () => {

    // To prevent mounting components in tabs before displaying them:
    const [activeTab, setActiveTab] = useState(TabsData[0].name);
    // const [displayRound, setDisplayRound] = useState<any>();

    const {profileContextData} = useContext(UserProfileContext);
    const {currentRound, client} = profileContextData;

    const getTabComponent = (tabName: string) => {
        if (!client)
            return;
        switch (tabName) {
            case 'Details':
                return <Details/>

            case 'Files':
                return <ProfileFiles/>

            case 'Products':
                return <InsuranceProductsMain/>

            default:
                break;
        }
    }


    return (
        <div className='main-data-wrapper'>
            <Tabs
                defaultActiveKey="Details"
                transition={false}
                activeKey={activeTab}
                onSelect={(k: any) => setActiveTab(k)}
            >
                {
                    client && TabsData.map((tab: any, index: number) => {
                        return <Tab
                            key={index}
                            eventKey={tab.name}
                            title={tab.name}
                        >
                            {activeTab === tab.name ? getTabComponent(tab.name) : <></>}
                        </Tab>
                    })
                }
            </Tabs>

        </div>
    )
}

export default MainData;