import { TaxData } from "../../../../../interfaces/TaxData";
import DonationsForm from "./DonationsForm";
import LifeInsuranceForm from "./LifeInsuranceForm";
import PersonYearlyDataForm from "./PersonYearlyDataForm";
import TaxDeductionForm from "./TaxDeductionForm";
import { useTranslation } from 'react-i18next';

interface PerYearDataSectionProps {
    taxData: TaxData;
    indexOfPerYearData: number;
    onChange: (e: any) => void;
}

const PerYearDataSection = ({ taxData, indexOfPerYearData, onChange }: PerYearDataSectionProps) => {
    const { t } = useTranslation("taxReturn");

    return (
        <div className="per-year-data-form">
            {taxData.clientTaxData?.perYearData[indexOfPerYearData] && (
                <div className="client-spouse-data">
                    <h4>{t("Client")}</h4>
                    <PersonYearlyDataForm data={taxData.clientTaxData.perYearData[indexOfPerYearData]} prefix={`clientTaxData.perYearData[${indexOfPerYearData}]`} onChange={onChange} />
                </div>
            )}
            {taxData.spouseTaxData?.perYearData[indexOfPerYearData] && (
                <div className="client-spouse-data">
                    <h4>{t("Spouse")}</h4>
                    <PersonYearlyDataForm data={taxData.spouseTaxData.perYearData[indexOfPerYearData]} prefix={`spouseTaxData.perYearData[${indexOfPerYearData}]`} onChange={onChange} />
                </div>
            )}
            {taxData.perYearCoupleData?.[indexOfPerYearData] && (
                <div className="client-spouse-data">
                    <h4>{t("Couple")}</h4>
                    <div className="data-form">
                        {taxData.perYearCoupleData[indexOfPerYearData].donations && (
                            <DonationsForm data={taxData.perYearCoupleData[indexOfPerYearData].donations} prefix={`perYearCoupleData[${indexOfPerYearData}].donations`} onChange={onChange} />
                        )}
                        {taxData.perYearCoupleData[indexOfPerYearData].lifeInsurance && (
                            <LifeInsuranceForm data={taxData.perYearCoupleData[indexOfPerYearData].lifeInsurance} prefix={`perYearCoupleData[${indexOfPerYearData}].lifeInsurance`} onChange={onChange} />
                        )}
                        {taxData.perYearCoupleData[indexOfPerYearData].taxDeduction867Form && (
                            <TaxDeductionForm data={taxData.perYearCoupleData[indexOfPerYearData].taxDeduction867Form} prefix={`perYearCoupleData[${indexOfPerYearData}].taxDeduction867Form`} onChange={onChange} />
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default PerYearDataSection;