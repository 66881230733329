import { User } from "./User";
import { ClientFile } from "./ClientFile";
import { Client } from "./Client";
import { FutureMessage } from "./FutureMessage";

export interface Message {
    _id: string,
    repliedMessage?: Message,
    client: Client,
    date: Date,
    reps: User[],
    rep?: any,
    sender: string,
    communicationMethod: string,
    futureMessage?: FutureMessage,
    type: string,
    messageType: string
    subject: string,
    body: string,
    createdAt: Date,
    file: ClientFile | undefined,
    status: string,
    buttons: [{
        type: string,
        text: string,
        action?: string,
    }]
    responseStatus: responseStatus,
    mediaDetails?: [{
        url: string,
        contentType: string
    }]
};

export type responseStatus = "initial" | "answeredByBot" | "answeredByRep" | "anotherClientMessage" | "noResponseRequired" | "firstNotificationSent" | "secondNotificationSent" | "unknown";

export const ColorCodes = {
    yellow: "#FFA500",  
    green: "#3CB371",   
    red: "#FF4500",     
    grey: "#808080",    
}

export const responseStatusToColor = {
    initial: ColorCodes.yellow,
    answeredByBot: ColorCodes.green,
    answeredByRep: ColorCodes.green,
    anotherClientMessage: ColorCodes.green,
    noResponseRequired: ColorCodes.green,
    firstNotificationSent: ColorCodes.red,
    secondNotificationSent: ColorCodes.red,
    unknown: ColorCodes.grey,
}

export const colorToText = {
    [ColorCodes.green]: "Response Not Required",
    [ColorCodes.yellow]: "Response Required",
    [ColorCodes.red]: "Response Required Immediately",
    [ColorCodes.grey]: "Unknown",
}