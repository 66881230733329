import {addCommas} from "../utils/addCommas";
import React from "react";

const MoneyColumn = (params: any) => {
    const val = Number(params.cell.value);

    return (
        <div className='d-flex justify-content-center'>
            {!val?'-':''}
            {val && val >= 0?`₪${addCommas(val)}`:''}
            {val && val < 0?`-₪${addCommas(-val)}`:''}
        </div>
    );
};

export default MoneyColumn;