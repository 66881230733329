import { useContext, useEffect, useState } from 'react'
import InsuranceProductsMenuHeader from './InsuranceProductsMenuHeader';
import CreateNewInsuranceProductModal from './insuranceCreationAndEdit/CreateNewInsuranceProductModal';
import { InsurancePolicy, InsurancePolicyMainBranch, InsurancePolicyPlanTypes } from '../../../interfaces/InsurancePolicy';
import { InsuranceAppendix, AppendixCombinedWithPolicy, shrinkCombinedObjectToAppendix } from '../../../interfaces/InsuranceAppendix';
import { appendicesCombinedWithPolicesColumns } from './insuranceProductColumns';
import { getInsurancePolicies } from '../../../utils/api/insurancePolicy';
import { getInsuranceAppendices } from '../../../utils/api/insuranceAppendix';
import { UserProfileContext } from '../../../context/userProfileContext';
import { getValueByField } from '../../../utils/nestedFieldsLogic';
import { createNewAppendix, createNewPolicy, editExistingAppendix } from './insuranceCreationAndEdit/appendixAndPolicyCreationFunctions';
import { ProductsContext } from './insuranceProductsContext';
import AppendixCreationModal, { CreateOrEditAppendixModal } from './insuranceCreationAndEdit/AppendixCreationAndEditingModal';
import { initialInsuranceAppendixState } from './insuranceCreationAndEdit/initialPolicyAndAppendixState';
import { getClientFamilyMembers } from '../../../utils/getClientFamilyMembers';
import Table from '../../../components/Table';
import InsuranceTotalDataInfoHeader from './InsuranceTotalDataInfoHeader';

const InsuranceProducts = () => {
    const [sortBy, setSortBy] = useState<string>("");
    const { profileContextData } = useContext(UserProfileContext);
    const { client } = profileContextData;

    const [showNewProductCreationModal, setShowNewProductCreationModal] = useState(false);
    const [showNewAppendixCreationModal, setShowNewAppendixCreationModal] = useState(false);
    const [showEditAppendixModal, setShowEditAppendixModal] = useState(false);

    const [appendixToEdit, setAppendixToEdit] = useState<AppendixCombinedWithPolicy | null>(null);

    const [filterByPolicyNumbers, setFilterByPolicyNumbers] = useState<string[]>([]);
    const [filterByInsuredPerson, setFilterByInsuredPerson] = useState<string>('');

    const [insurancePolicies, setInsurancePolicies] = useState<InsurancePolicy[]>([]);
    const [insuranceAppendices, setInsuranceAppendices] = useState<InsuranceAppendix[]>([]);
    const [appendicesCombinedWithPolicies, setAppendicesCombinedWithPolicies] = useState<(AppendixCombinedWithPolicy)[]>([]);

    const [filteredAppendicesCombinedWithPolicies, setFilteredAppendicesCombinedWithPolicies] = useState<(AppendixCombinedWithPolicy)[]>([...appendicesCombinedWithPolicies]);


    // Data creation
    const fullProductCreationHandler = async (newInsurancePolicy: InsurancePolicy, newInsuranceAppendices: InsuranceAppendix[]) => {
        try {
            const newPolicy = await createNewPolicy(newInsurancePolicy);
            setInsurancePolicies([...insurancePolicies, newPolicy]);
            const appendicesWithPolicies = await Promise.all(newInsuranceAppendices.map(async (appendix) => {
                try {
                    const appendixWithPolicyId = { ...appendix, policy: newPolicy._id, policyId: newPolicy._id };
                    const newAppendix = await createNewAppendix(appendixWithPolicyId);
                    return newAppendix;
                } catch (error) {
                    console.error('Error creating appendix:', error);
                    alert('An error occurred while creating an appendix. Please check the console for more details.');
                }
            }));
            setInsuranceAppendices([...insuranceAppendices, ...appendicesWithPolicies]);
        } catch (error) {
            console.error('Error creating policy:', error);
            alert('An error occurred while creating a policy. Please check the console for more details.');
        }
    }

    const appendixCreationHandler = async (newInsuranceAppendix: InsuranceAppendix) => {
        try {
            const newAppendix = await createNewAppendix(newInsuranceAppendix);
            setInsuranceAppendices([...insuranceAppendices, newAppendix]);
            return newAppendix;
        } catch (error) {
            console.error('Error creating appendix:', error);
            alert('An error occurred while creating an appendix. Please check the console for more details.');
        }
    }

    // Data fetching
    useEffect(() => { // Get insurance policies
        const params = { clientId: client._id }
        const getClientsPolicies = async () => {
            try {
                const response = await getInsurancePolicies(params);
                const policies = response.policies;
                policies.forEach((policy: InsurancePolicy) => {
                    policy.clientId = policy.client ?? client._id;
                });
                setInsurancePolicies(policies);
            }
            catch (error) {
                console.error('Error fetching policies:', error);
            }
        }
        getClientsPolicies();
    }, [])

    useEffect(() => { // Get insruance appendices
        const params = { clientId: client._id }
        const getClientsAppendices = async () => {
            try {
                const response = await getInsuranceAppendices(params);
                const appendices = response.appendices;
                appendices.forEach((appendix: InsuranceAppendix) => {
                    appendix.clientId = appendix.client ?? client._id;
                });
                setInsuranceAppendices(appendices);
            }
            catch (error) {
                console.error('Error fetching apeendicies:', error);
            }
        }
        getClientsAppendices();
    }, [])

    // Data creation
    const combineAppendixWithPolicy = (appendix: InsuranceAppendix, policy: InsurancePolicy) => {
        const { branch, _id, ...combined } = { ...appendix, ...policy, mainBranch: policy.branch, secondaryBranch: appendix.branch, appendixId: appendix._id, policyId: policy._id };
        return { ...combined };
    }

    const combineAppendicesWithPolicies = () => {
        const combinedData = insuranceAppendices
            .map((appendix) => {
                const policy = insurancePolicies.find((policy) => policy._id === appendix.policy);
                if (policy) {
                    return combineAppendixWithPolicy(appendix, policy);
                }
                return null;
            })
            .filter((data): data is AppendixCombinedWithPolicy => data !== null);
        const sortedByReplacementCombinedData = sortAppendicesByReplacement(combinedData);
        setAppendicesCombinedWithPolicies(sortedByReplacementCombinedData);
    }

    useEffect(() => {
        combineAppendicesWithPolicies()
    }, [insurancePolicies, insuranceAppendices])

    // Data sorting
    const sortAppendicesByReplacement = (appendices: AppendixCombinedWithPolicy[]) => {
        const originalAppendices: AppendixCombinedWithPolicy[] = []
        const recommendedAppendices: AppendixCombinedWithPolicy[] = []

        appendices.forEach((appendix) => {
            if (appendix.replaceAppendix) {
                recommendedAppendices.push(appendix)
            } else {
                originalAppendices.push(appendix)
            }
        })

        const sortedAppendices: AppendixCombinedWithPolicy[] = []
        originalAppendices.forEach((appendix) => {
            sortedAppendices.push(appendix)
            recommendedAppendices.forEach((recommendedAppendix) => {
                if (appendix.appendixId === recommendedAppendix.replaceAppendix) {
                    sortedAppendices.push(recommendedAppendix)
                }
            })
        })
        return sortedAppendices
    }

    // Data filtering
    useEffect(() => {
        const filteredByPolicyNumber = filterByPolicyNumbers ? appendicesCombinedWithPolicies.filter((appendix) => filterByPolicyNumbers.includes(appendix.policyId)) : appendicesCombinedWithPolicies;
        const filteredByInsuredPerson = filterByInsuredPerson ? filteredByPolicyNumber.filter((appendix) => appendix.clientIdNumber === filterByInsuredPerson) : filteredByPolicyNumber;
        // const filteredByInsuredPerson = filterByInsuredPerson ? filteredByPolicyNumber.filter((appendix) => appendix.insuredList.some((insured) => insured.idNumber === filterByInsuredPerson)) : filteredByPolicyNumber;
        setFilteredAppendicesCombinedWithPolicies(filteredByInsuredPerson);
    }, [filterByPolicyNumbers, filterByInsuredPerson, appendicesCombinedWithPolicies])

    // Data sorting
    const handleSortChanged = (newSortBy: string) => {
        setSortBy(newSortBy);
    };

    const handleSortData = () => {
        const isDescending = sortBy.startsWith("-");

        const fieldName = isDescending ? sortBy.substring(1) : sortBy;

        const sortedData = [...filteredAppendicesCombinedWithPolicies].sort((a, b) => {
            let valueA = getValueByField(a, fieldName);
            let valueB = getValueByField(b, fieldName);

            if (!isNaN(valueA) && !isNaN(valueB)) {
                valueA = Number(valueA);
                valueB = Number(valueB);
            }

            if (valueA === valueB) return 0;
            if (isDescending) {
                return valueA > valueB ? -1 : 1; // Descending
            } else {
                return valueA < valueB ? -1 : 1; // Ascending
            }
        });

        const secondSortByReplacment = sortAppendicesByReplacement(sortedData);
        setFilteredAppendicesCombinedWithPolicies(secondSortByReplacment);
    }

    useEffect(() => {
        if (sortBy) {
            handleSortData();
        }
    }, [sortBy])

    // Data editing
    const handleRowClicked = (appendix: AppendixCombinedWithPolicy) => {
        console.log(appendix)
        if (!appendix.isNew) {
            return;
        }
        setAppendixToEdit(appendix);
    }

    useEffect(() => {
        if (appendixToEdit) {
            setShowEditAppendixModal(true);
        }
    }, [appendixToEdit])

    useEffect(() => {
        if (!showEditAppendixModal) {
            setAppendixToEdit(null);
        }
    }, [showEditAppendixModal])

    const handleEditAppendix = async (modifiedStateOfAppendix: InsuranceAppendix) => {
        try {
            const editedInsuranceAppendix = await editExistingAppendix(modifiedStateOfAppendix)
            setInsuranceAppendices(insuranceAppendices.map((insuranceAppendix) => insuranceAppendix._id === editedInsuranceAppendix._id ? editedInsuranceAppendix : insuranceAppendix));
            return editedInsuranceAppendix;
        }
        catch (error) {
            console.log(error);
        }
        finally {
            handleHideEditAppendixModal();
        }
    }

    const handleHideEditAppendixModal = () => {
        setShowEditAppendixModal(false);
        setAppendixToEdit(null);
    }

    // Table styling
    const rowStyle = (row: any, rowIndex: number) => {
        const appendix = row.original;

        const styles: any = {
            borderLeft: '2px solid grey',
            borderRight: '2px solid grey',
        };

        if (appendix.isNew) {
            styles.cursor = 'pointer'
        }

        if (rowIndex === filteredAppendicesCombinedWithPolicies.length - 1) {
            styles.borderBottom = '2px solid grey';
            styles.borderTop = '2px solid grey';
        }

        if (isAppendixReplacement(appendix)) {
            styles.backgroundColor = 'rgb(198, 236, 227)';
            styles.borderBottom = '2px solid grey';
        }

        if (!isAppendixReplacement(appendix)) {
            styles.borderTop = '2px solid grey';
        }

        if (appendix.isNew) {
            styles.backgroundColor = 'rgb(198, 236, 227)'
        }

        return styles;
    };


    const [indexMapping, setIndexMapping] = useState<{ [key: string]: string }>({});

    useEffect(() => {
        const mapping: { [key: string]: string } = {};
        let lastPureIndex = 0;
        let lastRowWasReplacement = false;
        filteredAppendicesCombinedWithPolicies.forEach((appendix, index) => {
            if (isAppendixReplacement(appendix)) {
                if (lastRowWasReplacement) {
                    if (lastPureIndex === 0) {
                        lastPureIndex++;
                    }
                    mapping[index.toString()] = lastPureIndex.toString();
                } else {
                    if (lastPureIndex === 0) {
                        lastPureIndex++;
                    }
                    mapping[index.toString()] = lastPureIndex.toString() + '\u2191';
                }
                lastRowWasReplacement = true;
            } else {
                lastPureIndex++;
                mapping[index.toString()] = lastPureIndex.toString();
                lastRowWasReplacement = false;
            }
        });

        setIndexMapping(mapping);
    }, [filteredAppendicesCombinedWithPolicies]);


    const rowNumbering = (row: any) => {
        return indexMapping[row.index];
    }

    // utils 
    const isAppendixReplacement = (appendix: AppendixCombinedWithPolicy) => {
        return appendix.replaceAppendix || appendix.replaceAppendixId;
    }

    useEffect(() => {
        setFilterByPolicyNumbers(insurancePolicies.map((policy) => policy._id));
    }, [insurancePolicies])

    return (
        <ProductsContext.Provider value={{
            insurancePolicies,
            setInsurancePolicies,
            insuranceAppendices,
            setInsuranceAppendices,
            appendicesCombinedWithPolicies,
            setAppendicesCombinedWithPolicies,
            combineAppendixWithPolicy,
            client
        }}
        >
            <div className="overflow-x-hidden">
                <InsuranceProductsMenuHeader
                    setShowNewProductCreationModal={setShowNewProductCreationModal}
                    setShowNewAppendixCreationModal={setShowNewAppendixCreationModal}
                    insurancePolicies={insurancePolicies}
                    filterByPolicyNumbers={filterByPolicyNumbers}
                    setFilterByPolicyNumbers={setFilterByPolicyNumbers}
                    insuredPersons={getClientFamilyMembers(client, true).map((member) => ({ name: member.firstName + ' ' + member.lastName, idNumber: member.idNumber }))}
                    filterByInsuredPerson={filterByInsuredPerson}
                    setFilterByInsuredPerson={setFilterByInsuredPerson}
                    clientId={client._id}
                    clientFullName={client.firstName + ' ' + client.lastName}
                />
                <InsuranceTotalDataInfoHeader
                    totalElementaryPolicies={insurancePolicies.filter((policy) => policy.branch === InsurancePolicyMainBranch.elementaryInsurance).length}
                    totalIndividualPolicies={insurancePolicies.filter((policy) => policy.planType === InsurancePolicyPlanTypes.individual).length}
                />
                <CreateNewInsuranceProductModal
                    show={showNewProductCreationModal}
                    onHide={() => setShowNewProductCreationModal(false)}
                    productCreationHandler={fullProductCreationHandler}
                />
                <AppendixCreationModal
                    show={showNewAppendixCreationModal}
                    onHide={() => setShowNewAppendixCreationModal(false)}
                    onSubmit={appendixCreationHandler}
                    currentAppendixState={initialInsuranceAppendixState}
                    isAppendixEditingPhase={false}
                />

                {insurancePolicies.length > 0 &&
                    <Table
                        columns={appendicesCombinedWithPolicesColumns}
                        data={filteredAppendicesCombinedWithPolicies}
                        rowNumbering={rowNumbering}
                        isSortable={true}
                        pagination={false}
                        onRowClicked={(row) => handleRowClicked(row.original)}
                        onSortChanged={handleSortChanged}
                        tableClass="table-nowrap products-table"
                        rowStyle={rowStyle}
                    />}

                {appendixToEdit && showEditAppendixModal &&
                    <CreateOrEditAppendixModal
                        show={showEditAppendixModal}
                        onHide={() => setShowEditAppendixModal(false)}
                        onSubmit={handleEditAppendix}
                        currentAppendixState={shrinkCombinedObjectToAppendix(appendixToEdit)}
                        isAppendixEditingPhase={true}
                    />
                }

            </div>
        </ProductsContext.Provider>


    )
}

export default InsuranceProducts

