import React, { useContext, useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import SendIcon from '@mui/icons-material/Send';
import { Button as MuiButton } from '@mui/material';
import UploadLinkModal from "./UploadLinkModal";
import { formatDate } from "../../utils/formatDate";
import { removeTags } from "../../utils/removeTags";
import { camelCaseToText } from "../../utils/camelCaseToText";
import { createUploadLink } from "../../utils/api/files";
import './ProfileBox.scss';
import { Client } from '../../interfaces/Client';
import InputWrapper from "../../components/inputWrapper/InputWrapper";
import { User } from "../../interfaces/User";
import { UserProfileContext } from "../../context/userProfileContext";
import { Onboarding } from "../../interfaces/Onboarding";
import { getSignatures } from "../../utils/api/signatures";
import { SignedDocument } from "../../interfaces/SignedDocument";
import AddEmployer from "../employers/AddEmployer";
import EmployerAutocomplete from "../../components/EmployerAutocomplete";
import { Employer } from "../../interfaces/Employer";
import { editClient } from "../../utils/api/clients";
import { useTranslation } from "react-i18next";
import { isPOAValid } from '../../utils/api/pension';

interface ProfileBoxProps {
    isOnboarding: boolean,
    client?: Client,
}

const ProfileBox = (props: ProfileBoxProps) => {
    const { t } = useTranslation("profile")

    const [showUploadLink, setShowUploadLink] = useState(false);
    const [newEmployerName, setNewEmployerName] = useState('');
    const [signedDocument, setSignedDocument] = useState<SignedDocument | undefined>();
    const [linkInfo, setLinkInfo] = useState<{ link?: string; text?: string; outOfDate?: boolean }>({});
    const { profileContextData, onEditRound, users } = useContext(UserProfileContext);
    const [client, setClient] = useState<Client>(props.client || profileContextData.client);
    const selectedModule = profileContextData.selectedModule;
    const allowedStatusesOptions = selectedModule && selectedModule.allowedStatuses ? Object.values(selectedModule.allowedStatuses) : [];
    const selectedModuleName = selectedModule?.data?.moduleName;
    const [onboarding, setOnboarding] = useState<Onboarding>(profileContextData.currentRound as Onboarding);
    // const onboarding = profileContextData?.currentRound as Onboarding;
    const [showEmployerAutocomplete, setShowEmployerAutocomplete] = useState(false);

    const [interviewers, setInterviewers] = useState<User[]>(users.filter((rep: User) => rep.roles.includes('interviewer') || (rep && rep._id === onboarding?.rep)));

    const [showAddEmployer, setShowAddEmployer] = useState(false);
    const [userInputTextReasonOther, setUserInputTextReasonOther] = useState('')

    useEffect(() => {
        if (client) {
            setOnboarding(profileContextData.currentRound as Onboarding);
        }
    }, [profileContextData.currentRound]);


    useEffect(() => {
        if (props.isOnboarding)
            setInterviewers(users.filter((rep: User) => rep.roles.includes("interviewer") || rep._id === onboarding?.rep));
    }, [users, props.client, profileContextData.client]);


    const getPOASignature = async () => {
        try {
            const { signedDocuments } = await getSignatures({ clientId: client._id, documentType: 'powerOfAttorney' }); //TODO: add here a flag for witch module we need the reps list.
            setSignedDocument(signedDocuments[0]);
        } catch (err) {
            alert("Can't get POA signature");
        }
    }

    const getSignatureLinkInfo = async () => {
        if (!signedDocument)
            return {};
        const { success: isValid } = await isPOAValid(client._id);
        let linkInfo: { link?: string; text?: string; outOfDate?: boolean } = {};
        if (signedDocument.file) {
            linkInfo = { link: signedDocument.file.link, text: 'Download signed document' };
        } else if (signedDocument.agentSigningLink) {
            linkInfo = { link: signedDocument.agentSigningLink, text: 'Agent signing link' };
        } else if (signedDocument.clientSigningLink) {
            linkInfo = { link: signedDocument.clientSigningLink, text: 'Client signing link' };
        }
        else {
            return {};
        }
        if (!isValid) {
            linkInfo.outOfDate = true;
        }
        setLinkInfo(linkInfo);
    }

    useEffect(() => {
        if (props.isOnboarding)
            getPOASignature();
    }, []);

    useEffect(() => {
        if (props.isOnboarding)
            getSignatureLinkInfo();
    }, [signedDocument]);

    useEffect(() => {
        setClient(props.client || profileContextData.client);
    }, [props.client]);

    const getPhoneNumber = (client?: Client) => {
        const phoneNumber: string = client?.phoneNumber?.replace("+972", "0") || '';
        return `${phoneNumber.substring(0, 3)}-${phoneNumber.substring(3)}`;
    }


    if (!onboarding && props.isOnboarding)
        return <></>;

    const handleNewEmployer = (name: string) => {
        setNewEmployerName(name);
        setShowEmployerAutocomplete(false);
        setShowAddEmployer(true);
    }

    const onExistingEmployerSelected = async (employer?: Employer) => {
        setShowEmployerAutocomplete(false);
        if (!employer)
            return;
        try {
            await editClient(client._id, { employer: employer._id });
            setClient({ ...client, employer });
        } catch (err) {
            console.log(err);
            alert('Something went wrong...');
        } finally {
            setShowAddEmployer(false);
        }
    }

    const onStatusChanged = (status: string) => {
        if ((status === 'postZoom' || status === 'postZoomMissingCriticalFiles') && client.employmentStatus !== 'selfEmployed' && !client.employer) {
            alert("Please select an employer first");
            return;
        }
        const prevStatus = onboarding.status;
        setOnboarding({ ...onboarding, status })
        onEditRound({ status }, selectedModuleName, onboarding._id, undefined, () => setOnboarding({ ...onboarding, status: prevStatus }));
    }

    const onInterviewerChanged = (userId: string) => {
        const prevRep = onboarding.rep;
        setOnboarding({ ...onboarding, rep: userId });
        onEditRound({ rep: userId }, selectedModuleName, onboarding._id, undefined, () => setOnboarding({ ...onboarding, rep: prevRep }));
    }
    const lastChangeDateString = onboarding?.lastStatusChangeDate ? `Status (Last change: ${formatDate(new Date(onboarding.lastStatusChangeDate))})` : 'Status';

    return (
        <Card className="text-center user-profile-box">
            <Card.Body>
                <div className="box">
                    {
                        !props.isOnboarding ?
                            <div className="buttons-row">
                                <a href={`/clients/profile?clientId=${client?._id}`} target="_blank"
                                    rel="noopener noreferrer">
                                    <Button variant='success'>
                                        Open Client Profile Page
                                        <i className="fe-user-check" />
                                    </Button>
                                </a>
                            </div>
                            : <></>
                    }

                    {client ?
                        <div className="text-start mt-3">

                            {
                                !props.isOnboarding ?
                                    <>
                                        <p className="mb-2 font-13">
                                            <strong>Client Name:</strong>
                                            <span className="ms-2">{client?.firstName} {client?.lastName}</span>
                                        </p>

                                        <p className="mb-2 font-13">
                                            <strong>Module Status:</strong>
                                            <span className="ms-2">{camelCaseToText(client.pensionStatus)}</span>
                                        </p>

                                        <p className="mb-2 font-13">
                                            <strong>ID:</strong>
                                            <span className="ms-2">{client.idNumber}</span>
                                        </p>

                                        <p className="mb-2 font-13">
                                            <strong>Mobile:</strong>
                                            <span className="ms-2">{getPhoneNumber(client)}</span>
                                        </p>

                                        <p className="mb-2 font-13">
                                            <strong>Email:</strong>
                                            <span className="ms-2">{client.email}</span>
                                        </p>

                                        <p className="mb-2 font-13">
                                            <strong>Employer:</strong>
                                            <span className="ms-2">{client?.employer?.name}</span>
                                        </p>

                                    </>
                                    : <></>
                            }


                            {
                                props.isOnboarding ?
                                    <>
                                        <div className='select-area'>
                                            {client ?
                                                <>
                                                    {allowedStatusesOptions && allowedStatusesOptions.length > 0 ?
                                                        <InputWrapper status={{ text: '' }} label={lastChangeDateString}
                                                            wrapperClass='input-field select-field'>
                                                            <select
                                                                value={onboarding.status}
                                                                className='form-select'
                                                                name="pensionStatus"
                                                                onChange={(e: any) => onStatusChanged(e.target.value)}
                                                            >
                                                                <option disabled value={'initial'}> Status</option>
                                                                {allowedStatusesOptions.map((option: any, index: number) => (
                                                                    <option key={index} value={option}>
                                                                        {option}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </InputWrapper>
                                                        : null}

                                                    {interviewers ?
                                                        <InputWrapper status={{ text: '' }} label={'Interviewer'}
                                                            wrapperClass='input-field select-field'>
                                                            <select
                                                                value={onboarding.rep}
                                                                className='form-select'
                                                                name="status"
                                                                onChange={(e: any) => onInterviewerChanged(e.target.value)}
                                                            >
                                                                <option disabled value={''}> Change interviewer</option>

                                                                {interviewers.map((interviewer: any, index: number) => (
                                                                    <option key={index} value={interviewer._id}>
                                                                        {interviewer.fullName}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </InputWrapper> : null}

                                                </>
                                                : <div>No data yet...</div>}
                                        </div>


                                        <p className="mb-2 font-13">
                                            <strong>Payslip Password:</strong>
                                            <span
                                                className="ms-2">{client?.payslipPasswords.join(", ") || 'no password'}</span>
                                        </p>
                                        {/* //TODO: add this also to 'files' tab  */}
                                        <p className="mb-2 font-13" onClick={(label) => setShowUploadLink(true)}>
                                            <a href="#"><strong>Upload files link</strong><i
                                                className="ms-2 mdi mdi-link-variant" /></a>
                                        </p>

                                        {onboarding.zoomMeeting ?
                                            <p className="mb-2 font-13">
                                                <strong>Onboarding
                                                    Zoom:</strong> {formatDate(onboarding.zoomMeeting.startTime, false, false)}
                                                {"   "} <a href={onboarding.zoomMeeting.zoomLink} target="_blank"
                                                    rel="noreferrer"><strong>Click to connect<i
                                                        className="ms-2 mdi mdi-video" /></strong></a>
                                                <a href={onboarding.zoomMeeting.cancelUrl} target="_blank"
                                                    className="cancel-zoom" rel="noreferrer">Cancel Zoom</a>
                                            </p> : null}

                                        <p className="mb-2 font-13">
                                            <strong>Client Source:</strong>
                                            <span
                                                className="ms-2">{client?.source.sourceType} {client?.source ? `(${client.source.campaign || client.source.referredBy?.employer?.name || ''})` : ''}</span>
                                        </p>


                                        {client?.onboardingRemarks ?
                                            <p className="mb-2 font-13">
                                                <strong>Onboarding Remarks:</strong>
                                                <br />
                                                <span className="ms-2">{removeTags(client.onboardingRemarks)}</span>
                                            </p> : null}

                                        {signedDocument ?
                                            <p className="mb-2 font-13">
                                                <strong>Power of attorney:</strong>
                                                <strong><a href={linkInfo.link} target="_blank"
                                                    className="ms-2">{linkInfo.text}</a></strong>
                                                {
                                                    linkInfo.outOfDate &&
                                                    <span className="text-danger"> (out of date)</span>
                                                }
                                            </p> : null}

                                        <p className="mb-2 font-13">
                                            <div className="mb-2">

                                                <strong>Employer:</strong>
                                                {client.employmentStatus === 'selfEmployed' ?
                                                    <span className="ms-2">Self Employed</span>
                                                    :
                                                    <span className="ms-2">{client?.employer?.name}
                                                        {!!client?.employer && <span className="edit"
                                                            onClick={() => setShowEmployerAutocomplete(!showEmployerAutocomplete)}><i
                                                                className="mdi mdi-square-edit-outline" /> {showEmployerAutocomplete ? 'Close' : 'Edit'}</span>
                                                        }
                                                    </span>
                                                }
                                            </div>
                                            {(showEmployerAutocomplete || !client?.employer) && <EmployerAutocomplete
                                                value={client?.employer?.name}
                                                handleFreeSearch={handleNewEmployer}
                                                onAutocompleteSelect={onExistingEmployerSelected}
                                            />}
                                        </p>

                                        <UploadLinkModal
                                            show={showUploadLink}
                                            onHide={() => setShowUploadLink(false)}
                                            title="Public Upload Link"
                                            getLink={() => createUploadLink({ clientId: client._id })}
                                        />
                                    </>
                                    : // Not onboarding:
                                    <>
                                        <p className="mb-2 font-13">
                                            <strong>Client Type:</strong>
                                            <span
                                                className="ms-2">{camelCaseToText(client.clientType) || '-'}</span>
                                        </p>

                                        <p className="mb-2 font-13">
                                            <strong>Date Of Birth:</strong>
                                            {client?.dateOfBirth ?
                                                <span className="ms-2">
                                                    {formatDate(client?.dateOfBirth, true, false)} (age {new Date().getFullYear() - new Date(client.dateOfBirth).getFullYear()})
                                                </span>
                                                : null}
                                        </p>

                                        <p className="mb-2 font-13">
                                            <strong>Gender:</strong>
                                            <span className="ms-2">{client?.gender}</span>
                                        </p>

                                        <p className="mb-2 font-13">
                                            <strong>Marital Status:</strong>
                                            <span className="ms-2">{client?.maritalStatus}</span>
                                        </p>

                                        <p className="mb-2 font-13">
                                            <strong>Address:</strong>
                                            {client.address?.city ?
                                                <span className="ms-2">
                                                    {client.address.street} {client.address.houseNumber}, {client.address.city}
                                                </span>
                                                : null}
                                        </p>

                                        <p className="mb-2 font-13">
                                            <strong>Employment Status:</strong>
                                            <span
                                                className="ms-2">{camelCaseToText(client?.employmentStatus)}</span>
                                        </p>


                                    </>
                            }

                        </div> : null}

                    {/* add employer modal */
                        showAddEmployer &&
                        <AddEmployer
                            show={showAddEmployer}
                            onHide={() => setShowAddEmployer(false)}
                            onAddEmployer={onExistingEmployerSelected}
                            newEmployerName={newEmployerName}
                        />
                    }

                </div>
            </Card.Body>
        </Card>

    )


};

export default ProfileBox;
