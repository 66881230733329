import { APICore, API_URL } from './apiCore';
import { FileType } from "../../interfaces/FileType";

const api = new APICore();

type CompanyType = "insurance" | "pension" | "bank" | "credit";
const getCompanies = (params: { page?: number, search?: string, isDeal?: string, type?: CompanyType }) => {
    const relativeUrl = '/companies';
    return api.get(`${API_URL}${relativeUrl}`, params);
}


const createCompany = (params: any, file?: FileType) => {
    const relativeUrl = '/companies';
    if (file)
        params.file = file;
    return api.createWithFile(`${API_URL}${relativeUrl}`, params);
}

const editCompany = (id: string, params: any, file?: FileType) => {
    const relativeUrl = '/companies/' + id;
    if (file)
        params.file = file;

    return api.updateWithFile(`${API_URL}${relativeUrl}`, params);
}

const deleteCompany = (id: string) => {
    const relativeUrl = '/companies/' + id;
    return api.delete(`${API_URL}${relativeUrl}`);
}


export {
    editCompany,
    deleteCompany,
    getCompanies,
    createCompany
}