import React, { useState } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { ClientsChild, ClientsSpouse } from '../../interfaces/Client';
import { formatDate } from '../../utils/formatDate';

type FamilyMembersInputFields = {
    show: boolean;
    onHide: () => void;
    children: ClientsChild[];
    spouse: ClientsSpouse;
    onSubmit: (children: ClientsChild[], spouse: ClientsSpouse) => void;
};

const FamilyMembersInputFields = ({ show, onHide, children, spouse, onSubmit }: FamilyMembersInputFields) => {
    const [childrenInputFields, setChildrenInputFields] = useState<ClientsChild[]>(children);
    const [spouseInputFields, setSpouseInputFields] = useState<ClientsSpouse>(spouse);

    const handleChildFormChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const { name, value } = e.target;
        setChildrenInputFields((prevState) => {
            const updatedChildren = [...prevState];
            updatedChildren[index] = {
                ...updatedChildren[index],
                [name]: value,
            };
            return updatedChildren;
        });
    };

    const handleSpouseFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setSpouseInputFields((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleDeleteChild = (index: number) => {
        const updatedChildren = [...childrenInputFields];
        updatedChildren.splice(index, 1);
        setChildrenInputFields(updatedChildren);
    };

    const handleDeleteSpouse = () => {
        setSpouseInputFields({ type: undefined, firstName: '', lastName: '', idNumber: '', dateOfBirth: undefined, phoneNumber: '' });
    };

    const addChild = () => {
        setChildrenInputFields([...childrenInputFields, { type: 'child', firstName: '', lastName: '', hasSpecialNeeds: false, idNumber: '', dateOfBirth: null as any }]);
    };

    const addSpouse = () => {
        if (!spouseInputFields.type) {
            setSpouseInputFields({ type: 'spouse', firstName: '', lastName: '', idNumber: '', dateOfBirth: undefined, phoneNumber: '' });
        }
    };

    return (
        <Modal show={show} onHide={onHide} centered size='lg'>
            <Modal.Header className='bg-light' closeButton>
                <Modal.Title>Create & Edit Family Members</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <h5 className='mb-3'>Children</h5>
                    {childrenInputFields.map((child, index) => (
                        <Row key={index}>
                            <Row>
                                <Col>
                                    <Form.Group controlId={`childFirstName${index}`}>
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="firstName"
                                            value={child.firstName}
                                            onChange={(e: any) => handleChildFormChange(e, index)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId={`childLastName${index}`}>
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="lastName"
                                            value={child.lastName}
                                            onChange={(e: any) => handleChildFormChange(e, index)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId={`childIdNumber${index}`}>
                                        <Form.Label>ID Number</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="idNumber"
                                            value={child.idNumber}
                                            onChange={(e: any) => handleChildFormChange(e, index)}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group controlId={`childDateOfBirth${index}`}>
                                        <Form.Label>Date Of Birth</Form.Label>
                                        <Form.Control
                                            type="date"
                                            name="dateOfBirth"
                                            value={formatDate(child.dateOfBirth, true, false, "en-CA")}
                                            onChange={(e: any) => handleChildFormChange(e, index)}
                                        />
                                    </Form.Group>
                                </Col>
                                {child.dateOfBirth && (new Date().getFullYear() - new Date(child.dateOfBirth).getFullYear()) >= 18 && (
                                    <>
                                        <Col>
                                            <Form.Group controlId={`childIdIssueDate${index}`}>
                                                <Form.Label>ID Issue Date</Form.Label>
                                                <Form.Control
                                                    type="date"
                                                    name="idIssueDate"
                                                    value={formatDate(child.idIssueDate, true, false, "en-CA")}
                                                    onChange={(e: any) => handleChildFormChange(e, index)}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId={`childPhoneNumber${index}`}>
                                                <Form.Label>Phone Number</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="phoneNumber"
                                                    value={child.phoneNumber}
                                                    onChange={(e: any) => handleChildFormChange(e, index)}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </>
                                )}
                            </Row>
                            <Row>
                                <Col>
                                    <Button
                                        variant="danger"
                                        className='mt-3 mb-4'
                                        onClick={() => handleDeleteChild(index)}
                                    >
                                        Delete Child
                                    </Button>
                                </Col>
                            </Row>
                        </Row>
                    ))}
                    <div className="d-flex justify-content-end">
                        <Button className='mb-3' variant="primary" onClick={addChild}>
                            Add Child
                        </Button>
                    </div>

                    <hr />

                    <h5 className='mb-3'>Spouse</h5>
                    {spouseInputFields.type && (
                        <>
                            <Row>
                                <Col>
                                    <Form.Group controlId="spouseFirstName">
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="firstName"
                                            value={spouseInputFields.firstName}
                                            onChange={handleSpouseFormChange}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="spouseLastName">
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="lastName"
                                            value={spouseInputFields.lastName}
                                            onChange={handleSpouseFormChange}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="spouseIdNumber">
                                        <Form.Label>ID Number</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="idNumber"
                                            value={spouseInputFields.idNumber}
                                            onChange={handleSpouseFormChange}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group controlId="spouseDateOfBirth">
                                        <Form.Label>Date of Birth</Form.Label>
                                        <Form.Control
                                            type="date"
                                            name="dateOfBirth"
                                            value={formatDate(spouseInputFields.dateOfBirth, true, false, "en-CA")}
                                            onChange={handleSpouseFormChange}
                                        />
                                    </Form.Group>
                                </Col>
                                {spouseInputFields.dateOfBirth && (new Date().getFullYear() - new Date(spouseInputFields.dateOfBirth).getFullYear()) >= 18 && (
                                    <Col>
                                        <Form.Group controlId="spouseIdIssueDate">
                                            <Form.Label>ID Issue Date</Form.Label>
                                            <Form.Control
                                                type="date"
                                                name="idIssueDate"
                                                value={formatDate(spouseInputFields.idIssueDate, true, false, "en-CA")}
                                                onChange={handleSpouseFormChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                )}
                                <Col>
                                    <Form.Group controlId="spousePhoneNumber">
                                        <Form.Label>Phone Number</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="phoneNumber"
                                            value={spouseInputFields.phoneNumber}
                                            onChange={handleSpouseFormChange}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Button
                                variant="danger"
                                className='mt-3'
                                onClick={handleDeleteSpouse}
                            >
                                Delete Spouse
                            </Button>
                        </>
                    )}
                    {!spouseInputFields.type && (
                        <Button className='mt-3' variant="primary" onClick={addSpouse}>
                            Add Spouse
                        </Button>
                    )}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="success" onClick={() => onSubmit(childrenInputFields, spouseInputFields)}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    );
};


export default FamilyMembersInputFields;