export enum FileClassificationsHebrew {
    oldIdentificationCard = 'תעודת זהות ישנה',
    identificationCardAttachment = 'ספח תעודת זהות',
    newIdentificationCardFront = 'תעודת זהות ביומטרית צד קדמי',
    newIdentificationCardBack = 'תעודת זהות ביומטרית צד אחורי',
    driversLicense = 'רישיון נהיגה',
    passport = 'דרכון',
    bankAccountId = 'תעודת זהות בנקאית',
    bankAccountOwnership = 'אישור ניהול חשבון',
    form867 = 'אישור ניכוי מס במקור (867)',
    powerOfAttorney = 'ייפוי כוח',
    brokerStocksPortfolio = 'הרכב תיק ניירות ערך',
    brokerCommissions = 'עמלות ברוקר',
    employerPensionAgreement = 'הסדר פנסיוני מהמעסיק',
    payslip = 'תלוש שכר',
    grantLetter = 'מכתב גראנט',
    specialChildSchoolApproval = 'אישור בית הספר לילד מיוחד',
    specialChildCommitteeApproval = 'אישור ועדת השמה לילד מיוחד',
    academicDegreeApproval = 'אישור תואר אקדמי',
    academicDegreeApprovalSpouse = 'אישור תואר אקדמי בן/בת זוג',
    donationReceipt = 'קבלה מתרומה',
    pensionProcessorFile = 'מסלקה',
    insuranceMountain = 'הר הביטוח',
    transferFund = 'מסמכי ניוד חתומים',
    b2Form = 'ב2',
    clientHanmaka = 'הנמקה',
    paymentHaltForm = 'הפסקת תשלומים',
    riskInsurance = 'ביטוח חיים',
    healthInsurance = 'ביטוח בריאות',
    elementaryInsurance = 'ביטוח אלמנטרי',
    newOrReturningImmigrant = 'תעודת עולה חדש',
    newOrReturningImmigrantSpouse = 'תעודת עולה חדש בן/בת זוג',
    militaryDischarge = 'תעודת שחרור מצה\"ל',
    militaryDischargeSpouse = 'תעודת שחרור מצה\"ל בן/בת זוג',
    medicalDocuments = 'מסמכים רפואיים',
    insuranceCompanyInformation = 'מידעים מחברות ביטוח',
    certificateOfDisability = 'תעודה נכה',
    childSupportVerdict = 'פסק דין מזונות',
    longTermHospitalization = 'אישורים על החזקת קרוב במוסד',
    pensionFundWithdrawal = 'אישור משיכת כספים מחברת ביטוח',
    powerOfAttorneyIRS = "יפוי כוח מס הכנסה",
    powerOfAttorneyNII = "יפוי כוח ביטוח לאומי",
    powerOfAttorneyNIIMerged = "יפוי כוח ביטוח לאומי - סופי",
    powerOfAttorneyInsuranceCompany = "יפוי כוח ביטוח",
    powerOfAttorneyInsuranceSpouse = "יפוי כוח ביטוח בן/בת זוג",
    longTermHospitalizationInstituteApproval = "אישורים על החזקת קרוב במוסד",
    longTermHospitalizationMonthlyPaymentApproval = "אישורים תשלום החזקת קרוב במוסד",
    abroadIncomeAnnualReport = "דוח שנתי על הכנסות בחו״ל",
    annualLifeInsuranceReport = "דו״ח שנתי מקוצר ביטוח חיים / אכ״ע",
    other = 'אחר',
    medicalInsurancePolicy = 'פוליסת בריאות',
    criticalIllnessInsurancePolicy = 'פוליסת מחלות',
    accidentInsurancePolicy = "פוליסת תאונות",
    longTermCareInsurancePolicy = "פוליסת סיעוד",
    lifeInsurancePolicy = "פוליסת חיים",
    mortgageInsurancePolicy = "פוליסת משכנתא",
    oldAccidentInsurancePolicy = "פוליסת תאונות ישנה",
    disabilityInsurancePolicy = "פוליסת נכות",
    workDisabilityInsurancePolicy = "פוליסת אובדן כושר עבודה",
    homeInsurancePolicy = "פוליסת ביטוח דירה",
    carInsurancePolicy = "פוליסת ביטוח רכב",
    individualInsurancePolicy = "פוליסת ביטוח פרט",
    mortgageRemainingPayments = "יתרות לסילוק משכנתא",
    cubesForm = "טופס קוביות",
    powerOfAttorneyMislaka = "יפוי כוח מיסלקה",
    transferMultipleProducts = "מסמכי פנסיה מלאים",
    newPolicy = 'טופס הצעה',
    healthAppendix = 'שאלון בריאות',
    otherPayer = 'משלם חריג',
    advisorySummary = 'סיכום שיחה',
    needsAlignment = 'התאמת צרכים',
    premiumTable = 'לוח התפתחות פרמיה',
    cancelPolicy = 'מסמך ביטול פוליסה',
    form106 = 'טופס 106',
    form106Spouse = 'טופס 106 בן/בת זוג',
    memorialExpenses = 'הוצאות הנצחה',
    nationalInsuranceBenefit = 'תקבולי ביטוח לאומי',
    nationalInsuranceBenefitSpouse = 'תקבולי ביטוח לאומי בן/בת זוג',
}


export interface ClientFile {
    _id: string,
    user: string,
    mimeType: string,
    publicId: string,
    storeType: string,
    fileClassifications: string[]
    link: string,
    thumbUrl?: string,
    largeThumbUrl?: string,
    fileName: string,
    createdAt: Date,
    sentToClientDate: string,
}


