import { useEffect, useState, useMemo } from 'react';

import { APICore } from '../utils/api/apiCore';
import { User } from '../interfaces/User';

const useUser = (): User|undefined => {
    const api = useMemo(() => new APICore(), []);

    const [user, setUser] = useState<User>();

    useEffect(() => {
        if (api.isUserAuthenticated()) {
            const {user} = api.getLoggedInUser();
            setUser(user);
        }
    }, [api]);

    return user;
};

export default useUser;
