import { APICore, API_URL, PUBLIC_API_URL, FILE_DATA_EXTRACTION_URL } from './apiCore';

// import {FileClassifications} from "../../interfaces/ClientFile";

const api = new APICore();

const getFiles = (params: { clientId?: string; ids?: string, token?: string, requiredFilesOnly?: boolean, section?: string }) => {
    const relativeUrl = '/files';
    return api.get(`${API_URL}${relativeUrl}`, { clientId: params.clientId, ids: params.ids, tokenStr: params.token, requiredFilesOnly: params.requiredFilesOnly, section: params.section });
}

const getEncryptedFileLink = (fileId: string) => {
    const relativeUrl = `/files/${fileId}/encrypted`;
    return api.get(`${API_URL}${relativeUrl}`);
}

const createUploadLink = (params: { clientId: string }) => {
    const relativeUrl = '/files/link';
    return api.create(`${API_URL}${relativeUrl}`, params);
}

const getTokenDetails = (token: string) => {
    const relativeUrl = '/public/link/' + token;
    return api.get(`${PUBLIC_API_URL}${relativeUrl}`);
}


const uploadFileWithToken = (params: { token: string, file: Blob }) => {
    const relativeUrl = '/files/upload/token/' + params.token;
    return api.createWithFile(`${PUBLIC_API_URL}${relativeUrl}`, { file: params.file });
}

const uploadFile = (params: { clientId: string, file: Blob, sendToUser?: boolean, pensionId?: string, taxReturnId?: string }) => {
    const relativeUrl = '/files/upload/client';
    return api.createWithFile(`${API_URL}${relativeUrl}`, params);
}


const deleteFile = (id: string) => {
    const relativeUrl = '/files/' + id;
    return api.delete(`${API_URL}${relativeUrl}`);
}

const editFile = (id: string, fileClassifications: string[]) => {
    const relativeUrl = '/files/' + id;
    return api.update(`${API_URL}${relativeUrl}`, { fileClassifications });
}

const sendFileToClient = (fileId: string) => {
    const relativeUrl = `/files/${fileId}/send-to-client`;
    return api.get(`${API_URL}${relativeUrl}`);
}

const getFileDataLabeling = (fileIds: string[]) => {
    const relativeUrl = '/extract';
    return api.get(`${FILE_DATA_EXTRACTION_URL}${relativeUrl}`, { fileIds });
}

const autoLabelFileData = (fileId: string) => {
    const relativeUrl = `/extract`;
    return api.create(`${FILE_DATA_EXTRACTION_URL}${relativeUrl}`, { fileId });
}

const editFileDataLabeling = (fileId: string, data: any, fileClassification?: string) => {
    const relativeUrl = `/extract/?fileId=${fileId}`;
    return api.update(`${FILE_DATA_EXTRACTION_URL}${relativeUrl}`, { data, fileClassification });
}

export {
    getTokenDetails,
    getFiles,
    uploadFileWithToken,
    createUploadLink,
    uploadFile,
    deleteFile,
    editFile,
    getEncryptedFileLink,
    sendFileToClient,
    getFileDataLabeling,
    autoLabelFileData,
    editFileDataLabeling
}