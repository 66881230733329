import React, { useState, InputHTMLAttributes, useEffect } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import classNames from 'classnames';

import { FieldErrors, Control } from 'react-hook-form';
import { Option } from "../interfaces/Option";

interface PasswordInputProps {
    name: string;
    placeholder?: string;
    refCallback?: any;
    errors: FieldErrors;
    control?: Control<any>;
    register?: any;
    className?: string;
    onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
}

/* Password Input */
const PasswordInput = ({
    name,
    placeholder,
    refCallback,
    errors,
    control,
    onChange,
    register,
    className,
}: PasswordInputProps) => {
    const [showPassword, setShowPassword] = useState<boolean>(false);

    return (
        <>
            <InputGroup className="mb-0">
                <Form.Control
                    type={showPassword ? 'text' : 'password'}
                    placeholder={placeholder}
                    name={name}
                    id={name}
                    as="input"
                    ref={(r: HTMLInputElement) => {
                        if (refCallback) refCallback(r);
                    }}
                    className={className}
                    isInvalid={errors && errors[name] !== undefined ? true : false}
                    {...(register ? register(name) : {})}
                    autoComplete={name}
                    onChange={onChange}
                />
                <div
                    className={classNames('input-group-text', 'input-group-password', {
                        'show-password': showPassword,
                    })}
                    data-password={showPassword ? 'true' : 'false'}
                >
                    <span
                        className="password-eye"
                        onClick={() => {
                            setShowPassword(!showPassword);
                        }}
                    ></span>
                </div>
            </InputGroup>
        </>
    );
};


interface FormInputProps extends InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    type?: string;
    name: string;
    placeholder?: string;
    register?: any;
    errors?: FieldErrors;
    control?: Control<any>;
    className?: string;
    labelClassName?: string;
    containerClass?: string;
    refCallback?: any;
    children?: any;
    rows?: string;
    options?: Option[],
    onSelected?: any,
    onInputChange?: any,
    hide?: boolean
    // onInputChange?: ()=>void
}

const FormInput = ({
    label,
    type,
    name,
    placeholder,
    register,
    errors,
    control,
    className,
    labelClassName,
    containerClass,
    refCallback,
    children,
    rows,
    options,
    onSelected,
    onInputChange,
    ...otherProps
}: FormInputProps) => {


    // handle input type
    const comp = type === 'textarea' ? 'textarea' : type === 'select' ? 'select' : 'input';

    const forceUpdate = React.useReducer(() => ({}), {})[1] as () => void;

    useEffect(() => {
        forceUpdate();
    }, [options]);

    // if (type === "date"){
    //     debugger
    // }
    return (
        <>
            {type === 'checkbox-list' ?
                <>
                    <Form.Group className={containerClass}>
                        {[0, 1, 2, 3].map(index =>
                            <Form.Check
                                key={index}
                                type={"checkbox"}
                                label={label}
                                name={`${name}.${index}`}
                                id={`${name}.${index}`}
                                ref={(r: HTMLInputElement) => {
                                    if (refCallback) refCallback(r);
                                }}
                                className={className}
                                isInvalid={errors && errors[name] !== undefined ? true : false}
                                {...(register ? register(name) : {})}
                                {...otherProps}
                            />)}


                        {errors && errors[name] ? (
                            <Form.Control.Feedback type="invalid">
                                {(errors[name] as any)['message'] as any}
                            </Form.Control.Feedback>
                        ) : null}
                    </Form.Group>
                </>
                : null}
            {type === 'hidden' ? (
                <input type={type} name={name} {...(register ? register(name) : {})} {...otherProps} />
            ) : (
                <>
                    {type === 'password' ? (
                        <>
                            <Form.Group className={containerClass}>
                                {label ? (
                                    <>
                                        {' '}
                                        <Form.Label className={labelClassName}>{label}</Form.Label> {children}{' '}
                                    </>
                                ) : null}
                                <PasswordInput
                                    name={name}
                                    placeholder={placeholder}
                                    refCallback={refCallback}
                                    errors={errors!}
                                    register={register}
                                    className={className}
                                    {...otherProps}
                                />

                                {errors && errors[name] ? (
                                    <Form.Control.Feedback type="invalid" className="d-block">
                                        {(errors[name] as any)['message'] as any}
                                    </Form.Control.Feedback>
                                ) : null}
                            </Form.Group>
                        </>
                    ) : type === 'select' ? (
                        <>
                            <Form.Group className={containerClass}>
                                <div className="form-label">{label}</div>

                                <Form.Select
                                    aria-label="Default select example"
                                    type={type}
                                    label={label}
                                    name={name}
                                    id={name}
                                    ref={(r: HTMLInputElement) => {
                                        if (refCallback) refCallback(r);
                                    }}
                                    onChange={onSelected}
                                    className={className}
                                    isInvalid={errors && errors[name] !== undefined ? true : false}
                                    {...(register ? register(name) : {})}
                                    {...otherProps}
                                >
                                    {options?.map((option, i) => <option key={i} value={option.value}>{option.label}</option>)}
                                </Form.Select>
                                {errors && errors[name] ? (
                                    <Form.Control.Feedback type="invalid">
                                        {(errors[name] as any)['message'] as any}
                                    </Form.Control.Feedback>
                                ) : null}
                            </Form.Group>
                        </>
                    ) : (
                        <>
                            {type === 'checkbox' || type === 'radio' ? (
                                <>
                                    <Form.Group className={containerClass}>
                                        <Form.Check
                                            type={type}
                                            label={label}
                                            name={name}
                                            id={name}
                                            ref={(r: HTMLInputElement) => {
                                                if (refCallback) refCallback(r);
                                            }}
                                            className={className}
                                            isInvalid={errors && errors[name] !== undefined ? true : false}
                                            {...(register ? register(name) : {})}
                                            {...otherProps}
                                        >
                                        </Form.Check>

                                        {errors && errors[name] ? (
                                            <Form.Control.Feedback type="invalid">
                                                {(errors[name] as any)['message'] as any}
                                            </Form.Control.Feedback>
                                        ) : null}
                                    </Form.Group>
                                </>
                            ) : type === 'date' ? (
                                <Form.Group className={containerClass}>
                                    {label ? (
                                        <>
                                            {' '}
                                            <Form.Label className={labelClassName}>{label}</Form.Label> {children}{' '}
                                        </>
                                    ) : null}
                                    <Form.Control
                                        type="date"
                                        name={name}
                                        id={name}
                                        ref={(r: HTMLInputElement) => {
                                            if (refCallback) refCallback(r);
                                        }}
                                        onChange={onInputChange}
                                        className={className}
                                        isInvalid={errors && errors[name] !== undefined ? true : false}
                                        {...(register ? register(name) : {})}
                                        {...otherProps}
                                    />
                                    {errors && errors[name] ? (
                                        <Form.Control.Feedback type="invalid" className="d-block">
                                            {(errors[name] as any)['message'] as any}
                                        </Form.Control.Feedback>
                                    ) : null}
                                </Form.Group>
                            ) : (
                                <Form.Group className={containerClass}>
                                    {label ? <Form.Label className={labelClassName}>{label}</Form.Label> : null}

                                    <Form.Control
                                        type={type}
                                        placeholder={placeholder}
                                        name={name}
                                        id={name}
                                        as={comp}
                                        ref={(r: HTMLInputElement) => {
                                            if (refCallback) refCallback(r);
                                        }}
                                        className={className}
                                        isInvalid={errors && errors[name] !== undefined ? true : false}
                                        {...(register ? register(name) : {})}
                                        rows={rows}
                                        {...otherProps}
                                        autoComplete={name}
                                    >
                                        {children ? children : null}
                                    </Form.Control>

                                    {errors && errors[name] ? (
                                        <Form.Control.Feedback type="invalid">
                                            {(errors[name] as any)['message'] as any}
                                        </Form.Control.Feedback>
                                    ) : null}
                                </Form.Group>
                            )}
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default FormInput;
