import { useContext, useState } from 'react'
import { UserProfileContext } from '../../../context/userProfileContext';
import { Insurance } from '../../../interfaces/Insurance';
import { Form } from 'react-bootstrap';
import { getClientFamilyMembers } from '../../../utils/getClientFamilyMembers';

const FamilyMemebrsToInsureCheckBoxes = () => {
  const { profileContextData, onEditRound } = useContext(UserProfileContext);
  const { client } = profileContextData;
  const [currentRound, setCurrentRound] = useState<Insurance>(profileContextData.currentRound as Insurance)

  const relevantFamilyMembersForInsurance = getClientFamilyMembers(client).filter(member => member.idNumber !== undefined);

  const handleCheckboxChange = (memberId: string) => {
    const newSelectedMembers = [...currentRound.familyMembersIdsToInsure];
    if (newSelectedMembers.includes(memberId)) {
      const index = newSelectedMembers.indexOf(memberId);
      newSelectedMembers.splice(index, 1);
    } else {
      newSelectedMembers.push(memberId);
    }
    onEditRound({ familyMembersIdsToInsure: newSelectedMembers }, 'insurance', currentRound._id as string, () => setCurrentRound({ ...currentRound, familyMembersIdsToInsure: newSelectedMembers }));
  };

  return (
    <div>
      <p>Select Family Members to Insure:</p>
      {relevantFamilyMembersForInsurance.length > 0 ? (
        relevantFamilyMembersForInsurance.map((member) => (
          <Form.Check
            key={member.idNumber} 
            type="checkbox"
            id={member.idNumber}
            label={`${member.firstName} ${member.lastName}`}
            data-label={`${member.firstName} ${member.lastName}`}
            checked={currentRound.familyMembersIdsToInsure?.includes(member.idNumber as string)}
            onChange={() => handleCheckboxChange(member.idNumber as string)}
            value={member.idNumber}
          />
        ))
      ) : (
        <p className='fw-normal'>No family members to insure</p>
      )}
    </div>
  );
};

export default FamilyMemebrsToInsureCheckBoxes