import React from 'react';

const BasicInfoColumn = (params: any) => {
  if (!params.value) return <></>;
  const MAX_WORDS_PER_LINE = 10; 
  const words = params.value.split(' '); 
  const lines = [];

  for (let i = 0; i < words.length; i += MAX_WORDS_PER_LINE) {
    const line = words.slice(i, i + MAX_WORDS_PER_LINE).join(' '); 
    lines.push(line);
  }

  return (
    <>
      {lines.map((line, index) => (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      ))}
    </>
  );
};

export default BasicInfoColumn;