import { useContext, useEffect, useState } from 'react'
import { UserProfileContext } from '../../../../context/userProfileContext';
import { InsurancePolicy } from '../../../../interfaces/InsurancePolicy';
import { InsuranceAppendix } from '../../../../interfaces/InsuranceAppendix';
import { Button, Form, Modal } from 'react-bootstrap';
import PolicyInputFields from './PolicyInputFields';
import AppendixInputFields from './AppendixInputFields';
import LaddaButton, { EXPAND_LEFT } from 'react-ladda-button';
import { initialInsuranceAppendixState, initialInsurancePolicyState } from './initialPolicyAndAppendixState';
import { addAdditionalInfoToAppendx, addAdditionalInfoToPolicy } from './appendixAndPolicyCreationFunctions';
import { Company } from '../../../../interfaces/Company';
import { getCompanies } from '../../../../utils/api/companies';


interface CreateNewInsuranceProductProps {
    show: boolean;
    onHide: () => void;
    productCreationHandler: (newInsurancePolicy: InsurancePolicy, newInsuranceAppendices: InsuranceAppendix[]) => void;
}

const CreateNewInsuranceProductModal = ({ show, onHide, productCreationHandler }: CreateNewInsuranceProductProps) => {
    const { profileContextData } = useContext(UserProfileContext);
    const { client } = profileContextData;

    const [newInsurancePolicy, setNewInsurancePolicy] = useState<InsurancePolicy>(initialInsurancePolicyState);
    const [newInsuranceAppendices, setNewInsuranceAppendices] = useState<InsuranceAppendix[]>([]);

    const [companyNames, setCompanyNames] = useState<string[]>([]);
    useEffect(() => {
        const getCompanyNames = async () => {
            const { companies } = await getCompanies({ type: 'insurance' });
            const allNames = companies.map((company: Company) => company.name);
            setCompanyNames(allNames);
        }
        getCompanyNames();
    }, []);

    const [isSaving, setIsSaving] = useState(false);

    const handleAddNewAppendix = () => {
        const initialInsuranceAppendix = { ...initialInsuranceAppendixState };
        setNewInsuranceAppendices([...newInsuranceAppendices, initialInsuranceAppendix]);
    }

    const handleRemoveAppendix = (indexToRemove: number) => {
        const updatedAppendices = newInsuranceAppendices.filter((_, index) => index !== indexToRemove);
        setNewInsuranceAppendices(updatedAppendices);
    };

    const handleAppendixInputChange = (newInsuranceAppendix: InsuranceAppendix, index: number) => {
        const newInsuranceAppendicesCopy = [...newInsuranceAppendices];
        newInsuranceAppendicesCopy[index] = { ...newInsuranceAppendix };
        setNewInsuranceAppendices(newInsuranceAppendicesCopy);
    };


    const handleFormSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        const finalPolicy = addAdditionalInfoToPolicy(newInsurancePolicy, client?._id ?? '', profileContextData.currentRound?._id ?? '');
        setNewInsurancePolicy(finalPolicy);
        const finalAppendices: InsuranceAppendix[] = []
        newInsuranceAppendices.forEach((appendix) => {
            const finalAppendix = addAdditionalInfoToAppendx(appendix, client?._id ?? '');
            finalAppendices.push(finalAppendix);
        })
        setNewInsuranceAppendices(finalAppendices);

        setIsSaving(true);

        try {
            await productCreationHandler(finalPolicy, finalAppendices);
            setIsSaving(false);
            onHide();
        } catch (error) {
            console.error('Error completing product creation:', error);
            setIsSaving(false);
        }
    }

    return (
        <Modal show={show} onHide={onHide} aria-labelledby="contained-modal-title-vcenter" centered onClick={(e: any) => e.stopPropagation()}>
            <Modal.Header className="bg-success" closeButton>
                <Modal.Title className="text-white">Create New Policy</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleFormSubmit}>
                    <fieldset>
                        <legend className='fw-bold'>Policy</legend>
                        <PolicyInputFields
                            newInsurancePolicy={newInsurancePolicy}
                            setNewInsurancePolicy={setNewInsurancePolicy}
                            companies={companyNames}
                        />
                    </fieldset>
                    {newInsuranceAppendices.map((newInsuranceAppendix, index) => (
                        <fieldset key={index} >
                            <hr />
                            <legend className="fw-bold d-flex justify-content-between">
                                Appendix {index + 1}
                                <Button className="btn btn-danger btn-sm"
                                    onClick={() => { handleRemoveAppendix(index) }}>
                                    Remove
                                </Button>
                            </legend>
                            <AppendixInputFields
                                isPolicyCreationPhase={true}
                                isAppendixEditingPhase={false}
                                newInsuranceAppendix={newInsuranceAppendix}
                                setNewInsuranceAppendix={(newInsuranceAppendix) => handleAppendixInputChange(newInsuranceAppendix, index)}
                                associatedPolicyState={newInsurancePolicy}
                            />
                        </fieldset>
                    ))}
                    <Button className='btn btn-light fs-5' onClick={handleAddNewAppendix}>
                        <i className="mdi mdi-plus-circle-outline mdi-18px"></i>
                        Add New Appendix
                    </Button>
                    <LaddaButton
                        type='submit'
                        loading={isSaving}
                        data-style={EXPAND_LEFT}
                        className={`right btn btn-success btn-lg w-${isSaving ? '50' : '25'} mt-4`}
                    // onClick={handleFormSubmit}
                    >
                        {isSaving ? 'Saving...' : 'Save'}
                    </LaddaButton>
                </Form>
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>
    )
}

export default CreateNewInsuranceProductModal





