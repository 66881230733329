import {APICore, API_URL} from './apiCore';
import {FileType} from "../../interfaces/FileType";

const api = new APICore();
// const API_URL = 'http://localhost:5000/api'


const getEmployers = (params: { page?: number, search:string }) => {
    const relativeUrl = '/employers';
    return api.get(`${API_URL}${relativeUrl}`, params);
}

const getEmployersAutocomplete = (text: string) => {
    const relativeUrl = '/employers/autocomplete';
    return api.get(`${API_URL}${relativeUrl}`,{text});
}



const getEmployersFile = (params: { search:string }) => {
    const relativeUrl = '/employers/download';
    return api.getFile(`${API_URL}${relativeUrl}`, params);
}

const createEmployer = (params: any, file?:FileType) => {
    const relativeUrl = '/employers';
    if (file)
        params.file = file;
    return api.createWithFile(`${API_URL}${relativeUrl}`, params);
}

const editEmployer = (id: string, params: any, file?: FileType) => {
    const relativeUrl = '/employers/'+id;
    if (file)
        params.file = file;

    if (file)
        return api.updateWithFile(`${API_URL}${relativeUrl}`, params);
    else
        return api.update(`${API_URL}${relativeUrl}`, params);
}

const deleteEmployer = (id: string ) => {
    const relativeUrl = '/employers/'+id;
    return api.delete(`${API_URL}${relativeUrl}`);
}



export {
    getEmployersAutocomplete,
    getEmployersFile,
    editEmployer,
    deleteEmployer,
    getEmployers,
    createEmployer
}