import {APICore, INSURANCE_URL} from './apiCore';
import { InsurancePolicy } from '../../interfaces/InsurancePolicy';


const api = new APICore();

export const getInsurancePolicies = (params: {clientId? : string}) => {
    const relativeUrl = '/policy';
    return api.get(`${INSURANCE_URL}${relativeUrl}`, params);
}

export const createInsurancePolicy = (newInsurancePolicy: InsurancePolicy) => {
    const relativeUrl = '/policy';
    return api.create(`${INSURANCE_URL}${relativeUrl}`, newInsurancePolicy);
}

export const editInsurancePolicy = (id: string, policy: any) => {
    const relativeUrl = '/policy/' + id;
    return api.update(`${INSURANCE_URL}${relativeUrl}`, policy);
}