import React from 'react';
import { Form } from 'react-bootstrap';
import {
    donationReceiptFormInfo,
    newIdentificationCardFrontFormInfo,
    oldIdentificationCardFormInfo,
    identificationCardAttachmentFormInfo,
    form106FormInfo,
    academicDegreeApprovalFormInfo,
    form867FormInfo,
    annualLifeInsuranceReportFormInfo,
    nationalInsuranceBenefitFormInfo,
    newOrReturningImmigrantFormInfo,
    militaryDischargeFormInfo,
    ValidFileClassificationDataForLabeling
} from '../../interfaces/ClientFileDataLabeling';
import { useTranslation } from 'react-i18next';

interface DynamicFormForDataLabelingGeneratorProps {
    data: NonNullable<ValidFileClassificationDataForLabeling>;
    setData: (data: NonNullable<ValidFileClassificationDataForLabeling>) => void;
    markedFields?: string[];
}

const formInfoMapping = {
    donationReceipt: donationReceiptFormInfo,
    newIdentificationCardFront: newIdentificationCardFrontFormInfo,
    newIdentificationCardFrontSpouse: newIdentificationCardFrontFormInfo,
    oldIdentificationCard: oldIdentificationCardFormInfo,
    oldIdentificationCardSpouse: oldIdentificationCardFormInfo,
    identificationCardAttachment: identificationCardAttachmentFormInfo,
    identificationCardAttachmentSpouse: identificationCardAttachmentFormInfo,
    form106: form106FormInfo,
    form106Spouse: form106FormInfo,
    academicDegreeApproval: academicDegreeApprovalFormInfo,
    academicDegreeApprovalSpouse: academicDegreeApprovalFormInfo,
    form867: form867FormInfo,
    annualLifeInsuranceReport: annualLifeInsuranceReportFormInfo,
    nationalInsuranceBenefit: nationalInsuranceBenefitFormInfo,
    nationalInsuranceBenefitSpouse: nationalInsuranceBenefitFormInfo,
    newOrReturningImmigrant: newOrReturningImmigrantFormInfo,
    newOrReturningImmigrantSpouse: newOrReturningImmigrantFormInfo,
    militaryDischarge: militaryDischargeFormInfo,
    militaryDischargeSpouse: militaryDischargeFormInfo,
};

const DynamicFormForDataLabelingGenerator = ({ data, setData, markedFields }: DynamicFormForDataLabelingGeneratorProps) => {
    type DataField = keyof typeof data;
    const { t } = useTranslation("files");
    const fileClassification = data.fileClassification;
    const formInfo = formInfoMapping[fileClassification as keyof typeof formInfoMapping];
    if (!formInfo) {
        console.error(`No form info found for file classification '${fileClassification}'`);
        return <>This file classification is not yet supported</>
    }

    const setNestedData = (obj: any, path: string, value: any) => {
        const keys = path.split('.');
        let current = obj;
        for (let i = 0; i < keys.length - 1; i++) {
            const key = keys[i];
            if (current[key] === undefined || current[key] === null) current[key] = {};
            current = current[key];
        }
        current[keys[keys.length - 1]] = value;
        return obj;
    };

    const getNestedData = (obj: any, path: string) => {
        const keys = path.split('.');
        let current = obj;
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            if (current[key] === undefined || current[key] === null) return undefined;
            current = current[key];
        }
        return current;
    }

    const handleChange = (e: any, key: string, dynamicContext?: { sectionKey: string; index: number }) => {
        let newValue: string | boolean;
        if (e.target.type === 'checkbox') {
            newValue = e.target.checked;
        } else {
            newValue = e.target.value;
        }

        if (dynamicContext) {
            const dynamicData = [...(data[dynamicContext.sectionKey as DataField] as any[])];
            const updatedDynamicData = setNestedData(
                dynamicData[dynamicContext.index],
                key,
                newValue
            );
            dynamicData[dynamicContext.index] = updatedDynamicData;
            setData({ ...data, [dynamicContext.sectionKey]: dynamicData });
        } else {
            const updatedData = setNestedData({ ...data }, key, newValue);
            setData(updatedData);
        }
    };


    const addDynamicEntry = (sectionKey: string) => {
        const updatedSection = [...(data[sectionKey as DataField] as any[] || []), {}];
        setData({ ...data, [sectionKey]: updatedSection });
    };

    const removeDynamicEntry = (sectionKey: string, index: number) => {
        const updatedSection = [...(data[sectionKey as DataField] as any[])];
        updatedSection.splice(index, 1);
        setData({ ...data, [sectionKey]: updatedSection });
    };

    const renderDynamicSection = (sectionKey: string, config: any) => {
        const dynamicData = data[sectionKey as DataField] as any[] || [];
        return (
            <div key={sectionKey}>
                <hr />
                <div className='d-flex justify-content-between align-items-center'>
                    <h5 className="mb-3">{t(config.label)}</h5>
                    <span className="btn p-0 me-2 mb-1 fs-4 text-success" onClick={() => addDynamicEntry(sectionKey)}><i className="mdi mdi-plus-circle-outline"></i></span>
                </div>
                {dynamicData.map((_, index: number) => (
                    <div key={index}>
                        <div className='mb-1 fw-bold d-flex justify-content-between align-items-center'>
                            <span>({index + 1})</span>
                            <button className="btn p-0 me-2 mt-1 fs-4 text-danger" onClick={() => removeDynamicEntry(sectionKey, index)}><i className="mdi mdi-delete-variant"></i></button>
                        </div>
                        {Object.entries(config.entryStructure).map(([fieldKey, fieldConfig]: [string, any]) =>
                            renderFormField(fieldKey, fieldConfig, { sectionKey, index })
                        )}
                    </div>
                ))}
                <hr />
            </div>
        );
    };

    const renderFormField = (key: string, config: any, dynamicContext?: { sectionKey: string; index: number }) => {
        const actualKey = dynamicContext ? `${dynamicContext.sectionKey}.${dynamicContext.index}.${key}` : key;
        let label = t(config.label);
        let value;
        if (dynamicContext) {
            const sectionData = data[dynamicContext.sectionKey as DataField];
            if (sectionData && Array.isArray(sectionData)) {
                const element = sectionData[dynamicContext.index];
                value = element && getNestedData(element, key);
            } else {
                console.error(`Unexpected data structure for section '${dynamicContext.sectionKey}'`);
                value = undefined;
            }
        } else {
            value = getNestedData(data, key);
        }

        const markedField = markedFields?.includes(actualKey);

        switch (config.type) {
            case 'text':
            case 'number':
                return (
                    <Form.Group className="mb-3" key={actualKey}>
                        <Form.Label>{label}</Form.Label>
                        <Form.Control
                            type={config.type}
                            value={value}
                            onChange={(e) => handleChange(e, key as DataField, dynamicContext)}
                            {...(config.AdditionalProps || {})}
                            isInvalid={markedField}
                        />
                    </Form.Group>
                );
            case 'date':
                return (
                    <Form.Group className="mb-3" key={actualKey}>
                        <Form.Label>{label}</Form.Label>
                        <Form.Control
                            type="date"
                            value={value?.toString().split('T')[0]}
                            onChange={(e) => handleChange(e, key as DataField, dynamicContext)}
                            {...(config.AdditionalProps || {})}
                            isInvalid={markedField}
                        />
                    </Form.Group>
                );
            case 'checkbox':
                return (
                    <Form.Group className="mb-3" key={actualKey}>
                        <Form.Check
                            type="checkbox"
                            label={label}
                            checked={value}
                            onChange={(e) => handleChange(e, key as DataField, dynamicContext)}
                            {...(config.AdditionalProps || {})}
                            isInvalid={markedField}
                        />
                    </Form.Group>
                );
            case 'select':
                return (
                    <Form.Group className="mb-3" key={actualKey}>
                        <Form.Label>{label}</Form.Label>
                        <Form.Select
                            value={value ?? ""}
                            onChange={(e) => handleChange(e, key as DataField, dynamicContext)}
                            {...(config.AdditionalProps || {})}
                            isInvalid={markedField}
                        >
                            <option value="" disabled>{config.placeholder || ''}</option>
                            {config.options.map((option: { value: string, label: string }) => (
                                <option key={option.value} value={option.value}>{t(option.label)}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                );
            case 'dynamic':
                return renderDynamicSection(key, config);
            default:
                return null;
        }
    }

    return <>{Object.entries(formInfo).map(([key, config]) => renderFormField(key, config))}</>
}

export default DynamicFormForDataLabelingGenerator;

