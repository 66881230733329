import { FormEvent, useState } from 'react';
import { Modal, Form } from 'react-bootstrap'; // Import Form from react-bootstrap
import AppendixInputFields from './AppendixInputFields';
import { InsuranceAppendix, missingFields } from '../../../../interfaces/InsuranceAppendix';
import { camelCaseToText } from '../../../../utils/camelCaseToText';
import LaddaButton, { EXPAND_LEFT } from 'react-ladda-button';

interface CreateNewAppendixModalProps {
    show: boolean;
    onHide: () => void;
    onSubmit: (newInsuranceAppendix: InsuranceAppendix, otherProps?: any) => Promise<InsuranceAppendix>
    currentAppendixState: InsuranceAppendix
    isAppendixEditingPhase?: boolean
    isAppendixSuggestionsPhase?: boolean
}

export const CreateOrEditAppendixModal = ({ show, onHide, onSubmit, currentAppendixState, isAppendixEditingPhase = false, isAppendixSuggestionsPhase = false }: CreateNewAppendixModalProps) => {
    const [newInsuranceAppendix, setNewInsuranceAppendix] = useState<InsuranceAppendix>(currentAppendixState);
    const [isSaving, setIsSaving] = useState(false);

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const missing = missingFields(newInsuranceAppendix);
        if (missing.length > 0) {
            alert(`Missing fields: ${missing.map(camelCaseToText).join(', ')}`);
            return;
        }
        
        setIsSaving(true);

        try {
            const savedInsuranceAppendix = await onSubmit(newInsuranceAppendix);
            setNewInsuranceAppendix(isAppendixEditingPhase ? savedInsuranceAppendix : currentAppendixState);
            setIsSaving(false);
            onHide();
        }
        catch (error) {
            setIsSaving(false);
            console.log(error);
        }
    };

    const styling = {
        headerColor: isAppendixEditingPhase ? 'bg-primary' : 'bg-success',
        headerTextColorClass: 'text-white', 
        buttonText: isSaving ? (isAppendixEditingPhase ? 'Saving Changes...' : 'Saving...') : (isAppendixEditingPhase ? 'Save Changes' : 'Save'),
        buttonColor: isAppendixEditingPhase ? 'primary' : 'success',
        buttonWidth: isSaving ? (isAppendixEditingPhase ? '50' : '50') : (isAppendixEditingPhase ? '40' : '25')
    }

    return (
        <Modal show={show} onHide={onHide} onClick={(e: any) => e.stopPropagation()}>
            <Modal.Header className={styling.headerColor} closeButton>
                <Modal.Title className={styling.headerTextColorClass}>{!isAppendixEditingPhase ? "Create New Appendix" : "Edit Appendix"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <AppendixInputFields
                        isPolicyCreationPhase={false}
                        isAppendixEditingPhase={false}
                        isAppendixSuggestionsPhase={isAppendixSuggestionsPhase}
                        newInsuranceAppendix={newInsuranceAppendix}
                        setNewInsuranceAppendix={setNewInsuranceAppendix}
                    />
                    <LaddaButton
                        type='submit'
                        loading={isSaving}
                        data-style={EXPAND_LEFT}
                        className={`right btn btn-${styling.buttonColor} btn-lg w-${styling.buttonWidth} mt-4`}
                    >
                        {styling.buttonText}
                    </LaddaButton>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default CreateOrEditAppendixModal;
