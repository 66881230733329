import { Navigate, useLocation } from "react-router-dom";
import { APICore } from "../utils/api/apiCore";

const PublicRoute = ({ children }: { children: JSX.Element }) => {
    const api = new APICore();
    const location = useLocation();
    if (!api.isUserAuthenticated()) {
        // Redirect to login
        return <Navigate to='/auth/login' state={{ from: location.pathname }} replace={true} />;
    }
    return children;
}

export default PublicRoute;