import React from 'react'
import { sendCubeToEmployer } from '../utils/api/cubes'
import { Button } from 'react-bootstrap'

import { useTranslation } from 'react-i18next'
interface SendCubesToEmployerButtonProps {
    pensionId: string,
    isDisabled: boolean
    text: string
}
const SendCubesToEmployerButton = ({ pensionId, isDisabled, text }: SendCubesToEmployerButtonProps) => {
    const { t } = useTranslation('cubes')
    const handleClick = async () => {
        try {
            const shouldSend = window.confirm(t('Are you sure you want to send the cubes to the employer?'));
            if (!shouldSend) {
                return;
            }

            await sendCubeToEmployer(pensionId);

            alert(t('Successfully sent cubes to employer'));
        }
        catch (error: any) {
            console.log(error)
            alert(error.json.message || 'Error occured. Please check console for more details.')
        }
    }
    return (
        <Button onClick={handleClick} disabled={isDisabled}>
            <i className="mdi mdi-email"></i>
            {" "}
            {t(text)}
        </Button>
    )
}

export default SendCubesToEmployerButton