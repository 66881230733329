import { useContext, useEffect, useState } from 'react';
import { chatContext } from '../../context/chatContext';
import { Chat } from '../../interfaces/Chat';
import { getChats } from '../../utils/api/chat';
import ChatList from './ChatList';
import ChatListActions from './ChatListActions';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useChatAndTaskCountContext } from '../../context/ChatAndTaskCountContext';

const ChatsListWrapper = () => {

    const [chatList, setChatList] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [hasMore, setHasMore] = useState<boolean>(true);

    const { chatData, setChatData, filters, sortBy, page, setPage, shouldUpdateChatsList, setShouldUpdateChatsList } = useContext(chatContext);
    const { unreadCountNotPinned, unreadCountPinned, lastMessageTime } = useChatAndTaskCountContext();

    const getChatsList = async (allowFilters: boolean = true, renderLoading: boolean = true) => {
        renderLoading && setLoading(true);
        let result: any;

        try {

            if (!allowFilters) {
                result = await getChats({ page: 0 });
            } else {
                result = await getChats({ ...filters, ...sortBy, page });

            }

            if (page === 0) {
                setChatList(result.chats);
            } else {
                if (result.chats.length === 0) {
                    setHasMore(false);
                    if (!page) { // if page is null/undefined
                        setChatList(result.chats);
                    }
                } else {
                    // Concat old list with the new fetched list for infinite-scroll:
                    setChatList(currentList => [...currentList, ...result.chats]);
                }
            }
        } catch (error) {
            console.log(error);
        }
        finally {
            renderLoading && setLoading(false);
        }
    }

    useEffect(() => {
        if (shouldUpdateChatsList) {
            getChatsList(true, false);
            setShouldUpdateChatsList(false);
        }
    }, [shouldUpdateChatsList])

    useEffect(() => {
        if (chatData.selectedChat) {
            const updatedChat = chatList.find((chat: Chat) => chat._id === chatData.selectedChat._id)

            setChatData((prev: any) => ({
                ...prev,
                selectedUnreadCount: updatedChat?.unreadCount || 0
            }));
        }
    }, [chatList])

    useEffect(() => {
        // Every time unreadCount change (due to socket ping),
        // regenerate chat list to update status of unread on chats display.
        // If currently in 'Join-Mode', don't interrupt.
        setPage(0);
    }, [unreadCountNotPinned, unreadCountPinned, lastMessageTime]);

    useEffect(() => {
        if (filters || sortBy) {
            setHasMore(true);
        }

        getChatsList(true);
    }, [filters, sortBy, page]);

    return (
        <section className='chat-list-wrapper'>
            <ChatListActions />
            {!page && loading ?
                <div className='chat-skeleton-container'>
                    {Array(10) // Assuming you want to display 10 skeletons
                        .fill(0)
                        .map((_,) => (
                            <div className='chat-skeleton-container'>
                                <div className="chat-skeleton-item">
                                    <Skeleton className="avatar-placeholder" circle={true} height={40} width={40} />

                                    <div className="text-placeholder">
                                        <Skeleton className="first-line" />
                                        <Skeleton className="second-line" />
                                    </div>
                                </div>
                                <hr />
                            </div>
                        ))}
                </div> :
                <>{(chatList?.length > 0) ?
                    <ChatList hasMore={hasMore} chatList={chatList}
                        setChatList={setChatList} />
                    : <div className='no-chats-found'>The chat list is empty</div>
                }</>
            }
        </section>
    )
}

export default ChatsListWrapper