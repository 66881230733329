import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import TextField from '@mui/material/TextField';
import { Follower } from '../../interfaces/Follower';
import { Client } from '../../interfaces/Client';

interface FollowersDropDownProps {
    followers: Follower[];
    setFollowers: (followers: Follower[]) => void;
    client: Client;
    followClient: (action: string, clientId: string, followerId: string) => void;
}

const FollowersDropDown = ({ followers, setFollowers, client, followClient }: FollowersDropDownProps) => {

    const [searchTerm, setSearchTerm] = useState('');

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const getDropdownItem = (follower: Follower, key: number) => {
        const getBadge = () => {
            if (follower.isActiveRep)
                return <span className="badge bg-info" key={key}>Active</span>;
            else if (follower.isInvitee)
                return <span className="badge bg-success" key={key}>Invited</span>;
            else if (follower.isWatcher)
                return <span className="badge bg-success" key={key}>Following</span>;
            else
                return <span className="badge bg-add" key={key}>Add</span>;
        }

        return <Dropdown.Item key={follower.user._id} onClick={() => followUnFollow(follower)}>
            {getBadge()}
            {follower.user.fullName}{' '}
        </Dropdown.Item>
    }

    const followUnFollow = async (follower: Follower) => {
        setSearchTerm('');
        let follow;
        if (follower.isActiveRep)
            return;
        else if (follower.isInvitee)
            follow = false;
        else if (follower.isWatcher)
            follow = false;
        else
            follow = true;

        if (!follow) {
            if (follower.isInvitee)
                follower.isInvitee = false;
            if (follower.isWatcher)
                follower.isWatcher = false;
        } else {
            follower.isInvitee = true;
        }

        const list = [...followers];
        list[followers.indexOf(follower)] = follower;
        setFollowers(list);

        try {
            await followClient(follow ? 'follow' : 'unFollow', client._id, follower.user._id);
        } catch (error) {
            console.log(error)
            alert("Failed to add user");
        }
    }
    const getNumOfFollowers = () => followers.filter(f => f.isActiveRep || f.isInvitee || f.isWatcher).length;

    const filteredFollowers = followers.filter((follower) =>
        follower.user.fullName?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="followers">
            <Dropdown className="select-action" align={'end'}>
                <Dropdown.Toggle as="a" className="btn-sm card-drop cursor-pointer">
                    <span>
                        {getNumOfFollowers()} Follower{getNumOfFollowers() > 1 ? 's' : ''}
                        <i className="mdi mdi-chevron-down font-40"></i>
                    </span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <TextField
                        label="Search Followers"
                        onChange={handleSearchChange}
                        value={searchTerm}
                        style={{ width: '100%', marginBottom: '20px' }}
                    />
                    <div style={{ maxHeight: '600px', overflowY: 'auto' }}>
                        {filteredFollowers.map((follower, index) =>
                            getDropdownItem(follower, index)
                        )}
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
};

export default FollowersDropDown;
