import React from 'react';
import './../tax-return-complete-form.scss';
import { useTranslation } from 'react-i18next';

interface TabsContainerProps {
  tabs: (string | number)[];
  activeTab: string | number;
  handleTabClick: (tab: string | number) => void;
}

const TabsContainer = ({ tabs, activeTab, handleTabClick }: TabsContainerProps) => {
  const { t  } = useTranslation("taxReturn");
  return (
    <ul className="tabs-container">
      {tabs.map((tab, index) => (
        <li
          className={`tab btn btn-primary ${activeTab === tab ? 'active-tab' : ''}`}
          key={index}
          onClick={() => handleTabClick(tab)}
        >
          {t(tab as string)}
        </li>
      ))}
    </ul>
  );
};

export default TabsContainer;