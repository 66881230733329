const Modules = {
    onboarding: 'onboarding',
    pension: 'pension',
    taxReturn: 'taxReturn',
    deposit: "deposit",
    loans: "loan",
    insurance: "insurance",
    mortgage: "mortgage",
    bankFees: "bankFees",
    investment: "investment",
}

export default Modules;